import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInputValidate from "components/CustomInput/CustomInputValidate.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDialog from "components/CustomDialog/CustomDialog";
import CustomInputStates from "components/CustomInput/CustomInputStates.jsx";
import { returnSubtype } from "../Contacts/ContactsLogic";
import { contactSubTypeDecider } from "./CompaniesLogic";
// import ContactW9Upload from "./ContactW9Upload";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FileUploader from "../../../components/FileUploader/FileUploader";
import companyFields from "./CompaniesObj";
import {
    min1,
    min6,
    min4,
    min9,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max20,
    max45,
    max50,
    max100,
    max125,
    max250,
    max500,
    valid_url
} from "shared/validation";
import Person from "@material-ui/icons/Person";
import Button from "../../../components/CustomButtons/Button";

const validate = {
    min1,
    min6,
    min4,
    min9,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max20,
    max45,
    max50,
    max100,
    max125,
    max250,
    max500,
};

const getValidations = (f) => {
    
    return companyFields[f].validations.map((v) => validate[v]);
};

const ContactsFormGeneral = (props) => {
    const [openedModal, setOpenedModal] = useState(false);
    const [showEntityTypeInput, setShowEntityTypeInput] = useState(false);

    const contactTypes = props.dataObj.contact_type
        ? props.dataObj.contact_type.split(",")
        : [];
    const contactSubtypes = props.dataObj.contact_subtype
        ? props.dataObj.contact_subtype.split(",")
        : [];

    const vendorOptions = props.contactOptions
        ? props.contactOptions.vendorOptions
        : [];

    const number_edit_allowed = props.crmAllowed ?
        props.crmAllowed.hasOwnProperty('number_edit') ?
            true
            :
            false
        :
        false;

    const renderInput = (field, showLink) => {
        const val = props.dataObj[field];
        let validURL = false;
        if (showLink) {
            validURL = valid_url(val);
        }
        return <div>
            <CustomInputValidate
                label={companyFields[field].label}
                value={props.dataObj[field]}
                onChange={(e) => {
                    props.updateDataObj(field, e.target.value);
                }}
                validate={getValidations(field)}
                readonly={props.action === "View" ? true : false}
            />
            <div>
                {(val && (validURL && showLink)) && <a href={val} 
                    style={{textDecoration:'underline',fontSize:9,opacity: (showLink && val) ? 1 : 0}} 
                    target="_blank"
                >Open Link</a>}
                {(val && (!validURL && showLink)) && <span 
                    className="red-text"
                    style={{fontSize:9}}
                >Invalid URL</span>}
            </div>
        </div>
    }

    return (
        <div className={props.action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Main Info</h4>
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="Company Name"
                        value={props.dataObj.name}
                        onChange={(e) => { props.updateDataObj('name', e.target.value) }}
                        validate={getValidations('name')}
                        readonly={props.action === 'View' ? true : false}
                        autoFocus={true}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="Company Email"
                        value={props.dataObj.email}
                        onChange={(e) => { props.updateDataObj('email', e.target.value) }}
                        validate={getValidations('email')}
                        readonly={props.action === 'View' ? true : false}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="Website URL"
                        value={props.dataObj.website_url}
                        onChange={(e) => { props.updateDataObj('website_url', e.target.value) }}
                        validate={getValidations('website_url')}
                        readonly={props.action === 'View' ? true : false}
                    />
                </GridItem>

                <GridItem xs={12} sm={3} md={3}>
                    <CustomInputValidate
                        label="Use Type"
                        value={props.dataObj.use_type}
                        onChange={(e) => { }}
                        validate={getValidations('department')}
                        readonly={props.action === 'View' ? true : false}
                        onClick={() => {
                            setOpenedModal(true);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <CustomInputValidate
                        label="Tax ID Number"
                        value={props.dataObj.tin}
                        onChange={(e) => { props.updateDataObj('tin', e.target.value) }}
                        validate={getValidations('tin')}
                        readonly={props.action === 'View' ? true : false}
                    />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {!showEntityTypeInput && <CustomSelect
                        label="Entity Type"
                        options={[
                            'DBA', 'Corporation', 'LLC', 'Partnership', 'Other'
                        ]}
                        choose={(e, n) => {
                            if (e === 'Other') {
                                props.updateDataObj("entity_type", '');
                                setShowEntityTypeInput(true);
                            } else {
                                props.updateDataObj("entity_type", e);
                            }
                        }}
                        default={props.dataObj.entity_type}
                    />}
                    {showEntityTypeInput && <CustomInputValidate
                        label="Enter Other Entity Type"
                        value={props.dataObj.entity_type}
                        onChange={(e) => { props.updateDataObj('entity_type', e.target.value) }}
                        validate={getValidations('entity_type')}
                        readonly={props.action === 'View' ? true : false}
                    />}
                    {showEntityTypeInput && <Button color="rose" size="sm"
                        onClick={() => {
                            props.updateDataObj("entity_type", '');
                            setShowEntityTypeInput(false);
                        }}
                    >Select Entity Type</Button>}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {<CustomInputStates 
                        onChange={(value) => {
                            props.updateDataObj('state_entity_established_in', value)
                        }}
                        value={props.dataObj.state_entity_established_in}
                        label="State Entity Established In"
                    />}
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <h4 className="bold">Phone Numbers</h4>
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="Main Phone"
                        value={props.dataObj.main_phone}
                        onChange={(e) => {
                            props.updateDataObj("main_phone", e.target.value);
                        }}
                        validate={getValidations("main_phone")}
                        readonly={props.action === "View" ? true : false}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="Other Phone"
                        value={props.dataObj.other_phone}
                        onChange={(e) => {
                            props.updateDataObj("other_phone", e.target.value);
                        }}
                        validate={getValidations("other_phone")}
                        readonly={props.action === "View" ? true : false}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="Fax"
                        value={props.dataObj.fax}
                        onChange={(e) => {
                            props.updateDataObj("fax", e.target.value);
                        }}
                        validate={getValidations("fax")}
                        readonly={props.action === "View" ? true : false}
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<hr style={{marginTop:40}} />
					<h4 className="bold">Social Media</h4>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
                    {renderInput('twitter', 'link')}
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					{renderInput('facebook', 'link')}
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					{renderInput('linkedin', 'link')}
				</GridItem>
                <GridItem xs={12} sm={4} md={3}>
					{renderInput('instagram', 'link')}
				</GridItem>
			</GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={9} md={9}>
                    <hr />
                    <h4 className="bold">Company Type</h4>
                    <small>(Select all that apply)</small>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}></GridItem>

                {/* PROPERTY OWNER */}
                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf("Property Owner") !== -1}
                                    onChange={() => {
                                        const v =
                                            contactTypes.indexOf("Property Owner") !== -1
                                                ? contactTypes.filter((c) => {
                                                    if (c === "Property Owner") return false;
                                                    return true;
                                                })
                                                : contactTypes.concat(["Property Owner"]);
                                        props.updateDataObj("contact_type", v.join(","));
                                    }}
                                    color="default"
                                />
                            }
                            label="Property Owner"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf("Property Owner") !== -1 && (
                        <CustomSelect
                            label="Property Owner Subtype"
                            options={contactSubTypeDecider("Property Owner", vendorOptions)}
                            choose={(e, n) => {
                                let subtypes = props.dataObj.contact_subtype
                                    ? props.dataObj.contact_subtype.split(",")
                                    : [];
                                if (subtypes.indexOf(e) === -1) {
                                    subtypes.push(e);
                                }
                                props.updateDataObj("contact_subtype", subtypes.join(","));
                            }}
                            default={
                                props.dataObj.contact_subtype
                                    ? returnSubtype(
                                        props.dataObj.contact_subtype,
                                        "Property Owner"
                                    )
                                    : ""
                            }
                        />
                    )}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Buyer') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Buyer') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Buyer') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Buyer']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Buyer"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Lessee') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Lessee') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Lessee') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Lessee']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Lessee"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Lessee') !== -1 && <CustomSelect
                        label="Lessee Subtype"
                        options={contactSubTypeDecider('Lessee', vendorOptions)}
                        choose={(e, n) => {
                            let subtypes = props.dataObj.contact_subtype ? props.dataObj.contact_subtype.split(',') : [];
                            if (subtypes.indexOf(e) === -1) {
                                subtypes.push(e);
                            }
                            props.updateDataObj('contact_subtype', subtypes.join(','));
                        }}
                        default={props.dataObj.contact_subtype ? returnSubtype(props.dataObj.contact_subtype, 'Lessee') : ''}
                    />}
                    {contactTypes.indexOf('Lessee') !== -1 && <CustomSelect
                        label="Exclusive Right to Represent Buyer ?"
                        options={['','No','Yes']}
                        choose={(e, n) => {
                            props.updateDataObj('exclusive_right_to_represent_buyer', e);
                        }}
                        default={props.dataObj.exclusive_right_to_represent_buyer}
                    />}
                    {(contactTypes.indexOf('Lessee') !== -1 && props.dataObj.exclusive_right_to_represent_buyer === 'Yes') && <div className="boxed">
                        <FileUploader 
                            title="AIR Exclusive Right to Represent Buyer/Lessee"
                            update={(val) => {
                                props.updateDataObj('air_exclusive_right_to_represent_buyer', val);
                            }}
                            remove={() => {
                                props.updateDataObj('air_exclusive_right_to_represent_buyer', '');
                            }}
                            the_filename={props.dataObj.air_exclusive_right_to_represent_buyer}
                            type="crm"
                        />
                    </div>}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Vendor') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Vendor') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Vendor') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Vendor']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Vendor"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Vendor') !== -1 && <CustomSelect
                        label="Vendor Subtype"
                        options={contactSubTypeDecider('Vendor', vendorOptions)}
                        choose={(e, n) => {
                            let subtypes = props.dataObj.contact_subtype ? props.dataObj.contact_subtype.split(',') : [];
                            if (subtypes.indexOf(e) === -1) {
                                subtypes.push(e);
                            }
                            props.updateDataObj('contact_subtype', subtypes.join(','));
                        }}
                        default={props.dataObj.contact_subtype ? returnSubtype(props.dataObj.contact_subtype, 'Vendor') : ''}
                    />}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Property Manager') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Property Manager') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Property Manager') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Property Manager']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Property Manager"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>
                
                {/* Removed per wrike task CRM Updates 11-13-23 */}
                {/* {<GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Real Estate Agent') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Real Estate Agent') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Real Estate Agent') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Real Estate Agent']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Real Estate Agent"
                        />
                    </FormGroup>
                </GridItem>} */}
                {/* <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Real Estate Agent') !== -1 && <CustomSelect
                        label="Real Estate Agent Subtype"
                        options={contactSubTypeDecider('Real Estate Agent', vendorOptions)}
                        choose={(e, n) => {
                            let subtypes = props.dataObj.contact_subtype ? props.dataObj.contact_subtype.split(',') : [];
                            if (subtypes.indexOf(e) === -1) {
                                subtypes.push(e);
                            }
                            props.updateDataObj('contact_subtype', subtypes.join(','));
                        }}
                        default={props.dataObj.contact_subtype ? returnSubtype(props.dataObj.contact_subtype, 'Real Estate Agent') : ''}
                    />}
                </GridItem> */}
                {/* <GridItem xs={12} sm={6} md={6}>
                    {(contactTypes.indexOf('Real Estate Agent') !== -1) && <CustomInputValidate
                        label="DRE#"
                        value={props.dataObj.dre}
                        onChange={(e) => {
                            props.updateDataObj("dre", e.target.value);
                        }}
                        validate={getValidations('dre')}
                        readonly={props.action === "View" ? true : false}
                    />}
                </GridItem> */}


                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Real Estate Broker') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Real Estate Broker') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Real Estate Broker') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Real Estate Broker']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Real Estate Brokerage"
                        />
                    </FormGroup>
                </GridItem>

                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Real Estate Broker') !== -1 && <CustomSelect
                        label="Real Estate Brokerage Subtype"
                        options={contactSubTypeDecider('Real Estate Broker', vendorOptions)}
                        choose={(e, n) => {
                            let subtypes = props.dataObj.contact_subtype ? props.dataObj.contact_subtype.split(',') : [];
                            if (subtypes.indexOf(e) === -1) {
                                subtypes.push(e);
                            }
                            props.updateDataObj('contact_subtype', subtypes.join(','));
                        }}
                        default={props.dataObj.contact_subtype ? returnSubtype(props.dataObj.contact_subtype, 'Real Estate Broker') : ''}
                    />}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {(contactTypes.indexOf('Real Estate Broker') !== -1) && <CustomInputValidate
                        label="DRE#"
                        value={props.dataObj.dre_broker}
                        onChange={(e) => {
                            props.updateDataObj("dre_broker", e.target.value);
                        }}
                        validate={getValidations('dre_broker')}
                        readonly={props.action === "View" ? true : false}
                    />}
                </GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Other') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Other') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Other') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Other']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Other"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Other') !== -1 && <CustomInputValidate
                        label="Specify Other Contact Type"
                        value={props.dataObj.contact_type_other}
                        // validate={getValidations('contact_type_other')}
                        onChange={(e) => { props.updateDataObj('contact_type_other', e.target.value) }}
                    />}
                </GridItem>

                <GridItem xs={12} sm={12} md={12}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Escrow') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Escrow') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Escrow') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Escrow']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Escrow"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}><hr /></GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <div className="boxed">
                        <FileUploader 
                            title="Upload W9"
                            update={(val) => {
                                props.updateDataObj('w9', val);
                            }}
                            remove={() => {
                                props.updateDataObj('w9', '');
                            }}
                            the_filename={props.dataObj.w9}
                            type="crm"
                        />
                    </div>
                </GridItem>
                {/* ['Property Owner', 'Buyer', 'Tenant', 'Vendor',  */}
                {/* 'Property Manager', 'Real Estate Agent', 'Real Estate Broker','Other'] */}

                <GridItem xs={12} sm={6} md={6}>
                    {/* <CustomSelect
                        label="Contact Sub-Type"
                        default={props.dataObj.contact_subtype ? props.dataObj.contact_subtype : ''}
                        options={contactSubTypeDecider(contactTypes, vendorOptions)}
                        choose={(v) => {
                            props.updateDataObj('contact_subtype', v);
                        }}
                    /> */}
                </GridItem>

            </GridContainer>

            {openedModal && (
                <CustomDialog
                    title='Select Use Type'
                    label='Filter Use Type'
                    icon={<Person />}
                    open={openedModal}
                    close={() => {
                        setOpenedModal(false);
                    }}
                    choose={(choice) => {
                        props.updateDataObj('use_type', choice);
                        setOpenedModal(false);
                    }}
                    options={props.contactOptions ? props.contactOptions.useTypeOptions : []}
                />
            )}
        </div>
    );
};

export default ContactsFormGeneral;
