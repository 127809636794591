import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { clone, get } from "lodash";

import { getDateDiffs, formatDollar } from "../../../shared/utility";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInputValidate";

import {
    addTermRangesError,
    calculateEstimatedAbatedRanges,
    calculateEstimatedAbatedYearRanges,
    calculateEstimatedTermsTotal,
    calculateYearAbatedRanges,
    isEstimatedLease,
    isFlatFeeCommissionEnabled,
    calculateAbatedRanges_RS as calculateAbatedRanges,
    calculateStartingRent,
    makeAllRanges,
	newYearlyCalculation,
} from "../RSheetsLogic";

import termLogic from "../CommonCustomElements/Term/TermLogicMain";
import { Step3RentTotals } from "../CommonCustomElements/Term/TermLogicMain";
import TermEstimatedMain from "../CommonCustomElements/Term/Estimated/TermEstimatedMain";

const Step3 = (props) => {
    const { renderElement, data, setData, setOpenedCompanyModal, updateDataObj } = props;
    const [calculatedAbatedRanges, setCalculatedAbatedRanges] = useState(null);

    const isEstimated = isEstimatedLease(data);
    const shouldShowRanges = !isFlatFeeCommissionEnabled(data, true);
    const hideStartingRentPerSqft = get(data, 'flat_fee_space', '') === 'Yes';

    useEffect(() => {
        const calc = calculateAbatedRanges({
            lease_term: data.lease_term,
            abated: data.abated_rent_ranges,
        });
        setCalculatedAbatedRanges(calc);
    }, [data.lease_term, data.abated_rent_ranges]);

    const prev_lease_length = usePrevious(data.lease_length);
    useEffect(() => {
        const newData = clone(data);
        if (isEstimated) {
            if (data.lease_length) {
                if (prev_lease_length !== undefined && prev_lease_length !== data.lease_length) {
                    let numOfYears = parseInt(parseInt(data.lease_length) / 12);
                    if (parseInt(data.lease_length) % 12 > 0) {
                        numOfYears += 1;
                    }
                    const terms = [];
                    for (let i = 1; i <= numOfYears; ++i) {
                        terms.push({
                            ranges: [],
                            year_end: null,
                            year_num: i,
                            year_start: null,
                        });
                    }
                    newData.abated_rent_ranges = clone(terms);
                    newData.lease_term = clone(terms);
                }
            } else {
                newData.abated_rent_ranges = [];
                newData.lease_term = [];
            }
        }
        setData(newData);
    }, [data.lease_length]);

    const typeOfLease = get(data, "type_of_lease", "");
    const isRenewal = typeOfLease === "Renewal";

    useEffect(() => {
        const newData = clone(data);
        if (isRenewal) {
            newData.occupancy_date = "Existing";
        } else {
            const occupancyDate = get(data, "occupancy_date", "");
            newData.occupancy_date = occupancyDate === "Existing" ? "" : occupancyDate;
        }
        setData(newData);
    }, [isRenewal]);

    const allRanges = makeAllRanges(data);

    const convert = (date) => {
        const darr = date.split("/");
        const y = darr[2];
        const m = darr[0];
        const d = darr[1];
        return `${y}-${m}-${d}`;
    };
    const getDateDifference = () => {
        let rent_commencement_is_more = false;
        if (data.rent_commencement && data.lease_start_date) {
            if (
                moment(data.rent_commencement, "MM/DD/YYYY", true).isValid() &&
                moment(data.lease_start_date, "MM/DD/YYYY", true).isValid()
            ) {
                const timestamp1 = moment(data.rent_commencement, "MM/DD/YYYY", true).unix();
                const timestamp2 = moment(data.lease_start_date, "MM/DD/YYYY", true).unix();
                if (timestamp1 > timestamp2) {
                    //rent_commencement_is_more = true;
                }
            }
        }
        const date1 = rent_commencement_is_more ? data.rent_commencement : data.lease_start_date;
        const date2 = data.lease_end_date;
        if (date1 && date2) {
            const diffObj = getDateDiffs(convert(date1), convert(date2));

            return (
                <div>
                    {diffObj.years} Years, {diffObj.months} Months, {diffObj.days} Days
                </div>
            );
        }
        return null;
    };

    const warning = "WARNING! Changing this value will erase the previous Lease Term and Abated Rent Range data. ";

    const calcs = termLogic.calculateLeaseTerm(data);

    return (
        <GridContainer>
            {/* Lease Information */}
			<GridItem xs={12} sm={12} md={12}>
                <Button
                    color="primary"
                    onClick={() => setOpenedCompanyModal(true)}
                >
                    SELECT COMPANY
                </Button>
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
                {renderElement("business_name")}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {renderElement("type_of_business")}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
                    {renderElement("lease_start_date", null, warning)}
                </div>
            </GridItem>
            {(data.lease_start_date_estimated || data.rent_commencement_estimated || data.occupancy_date_estimated) && (
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement("lease_length", null, warning)}
                </GridItem>
            )}
            <GridItem xs={12} sm={3} md={3}>
                {data.lease_start_date && <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
                    {renderElement("lease_end_date", null, warning)}
                </div>}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
                    {renderElement("rent_commencement", null, warning)}
                </div>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement("total_sf_leased")}
            </GridItem>

            {!hideStartingRentPerSqft && <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                    label="Starting Rent per Sq.Ft."
                    value={calculateStartingRent(data) ? calculateStartingRent(data) : ""}
                    readonly={true}
                    readonly_show_gray={true}
                    isDollar
                />
            </GridItem>}
            <GridItem xs={12} sm={3} md={3}>
                {renderElement("date_lease_signed")}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {isRenewal ? (
                    <CustomInput label="Occupancy Date" value="Existing" readonly />
                ) : (
                    renderElement("occupancy_date", null, warning)
                )}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("early_possession")}
            </GridItem>
            {data.early_possession === "Yes" && (
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement("early_possession_date")}
                </GridItem>
            )}

            <GridItem xs={12} sm={3} md={3}>
                <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
                    {renderElement("flat_fee_commission")}
                </div>
            </GridItem>
            {data.flat_fee_commission === "Yes" && (
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement("flat_fee_commission_amount")}
                </GridItem>
            )}

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("option_renewal")}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement("option_commission")}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement("lease_type")}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("contingency")}
            </GridItem>
            {data.contingency === "Yes" && (
                <GridItem xs={12} sm={12} md={12}>
                    {renderElement("contingency_detail")}
                </GridItem>
            )}

			<GridItem xs={12} sm={6} md={6}>
                
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
                {renderElement("rate_type")}<span style={{position:'relative'}}>
                {get(data, 'rate_type') === 'Fixed Percentage' && (
                    <div className="rate-type">
                        {renderElement("rate_type_cpi_percentage")}
                    </div>
                )}
                </span>
            </GridItem>

            {shouldShowRanges && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="group-wrap">
                            <h3>
                                <strong>LEASE TERM &amp; ABATED RENT</strong>
                            </h3>
                            {isEstimated ? <div>{data.lease_length} Months</div> : getDateDifference()}
                            <hr />
                            {addTermRangesError(data) && <div className="red-text">{addTermRangesError(data)}</div>}
                            <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
                            {!isEstimated && renderElement("lease_term",
                                null, 
                                null, 
                                null, 
                                null, 
                                [], 
                                null, 
                                null,
                                calcs
                            )}
                            {isEstimated && <TermEstimatedMain 
                                                data={data}
                                                calcs={calcs}
                                                updateDataObj={updateDataObj}
                                                setData={setData}
                                            />}
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="group-wrap">
                            {
                                <div>
                                    <h3>Rent Totals</h3>
                                    <hr />
                                    <Step3RentTotals 
                                        calcs={calcs}
                                    />
                                </div>
                            }
                        </div>
                    </GridItem>
                </>
            )}

        </GridContainer>
    );
};

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export default Step3;
