import React from "react";
import { useEffect, useCallback } from "react";
import { get } from 'lodash';
import { clone } from "../../../shared/utility";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from '../../../components/CustomInput/CustomInputValidate';

import { checkOutsideBrokerOrReferralFirmFields, setReferralFirmFeeAmountChoice } from "../RSheetsLogic";
import Brokers from "../CommonCustomElements/Brokers";

const LESSEE_REFERRAL_FIRM_FIELDS = [
    "lessee_referral_firm_company_name",
    "lessee_referral_firm_name",
    "lessee_referral_firm_lastname",
    "lessee_referral_firm_contact_dba_name",
    "lessee_referral_firm_address1",
    "lessee_referral_firm_city",
    "lessee_referral_firm_state",
    "lessee_referral_firm_zip",
    "lessee_referral_firm_contact",
    "lessee_referral_firm_fax",
    "lessee_referral_firm_main_phone",
    "lessee_referral_firm_work_phone",
    "lessee_referral_firm_mobile_phone",
    "lessee_referral_firm_email",
    "lessee_referral_firm_taxid",
    "lessee_referral_firm_w9",
    "lessee_referral_firm_w9_upload",
    "lessee_referral_firm_fee_amount_choice",
    "lessee_referral_firm_fee_amount_percentage",
    "lessee_referral_firm_fee_amount",
];

const LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS = ["lessee_referral_firm_fax", "lessee_referral_firm_fee_amount_choice","lessee_referral_firm_company_name",
    "lessee_referral_firm_taxid"
];

const Step4 = (props) => {
    const { setQueryFor, getContacts, setOpenedContactModal, renderElement, data, setData } = props;

    useEffect(() => {
        setReferralFirmFeeAmountChoice(setData, data)("lessee");
    }, []);

    const areReferralFirmFieldsRequired = checkOutsideBrokerOrReferralFirmFields(data, LESSEE_REFERRAL_FIRM_FIELDS, LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS);

    const LESSEE_REFERRAL_FIRM_FIELDS_FILTERED = LESSEE_REFERRAL_FIRM_FIELDS.filter(lff => {
        const lf = lff.split('lessee_referral_firm_')[1];
        console.log('the-lf', lf, data.lessee_referral_firm_phone_type);
        if (data.lessee_referral_firm_phone_type) {
            if (data.lessee_referral_firm_phone_type === 'Work') {
                if (lf === 'mobile_phone' || lf === 'main_phone') return false;
            } else if (data.lessee_referral_firm_phone_type === 'Mobile') {
                if (lf === 'work_phone' || lf === 'main_phone') return false;
            } else if (data.lessee_referral_firm_phone_type === 'Main') {
                if (lf === 'work_phone' || lf === 'mobile_phone') return false;
            }
        } else {
            if (lf === 'work_phone' || lf === 'mobile_phone') return false;
        }
        return true;
    });

    const renderReferralFirmFields = useCallback(() => {
        console.log('LESSEE_REFERRAL_FIRM_FIELDS_FILTERED', LESSEE_REFERRAL_FIRM_FIELDS_FILTERED);
        return LESSEE_REFERRAL_FIRM_FIELDS_FILTERED.map((lfield) => {
            const extraValidations =
                !LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS.includes(lfield) && areReferralFirmFieldsRequired
                    ? ["required"]
                    : [];

            if (data.lessee_referral_firm_id) {
                // is a company
                if (data.lessee_referral_firm_id.toString().indexOf("c") !== -1 && get(data, 'lessee_referral_firm_company_only', false)) {
                    if (lfield === 'lessee_referral_firm_name') return null;
                    if (lfield === 'lessee_referral_firm_lastname') return null;
                }
            }

            if (!lfield) {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}></GridItem>;
            }

            if (lfield === 'lessee_referral_firm_contact_dba_name') {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                <CustomInput
                    label='Payee DBA'
                    value={data.lessee_referral_firm_contact_dba_name}
                    readonly
                    readonly_show_gray
                    endAdornment={data.lessee_referral_firm_contact_dba_active === 0 && 
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: '.7em'}}>(removed)</span>
                    }
                />
                </GridItem>
            }

            if (data.lessee_referral_firm_w9 !== "Yes") {
                if (lfield === "lessee_referral_firm_w9_upload") return null;
            }
            if (
                data.lessee_referral_firm_fee_amount_choice === "Percentage" &&
                lfield === "lessee_referral_firm_fee_amount"
            )
                return null;
            if (
                data.lessee_referral_firm_fee_amount_choice === "Value" &&
                lfield === "lessee_referral_firm_fee_amount_percentage"
            )
                return null;
            return (
                <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    {renderElement(lfield, null, null, null, null, extraValidations)}
                </GridItem>
            );
        });
    }, [data]);

    console.log('Step4 dat', data);

    return (
        <div>
            <GridContainer>
                {/* Lessee */}
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        color="primary"
                        onClick={() => {
                            setQueryFor("lessees");
                            getContacts({
                                names_only: "All",
                            });
                            setOpenedContactModal(true);
                        }}
                    >
                        <i className="fa fa-plus"></i> ADD LESSEE
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {data.lessees.length === 0 && <div className="red-text" style={{margin:20}}>Lessee Required</div>}
                    {renderElement('lessees')}
                    <hr />
                    {renderElement('lessee_contacts', undefined, undefined, undefined, undefined, undefined, undefined, true)}
                    <hr />
                </GridItem>

                <Brokers 
                    data={data}
                    setData={setData}
                    outside_brokers_field_name="outside_broker" 
                    outside_brokers_field_label="LESSEE"
                />

                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <h4>
                        <strong>LESSEE REFERRAL</strong>
                    </h4>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    {!data.lessee_referral_firm_id && <Button
                        color="primary"
                        onClick={() => {
                            setQueryFor("lessee_referral_firm");
                            props.getContacts({
                                names_only: "All",
                            });
                            setOpenedContactModal(true);
                        }}
                    >
                        SELECT REFERRAL
                    </Button>}
                    {data.lessee_referral_firm_id && (!data?.lessee_referrals || !data?.lessee_referrals.length) && <Button
                        color="success"
                        onClick={() => {
                            const newData = clone(data);
                            ['address1','city','company_name','contact','email','fax','fee_amount',
                            'fee_amount_choice','fee_amount_percentage','lastname',
                            'main_phone',
                            'mobile_phone',
                            'work_phone',
                            'phone_type',
                            'name','state','taxid','w9','w9_upload','zip','id',
                            'contact_dba_name',
                            'contact_dba_id',
                            'contact_dba_type',
                            'contact_dba_active',
                            'company_dba_name',
                            'company_dba_id',
                            'company_dba_type',
                            'company_dba_active',
                        ].forEach(fieldy => {
                                const field = `lessee_referral_firm_${fieldy}`;
                                newData[field] = '';
                            })
                            setData(newData);
                        }}
                    >
                        REMOVE REFERRAL
                    </Button>}
                </GridItem>

                {data.lessee_referral_firm_id ? renderReferralFirmFields() : null}
            </GridContainer>
        </div>
    );
};

export default Step4;
