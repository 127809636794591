import React, { useState, useCallback } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CustomDialogChooser from 'components/CustomDialog/CustomDialogChooser';
import CustomInput from '../../../components/CustomInput/CustomInputValidate';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import FileUploader from '../CommonCustomElements/FileUploader';
import CustomInputStates from '../../../components/CustomInput/CustomInputStates';
import NotificationAsk from 'components/Notification/NotificationAsk';

const OtherReferrals = (props) => {
	const [openedCustomDialogChooser, setOpenedCustomDialogChooser] = useState(false);
	const [notificationAsk, setNotificationAsk] = useState(false);
	const [itemIndex, setItemIndex] = useState(null);

	const { data, setData, name, title } = props;

	const choose = (chosen, a, b) => {
		console.log('chosenone', chosen);
		// clone data
		const newData = { ...data };

		const obj = {};
		let id = null;
		let company = {};
		let contact = {};
		let address = {};
		let dba = b;

		if (chosen.hasOwnProperty('company_data')) {
			// company with or without contact
			company = chosen.company_data;
			contact = chosen?.signers?.at(0) || {};
			dba = chosen?.dba_data.find((i) => i.id === chosen?.dba_id);
			id = `c${company.id}`;
		} else if (chosen?.company_id && chosen?.company_dats) {
			// contact with company
			company = chosen.company_dats.company_data;
			contact = chosen.company_dats.contact_data;
			id = contact.id;
		} else {
			// contact only
			contact = chosen;
		}

		address = contact?.addresses?.find((i) => i.type === 'M');

		obj['id'] = id;
		obj['company_name'] = company?.name;
		obj['name'] = contact?.first_name;
		obj['lastname'] = contact?.last_name;

		obj['address1'] = company?.mailing_address1 || address?.address1;
		obj['city'] = company?.mailing_city || address?.city;
		obj['state'] = company?.mailing_state || address?.state;
		obj['zip'] = company?.mailing_zip || address?.zip;

		obj['main_phone'] = company?.main_phone || contact?.main_phone;
		if (chosen.overrideContactPhone) {
			if (chosen.overrideContactPhone.type === 'Work') {
				obj['phone_type'] = 'Work';
				obj['work_phone'] = chosen.overrideContactPhone.phone;
			} else if (chosen.overrideContactPhone.type === 'Mobile') {
				obj['phone_type'] = 'Mobile';
				obj['mobile_phone'] = chosen.overrideContactPhone.phone;
			}
		}
		obj['email'] = company?.email || contact?.email;
		obj['fax'] = company?.fax || contact?.fax;
		// obj['contact'] = null;

		obj['contact_dba_id'] = dba?.id || dba?.dba_id;
		obj['contact_dba_name'] = dba?.name || dba?.dba_name;
		obj['contact_dba_type'] = 'contact';

		// create array if it doesn't exist
		if (!Array.isArray(newData[name])) {
			newData[name] = [];
		}

		newData[name].push(obj);
		setData(newData);
	};

	const updateField = (field, value, index) => {
		const newData = { ...data };
		newData[name][index][field] = value;
		setData(newData);
	};

	const required = (val) => {
		if (!val) return 'Required';
		return false;
	};

	const renderOtherReferrals = useCallback(
		(item, index) => {
			return (
				<div className="boxed mt-20" key={`other-${name}-referral-${index}`}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							{/* delete button */}
							<div style={{ textAlign: 'right' }}>
								<Button
									size="sm"
									color="success"
									onClick={() => {
										setItemIndex(index);
										setNotificationAsk(true);
									}}
								>
									REMOVE REFERRAL
								</Button>
							</div>
						</GridItem>

						{/* header */}
						<GridItem xs={12} sm={12} md={12}>
							<h4>Referral #{index + 2}</h4>
						</GridItem>

						{/* fields */}
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Payee Company Name"
								value={item['company_name']}
								onChange={(e) => {
									updateField(`company_name`, e.target.value, index);
								}}
								validate={[]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Payee First Name"
								value={item['name']}
								onChange={(e) => {
									updateField(`name`, e.target.value, index);
								}}
								validate={[required]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Payee Last Name"
								value={item['lastname']}
								onChange={(e) => {
									updateField(`lastname`, e.target.value, index);
								}}
								validate={[required]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Payee DBA"
								value={item['contact_dba_name']}
								readonly
								readonly_show_gray
								endAdornment={
									item['contact_dba_active'] === 0 && (
										<span
											style={{
												color: 'red',
												fontWeight: 'bold',
												fontSize: '.7em',
											}}
										>
											(removed)
										</span>
									)
								}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Address"
								value={item['address1']}
								onChange={(e) => {
									updateField(`address1`, e.target.value, index);
								}}
								validate={[required]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="City"
								value={item['city']}
								onChange={(e) => {
									updateField(`city`, e.target.value, index);
								}}
								validate={[required]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInputStates
								label="State"
								value={item['state']}
								onChange={(val) => {
									updateField(`state`, val, index);
								}}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Zip"
								value={item['zip']}
								onChange={(e) => {
									updateField(`zip`, e.target.value, index);
								}}
								validate={[required]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Contact"
								value={item['contact']}
								onChange={(e) => {
									updateField(`contact`, e.target.value, index);
								}}
								validate={[required]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Fax"
								value={item['fax']}
								onChange={(e) => {
									updateField(`fax`, e.target.value, index);
								}}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							{(!item.phone_type) && <CustomInput
								label="Main Phone"
								value={item['main_phone']}
								onChange={(e) => {
									updateField(`main_phone`, e.target.value, index);
								}}
								validate={[required]}
							/>}
							{(item.phone_type === 'Work') && <CustomInput
								label="Work Phone"
								value={item['work_phone']}
								onChange={(e) => {
									updateField(`work_phone`, e.target.value, index);
								}}
								validate={[required]}
							/>}
							{(item.phone_type === 'Mobile') && <CustomInput
								label="Mobile Phone"
								value={item['mobile_phone']}
								onChange={(e) => {
									updateField(`mobile_phone`, e.target.value, index);
								}}
								validate={[required]}
							/>}
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Email"
								value={item['email']}
								onChange={(e) => {
									updateField(`email`, e.target.value, index);
								}}
								validate={[required]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomInput
								label="Tax ID"
								value={item['taxid']}
								onChange={(e) => {
									updateField(`taxid`, e.target.value, index);
								}}
								validate={[]}
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<CustomRadio
								label={['W9'].concat(['Yes', 'No']).join(',')}
								options={['Yes', 'No']}
								onChange={(e) => {
									updateField(`w9`, e.target.value, index);
								}}
								value={item['w9']}
							/>
							{!item['w9'] && <div className="red-text mb-20 small-text">Required</div>}
						</GridItem>
						{item['w9'] === 'Yes' && (
							<GridItem xs={12} sm={3} md={3}>
								<div className="boxed">
									<div style={{ marginBottom: 10 }}>Referral W9</div>
									<FileUploader
										rs_filename={item['w9_upload']}
										update={(val) => {
											updateField(`w9_upload`, val, index);
										}}
										remove={() => {
											updateField(`w9_upload`, '', index);
										}}
									/>
								</div>
							</GridItem>
						)}
						<GridItem xs={12} sm={3} md={3}>
							<CustomRadio
								label={['Fee Amount'].concat(['Value', 'Percentage']).join(',')}
								options={['Value', 'Percentage']}
								onChange={(e) => {
									updateField(`fee_amount_choice`, e.target.value, index);
								}}
								value={item['fee_amount_choice']}
							/>
						</GridItem>
						{item['fee_amount_choice'] !== 'Value' && (
							<GridItem xs={12} sm={3} md={3}>
								<CustomInput
									label="Fee Amount Percentage"
									value={item['fee_amount_percentage']}
									onChange={(e) => {
										updateField(`fee_amount_percentage`, e.target.value, index);
									}}
									endAdornment="%"
									validate={[required]}
								/>
							</GridItem>
						)}
						{item['fee_amount_choice'] === 'Value' && (
							<GridItem xs={12} sm={3} md={3}>
								<CustomInput
									label="Fee Amount"
									value={item['fee_amount']}
									onChange={(e) => {
										updateField(`fee_amount`, e.target.value, index);
									}}
									isDollar={true}
									validate={[required]}
								/>
							</GridItem>
						)}
					</GridContainer>
				</div>
			);
		},
		[data]
	);

	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<hr />
					{title && (
						<h4>
							<strong>{title}</strong>
						</h4>
					)}
				</GridItem>

				<Button color="primary" onClick={() => setOpenedCustomDialogChooser(true)}>
					<i className="fa fa-plus"></i> ADD REFERRAL
				</Button>

				{data[name]?.map((item, index) => {
					return renderOtherReferrals(item, index);
				})}
			</GridContainer>

			{openedCustomDialogChooser && (
				<CustomDialogChooser
					open={openedCustomDialogChooser}
					close={() => {
						setOpenedCustomDialogChooser(false);
					}}
					choose={choose}
					showContactCompaniesTab={true}
					create_and_choose={choose}
					title="Select Contact or Company"
					label="Search Contact/Company"
					no_property={true}
					allowNoContacts={true}
				/>
			)}

			{notificationAsk && itemIndex !== null && (
				<NotificationAsk
					title="Are you sure?"
					message={`Do you want to remove referral #${itemIndex + 2} ?`}
					open={notificationAsk}
					close={() => {
						setNotificationAsk(false);
					}}
					success={() => {
						const newData = { ...data };
						newData[name].splice(itemIndex, 1);
						setData(newData);

						setItemIndex(null);
						setNotificationAsk(false);
					}}
				/>
			)}
		</>
	);
};

export default OtherReferrals;
