import moment from 'moment';
import axios from 'store/axios/axios-crm';
import {
	clone,
	find,
	get,
	isEmpty,
	last,
	orderBy,
	sortBy,
	concat,
	assign,
	set,
	first,
	forEach,
	forOwn,
	isEqual
} from 'lodash';
import {
	currencyToNumber,
	formatDollar,
	get2DecimalRound,
	commatize,
	convertToTimestamp2,
	getDayDiffs,
	getDateDiffs,
	getDateDiffs2,
	convertToUnix,
	convert,
	handleError,
	parseFloatString,
} from '../../shared/utility';
import { getUser } from '../../shared/authValidation';

export const statusDescriptions = {
	0: 'Voided',
	1: 'Draft',
	2: 'Submitted to level 1',
	3: 'Submitted to level 2',
	4: 'Submitted to level 3',
	5: 'Level 1 Denied to Submitter',
	6: 'Level 2 Denied to Level 1',
	7: 'Level 3 Denied to Level 2',
	8: 'Final Approver denied to Level 3',
	9: 'Final Approver approved',
	10: 'Submitted to final approver for approval',
	11: 'Under internal review',
	12: 'Internal review - Approved', // used for history only
	13: 'Internal review - Denied' // used for history only
};

export const typeDescriptions = {
	1: 'Residential',
	2: 'Lease',
	3: 'Sub-lease',
	4: 'Sale',
	5: 'Tenant Rep',
	6: 'Consulting',
	7: 'Option Commission',
	8: 'No Listing',
	9: 'Lease Assignment Fee',
};

export const doAction = (args) => {
	const { tab, getRsheets, status } = args;

	if (tab === 'user_drafts') {
		getRsheets({
			status: [1, 5],
		});
	} else if (tab === 'my_drafts') {
		getRsheets({
			own: true,
			status: [1, 5, 11],
		});
	} else if (tab === 'pending_my_drafts') {
		getRsheets({
			status: status ? status.concat([11]) : [11]
		});
	} else if (tab === 'pending_further_approval') {
		getRsheets({
			status,
		});
	} else if (tab === 'my_completed') {
		getRsheets({
			status: 9,
			own: true,
		});
	} else if (tab === 'all_completed') {
		getRsheets({
			status: 9,
		});
	} else if (tab === 'voided') {
		getRsheets({
			status: 0
		});
	}
};

const getAbsoluteMonths = (momentDate) => {
	var months = Number(momentDate.format('MM'));
	var years = Number(momentDate.format('YYYY'));
	return months + years * 12;
}

export const calculateLeaseLogic = (args) => {
	const { date1, date2, value, data } = args;

	// Allowed values: ["30 Days", "Actual Days"]
	const isActualDays = get(data, "commission_based_off", "") === "Actual Days";

	var startMonths = getAbsoluteMonths(moment(date1, 'MM/DD/YYYY'));
	var endMonths = getAbsoluteMonths(moment(date2, 'MM/DD/YYYY'));

	var months = endMonths - startMonths + 1;

	// SAMPLE
	// {
	// 	"daysInMonth": 29,
	// 	"monthIn": "07/03/2023",
	// 	"calc": 2416.6666666666665,
	// 	"calcRounded": 2416.67
	// }


	let calcs = [];
	for (let i = 0; i < months; i++) {
		const obj = {};
		const mom = moment(date1, 'MM/DD/YYYY').add(i, 'months');
		if (i === 0 || i === months - 1) {
			// calculate 1 month of this date
			// DATE 1
			const mom_day = parseInt(date1.split('/')[1]);
			const mom2_day = parseInt(date2.split('/')[1]);
			const daysInMonth = mom.daysInMonth();
			obj.daysInMonth = daysInMonth - mom_day + 1;

			obj.monthIn = mom.format('MM/DD/YYYY');
			if (i === months - 1 && months !== 1) {
				// always date start is 1 if months > 1 and last month
				const month_day = mom.format('MM/DD/YYYY');
				const month_day_split = month_day.split('/');
				obj.monthIn = month_day_split[0] + '/01/' + month_day_split[2];

				// subtract last day instead
				obj.daysInMonth = mom2_day - 1 + 1;
			}

			if (months === 1) {
				// calculate between start and end date
				obj.daysInMonth = mom2_day - mom_day + 1;
			}

			if (isActualDays) {
				obj.calc = (value ? parseFloat(value) : 0) * obj.daysInMonth / moment(obj.monthIn).daysInMonth()
			} else {
				obj.calc = (value ? parseFloat(value) : 0) * obj.daysInMonth / 30;
			}

			obj.calcRounded = get2DecimalRound(obj.calc);
		} else {
			// not the first month DATE 1
			const month_day = mom.format('MM/DD/YYYY');
			const month_day_split = month_day.split('/');
			obj.monthIn = month_day_split[0] + '/01/' + month_day_split[2];
			const mom_day = 1;
			const daysInMonth = mom.daysInMonth();

			obj.daysInMonth = daysInMonth - mom_day + 1;
			obj.calc = value;
			obj.calcRounded = get2DecimalRound(obj.calc);
		}
		calcs.push(obj);
	}
	return calcs;
};

export const calculateTermRent = (date1, date2, value) => {
	// const calcs = calculateLeaseLogic({
	// 	date1: start,
	// 	date2: end,
	// 	value: rent,
	// });
	// const total = calcs.reduce((prev, curr) => curr.calcRounded + prev, 0);
	// return total;

	const start = moment(date1).startOf('day');
	const end = moment(date2).startOf('day');
	let totalMonths = 0

	if (start.month() === end.month()) {
		const daysInMonth = start.daysInMonth();
		totalMonths = (end.date() - start.date() + 1) / daysInMonth;
	} else {
		let endOfMonth = moment(start).endOf('month').startOf('day')
		while (endOfMonth.isBefore(end)) {
			if (endOfMonth.month() === start.month()) {
				const daysInMonth = start.daysInMonth();
				totalMonths += (daysInMonth - start.date() + 1) / daysInMonth;
			} else {
				totalMonths += 1;
			}
			endOfMonth.add(1, 'month').endOf('month').startOf('day');
		}
	
		if (endOfMonth.isSame(end)) {
			totalMonths += 1
		} else {
			const daysInMonth = end.daysInMonth();
			totalMonths += end.date() / daysInMonth;
		}
	}

	return parseFloat(value) * totalMonths;
};

export const calculateMonths = (date1, date2, value) => {
	const start = moment(date1).startOf('day');
	const end = moment(date2).startOf('day');

	let totalMonths = 0
	
	if (start.month() === end.month()) {
		const daysInMonth = start.daysInMonth();
		totalMonths = (end.date() - start.date() + 1) / daysInMonth;
	} else {
		let endOfMonth = moment(start).endOf('month').startOf('day')
		while (endOfMonth.isBefore(end)) {
			if (endOfMonth.month() === start.month()) {
				const daysInMonth = start.daysInMonth();
				totalMonths += (daysInMonth - start.date() + 1) / daysInMonth;
			} else {
				totalMonths += 1;
			}
			endOfMonth.add(1, 'month').endOf('month').startOf('day');
		}
		
		if (endOfMonth.isSame(end)) {
			totalMonths += 1
		} else {
			const daysInMonth = end.daysInMonth();
			totalMonths += end.date() / daysInMonth;
		}
	}

	return totalMonths;
};

export const calculateCommission = (start, end, rent, rate) => {
	if (!rate || !rent) return 0;
	const termRent = calculateTermRent(start, end, rent);
	return (parseFloat(rate) / 100) * termRent;
};

export const calculateAbatedRanges = (args) => {
	const { lease_term, abated } = args;

	let negative_commissions = [];

	if (lease_term && abated) {
		lease_term.forEach((lt, i) => {
			// lt = 1 year
			if (lt.ranges) {
				// ltr = 1 range
				const sortedRanges = sortBy(lt.ranges, (a) => new Date(a.date1));

				sortedRanges.forEach((ltr, j) => {
					// find out if each abated range applies
					const { date1, date2, commissionRate, value, flat_fee } = ltr;
					if (date1 && date2 && (commissionRate || flat_fee) && value) {
						// Find if range fall between the abated range
						abated.forEach((ab) => {
							if (ab && ab.ranges) {
								const sortedAbatedRanges = sortBy(
									ab.ranges,
									(a) => new Date(a.date1)
								);
								sortedAbatedRanges.forEach((abr) => {
									let abatedStart, abatedEnd;

									if (
										moment(date1).isSameOrAfter(abr.date1) &&
										moment(date1).isSameOrBefore(abr.date2)
									) {
										abatedStart = date1;
									} else if (
										moment(abr.date1).isSameOrAfter(date1) &&
										moment(abr.date1).isSameOrBefore(date2)
									) {
										abatedStart = abr.date1;
									}

									if (
										moment(date2).isSameOrAfter(abr.date1) &&
										moment(date2).isSameOrBefore(abr.date2)
									) {
										abatedEnd = date2;
									} else if (
										moment(abr.date2).isSameOrAfter(date1) &&
										moment(abr.date2).isSameOrBefore(date2)
									) {
										abatedEnd = abr.date2;
									}

									if (abatedStart && abatedEnd) {
										negative_commissions.push({
											abated: true,
											date1: abatedStart,
											date2: abatedEnd,
											value: abr.value,
											commissionRate,
										});
									}
								});
							}
						});
					}
				});
			}
		});
	}

	return negative_commissions;
};

export const calculateYearAbatedRanges = (args) => {
	const { lease_term, year_abated } = args;

	let negative_commissions = [];

	if (lease_term && year_abated) {
		lease_term.forEach((lt, i) => {
			// lt = 1 year
			if (lt.ranges) {
				// ltr = 1 range
				const sortedRanges = sortBy(lt.ranges, (a) => new Date(a.date1));

				sortedRanges.forEach((ltr, j) => {
					// find out if each abated range applies
					const { date1, date2, commissionRate, value, flat_fee } = ltr;
					if (date1 && date2 && (commissionRate || flat_fee) && value) {
						// Find if range fall between the abated range

						const sortedAbatedRanges = sortBy(
							year_abated,
							(a) => new Date(a.date1)
						);
						sortedAbatedRanges.forEach((abr) => {
							let abatedStart, abatedEnd;

							if (
								moment(date1).isSameOrAfter(abr.date1) &&
								moment(date1).isSameOrBefore(abr.date2)
							) {
								abatedStart = date1;
							} else if (
								moment(abr.date1).isSameOrAfter(date1) &&
								moment(abr.date1).isSameOrBefore(date2)
							) {
								abatedStart = abr.date1;
							}

							if (
								moment(date2).isSameOrAfter(abr.date1) &&
								moment(date2).isSameOrBefore(abr.date2)
							) {
								abatedEnd = date2;
							} else if (
								moment(abr.date2).isSameOrAfter(date1) &&
								moment(abr.date2).isSameOrBefore(date2)
							) {
								abatedEnd = abr.date2;
							}

							if (abatedStart && abatedEnd) {
								negative_commissions.push({
									abated: true,
									date1: abatedStart,
									date2: abatedEnd,
									value: abr.value,
									commissionRate,
								});
							}
						});
					}
				});
			}
		});
	}

	return negative_commissions;
};

export const calculateAbatedValues = (args) => {
	const { lease_term, abated_range } = args;

	let negative_commissions = [];

	if (lease_term && abated_range) {
		lease_term.forEach((lt, i) => {
			// lt = 1 year
			if (lt.ranges) {
				// ltr = 1 range
				const sortedRanges = sortBy(lt.ranges, (a) => new Date(a.date1));

				sortedRanges.forEach((ltr, j) => {
					// find out if each abated range applies
					const { date1, date2, commissionRate, value, flat_fee } = ltr;
					if (date1 && date2 && (commissionRate || flat_fee) && value) {
						// Find if range fall between the abated range

						let abatedStart, abatedEnd;

						if (
							moment(date1).isSameOrAfter(abated_range.date1) &&
							moment(date1).isSameOrBefore(abated_range.date2)
						) {
							abatedStart = date1;
						} else if (
							moment(abated_range.date1).isSameOrAfter(date1) &&
							moment(abated_range.date1).isSameOrBefore(date2)
						) {
							abatedStart = abated_range.date1;
						}

						if (
							moment(date2).isSameOrAfter(abated_range.date1) &&
							moment(date2).isSameOrBefore(abated_range.date2)
						) {
							abatedEnd = date2;
						} else if (
							moment(abated_range.date2).isSameOrAfter(date1) &&
							moment(abated_range.date2).isSameOrBefore(date2)
						) {
							abatedEnd = abated_range.date2;
						}

						if (abatedStart && abatedEnd) {
							negative_commissions.push({
								abated: true,
								date1: abatedStart,
								date2: abatedEnd,
								value: abated_range.value,
								commissionRate,
							});
						}
					}
				});
			}
		});
	}

	return negative_commissions;
};

/**
 * Calculate the total of whole lease_term
 */
export const calculateLeaseTermTotal = (lease_term, isFlatFee = false) => {
	let totalMonths = 0;
	let totalTermRent = 0;
	let totalCommission = 0;

	if (!lease_term || !Array.isArray(lease_term))
		return {
			totalMonths,
			totalTermRent,
			totalCommission,
		};

	lease_term.forEach((term) => {
		const ranges = term.ranges;
		const total = calculateTermsTotal(ranges, {
			isFlatFee,
		});
		totalMonths += total.totalMonths;
		totalTermRent += total.totalTermRent;
		totalCommission += total.totalCommission;
	});
	return {
		totalMonths,
		totalTermRent,
		totalCommission,
	};
};


export const calculateTermsTotal = (ranges, opts = {}) => {
	let totalMonths = 0;
	let totalTermRent = 0;
	let totalCommission = 0;
	const { isFlatFee = false, isActualDays = false } = opts;

	if (!ranges || !Array.isArray(ranges))
		return {
			totalMonths,
			totalTermRent,
			totalCommission,
		};

	ranges.forEach((range) => {
		const months = calculateMonths(range.date1, range.date2, range.value);
		let rent = get(range, 'value', 0);
		const rate = get(range, 'commissionRate', 0) || 0;
		if (range.abated) {
			rent = -rent;
		}
		const termRent = calculateTermRent(range.date1, range.date2, rent);
		let commission = parseFloat((termRent * parseFloat(rate)) / 100);
		if (isFlatFee && range.flat_fee) {
			commission = calculateTermRent(
				range.date1,
				range.date2,
				parseFloat(range.flat_fee.toString().replace(/[$,]/g, ''))
			);
		}

		if (typeof months === 'number') {
			if (!range.abated) {
				totalMonths += months;
			}
		}
		if (typeof termRent === 'number') {
			totalTermRent += get2DecimalRound(termRent);
		}
		if (typeof commission === 'number') {
			totalCommission += get2DecimalRound(commission);
		}
	});
	return {
		totalMonths,
		totalTermRent,
		totalCommission,
	};
};

export const calculateEstimatedTotal = (range, isFlatFee = false) => {
	const {
		month1 = '',
		month2 = '',
		day1 = '',
		day2 = '',
		value = '',
		commissionRate = '',
		flat_fee,
		abated,
	} = range;
	let rent = parseFloat(value);
	if (abated) {
		rent = -rent;
	}

	let totalTermRent = 0;
	let totalCommission = 0;
	let totalMonths = 0;

	const monthStart = parseInt(month1.split(' ')[1]);
	const monthEnd = parseInt(month2.split(' ')[1]);
	const dayStart = parseInt(day1.split(' ')[1]);
	const dayEnd = parseInt(day2.split(' ')[1]);

	const numOfDays = monthEnd * 30 + dayEnd - (monthStart * 30 + dayStart) + 1;
	totalTermRent = (numOfDays * parseFloat(rent)) / 30;
	totalCommission = (totalTermRent * parseFloat(commissionRate)) / 100;

	if (isFlatFee && flat_fee) {
		totalCommission =
			(numOfDays * parseFloat(flat_fee.toString().replace(/[$,]/g, ''))) / 30;
	}

	if (isFlatFee && flat_fee && abated) {
		totalCommission = 0;
	}

	totalMonths = numOfDays / 30;
	return {
		totalTermRent,
		totalCommission,
		totalMonths,
	};
};

export const calculateEstimatedTermsTotal = (ranges, isFlatFee = false) => {
	let totalMonths = 0;
	let totalTermRent = 0;
	let totalCommission = 0;

	if (!ranges || !Array.isArray(ranges))
		return {
			totalMonths,
			totalTermRent,
			totalCommission,
		};

	ranges.forEach((range) => {
		const total = calculateEstimatedTotal(range, isFlatFee);
		totalMonths += total.totalMonths;
		totalTermRent += total.totalTermRent;
		totalCommission += total.totalCommission;
	});
	return {
		totalMonths,
		totalTermRent,
		totalCommission,
	};
};

export const calculateEstimatedRangeTable = (range) => {
	const tableData = [];
	const { month1 = '', month2 = '', day1 = '', day2 = '', value = '' } = range;
	let rent = parseFloat(value);

	const monthStart = parseInt(month1.split(' ')[1]);
	const monthEnd = parseInt(month2.split(' ')[1]);
	const dayStart = parseInt(day1.split(' ')[1]);
	const dayEnd = parseInt(day2.split(' ')[1]);

	if (monthStart === monthEnd) {
		const numOfDays = dayEnd - dayStart + 1;
		const total = (rent * numOfDays) / 30;
		tableData.push([
			`Month ${monthStart} - Day ${dayStart}`,
			`${numOfDays}`,
			formatDollar(total),
			formatDollar(total),
		]);
		return tableData;
	}

	for (let i = monthStart; i <= monthEnd; ++i) {
		if (i === monthStart) {
			const total = (parseFloat(rent) * (31 - dayStart)) / 30;
			tableData.push([
				`Month ${i} - Day ${dayStart}`,
				`${31 - dayStart}`,
				formatDollar(total),
				formatDollar(total),
			]);
		} else if (i === monthEnd) {
			const total = (parseFloat(rent) * dayEnd) / 30;
			tableData.push([
				`Month ${i}`,
				`${dayEnd}`,
				formatDollar(total),
				formatDollar(total),
			]);
		} else {
			tableData.push([
				`Month ${i}`,
				'30',
				formatDollar(rent),
				formatDollar(rent),
			]);
		}
	}
	return tableData;
};

// "Start Date", "Num of Days", "Commission Rate", "Total Abated Commission", "Total Abated Rent"
export const calculateEstimatedAbatedTable = (ranges) => {
	const tableData = [];
	ranges.forEach((range) => {
		const { month1, month2, day1, day2, value, commissionRate } = range;

		const monthStart = parseInt(month1.split(' ')[1]);
		const monthEnd = parseInt(month2.split(' ')[1]);
		const dayStart = parseInt(day1.split(' ')[1]);
		const dayEnd = parseInt(day2.split(' ')[1]);

		for (let i = monthStart; i <= monthEnd; ++i) {
			if (i === monthStart) {
				const total = (parseFloat(value) * (31 - dayStart)) / 30;
				tableData.push([
					`Month ${i} - Day ${dayStart}`,
					`${31 - dayStart}`,
					`${commissionRate} %`,
					formatDollar((total * commissionRate) / 100),
					formatDollar(total),
				]);
			} else if (i === monthEnd) {
				const total = (parseFloat(value) * dayEnd) / 30;
				tableData.push([
					`Month ${i}`,
					`${dayEnd}`,
					`${commissionRate} %`,
					formatDollar((total * commissionRate) / 100),
					formatDollar(total),
				]);
			} else {
				tableData.push([
					`Month ${i}`,
					'30',
					`${commissionRate} %`,
					formatDollar((value * commissionRate) / 100),
					formatDollar(value),
				]);
			}
		}
	});
	return tableData;
};

// "Start Date", "End Date", "Commission Rate", "Total Commission", "Total Rent"
export const calculateAbatedTable = (ranges) => {
	const tableData = [];
	ranges.forEach((range) => {
		const { date1, date2, value, commissionRate } = range;
		let rent = -parseFloat(value);
		const totalCommission = calculateCommission(
			date1,
			date2,
			rent,
			commissionRate
		);
		const totalRent = calculateTermRent(date1, date2, rent);
		tableData.push([
			moment(date1).format('MM/DD/YYYY'),
			moment(date2).format('MM/DD/YYYY'),
			`${commissionRate} %`,
			formatDollar(totalCommission, true),
			formatDollar(totalRent, true),
		]);
	});
	return tableData;
};

export const createMonthArray = (length, currentYear) => {
	console.log('length', length, currentYear);
	const current = parseInt(currentYear);
	const len = parseInt(length);
	const monthArray = [];
	const start = 12 * (current - 1) + 1;
	if (len <= current * 12) {
		for (let i = start; i <= len; i++) {
			monthArray.push(`Month ${i}`);
		}
	} else {
		for (let i = start; i <= current * 12; i++) {
			monthArray.push(`Month ${i}`);
		}
	}
	return monthArray;
};

// Check if month1 - day1 is SAME month2 - day2
// month1 - integer
// day1 - integer
// month2 - integer
// day2 - integer
export const isSameDate = (month1, day1, month2, day2) => {
	return month1 === month2 && day1 === day2;
};

// Check if month1 - day1 is SAME or BEFORE month2 - day2
// month1 - integer
// day1 - integer
// month2 - integer
// day2 - integer
export const isSameOrBefore = (month1, day1, month2, day2) => {
	let result = false;
	if (month1 === month2 && day1 <= day2) {
		result = true;
	} else if (month1 < month2) {
		result = true;
	}
	return result;
};

// Check if month1 - day1 is SAME or AFTER month2 - day2
// month1 - integer
// day1 - integer
// month2 - integer
// day2 - integer
export const isSameOrAfter = (month1, day1, month2, day2) => {
	let result = false;
	if (month1 === month2 && day1 >= day2) {
		result = true;
	} else if (month1 > month2) {
		result = true;
	}
	return result;
};

export const getNextDate = (month, day) => {
	if (day < 30) {
		return { month, day: day + 1 };
	}
	return {
		month: month + 1,
		day: 1,
	};
};

export const calculateEstimatedAbatedRanges = (args) => {
	const { lease_term, abated_rent_ranges } = args;

	let negative_commissions = [];

	if (lease_term && abated_rent_ranges) {
		lease_term.forEach((lt, i) => {
			// lt = 1 year
			if (lt.ranges) {
				// ltr = 1 range
				const sortedRanges = orderBy(lt.ranges, ['month1', 'day1'], 'asc');

				sortedRanges.forEach((ltr, j) => {
					// find out if each abated range applies
					const {
						month1 = '',
						month2 = '',
						day1 = '',
						day2 = '',
						commissionRate = '',
						value = '',
						flat_fee,
					} = ltr;

					const monthStart = parseInt(month1.split(' ')[1]);
					const monthEnd = parseInt(month2.split(' ')[1]);
					const dayStart = parseInt(day1.split(' ')[1]);
					const dayEnd = parseInt(day2.split(' ')[1]);

					if (day1 && day2 && (commissionRate || flat_fee) && value) {
						// Find if range fall between the abated range
						abated_rent_ranges.forEach((ab) => {
							if (ab && ab.ranges) {
								const sortedAbatedRanges = orderBy(
									ab.ranges,
									['month1', 'day1'],
									'asc'
								);
								sortedAbatedRanges.forEach((abr) => {
									const {
										month1: abatedMonth1,
										month2: abatedMonth2,
										day1: abatedDay1,
										day2: abatedDay2,
									} = abr;

									const abatedMonthStart = parseInt(abatedMonth1.split(' ')[1]);
									const abatedMonthEnd = parseInt(abatedMonth2.split(' ')[1]);
									const abatedDayStart = parseInt(abatedDay1.split(' ')[1]);
									const abatedDayEnd = parseInt(abatedDay2.split(' ')[1]);

									let abatedRangeMonthStart,
										abatedRangeMonthEnd,
										abatedRangeDayStart,
										abatedRangeDayEnd;

									// Find Start
									if (
										isSameOrAfter(
											monthStart,
											dayStart,
											abatedMonthStart,
											abatedDayStart
										) &&
										isSameOrBefore(
											monthStart,
											dayStart,
											abatedMonthEnd,
											abatedDayEnd
										)
									) {
										abatedRangeMonthStart = monthStart;
										abatedRangeDayStart = dayStart;
									} else if (
										isSameOrAfter(
											abatedMonthStart,
											abatedDayStart,
											monthStart,
											dayStart
										) &&
										isSameOrBefore(
											abatedMonthStart,
											abatedDayStart,
											monthEnd,
											dayEnd
										)
									) {
										abatedRangeMonthStart = abatedMonthStart;
										abatedRangeDayStart = abatedDayStart;
									}
									if (
										last(negative_commissions) &&
										last(negative_commissions).month2 ===
											`Month ${abatedRangeMonthStart}` &&
										last(negative_commissions).day2 ===
											`Day ${abatedRangeDayStart}`
									) {
										const nextDate = getNextDate(
											abatedRangeMonthStart,
											abatedRangeDayStart
										);
										abatedRangeMonthStart = nextDate.month;
										abatedRangeDayStart = nextDate.day;
									}

									// Find End
									if (
										isSameOrAfter(
											monthEnd,
											dayEnd,
											abatedMonthStart,
											abatedDayStart
										) &&
										isSameOrBefore(
											monthEnd,
											dayEnd,
											abatedMonthEnd,
											abatedDayEnd
										)
									) {
										abatedRangeMonthEnd = monthEnd;
										abatedRangeDayEnd = dayEnd;
									} else if (
										isSameOrAfter(
											abatedMonthEnd,
											abatedDayEnd,
											monthStart,
											dayStart
										) &&
										isSameOrBefore(
											abatedMonthEnd,
											abatedDayEnd,
											monthEnd,
											dayEnd
										)
									) {
										abatedRangeMonthEnd = abatedMonthEnd;
										abatedRangeDayEnd = abatedDayEnd;
									}

									if (
										abatedRangeMonthStart &&
										abatedRangeMonthEnd &&
										abatedRangeDayStart &&
										abatedRangeDayEnd
									) {
										negative_commissions.push({
											abated: true,
											month1: `Month ${abatedRangeMonthStart}`,
											month2: `Month ${abatedRangeMonthEnd}`,
											day1: `Day ${abatedRangeDayStart}`,
											day2: `Day ${abatedRangeDayEnd}`,
											value: abr.value,
											commissionRate,
										});
									}
								});
							}
						});
					}
				});
			}
		});
	}

	return negative_commissions;
};

export const calculateEstimatedAbatedYearRanges = (args) => {
	const { lease_term, abated_year_range } = args;

	let negative_commissions = [];

	if (lease_term && abated_year_range) {
		lease_term.forEach((lt, i) => {
			// lt = 1 year
			if (lt.ranges) {
				// ltr = 1 range
				const sortedRanges = orderBy(lt.ranges, ['month1', 'day1'], 'asc');

				sortedRanges.forEach((ltr, j) => {
					// find out if each abated range applies
					const {
						month1 = '',
						month2 = '',
						day1 = '',
						day2 = '',
						commissionRate = '',
						value = '',
						flat_fee = '',
					} = ltr;

					const monthStart = parseInt(month1.split(' ')[1]);
					const monthEnd = parseInt(month2.split(' ')[1]);
					const dayStart = parseInt(day1.split(' ')[1]);
					const dayEnd = parseInt(day2.split(' ')[1]);

					if (day1 && day2 && (commissionRate || flat_fee) && value) {
						// Find if range fall between the abated range
						abated_year_range.forEach((ab) => {
							const {
								month1: abatedMonth1,
								month2: abatedMonth2,
								day1: abatedDay1,
								day2: abatedDay2,
							} = ab;

							const abatedMonthStart = parseInt(abatedMonth1.split(' ')[1]);
							const abatedMonthEnd = parseInt(abatedMonth2.split(' ')[1]);
							const abatedDayStart = parseInt(abatedDay1.split(' ')[1]);
							const abatedDayEnd = parseInt(abatedDay2.split(' ')[1]);

							let abatedRangeMonthStart,
								abatedRangeMonthEnd,
								abatedRangeDayStart,
								abatedRangeDayEnd;

							// Find Start
							if (
								isSameOrAfter(
									monthStart,
									dayStart,
									abatedMonthStart,
									abatedDayStart
								) &&
								isSameOrBefore(
									monthStart,
									dayStart,
									abatedMonthEnd,
									abatedDayEnd
								)
							) {
								abatedRangeMonthStart = monthStart;
								abatedRangeDayStart = dayStart;
							} else if (
								isSameOrAfter(
									abatedMonthStart,
									abatedDayStart,
									monthStart,
									dayStart
								) &&
								isSameOrBefore(
									abatedMonthStart,
									abatedDayStart,
									monthEnd,
									dayEnd
								)
							) {
								abatedRangeMonthStart = abatedMonthStart;
								abatedRangeDayStart = abatedDayStart;
							}
							if (
								last(negative_commissions) &&
								last(negative_commissions).month2 ===
									`Month ${abatedRangeMonthStart}` &&
								last(negative_commissions).day2 === `Day ${abatedRangeDayStart}`
							) {
								const nextDate = getNextDate(
									abatedRangeMonthStart,
									abatedRangeDayStart
								);
								abatedRangeMonthStart = nextDate.month;
								abatedRangeDayStart = nextDate.day;
							}

							// Find End
							if (
								isSameOrAfter(
									monthEnd,
									dayEnd,
									abatedMonthStart,
									abatedDayStart
								) &&
								isSameOrBefore(monthEnd, dayEnd, abatedMonthEnd, abatedDayEnd)
							) {
								abatedRangeMonthEnd = monthEnd;
								abatedRangeDayEnd = dayEnd;
							} else if (
								isSameOrAfter(
									abatedMonthEnd,
									abatedDayEnd,
									monthStart,
									dayStart
								) &&
								isSameOrBefore(abatedMonthEnd, abatedDayEnd, monthEnd, dayEnd)
							) {
								abatedRangeMonthEnd = abatedMonthEnd;
								abatedRangeDayEnd = abatedDayEnd;
							}

							if (
								abatedRangeMonthStart &&
								abatedRangeMonthEnd &&
								abatedRangeDayStart &&
								abatedRangeDayEnd
							) {
								negative_commissions.push({
									abated: true,
									month1: `Month ${abatedRangeMonthStart}`,
									month2: `Month ${abatedRangeMonthEnd}`,
									day1: `Day ${abatedRangeDayStart}`,
									day2: `Day ${abatedRangeDayEnd}`,
									value: ab.value,
									commissionRate,
									flat_fee,
								});
							}
						});
					}
				});
			}
		});
	}

	return negative_commissions;
};

export const calculateEstimatedAbatedValues = (args) => {
	const { lease_term, abated_range } = args;

	let negative_commissions = [];

	if (lease_term && abated_range) {
		lease_term.forEach((lt, i) => {
			// lt = 1 year
			if (lt.ranges) {
				// ltr = 1 range
				const sortedRanges = orderBy(lt.ranges, ['month1', 'day1'], 'asc');

				sortedRanges.forEach((ltr, j) => {
					// find out if each abated range applies
					const {
						month1 = '',
						month2 = '',
						day1 = '',
						day2 = '',
						commissionRate = '',
						value = '',
						flat_fee = '',
					} = ltr;

					const monthStart = parseInt(month1.split(' ')[1]);
					const monthEnd = parseInt(month2.split(' ')[1]);
					const dayStart = parseInt(day1.split(' ')[1]);
					const dayEnd = parseInt(day2.split(' ')[1]);

					if (day1 && day2 && (commissionRate || flat_fee) && value) {
						// Find if range fall between the abated range

						const {
							month1: abatedMonth1,
							month2: abatedMonth2,
							day1: abatedDay1,
							day2: abatedDay2,
						} = abated_range;

						const abatedMonthStart = parseInt(abatedMonth1.split(' ')[1]);
						const abatedMonthEnd = parseInt(abatedMonth2.split(' ')[1]);
						const abatedDayStart = parseInt(abatedDay1.split(' ')[1]);
						const abatedDayEnd = parseInt(abatedDay2.split(' ')[1]);

						let abatedRangeMonthStart,
							abatedRangeMonthEnd,
							abatedRangeDayStart,
							abatedRangeDayEnd;

						// Find Start
						if (
							isSameOrAfter(
								monthStart,
								dayStart,
								abatedMonthStart,
								abatedDayStart
							) &&
							isSameOrBefore(monthStart, dayStart, abatedMonthEnd, abatedDayEnd)
						) {
							abatedRangeMonthStart = monthStart;
							abatedRangeDayStart = dayStart;
						} else if (
							isSameOrAfter(
								abatedMonthStart,
								abatedDayStart,
								monthStart,
								dayStart
							) &&
							isSameOrBefore(abatedMonthStart, abatedDayStart, monthEnd, dayEnd)
						) {
							abatedRangeMonthStart = abatedMonthStart;
							abatedRangeDayStart = abatedDayStart;
						}
						if (
							last(negative_commissions) &&
							last(negative_commissions).month2 ===
								`Month ${abatedRangeMonthStart}` &&
							last(negative_commissions).day2 === `Day ${abatedRangeDayStart}`
						) {
							const nextDate = getNextDate(
								abatedRangeMonthStart,
								abatedRangeDayStart
							);
							abatedRangeMonthStart = nextDate.month;
							abatedRangeDayStart = nextDate.day;
						}

						// Find End
						if (
							isSameOrAfter(
								monthEnd,
								dayEnd,
								abatedMonthStart,
								abatedDayStart
							) &&
							isSameOrBefore(monthEnd, dayEnd, abatedMonthEnd, abatedDayEnd)
						) {
							abatedRangeMonthEnd = monthEnd;
							abatedRangeDayEnd = dayEnd;
						} else if (
							isSameOrAfter(
								abatedMonthEnd,
								abatedDayEnd,
								monthStart,
								dayStart
							) &&
							isSameOrBefore(abatedMonthEnd, abatedDayEnd, monthEnd, dayEnd)
						) {
							abatedRangeMonthEnd = abatedMonthEnd;
							abatedRangeDayEnd = abatedDayEnd;
						}

						if (
							abatedRangeMonthStart &&
							abatedRangeMonthEnd &&
							abatedRangeDayStart &&
							abatedRangeDayEnd
						) {
							negative_commissions.push({
								abated: true,
								month1: `Month ${abatedRangeMonthStart}`,
								month2: `Month ${abatedRangeMonthEnd}`,
								day1: `Day ${abatedRangeDayStart}`,
								day2: `Day ${abatedRangeDayEnd}`,
								value: abated_range.value,
								commissionRate,
								flat_fee,
							});
						}
					}
				});
			}
		});
	}

	return negative_commissions;
};

// Check if the date is between start and end date
// Return fall if not between or same date
export const isDateBetween = (current, start, end, isEstimated = false) => {
	if (!isEstimated) {
		const currentDate = moment(current);
		const startDate = moment(start);
		const endDate = moment(end);
		return (
			currentDate.isBetween(startDate, endDate) ||
			currentDate.isSame(startDate) ||
			currentDate.isSame(endDate)
		);
	} else {
		const currentMonth = parseInt(current.month.split(' ')[1]);
		const currentDay = parseInt(current.day.split(' ')[1]);
		const startMonth = parseInt(start.month.split(' ')[1]);
		const startDay = parseInt(start.day.split(' ')[1]);
		const endMonth = parseInt(end.month.split(' ')[1]);
		const endDay = parseInt(end.day.split(' ')[1]);

		const isBetween =
			isSameOrAfter(currentMonth, currentDay, startMonth, startDay) &&
			isSameOrBefore(currentMonth, currentDay, endMonth, endDay);

		return isBetween;
	}
};

// Check if the lease range is estimated and TBD
export const isEstimatedLease = (data, check_lease_length = false) => {
	const isEstimated =
		get(data, 'lease_start_date_estimated') ||
		get(data, 'rent_commencement_estimated') ||
		get(data, 'occupancy_date_estimated');
	if (check_lease_length) return Boolean(isEstimated && data.lease_length);
	return Boolean(isEstimated);
};

// Check if the term ranges can be added in the Lease Information
export const addTermRangesError = (data) => {
	if (isEstimatedLease(data) && !data.lease_length) {
		return 'Lease Length is required';
	}
	if (
		!isEstimatedLease(data) &&
		!Boolean(
			data.lease_start_date && data.lease_end_date && data.rent_commencement
		)
	) {
		return 'Lease start date, Lease end date and Rent commencement required';
	}
	return null;
};

// Calculate the adjusted commission
export const calculateAdjustedCommission = (data) => {
	const commission_adjustment = get(data, 'commission_adjustment', null);

	if (commission_adjustment === 'No') return 0;

	const commission_adjustment_details = get(
		data,
		'commission_adjustment_details',
		[]
	);
	return commission_adjustment_details.reduce((prev, curr) => {
		const value = currencyToNumber(curr.value);
		return prev + value;
	}, 0);
};

// Handle Commission Split Logic
export const generateCommissionSplitData = (data, setData) => {
	const illi_represented_lessor = get(data, 'illi_represented_lessor', null);
	const illi_represented_lessee = get(data, 'illi_represented_lessee', null);

	const shouldAddRepresentedLessor = illi_represented_lessor === 'Yes';
	const shouldAddRepresentedLessee = illi_represented_lessee === 'Yes';
	const lessorAgents = get(data, 'illi_represented_lessor_agents', []);
	const lesseeAgents = get(data, 'illi_represented_lessee_agents', []);

	if (!isEmpty(lesseeAgents) || !isEmpty(lessorAgents)) {
		const newData = clone(data);
		if (data.commission_splits_agent) {
			let commission_splits_agent = clone(data.commission_splits_agent);
			lessorAgents.forEach((l) => {
				const found = find(data.commission_splits_agent, { id: l.id });
				if (!found && shouldAddRepresentedLessor) {
					const name = get(l, 'name', '') + ' ' + get(l, 'last_name', '');
					commission_splits_agent.push({
						id: l.id,
						Name: name,
						Percentage: '',
						dba_name: l?.dba_name,
						dba_id: l?.dba_id,
						dba_type: l?.dba_type,
					});
				} else if (found && !shouldAddRepresentedLessor) {
					commission_splits_agent = commission_splits_agent.filter(
						(item) => item.id !== l.id
					);
				}
			});
			newData.commission_splits_agent = commission_splits_agent;
		}
		if (data.commission_splits_agent2) {
			let commission_splits_agent2 = clone(data.commission_splits_agent2);
			lesseeAgents.forEach((l) => {
				const found = find(data.commission_splits_agent2, { id: l.id });
				if (!found && shouldAddRepresentedLessee) {
					const name = get(l, 'name', '') + ' ' + get(l, 'last_name', '');
					commission_splits_agent2.push({
						id: l.id,
						Name: name,
						Percentage: '',
						dba_name: l?.dba_name,
						dba_id: l?.dba_id,
						dba_type: l?.dba_type,
					});
				} else if (found && !shouldAddRepresentedLessee) {
					commission_splits_agent2 = commission_splits_agent2.filter(
						(item) => item.id !== l.id
					);
				}
			});
			newData.commission_splits_agent2 = commission_splits_agent2;
		}
		setData(newData);
	}
};

// Check if RS have flat fee commission enabled
export const isFlatFeeCommissionEnabled = (data, allowNull=false) => {
	if (data.sale_sale_commission) {
		return true;
	}
	if (allowNull) {
		return get(data, 'flat_fee_commission', null) !== 'No';
	}
	return get(data, 'flat_fee_commission', null) === 'Yes';
}

export const getFlatFeeCommission = (
	data,
	commissionField = 'flat_fee_commission_amount'
) => {
	// if (data.sale_sale_commission) {
	// 	return parseFloatString(data.sale_sale_commission);
	// }
	let num = parseFloat(currencyToNumber(get(data, commissionField, 0)));
	return num;
};

export const setReferralFirmFeeAmountChoice =
	(setData, data) => (fieldName) => {
		const fieldMap = {
			lessee: 'lessee_referral_firm_fee_amount_choice',
			lessor: 'referral_firm_fee_amount_choice',
			// ...
		};
		const fieldKey = fieldMap[fieldName];

		if (!data[fieldKey]) {
			const newData = clone(data);
			newData[fieldKey] = 'Value';
			setData(newData);
		}
	};

// rsDataType = 'illi_represented_lessee' or 'illi_represented_lessor'
export const validCommissions = (data, rsData, rsDataType) => {
	console.log('analyzing', data, rsData[rsDataType]);
	if (!data) return false;
	if (rsData && rsDataType) {
		const hasAssociate = get(rsData, rsDataType, '');
		if (hasAssociate === '') {
			return false;
		}
	}
	if (data.length === 0 && rsData && rsDataType) {
		const hasAssociate = get(rsData, rsDataType, '') === 'Yes';
		if (hasAssociate && data.length === 0) {
			return false;
		} else {
			return true;
		}
	}
	let total = 0;
	data.forEach((d) => {
		total += parseFloat(d.Percentage);
	});
	console.log('analyzing the total is', total);
	if (total !== 100) return false;
	return true;
};

// Check if other outside broker or referral firm fields are required
export const checkOutsideBrokerOrReferralFirmFields = (
	data,
	fields,
	exemptFields = []
) => {
	// If lessee referral firm company name is filled, then lessee referral firm name and lastname are not required
	if (data.lessee_referral_firm_company_name) {
		exemptFields.push('lessee_referral_firm_name');
		exemptFields.push('lessee_referral_firm_lastname');
	};

	return fields.reduce((result, field) => {
		if (exemptFields.includes(field)) return result;
		return !!get(data, field, false) || result;
	}, false);
};

// Check if needing to generate payment amount
export const shouldGetPaymentAmount = (option) => {
	return (
		option &&
		(option.toLowerCase().includes('half') ||
			option.toLowerCase().includes('full'))
	);
};

/**
 * Generate the amount for payment schedule automatically based on the option
 * Full -> Whatever Total
 * Half -> Whatever Total / 2
 * @param {string} option
 * @returns {number} payment
 */
export const getPaymentAmount = (data, option, defaultValue) => {
	const { lease_term, abated_rent_ranges } = data;

	let totalCommission = 0;

	const flatFeeEnabled = isFlatFeeCommissionEnabled(data);
	const isEstimated = isEstimatedLease(data, false);

	if (flatFeeEnabled) {
		const flatFeeCommission = getFlatFeeCommission(data);
		totalCommission = flatFeeCommission + calculateAdjustedCommission(data);
	} else {
		const abated_ranges = isEstimated
			? calculateEstimatedAbatedRanges({ lease_term, abated_rent_ranges })
			: calculateAbatedRanges({ lease_term, abated: data.abated_rent_ranges });
		const ranges = lease_term.flatMap((item) => item.ranges);
		const combinedRanges = concat(abated_ranges, ranges);
		const totalCalc = isEstimated
			? calculateEstimatedTermsTotal(combinedRanges)
			: calculateTermsTotal(combinedRanges);

		totalCommission =
			parseFloat(totalCalc.totalCommission) + calculateAdjustedCommission(data);
	}

	let payment = 0;

	if (option && option.toLowerCase().includes('first half')) {
		payment = parseFloat(totalCommission)
			? get2DecimalRound(get2DecimalRound(totalCommission) / 2)
			: 0;
	} else if (option && option.toLowerCase().includes('second half')) {
		const firstHalfPayment = parseFloat(totalCommission)
			? get2DecimalRound(get2DecimalRound(totalCommission) / 2)
			: 0;
		payment = get2DecimalRound(parseFloat(totalCommission) - firstHalfPayment);
	} else if (option && option.toLowerCase().includes('full')) {
		payment = parseFloat(totalCommission) ? parseFloat(totalCommission) : 0;
		payment = get2DecimalRound(payment);
	} else {
		if (defaultValue && typeof defaultValue === 'string') {
			payment = parseFloat(defaultValue.replace(/[$,]/g, ''));
		}
	}

	return payment;
};

/**
 * Calculates the total square footage based on lease term and total SF leased
 * @param {Object} data - The data object containing lease term and total SF leased
 * @returns {Number} - The calculated total square footage
 */
export const getTotalSqft = (data) => {
	// Check if data object is defined
	if (data) {
		// Check if lease term is defined in the data object
		if (data.lease_term) {
			// Initialize rent amount to 0
			let rent_amount = 0;
			// Get the first lease term
			const first = data.lease_term[0];
			if (first) {
				// Get the lease term object
				const lt = first;
				// Check if the lease term has any ranges
				if (lt.ranges.length > 0) {
					// Get the first range object
					const ltr = lt.ranges[0];
					// Parse the value of the range as a float and set it to rent amount
					rent_amount = ltr.value ? parseFloat(ltr.value) : 0;
				}

				// Check if total SF leased is defined in the data object
				if (data.total_sf_leased) {
					// Calculate the total square footage by dividing total SF leased by rent amount,
					// round to 2 decimal places, and add commas as thousands separators
					return commatize(
						get2DecimalRound(parseFloat(data.total_sf_leased) / rent_amount),
						true
					);
				}
			}
		}
	}
	// Return 0 if data object is not defined or no total square footage can be calculated
	return 0;
};

/**
 * Checks if there are any ranges in the lease term or abated rent ranges
 *
 * @param {Object} data - The data to check
 * @returns {Boolean} - Whether or not there are any ranges
 */
export const checkIfRangesExist = (data) => {
	// Check if there are lease term ranges
	const leaseTerm = get(data, 'lease_term', []);
	if (leaseTerm.some((term) => term.ranges?.length > 0)) {
		return true;
	}

	// Check if there are abated rent ranges
	const abatedRentRanges = get(data, 'abated_rent_ranges', []);
	if (abatedRentRanges.some((range) => range.ranges?.length > 0)) {
		return true;
	}

	// No ranges found
	return false;
};

/**
 * Checks if there is an interchange between a date field's estimated and estimated_tbd properties
 * @param {Object} oldValue - The object containing the old date values
 * @param {Object} newModalData - The object containing the new date values
 * @returns {boolean} - true if the interchange occurs in at least one of the date fields, false otherwise
 */
export const isEstimatedAndTBDSwapped = (oldValue, newModalData) => {
	const KEYS = ['lease_start_date', 'occupancy_date', 'rent_commencement'];
	const TEXT_ESTIMATED_MAPPING = {
		lease_start_date_estimated: 'Lease Start Date Estimated',
		occupancy_date_estimated: 'Occupancy Date Estimated',
		rent_commencement_estimated: 'Rent Commencement Estimated',
	};

	for (const key of KEYS) {
		// 1. If old value is estimated and new value is TBD
		const isEstimatedOld =
			oldValue[`${key}_estimated`] &&
			oldValue[`${key}_estimated`] ===
				TEXT_ESTIMATED_MAPPING[`${key}_estimated`];
		if (isEstimatedOld && newModalData[`${key}_estimated_tbd`] === 'TBD')
			return true;

		// 2. If old value is TBD and new value is estimated
		const isTBDOld = oldValue[`${key}_estimated_tbd`] === 'TBD';
		if (
			isTBDOld &&
			newModalData[`${key}_estimated`] ===
				TEXT_ESTIMATED_MAPPING[`${key}_estimated`]
		)
			return true;
	}

	return false;
};

/**
 * Checks if should show Warning and Reset ranges
 * @param {Object} oldValue - The object containing the old date values
 * @param {Object} newModalData - The object containing the new date values
 * @returns {boolean} - true if the interchange occurs in at least one of the date fields, false otherwise
 */
export const shouldShowWarningAndResetRanges = (
	oldValue,
	newModalData,
	data
) => {
	let should = false;

	const oldData = clone(data);
	assign(oldData, oldValue);

	const newData = clone(data);
	assign(newData, newModalData);

	const isEstimated = isEstimatedLease(newData, false);
	const KEYS = [
		'lease_start_date',
		'lease_end_date',
		'occupancy_date',
		'rent_commencement',
	];
	if (checkIfRangesExist(data)) {
		if (isEstimatedLease(oldData, false) !== isEstimated) {
			should = true;
		} else if (isEstimated) {
			if (
				get(oldData, 'lease_length', '') !== get(newData, 'lease_length', '')
			) {
				should = true;
			}
		} else {
			for (const key of KEYS) {
				if (get(oldData, key, '') !== get(newData, key, '')) {
					should = true;
				}
			}
		}
	}

	return should;
};

// * Common functions move from ALL RS Logic files

export const getAbatedTotal = (rr, data) => {
	let total = 0;
	if (rr) {
		if (rr.ranges) {
			if (rr.ranges.length > 0) {
				rr.ranges.forEach((ltt) => {
					const calcs = calculateLeaseLogic_RS({
						date1: ltt.date1,
						date2: ltt.date2,
						value: ltt.value,
						data,
					});

					let allTotal = 0;
					let tableData = [];
					calcs.forEach((cc) => {
						allTotal += cc.calcRounded;
					});
					total += allTotal;
				});
			}
		}
	}
	return total;
};

export const checkTermValidity = (lt, data, field_type) => {
	const { ranges } = lt;
	let { year_end, year_start } = lt;
	const ye = year_end * 1000;
	const ys = year_start * 1000;
	const lease_end_time = data.lease_end_date
		? moment(data.lease_end_date, 'MM/DD/YYYY').unix() * 1000
		: 0;

	let lease_end_is_greater = false;
	if (ye < lease_end_time) {
		lease_end_is_greater = true;
	}
	const lease_end = data.lease_end_date
		? moment(data.lease_end_date, 'MM/DD/YYYY')
		: 0;

	// get the year's term
	const { rent_commencement, lease_end_date } = data;
	const rent_commencement_timestamp = convertToTimestamp2(rent_commencement);
	const lease_end_date_timestamp = convertToTimestamp2(lease_end_date);

	let errors = [];
	if (ranges.length === 0 && field_type === 'lease_term') {
		return ['At least one range is required'];
	}
	if (rent_commencement_timestamp > year_start) {
		year_start = rent_commencement_timestamp;
	}
	if (lease_end_date_timestamp < year_end) {
		year_end = lease_end_date_timestamp;
	}
	for (let i = 0; i < ranges.length; i++) {
		const r = ranges[i];

		const date1_timestamp = convertToTimestamp2(r.date1);
		const date2_timestamp = convertToTimestamp2(r.date2);

		if (!r.value || !r.date1 || !r.date2 || !rent_commencement) {
			errors.push('All values, dates, and rent commencement are required');
			continue;
		}

		if (date1_timestamp > date2_timestamp) {
			errors.push('Starting date must be before end date');
			continue;
		}

		if (!rent_commencement_timestamp || !date1_timestamp) {
			errors.push('Invalid rent commencement or starting date');
			continue;
		}
		// check that date1 is = or greater than rent commencement
		if (
			rent_commencement_timestamp > date1_timestamp &&
			field_type === 'lease_term'
		) {
			errors.push(
				`Starting Date of Range ${i + 1} must be after Rent Commencement`
			);
			continue;
		}
		// check if date2 is lower than end of year
	}

	// new algorithm
	const start_of_year = moment(ys);
	const end_of_year = moment(ye);
	const rent_commencementy = moment(
		data.rent_commencement,
		'MM/DD/YYYY',
		true
	).isValid()
		? moment(data.rent_commencement, 'MM/DD/YYYY')
		: moment();

	const starty =
		rent_commencementy.unix() > start_of_year.unix()
			? rent_commencementy
			: start_of_year;
	const endy = lease_end_is_greater ? end_of_year : lease_end;
	const whole_year_days = getDayDiffs(endy, starty);
	let count_days = 0;

	for (let i = 0; i < ranges.length; i++) {
		const r = ranges[i];
		// calculate days between this range
		const range_start = moment(r.date1, 'MM/DD/YYYY');
		const range_end = moment(r.date2, 'MM/DD/YYYY');
		const range_days = getDayDiffs(range_end, range_start);
		count_days += range_days;
	}

	if (whole_year_days && whole_year_days !== count_days) {
		if (field_type === 'lease_term') {
			errors.push(`The whole year term must be included in the ranges 
                and must not contain overlapping days.`);
		}
	}
	if (field_type === 'abated_rent_ranges' && ranges.length === 0) {
		return {
			errors: '',
			whole_year_days,
			count_days,
		};
	}

	return {
		errors: errors.length === 0 ? null : errors.join('. '),
		whole_year_days,
		count_days,
	};

	// NOTE: This is a full year from rent commencement date. Not by calendar date.  So if the lease start date is: 05/01/2022, and the Rent commencement date is 07/01/2022, then Year 1 must have rows entered totaling from 07/01/2022 up until 04/30/2023, and on 05/01/2023 starts Year

	// 2. There can be 1 row with a range of 07/01/2022 - 04/30/2023 (Year 1), or there can be multiple rows like: 07/01/2022 - 08/15/2022, then 08/16/2022 - 02/05/2023, and then 02/06/2023 - 04/30/2023. As many rows as they want. But it has to have a value for the entire year of each year of the lease starting from the rent commencement date.

	// NOTE2: Year 1 is the only year that may be short. If the Least start date is: 01/01/2022 and the rent commencement date is 05/01/2022. Then year 1 would only have values from 05/01/2022 to 12/31/2022. Cause 01/01/2023 would be the start of Year 2. It is technically possible that the rent commencement date might start after Year 1. Example: Lease start date: 01/01/2022, but Rent Commencement date is 03/01/2023. Which would mean that Year 1 would have no values at all. which at this point is OK. If they want to add a rule to the Rent Commencement date to prevent that we can do that later. But we should build in to account for this possibility. It is likely with large projects that the lease start date would be years before the rent commencement date.
};


/** NEW range calculation for the RS and Invoice NEW Range breakdown calculation
 * Breakdown: E.g: Start date - Num of Days - Total - Rounded Total
 */
export const newRangeCalculation = (range, isEstimated = false, isActualDays = true) => {
	const breakdown = [];

	if (isEstimated) {
        const monthStart = parseInt(get(range, "month1", "").split(" ")[1]);
        const dayStart = parseInt(get(range, "day1", "").split(" ")[1]);

        const monthEnd = parseInt(get(range, "month2", "").split(" ")[1]);
        const dayEnd = parseInt(get(range, "day2", "").split(" ")[1]);

        let value = range.value;

        if (range.abated) {
            value = parseFloat(value) - parseFloat(get(range, 'abated.value', 0));
        }

        let totalMonths = (monthEnd * 30 + dayEnd - monthStart * 30 - dayStart + 1) / 30;

        return {
            months: totalMonths,
            termRent: parseFloat(value) * totalMonths,
        }
    } else {
        const start = moment(range.date1).startOf('day');
        const end = moment(range.date2).startOf('day');
        let value = range.value;

        if (range.abated) {
            value = parseFloat(value) - parseFloat(get(range, 'abated.value', 0));
        }

        let totalMonths = 0;

		if (start.month() === end.month()) {
			// const daysInMonth = start.daysInMonth();
			const daysInMonth = isActualDays ? start.daysInMonth() : 30;
			const numOfDays = end.date() - start.date() + 1
			totalMonths = (numOfDays) / daysInMonth;

			breakdown.push({
				start,
				numOfDays,
				total: totalMonths * value,
			})
		} else {
			let endOfMonth = moment(start).endOf('month').startOf('day')
			while (endOfMonth.isBefore(end)) {
				if (endOfMonth.month() === start.month()) {
					// const daysInMonth = start.daysInMonth();
					const daysInMonth = isActualDays ? start.daysInMonth() : 30;
					const numOfDays = start.daysInMonth() - start.date() + 1
					totalMonths += (numOfDays) / daysInMonth;

					breakdown.push({
						start,
						numOfDays,
						total: (numOfDays /  daysInMonth ) * value,
					})
				} else {
					totalMonths += 1;

					breakdown.push({
						start: endOfMonth.startOf('month').startOf('day'),
						numOfDays: endOfMonth.daysInMonth(),
						total: value,
					})
				}
				endOfMonth.add(1, 'month').endOf('month').startOf('day');;
			}
	
			if (endOfMonth.isSame(end)) {
				totalMonths += 1

				breakdown.push({
					start: endOfMonth.startOf('month').startOf('day'),
					numOfDays: endOfMonth.daysInMonth(),
					total: value,
				})
			} else {
				// const daysInMonth = end.daysInMonth();
				const daysInMonth = isActualDays ? end.daysInMonth() : 30;
				totalMonths += end.date() / daysInMonth;

				breakdown.push({
					start: endOfMonth.startOf('month').startOf('day'),
					numOfDays: end.date(),
					total: (end.date() / daysInMonth) * value,
				})
			}
		}

        return {
            months: totalMonths,
            termRent: parseFloat(value) * totalMonths,
			breakdown,
        }
    }
}

/**
 * NEW year total rent calculation. MUST use newRangeCalculation
 * @param {object} ranges - All RANGES in one year
 */
export const newYearlyCalculation = (ranges, isEstimated = false, isActualDays = true) => {
	let termRent = 0;
	let commission = 0;

	ranges.forEach(range => {
		const result = newRangeCalculation(range, isEstimated, isActualDays);
		termRent += result.termRent;
		commission += (result.termRent * parseFloat(range.commissionRate) / 100);
	})

	return {
		termRent,
		commission,
	}
}


export const calculateLeaseLogic_RS = (args) => {
	const { date1, date2, value, data } = args;
	const between = getDateDiffs2(date1, date2);

	// Allowed values: ["30 Days", "Actual Days"]
	const isActualDays = get(data, "commission_based_off", "") === "Actual Days";

	var startMonths = getAbsoluteMonths(moment(date1, 'MM/DD/YYYY'));
	var endMonths = getAbsoluteMonths(moment(date2, 'MM/DD/YYYY'));

	var months = endMonths - startMonths + 1;

	let calcs = [];
	for (let i = 0; i < months; i++) {
		const obj = {};
		const mom = moment(date1, 'MM/DD/YYYY').add(i, 'months');
		if (i === 0 || i === months - 1) {
			// calculate 1 month of this date
			// DATE 1
			const mom_day = parseInt(date1.split('/')[1]);
			const mom2_day = parseInt(date2.split('/')[1]);
			const daysInMonth = mom.daysInMonth();
			obj.daysInMonth = daysInMonth - mom_day + 1;

			obj.monthIn = mom.format('MM/DD/YYYY');
			if (i === months - 1 && months !== 1) {
				// always date start is 1 if months > 1 and last month
				const month_day = mom.format('MM/DD/YYYY');
				const month_day_split = month_day.split('/');
				obj.monthIn = month_day_split[0] + '/01/' + month_day_split[2];

				// subtract last day instead
				obj.daysInMonth = mom2_day - 1 + 1;
			}

			if (months === 1) {
				// calculate between start and end date
				obj.daysInMonth = mom2_day - mom_day + 1;
			}

			if (isActualDays) {
				obj.calc = (value ? parseFloat(value) : 0) * obj.daysInMonth / moment(obj.monthIn).daysInMonth()
			} else {
				obj.calc = (value ? parseFloat(value) : 0) * obj.daysInMonth / 30;
			}

			obj.calcRounded = get2DecimalRound(obj.calc);
		} else {
			// not the first month DATE 1
			const month_day = mom.format('MM/DD/YYYY');
			const month_day_split = month_day.split('/');
			obj.monthIn = month_day_split[0] + '/01/' + month_day_split[2];
			const mom_day = 1;
			const daysInMonth = mom.daysInMonth();
			obj.daysInMonth = daysInMonth - mom_day + 1;
			obj.calc = value;
			obj.calcRounded = get2DecimalRound(obj.calc);
		}
		calcs.push(obj);
	}
	return calcs;
};

export const createEstimatedYearRows = (args) => {
	const { data } = args;

	if (data.lease_length) {
		let numOfYears = parseInt(parseInt(data.lease_length) / 12);
		if (parseInt(data.lease_length) % 12 > 0) {
			numOfYears += 1;
		}
		const terms = [];
		for (let i = 1; i <= numOfYears; ++i) {
			terms.push({
				ranges: [],
				year_end: null,
				year_num: i,
				year_start: null,
			});
		}
		return terms;
	} else {
		return [
			{
				ranges: [],
				year_end: null,
				year_num: 0,
				year_start: null,
			},
		];
	}
};

export const createYearRows = (args) => {
	const { diffObj, data, lease_end_date_unix } = args;
	// create rows
	let year_rows = [];
	let ys = diffObj.years;
	if (diffObj.months) ys = ys + 1;
	if (diffObj.months === 0 && diffObj.days) ys = ys + 1;
	const arr = data.lease_start_date.split('/');
	const lstart_y = arr[2];
	const lstart_m = arr[0];
	const lstart_d = arr[1];
	let last_year_end = null;
	for (let i = 0; i < ys; i++) {
		let year_start = null;
		let year_end = null;
		if (i === 0) {
			// year 1
			year_start = moment(`${lstart_y}-${lstart_m}-${lstart_d}`).unix();
		} else {
			// if not year 1
			year_start = moment.unix(last_year_end).add(1, 'd').unix();
		}
		year_end = moment.unix(year_start).add(12, 'M').subtract(1, 'd').unix();

		if (year_end > lease_end_date_unix) {
			year_end = lease_end_date_unix;
		}

		if (year_end < year_start) {
			year_end = year_start;
		}

		last_year_end = year_end;

		year_rows.push({
			year_num: i + 1,
			year_start,
			year_end,
			ranges: [],
		});
	}
	return year_rows;
};

export const calculateAbatedRanges_RS = (args) => {
	const { lease_term, abated } = args;

	let negative_commissions = [];
	if (lease_term && abated) {
		lease_term.forEach((lt, i) => {
			// lt = 1 year
			if (lt.ranges) {
				// ltr = 1 range
				lt.ranges.forEach((ltr, j) => {
					// find out if each abated range applies
					const { date1, date2, commissionRate, value } = ltr;
					if (date1 && date2 && commissionRate) {
						const unix1 = convertToUnix(date1);
						const unix2 = convertToUnix(date2);
						abated.forEach((ab) => {
							if (ab) {
								if (ab.ranges) {
									ab.ranges.forEach((abr) => {
										const ab_unix1 = convertToUnix(abr.date1);
										const ab_unix2 = convertToUnix(abr.date2);

										// if this range's date falls between abated min and max
										if (unix1 >= ab_unix1 || unix2 <= ab_unix2) {
											// get days falling in the abated range
											let days = [];
											if (unix1 >= ab_unix1) {
												let d = unix1;
												while (d < ab_unix2 + 86400) {
													days.push(d);
													d += 86400;
												}
											} else if (unix2 <= ab_unix2) {
												let d = unix2;
												while (d > ab_unix1 - 86400) {
													days.push(d);
													d -= 86400;
												}
											}
											days.sort();

											let dates = [];
											days.forEach((dd) => {
												dates.push(moment.unix(dd).format('MM/DD/YYYY'));
											});

											// calculate what falls in abated range
											if (commissionRate && abr.value) {
												const newObj = {
													year_index: i,
													range_index: j,
													// ( monthly rent / 30 times days ) * commission
													//calc: ( (parseFloat(value) / 30) * dates.length ) * (parseFloat(commissionRate) * 0.01),
													calc:
														parseFloat(value) *
														(parseFloat(commissionRate) * 0.01),
													commissionRate,
													date_length: dates.length,
													value,
													dates,
												};
												negative_commissions.push(newObj);
											}
										}
									});
								}
							}
						});
					}
				});
			}
		});
	}

	return negative_commissions;
};

export const calculateAbatedRanges2_RS = (args) => {
	const { lease_term, abated } = args;

	let negative_commissions = [];

	if (lease_term && abated) {
		lease_term.forEach((lt, i) => {
			// lt = 1 year
			if (lt.ranges) {
				// ltr = 1 range
				const sortedRanges = sortBy(lt.ranges, (a) => new Date(a.date1));

				sortedRanges.forEach((ltr, j) => {
					// find out if each abated range applies
					const { date1, date2, commissionRate, value } = ltr;
					if (date1 && date2 && commissionRate && value) {
						// Find if range fall between the abated range
						abated.forEach((ab) => {
							if (ab && ab.ranges) {
								const sortedAbatedRanges = sortBy(
									ab.ranges,
									(a) => new Date(a.date1)
								);
								sortedAbatedRanges.forEach((abr) => {
									let abatedStart, abatedEnd;

									if (
										moment(date1).isSameOrAfter(abr.date1) &&
										moment(date1).isSameOrBefore(abr.date2)
									) {
										abatedStart = date1;
									} else if (
										moment(abr.date1).isSameOrAfter(date1) &&
										moment(abr.date1).isSameOrBefore(date2)
									) {
										abatedStart = abr.date1;
									}

									if (
										moment(date2).isSameOrAfter(abr.date1) &&
										moment(date2).isSameOrBefore(abr.date2)
									) {
										abatedEnd = date2;
									} else if (
										moment(abr.date2).isSameOrAfter(date1) &&
										moment(abr.date2).isSameOrBefore(date2)
									) {
										abatedEnd = abr.date2;
									}

									if (abatedStart && abatedEnd) {
										negative_commissions.push({
											abated: true,
											date1: abatedStart,
											date2: abatedEnd,
											value: abr.value,
											commissionRate,
										});
									}
								});
							}
						});
					}
				});
			}
		});
	}

	return negative_commissions;
};

export const makeAllRanges = (data) => {
	let allRanges = [];
	if (data.lease_term && Array.isArray(data.lease_term)) {
		let total_abated = 0;
		const abatedRanges = [];

		if (data.abated_rent_ranges) {
			data.abated_rent_ranges.forEach((arr, i) => {
				const tot = getAbatedTotal(arr);
				total_abated = tot;
				const range = {
					total: tot,
					year: arr.year_num,
				};
				abatedRanges.push(range);
			});
		}
		data.lease_term.forEach((ltt) => {
			const year = ltt.year_num;
			let abated = 0;
			let total = 0;
			let totalCommission = 0;
			ltt.ranges.forEach((mr) => {
				const { value, date1, date2, commissionRate } = mr;
				const calcs = calculateLeaseLogic_RS({
					date1,
					date2,
					value,
					data,
				});

				calcs.forEach((cc) => {
					total += cc.calcRounded;
					if (abatedRanges.length > 0) {
						for (let i = 0; i < abatedRanges.length; i++) {
							if (abatedRanges[i].year === year) {
								abated = abatedRanges[i].total;
							}
						}
					}
					totalCommission += commissionRate * 0.01 * cc.calcRounded;
				});
			});

			const range = {
				collected: total,
				abated: abated,
				commission: totalCommission,
				year: year,
			};

			allRanges.push(range);
		});
	}
	return allRanges;
};

export const makeTotalCalcs = (allRanges, calculatedAbatedRanges, data) => {
	let the_total = 0;
	let the_abated_total = 0;
	let the_commissions_added = 0;
	if (allRanges.length > 0) {
		allRanges.forEach((range) => {
			the_total += range.commission;
			the_abated_total += range.abated;
			the_commissions_added += range.commission;
		});
	} else {
		return {
			rentCollected: 'N/A',
			the_abated_total: 'N/A',
			netCollected: 'N/A',
			the_commissions_added: 'N/A',
			the_calculatedAR: 'N/A',
			netCommission: 'N/A',
		};
	}
	the_total = the_total - the_abated_total;
	let the_calculatedAR = 0;
	if (calculatedAbatedRanges) {
		calculatedAbatedRanges.forEach((car) => {
			if (car.dates) {
				if (car.dates.length > 0)
					the_calculatedAR += get2DecimalRound(car.calc);
			}
		});
	}
	const the_total_commission = the_commissions_added - the_calculatedAR;

	let rentCollected = 0;
	let abatedRent = 0;
	let totalCommission = 0;
	if (calculatedAbatedRanges) {
		calculatedAbatedRanges.forEach((car) => {
			abatedRent += get2DecimalRound(car.calc);
		});
	}
	if (data.lease_term && Array.isArray(data.lease_term)) {
		data.lease_term.forEach((lt, i) => {
			const termValidation = checkTermValidity(lt, data, 'lease_term');
			const abated_total = getAbatedTotal(
				data.abated_rent_ranges
					? data.abated_rent_ranges[i]
						? data.abated_rent_ranges[i]
						: null
					: null
			);
			// calculate months
			let total = 0;
			let subRentCollected = 0;
			//total = total - abated_total;
			// abatedRent += abated_total;
			const ystart = moment(lt.year_start * 1000).format('MM/DD/YYYY');
			const yend = moment(lt.year_end * 1000).format('MM/DD/YYYY');
			lt.ranges.forEach((ltt, j) => {
				const { value, date1, date2, commissionRate } = ltt;
				const isRequired = true;
				const calcs = calculateLeaseLogic_RS({
					date1,
					date2,
					value,
					data,
				});

				let allTotal = 0;
				let tableData = [];
				calcs.forEach((cc) => {
					allTotal += cc.calcRounded;
					tableData.push([
						cc.monthIn,
						cc.daysInMonth,
						`$ ${cc.calc ? commatize(cc.calc) : ''}`,
						`$ ${cc.calcRounded ? commatize(cc.calcRounded) : ''}`,
					]);
				});
				tableData.push([
					'',
					'',
					'',
					`Total rent for this range $ ${
						allTotal ? commatize(allTotal, true) : ''
					}`,
				]);
				total += allTotal;
				subRentCollected += allTotal;
				const length = getDateDiffs2(moment(date1), moment(date2));
				const allMonths = length.months
					? length.days
						? length.months + 1
						: length.months
					: 0;
				totalCommission += commissionRate ? commissionRate * allMonths : 0;
			});
			rentCollected += subRentCollected;
		});
	}

	const netCollected = rentCollected - the_abated_total;
	const netCommission = the_commissions_added - the_calculatedAR;

	return {
		rentCollected,
		the_abated_total,
		netCollected,
		the_commissions_added,
		the_calculatedAR,
		netCommission,
	};
};

export const getDateDifference = (data) => {
	let rent_commencement_is_more = false;
	if (data.rent_commencement && data.lease_start_date) {
		if (
			moment(data.rent_commencement, 'MM/DD/YYYY', true).isValid() &&
			moment(data.lease_start_date, 'MM/DD/YYYY', true).isValid()
		) {
			const timestamp1 = moment(
				data.rent_commencement,
				'MM/DD/YYYY',
				true
			).unix();
			const timestamp2 = moment(
				data.lease_start_date,
				'MM/DD/YYYY',
				true
			).unix();
			if (timestamp1 > timestamp2) {
				//rent_commencement_is_more = true;
			}
		}
	}
	const date1 = rent_commencement_is_more
		? data.rent_commencement
		: data.lease_start_date;
	const date2 = data.lease_end_date;
	if (date1 && date2) {
		const diffObj = getDateDiffs(convert(date1), convert(date2));

		return {
			years: diffObj.years,
			months: diffObj.months,
			days: diffObj.days,
		};
	}
	return null;
};

export const calculateStartingRent = (data) => {
	// total sqft divided by the rent amount

	if (data) {
		if (data.lease_term && Array.isArray(data.lease_term)) {
			let rent_amount = 0;
			const first = data.lease_term[0];
			if (first) {
				const lt = first;

				if (lt.ranges.length > 0) {
					const ltr = lt.ranges[0];
					rent_amount = ltr.value ? parseFloat(ltr.value) : 0;
				}

				if (data.total_sf_leased) {
					return commatize(
						get2DecimalRound(rent_amount / parseFloat(data.total_sf_leased)),
						true
					);
				}
			}
		}
	}
	return '';
};

export const processContact = (
	chosen,
	data,
	queryFor,
	setData,
	setQueryFor
) => {
	console.log('processing Contact', chosen, data, queryFor);
	if (!chosen) return;
	const overrideContacts = chosen.overrideContacts ? 
		chosen.overrideContacts.length > 0 ?
			chosen.overrideContacts[0]
		:
			null
	: 
		null;
	
	const newData = clone(data);
	if (queryFor === 'billing_information') {
		newData.billing_id = company_id; // 
		newData.billing_company = pc.name;

		newData.billing_dba_name = chosen?.dba_data?.find(i => i.id === chosen?.dba_id)?.name;
		newData.billing_dba_id = chosen?.dba_id;
		newData.billing_dba_type = 'company';
		newData.billing_dba_active = 1;

		newData.billing_attention = ss.first_name;
		newData.billing_attention_lastname = ss.last_name;
		newData.billing_address1 = pc.mailing_address1;
		newData.billing_address2 = pc.mailing_address2;
		newData.billing_city = pc.mailing_city;
		newData.billing_state = pc.mailing_state;
		newData.billing_zip = pc.mailing_zip;
		newData.billing_phone = pc.main_phone ? pc.main_phone : '';
		newData.billing_fax = pc.fax;
		newData.billing_email = pc.email ? pc.email : '';

		if (chosen.care_of_company) {
			const coc = chosen.care_of_company;
			newData.billing_company = `${pc.name} c/o ${coc.name}`;
			newData.billing_address1 = coc.mailing_address1;
			newData.billing_address2 = coc.mailing_address2;
			newData.billing_city = coc.mailing_city;
			newData.billing_state = coc.mailing_state;
			newData.billing_zip = coc.mailing_zip;
		}

		if (chosen.overrideContacts) {
			if (chosen.overrideContacts.length > 0) {
				const override = chosen.overrideContacts[0];
				const address = {};
				if (override.addresses) {
					override.addresses.forEach(add => {
						if (add.type === 'M') {
							address.mailing_address1 = add.street1;
							address.mailing_address2 = add.street2;
							address.mailing_city = add.city;
							address.mailing_state = add.state;
							address.mailing_zip = add.zip;
						}
					})
				}
				newData.billing_address1 = override.mailing_address1;
				newData.billing_address2 = override.mailing_address2;
				newData.billing_city = override.mailing_city;
				newData.billing_state = override.mailing_state;
				newData.billing_zip = override.mailing_zip;
			}
		}

		set(newData, `chosen.${queryFor}.company`, pc.id);
		set(newData, `chosen.${queryFor}.contact`, ss.id);
	}
	setData(newData);
	setQueryFor('');
}

export const processCompany = (
	chosen,
	data,
	queryFor,
	setData,
	setQueryFor
) => {
	console.log('processing Company', chosen, data, queryFor);
	// * Object binding
	// choosen: {
	//     billing: {
	//         company: [1,2,3],
	//         contact: [2,34,567]
	//     },
	//     lessee: {
	//         company: [1,2,3],
	//         contact: [2,34,567]
	//     },
	//     lessor: {
	//         company: [1,2,3],
	//         contact: [2,34,567]
	//     },
	// }

	if (!chosen) return;

	const pc = chosen.company_data;
	const ss = chosen.signers
		? chosen.signers.length > 0
			? chosen.signers[0]
			: {}
		: {};
	const ss_dat = ss ? ss.company_dats : {}; // company specific data
	const chosen_contacts = chosen.signers ? chosen.signers : [];
	const company_id = `c${pc.id}`;
	const newData = clone(data);
	if (!ss) return;

	if (queryFor === 'billing_information') {
		newData.billing_id = company_id;
		newData.billing_company = pc.name;
		newData.billing_dba_name = chosen?.dba_data.find(i => i.id === chosen?.dba_id)?.name;
		newData.billing_dba_id = chosen?.dba_id;
		newData.billing_dba_type = 'company';
		newData.billing_dba_active = 1;
		newData.billing_attention = ss.first_name;
		newData.billing_attention_lastname = ss.last_name;
		newData.billing_attention_dba_name = chosen?.contact_dba?.name;
		newData.billing_attention_dba_id = chosen?.contact_dba?.id;
		newData.billing_attention_dba_type = 'contact';
		newData.billing_address1 = pc.mailing_address1;
		newData.billing_address2 = pc.mailing_address2;
		newData.billing_city = pc.mailing_city;
		newData.billing_state = pc.mailing_state;
		newData.billing_zip = pc.mailing_zip;
		newData.billing_phone = ss_dat.phone ? ss_dat.phone : '';
		newData.billing_fax = pc.fax;
		newData.billing_email = ss_dat.email ? ss_dat.email : '';

		if (chosen.care_of_company) {
			const coc = chosen.care_of_company;
			newData.billing_company = `${pc.name} c/o ${coc.name}`;
			newData.billing_address1 = coc.mailing_address1;
			newData.billing_address2 = coc.mailing_address2;
			newData.billing_city = coc.mailing_city;
			newData.billing_state = coc.mailing_state;
			newData.billing_zip = coc.mailing_zip;
		}

		set(newData, `chosen.${queryFor}.company`, pc.id);
		set(newData, `chosen.${queryFor}.contact`, ss.id);
	} else if (queryFor === 'lessees') {
		const company_ids = get(newData, `chosen.${queryFor}.company`, []);
		company_ids.push(pc.id);
		set(newData, `chosen.${queryFor}.company`, company_ids);

		const newLessee = {
			id: company_id,
			company_name: pc.name,
			name: '',
			last_name: '',
			dba_name: chosen?.dba_data.find(i => i.id === chosen?.dba_id)?.name,
			dba_id: chosen?.dba_id,
			dba_type: 'company',
			dba_active: 1,
			main_phone: pc.main_phone,
			fax: pc.fax,
			email: pc.email,
			agency_disclosure: '',
			can_we_reach_out_for_testimonial: '',
			can_we_reach_out_for_testimonial_comment: '',
			llc_corp_verified: '',
			upload_sos: '',
			spousal_consent: '',
			upload_spousal_id: '',
			guarantor: '',
			guarantor_name: '',
			upload_guarantor_id: '',
			guarantor_spouse_name: '',
		};
		newLessee.address1 = pc.mailing_address1;
		newLessee.address2 = pc.mailing_address2;
		newLessee.city = pc.mailing_city;
		newLessee.state = pc.mailing_state;
		newLessee.zip = pc.mailing_zip;
		newData.lessees = newData.lessees.concat([newLessee]);

		if (chosen_contacts.length > 0) {
			chosen_contacts.forEach((ccss) => {
				const ccss_dat = ccss ? ccss.company_dats : {};
				let main_address = {};
				if (ccss.addresses) {
					ccss.addresses.forEach((cad) => {
						if (cad.type === 'M') {
							main_address = cad;
						}
					});
				}
				// add lessee contacts
				const newLesseeContact = {
					id: ccss.id,
					company_id: company_id,
					name: ccss.first_name,
					last_name: ccss.last_name,
					dba_name: chosen?.contact_dba?.name,
					dba_id: chosen?.contact_dba?.id,
					dba_type: 'contact',
					dba_active: 1,
					email: ccss_dat.email,
					contact_type: ccss.contact_type,
					main_phone: ccss_dat.phone,
					address1: main_address.street1,
					address2: main_address.street2,
					city: main_address.city,
					state: main_address.state,
					zip: main_address.zip,
				};
				// search current lessee contacts for existing
				if (newData.lessee_contacts) {
					let existingContact = false;
					newData.lessee_contacts.forEach((lc) => {
						if (lc.id === ccss.id) {
							existingContact = true;
						}
					});
					if (!existingContact) {
						newData.lessee_contacts = newData.lessee_contacts.concat([
							newLesseeContact,
						]);
					}
				}
				// Don't care if duplicate the id
				const contact_ids = get(newData, `chosen.lessee_contacts.contact`, []);
				contact_ids.push(ccss.id);
				set(newData, `chosen.lessee_contacts.contact`, contact_ids);
			});
		}
	} else if (queryFor === 'lessors') {
		const company_ids = get(newData, `chosen.${queryFor}.company`, []);
		company_ids.push(pc.id);
		set(newData, `chosen.${queryFor}.company`, company_ids);

		const newLessor = {
			id: company_id,
			company_name: pc.name,
			last_name: '',
			dba_name: chosen?.dba_data.find(i => i.id === chosen?.dba_id)?.name,
			dba_id: chosen?.dba_id,
			dba_type: 'company',
			dba_active: 1,
			main_phone: pc.main_phone,
			fax: pc.fax,
			email: pc.email,
			agency_disclosure: '',
			can_we_reach_out_for_testimonial: '',
			can_we_reach_out_for_testimonial_comment: '',
		};
		newLessor.address1 = pc.mailing_address1;
		newLessor.address2 = pc.mailing_address2;
		newLessor.city = pc.mailing_city;
		newLessor.state = pc.mailing_state;
		newLessor.zip = pc.mailing_zip;
		if (chosen_contacts.length > 0) {
			chosen_contacts.forEach((ccss) => {
				const ccss_dat = ccss ? ccss.company_dats : {};
				let main_address = {};
				if (ccss.addresses) {
					ccss.addresses.forEach((cad) => {
						if (cad.type === 'M') {
							main_address = cad;
						}
					});
				}
				// add lessor contacts
				const newLessorContact = {
					id: ccss.id,
					company_id: company_id,
					name: ccss.first_name,
					last_name: ccss.last_name,
					dba_name: chosen?.contact_dba?.name,
					dba_id: chosen?.contact_dba?.id,
					dba_type: 'contact',
					dba_active: 1,
					email: ccss_dat.email,
					contact_type: ccss.contact_type,
					main_phone: ccss_dat.phone,
					address1: main_address.street1,
					address2: main_address.street2,
					city: main_address.city,
					state: main_address.state,
					zip: main_address.zip,
				};
				// newLessor.lessor_contacts.push(newLessorContact)
				// search current lessor contacts for existing
				if (newData.lessor_contacts) {
					let existingContact = false;
					newData.lessor_contacts.forEach((lc) => {
						if (lc.id === ccss.id) {
							existingContact = true;
						}
					});
					if (!existingContact) {
						newData.lessor_contacts = newData.lessor_contacts.concat([
							newLessorContact,
						]);
					}
				}
				// Don't care if duplicate the id
				const contact_ids = get(newData, `chosen.lessor_contacts.contact`, []);
				contact_ids.push(ccss.id);
				set(newData, `chosen.lessor_contacts.contact`, contact_ids);
			});
		}

		newData.lessors = newData.lessors.concat([newLessor]);
	} else if (queryFor === 'assignors') {
		const company_ids = get(newData, `chosen.${queryFor}.company`, []);
		company_ids.push(pc.id);
		set(newData, `chosen.${queryFor}.company`, company_ids);

		const newAssignor = {
			id: company_id,
			company_name: pc.name,
			name: '',
			last_name: '',
			main_phone: pc.main_phone,
			fax: pc.fax,
			email: pc.email,
			agency_disclosure: '',
			can_we_reach_out_for_testimonial: '',
			can_we_reach_out_for_testimonial_comment: '',
		};
		newAssignor.address1 = pc.mailing_address1;
		newAssignor.address2 = pc.mailing_address2;
		newAssignor.city = pc.mailing_city;
		newAssignor.state = pc.mailing_state;
		newAssignor.zip = pc.mailing_zip;
		newData.assignors = newData.assignors.concat([newAssignor]);
		if (chosen_contacts.length > 0) {
			chosen_contacts.forEach((ccss) => {
				const ccss_dat = ccss ? ccss.company_dats : {};
				let main_address = {};
				if (ccss.addresses) {
					ccss.addresses.forEach((cad) => {
						if (cad.type === 'M') {
							main_address = cad;
						}
					});
				}
				// add assignor contacts
				const newAssignorContact = {
					id: ccss.id,
					company_id: company_id,
					name: ccss.first_name,
					last_name: ccss.last_name,
					email: ccss_dat.email,
					contact_type: ccss.contact_type,
					main_phone: ccss_dat.phone,
					address1: main_address.street1,
					address2: main_address.street2,
					city: main_address.city,
					state: main_address.state,
					zip: main_address.zip,
				};
				// search current lessor contacts for existing
				if (newData.assignor_contacts) {
					let existingContact = false;
					newData.assignor_contacts.forEach((lc) => {
						if (lc.id === ccss.id) {
							existingContact = true;
						}
					});
					if (!existingContact) {
						newData.assignor_contacts = newData.assignor_contacts.concat([
							newAssignorContact,
						]);
					}
				}
				// Don't care if duplicate the id
				const contact_ids = get(
					newData,
					`chosen.assignor_contacts.contact`,
					[]
				);
				contact_ids.push(ccss.id);
				set(newData, `chosen.assignor_contacts.contact`, contact_ids);
			});
		}
	} else if (queryFor === 'lessor_outside_broker') {
		newData.lessor_outside_broker_id = company_id;
		newData.lessor_outside_broker_company_name = pc.name;
		newData.lessor_outside_broker_name = '';
		newData.lessor_outside_broker_lastname = '';
		newData.lessor_outside_broker_main_phone = pc.main_phone;
		newData.lessor_outside_broker_fax = pc.fax;
		newData.lessor_outside_broker_email = pc.email;
		newData.lessor_outside_broker_address1 = pc.mailing_address1;
		newData.lessor_outside_broker_city = pc.mailing_city;
		newData.lessor_outside_broker_state = pc.mailing_state;
		newData.lessor_outside_broker_zip = pc.mailing_zip;
		if (chosen_contacts.length > 0) {
			chosen_contacts.forEach((ccss) => {
				// search for existing
				if (newData.illi_represented_lessor_agents) {
					let existingContact = false;
					newData.illi_represented_lessor_agents.forEach((lc) => {
						if (lc.id === ccss.id) {
							existingContact = true;
						}
					});
					if (!existingContact) {
						newData.illi_represented_lessor_agents.push({
							id: ccss.id,
							name: ccss.first_name,
							last_name: ccss.last_name,
						});
					}
				}
				// Don't care if duplicate the id
				const contact_ids = get(
					newData,
					`chosen.illi_represented_lessor_agents.contact`,
					[]
				);
				contact_ids.push(ccss.id);
				set(
					newData,
					`chosen.illi_represented_lessor_agents.contact`,
					contact_ids
				);
			});
		}
		set(newData, `chosen.${queryFor}.company`, pc.id);
	} else if (queryFor === 'assignor_outside_broker') {
		newData.assignor_outside_broker_id = company_id;
		newData.assignor_outside_broker_company_name = pc.name;
		newData.assignor_outside_broker_name = '';
		newData.assignor_outside_broker_lastname = '';
		newData.assignor_outside_broker_main_phone = pc.main_phone;
		newData.assignor_outside_broker_fax = pc.fax;
		newData.assignor_outside_broker_email = pc.email;
		newData.assignor_outside_broker_address1 = pc.mailing_address1;
		newData.assignor_outside_broker_city = pc.mailing_city;
		newData.assignor_outside_broker_state = pc.mailing_state;
		newData.assignor_outside_broker_zip = pc.mailing_zip;
		if (chosen_contacts.length > 0) {
			chosen_contacts.forEach((ccss) => {
				// search for existing
				if (newData.illi_represented_assignor_agents) {
					let existingContact = false;
					newData.illi_represented_assignor_agents.forEach((lc) => {
						if (lc.id === ccss.id) {
							existingContact = true;
						}
					});
					if (!existingContact) {
						newData.illi_represented_assignor_agents.push({
							id: ccss.id,
							name: ccss.first_name,
							last_name: ccss.last_name,
						});
					}
				}
				// Don't care if duplicate the id
				const contact_ids = get(
					newData,
					`chosen.illi_represented_assignor_agents.contact`,
					[]
				);
				contact_ids.push(ccss.id);
				set(
					newData,
					`chosen.illi_represented_assignor_agents.contact`,
					contact_ids
				);
			});
		}
		set(newData, `chosen.${queryFor}.company`, pc.id);
	} else if (queryFor === 'outside_broker') {
		newData.outside_broker_id = company_id;
		newData.outside_broker_company_name = pc.name;
		newData.outside_broker_name = '';
		newData.outside_broker_lastname = '';
		newData.outside_broker_main_phone = pc.main_phone;
		newData.outside_broker_fax = pc.fax;
		newData.outside_broker_email = pc.email;
		newData.outside_broker_address1 = pc.mailing_address1;
		newData.outside_broker_city = pc.mailing_city;
		newData.outside_broker_state = pc.mailing_state;
		newData.outside_broker_zip = pc.mailing_zip;
		if (chosen_contacts.length > 0) {
			chosen_contacts.forEach((ccss) => {
				// search for existing
				if (newData.illi_represented_lessee_agents) {
					let existingContact = false;
					newData.illi_represented_lessee_agents.forEach((lc) => {
						if (lc.id === ccss.id) {
							existingContact = true;
						}
					});
					if (!existingContact) {
						newData.illi_represented_lessee_agents.push({
							id: ccss.id,
							name: ccss.first_name,
							last_name: ccss.last_name,
						});
					}
				}
				// Don't care if duplicate the id
				const contact_ids = get(
					newData,
					`chosen.illi_represented_lessee_agents.contact`,
					[]
				);
				contact_ids.push(ccss.id);
				set(
					newData,
					`chosen.illi_represented_lessee_agents.contact`,
					contact_ids
				);
			});
		}
		set(newData, `chosen.${queryFor}.company`, pc.id);
	} else if (queryFor === 'referral_firm') {
		newData.referral_firm_id = company_id;
		newData.referral_firm_company_name = pc.name;
		newData.referral_firm_name = '';
		newData.referral_firm_lastname = '';
		newData.referral_firm_main_phone = pc.main_phone;
		newData.referral_firm_fax = pc.fax;
		newData.referral_firm_email = pc.email;
		newData.referral_firm_address1 = pc.mailing_address1;
		newData.referral_firm_city = pc.mailing_city;
		newData.referral_firm_state = pc.mailing_state;
		newData.referral_firm_zip = pc.mailing_zip;

		if (chosen?.contact_dba?.id) {
			newData.referral_firm_contact_dba_name = chosen.contact_dba.name;
			newData.referral_firm_contact_dba_id = chosen.contact_dba.id;
			newData.referral_firm_contact_dba_type = 'contact';
			newData.referral_firm_contact_dba_active = 1;
		}

		if (chosen?.dba_id && chosen?.dba_data?.length) {
			const dba = chosen.dba_data.find(i => i.id === chosen.dba_id);
			newData.referral_firm_company_dba_name = dba?.name;
			newData.referral_firm_company_dba_id = dba?.id;
			newData.referral_firm_company_dba_type = 'company';
			newData.referral_firm_company_dba_active = 1;
		}

		if (chosen_contacts.length > 0) {
			const contact = first(chosen_contacts);
			set(newData, 'referral_firm_name', contact.first_name);
			set(newData, 'referral_firm_lastname', contact.last_name);
		}

		if (chosen_contacts.length === 0) {
			set(newData, 'referral_firm_company_only', true)
		} else {
			set(newData, 'referral_firm_company_only', false)
		}

		set(newData, `chosen.${queryFor}.company`, pc.id);
		set(newData, `chosen.${queryFor}.contact`, ss.id);
	} else if (queryFor === 'lessee_referral_firm') {
		newData.lessee_referral_firm_id = company_id;
		newData.lessee_referral_firm_company_name = pc.name;
		newData.lessee_referral_firm_name = '';
		newData.lessee_referral_firm_lastname = '';
		newData.lessee_referral_firm_main_phone = pc.main_phone;
		newData.lessee_referral_firm_fax = pc.fax;
		newData.lessee_referral_firm_email = pc.email;
		newData.lessee_referral_firm_address1 = pc.mailing_address1;
		newData.lessee_referral_firm_city = pc.mailing_city;
		newData.lessee_referral_firm_state = pc.mailing_state;
		newData.lessee_referral_firm_zip = pc.mailing_zip;

		if (chosen?.contact_dba?.id) {
			newData.lessee_referral_firm_contact_dba_name = chosen.contact_dba.name;
			newData.lessee_referral_firm_contact_dba_id = chosen.contact_dba.id;
			newData.lessee_referral_firm_contact_dba_type = 'contact';
			newData.lessee_referral_firm_contact_dba_active = 1;
		}

		if (chosen?.dba_id && chosen?.dba_data?.length) {
			const dba = chosen.dba_data.find(i => i.id === chosen.dba_id);
			newData.lessee_referral_firm_company_dba_name = dba?.name;
			newData.lessee_referral_firm_company_dba_id = dba?.id;
			newData.lessee_referral_firm_company_dba_type = 'company';
			newData.lessee_referral_firm_company_dba_active = 1;
		}

		if (chosen_contacts.length > 0) {
			const contact = first(chosen_contacts);
			set(newData, 'lessee_referral_firm_name', contact.first_name);
			set(newData, 'lessee_referral_firm_lastname', contact.last_name);
		}

		if (chosen_contacts.length === 0) {
			set(newData, 'lessee_referral_firm_company_only', true)
		} else {
			set(newData, 'lessee_referral_firm_company_only', false)
		}

		set(newData, `chosen.${queryFor}.company`, pc.id);
		set(newData, `chosen.${queryFor}.contact`, ss.id);
	} else if (queryFor === 'assignor_referral_firm') {
		newData.assignor_referral_firm_id = company_id;
		newData.assignor_referral_firm_company_name = pc.name;
		newData.assignor_referral_firm_name = '';
		newData.assignor_referral_firm_lastname = '';
		newData.assignor_referral_firm_main_phone = pc.main_phone;
		newData.assignor_referral_firm_fax = pc.fax;
		newData.assignor_referral_firm_email = pc.email;
		newData.assignor_referral_firm_address1 = pc.mailing_address1;
		newData.assignor_referral_firm_city = pc.mailing_city;
		newData.assignor_referral_firm_state = pc.mailing_state;
		newData.assignor_referral_firm_zip = pc.mailing_zip;

		if (chosen_contacts.length > 0) {
			const contact = first(chosen_contacts);
			set(newData, 'assignor_referral_firm_name', contact.first_name);
			set(newData, 'assignor_referral_firm_lastname', contact.last_name);
		}

		if (chosen_contacts.length === 0) {
			set(newData, 'assignor_referral_firm_company_only', true)
		} else {
			set(newData, 'assignor_referral_firm_company_only', false)
		}

		set(newData, `chosen.${queryFor}.company`, pc.id);
		set(newData, `chosen.${queryFor}.contact`, ss.id);
	} else if (queryFor === 'escrow') {
		if (chosen.company_data) {
			const company_name = get(chosen, "company_data.name", "");
			const company_data = chosen.company_data;
			const signer = get(chosen, 'signers[0]', null);
			if (signer) {
				const company_dats = get(chosen, 'signers[0].company_dats', null);
				if (company_dats) {
					newData.escrow_contact_id = signer.id;
					newData.escrow_officer_name = `${signer.first_name} ${signer.last_name}`;
					newData.escrow_company = company_name;
					newData.escrow_phone = company_dats.phone;
					newData.escrow_email = company_dats.email;
					newData.escrow_address = company_data.mailing_address1;
					newData.escrow_address2 = company_data.mailing_address2;
					newData.escrow_city = company_data.mailing_city;
					newData.escrow_state = company_data.mailing_state;
					newData.escrow_zip = company_data.mailing_zip;
				}
			}
		}
	}
	setData(newData);
	setQueryFor('');
};

export const checkAllChanges = (data, {
	getCompanyChanges,
	getContactChanges,
	getPropertyChanges
}) => {
	let propertyChangesData = [];
	// * PROPERTY
	if (data && data.property_id && data.illi_number && data.project_name) {
		propertyChangesData.push({
			id: data.property_id,
			illi_number: data.illi_number,
			name: data.project_name,
			street1: data.street1,
		});
	}

	let changesData = [];
	// * CONTACTS & COMPANIES
	// Listings procured by
	if (data && data.listings_procured_by !== undefined) {
		data.listings_procured_by.forEach((dl) => {
			changesData.push({
				id: dl.id,
				name: dl.company_name,
				first_name: dl.name,
				last_name: dl.last_name,
				main_phone: dl.main_phone,
			});
		});
	}
	// Deal generated by
	// if (data.deal_generated_by !== undefined) {
	// 	data.deal_generated_by.forEach((dl) => {
	// 		changesData.push({
	// 			id: dl.id,
	// 			first_name: dl.name,
	// 			last_name: dl.last_name,
	// 			main_phone: dl.main_phone,
	// 		});
	// 	});
	// }

	// ****************
	// ** LESSEE TAB **
	// ****************
	// Lessees
	get(data, 'lessees', []).forEach((dl) => {
		changesData.push({
			id: dl.id,
			name: dl.company_name,
			first_name: dl.name,
			last_name: dl.last_name,
			email: dl.email ? dl.email : null,
			address: dl.address1 ? dl.address1 : null,
			city: dl.city ? dl.city : null,
			state: dl.state ? dl.state : null,
		});
	});
	// Lessee contacts
	get(data, 'lessee_contacts', []).forEach((dlc) => {
		changesData.push({
			id: dlc.id,
			first_name: dlc.name,
			last_name: dlc.last_name,
			address: dlc.address ? dlc.address : null,
			email: dlc.email ? dlc.email : null,
		});
	});
	// Lessee outside broker
	if (data && data.outside_broker_id) {
		const outside_broker_obj = {
			id: data.outside_broker_id,
			name: data.outside_broker_company_name,
			first_name: data.outside_broker_name,
			last_name: data.outside_broker_lastname,
			address: data.outside_broker_address1,
			city: data.outside_broker_city,
			state: data.outside_broker_state,
			zip: data.outside_broker_zip,
			email: data.outside_broker_email,
			fax: data.outside_broker_fax,
			main_phone: data.outside_broker_main_phone,
		};
		changesData.push(outside_broker_obj);
	}
	// Lessee referral firm
	if (data && data.lessee_referral_firm_id) {
		changesData.push({
			id: data.lessee_referral_firm_id,
			name: data.lessee_referral_firm_company_name,
			first_name: data.lessee_referral_firm_name,
			last_name: data.lessee_referral_firm_lastname,
			address: data.lessee_referral_firm_address1,
			city: data.lessee_referral_firm_city,
			state: data.lessee_referral_firm_state,
			zip: data.lessee_referral_firm_zip,
			email: data.lessee_referral_firm_email,
			fax: data.lessee_referral_firm_fax,
			main_phone: data.lessee_referral_firm_main_phone,
		});
	}

	// ****************
	// ** LESSORS TAB **
	// ****************
	// lessors
	get(data, 'lessors' ,[]).forEach((dl) => {
		changesData.push({
			id: dl.id,
			name: dl.company_name,
			first_name: dl.name,
			last_name: dl.last_name,
			email: dl.email ? dl.email : null,
			address: dl.address1 ? dl.address1 : null,
			city: dl.city ? dl.city : null,
			state: dl.state ? dl.state : null,
		});
	});
	// lessor contacts
	get(data, 'lessor_contacts', []).forEach((dlc) => {
		changesData.push({
			id: dlc.id,
			first_name: dlc.name,
			last_name: dlc.last_name,
			address: dlc.address ? dlc.address : null,
			email: dlc.email ? dlc.email : null,
		});
	});
	// lessor referral firm
	if (data && data.referral_firm_id) {
		changesData.push({
			id: data.referral_firm_id,
			name: data.referral_firm_company_name,
			first_name: data.referral_firm_name,
			last_name: data.referral_firm_lastname,
			address: data.referral_firm_address1,
			city: data.referral_firm_city,
			state: data.referral_firm_state,
			zip: data.referral_firm_zip,
			email: data.referral_firm_email,
			fax: data.referral_firm_fax,
			main_phone: data.referral_firm_main_phone,
		});
	}
	// lessor outside broker
	if (data && data.lessor_outside_broker_id) {
		changesData.push({
			id: data.lessor_outside_broker_id,
			name: data.lessor_outside_broker_company_name,
			first_name: data.lessor_outside_broker_name,
			last_name: data.lessor_outside_broker_lastname,
			address: data.lessor_outside_broker_address1,
			city: data.lessor_outside_broker_city,
			state: data.lessor_outside_broker_state,
			zip: data.lessor_outside_broker_zip,
			email: data.lessor_outside_broker_email,
			fax: data.lessor_outside_broker_fax,
			main_phone: data.lessor_outside_broker_main_phone,
		});
	}

	// ****************
	// ** ASSIGNORS TAB **
	// ****************
	// assignors
	if (data && data.assignors) {
		data.assignors.forEach((dl) => {
			changesData.push({
				id: dl.id,
				name: dl.company_name,
				first_name: dl.name,
				last_name: dl.last_name,
				email: dl.email ? dl.email : null,
				address: dl.address1 ? dl.address1 : null,
				city: dl.city ? dl.city : null,
				state: dl.state ? dl.state : null,
			});
		});
	}
	// assignor contacts
	if (data && data.assignor_contacts) {
		data.assignor_contacts.forEach((dlc) => {
			changesData.push({
				id: dlc.id,
				first_name: dlc.name,
				last_name: dlc.last_name,
				address: dlc.address ? dlc.address : null,
				email: dlc.email ? dlc.email : null,
			});
		});
	}
	// assignor referral firm
	if (data && data.assignor_referral_firm_id) {
		changesData.push({
			id: data.assignor_referral_firm_id,
			name: data.assignor_referral_firm_company_name,
			first_name: data.assignor_referral_firm_name,
			last_name: data.assignor_referral_firm_lastname,
			address: data.assignor_referral_firm_address1,
			city: data.assignor_referral_firm_city,
			state: data.assignor_referral_firm_state,
			zip: data.assignor_referral_firm_zip,
			email: data.assignor_referral_firm_email,
			fax: data.assignor_referral_firm_fax,
			main_phone: data.assignor_referral_firm_main_phone,
		});
	}
	// lessor outside broker
	if (data && data.assignor_outside_broker_id) {
		changesData.push({
			id: data.assignor_outside_broker_id,
			name: data.assignor_outside_broker_company_name,
			first_name: data.assignor_outside_broker_name,
			last_name: data.assignor_outside_broker_lastname,
			address: data.assignor_outside_broker_address1,
			city: data.assignor_outside_broker_city,
			state: data.assignor_outside_broker_state,
			zip: data.assignor_outside_broker_zip,
			email: data.assignor_outside_broker_email,
			fax: data.assignor_outside_broker_fax,
			main_phone: data.assignor_outside_broker_main_phone,
		});
	}

	// *****************************
	// ** BILLING INFORMATION TAB **
	// *****************************
	// billing company / first name
	if (data && data.billing_id) {
		const billingObj = {
			id: data.billing_id,
			name: data.billing_company,
			first_name: data.billing_attention,
			last_name: data.billing_attention_lastname,
			address: data.billing_address1,
			address2: data.billing_address2,
			city: data.billing_city,
			state: data.billing_state,
			zip: data.billing_zip,
			email: data.billing_email,
			fax: data.billing_fax,
			main_phone: data.billing_phone,
		};
		if (data.billing_id) {
			if (data.billing_id.toString().indexOf("c") !== -1) {
				billingObj.last_name = "";
				billingObj.first_name = data.billing_company;
			}
		}
		changesData.push(billingObj);

		// TODO : How about billing_company
	}

	let companyChangesData = [];
	let contactChangesData = [];
	changesData.forEach((cd) => {
		if (cd.id.toString().indexOf("c") === -1) {
			contactChangesData.push(cd);
		} else {
			companyChangesData.push(cd);
		}
	});

	getCompanyChanges(companyChangesData);
	getContactChanges(contactChangesData);
	getPropertyChanges(propertyChangesData);

	return;
}

export const checkChangesData = (propertyChangesData, contactChangesData, companyChangesData, data) => {
	let hasChangesData = false;

	const checkChanges = (changesData, arrayOfIDs) => {
		console.log('checking for changes', changesData, arrayOfIDs);
		if (changesData && changesData.length > 0) {
			forEach(changesData, (item) => {
				if (arrayOfIDs) {
					if (arrayOfIDs.indexOf(item.after.id) === -1) {
						forOwn(item.after, (value, key) => {
							if (!isEqual(value, item.before[key])) {
								hasChangesData = true;
							}
						});
					}
				} else {
					forOwn(item.after, (value, key) => {
					
						if (!isEqual(value, item.before[key])) {
							hasChangesData = true;
						}
					});
				}
			});
		}
	};

	checkChanges(propertyChangesData, get(data, 'changesDataDeclined.propertyChanges', null));
	checkChanges(contactChangesData, get(data, 'changesDataDeclined.contactChanges', null));
	checkChanges(companyChangesData, get(data, 'changesDataDeclined.companyChanges', null));

	return hasChangesData;
};

/**
 * Get the first full month and current index
 * @param {object} rsData
 * @returns {object} last FULL MONTH rent range from the currentRange
 */
export const getLastFullMonthRange = (rsData, currentYear, currentIndex, isAbated = false) => {
	let result = {
		range: null,
		yearIndex: null,
		rangeIndex: null,
	}

	if (isAbated) return result;

	const leaseTerm = get(rsData, 'lease_term', []);
	const isEstimated = isEstimatedLease(rsData);

	if (isEstimated) {
		for (let i = 0; i <= currentYear; i++) {
			const ranges = get(leaseTerm[i], 'ranges', []);
			ranges.sort((a, b) => {
				if (isSameOrAfter(a.month1, a.day1, b.month1, b.day2)) return 1;
				return -1;
			})
			console.log('cranges', ranges);
			let stop = ranges.length;
			if (i === currentYear) {
				console.log('cranges -', i, currentYear);
				stop = currentIndex;
			}
			for (let j = 0; j < stop; j++) {
				const item = ranges[j];
				if (item) {
					const monthStart = parseInt(item.month1.split(' ')[1]);
					const monthEnd = parseInt(item.month2.split(' ')[1]);
					const dayStart = parseInt(item.day1.split(' ')[1]);
					const dayEnd = parseInt(item.day2.split(' ')[1]);
					const nextMonth = {
						month: monthStart + 1,
						day: dayStart - 1,
					}
					if (nextMonth.day === 0) {
						nextMonth.month = nextMonth.month - 1;
						nextMonth.day = 30
					}
					if (isSameOrAfter(monthEnd, dayEnd, nextMonth.month, nextMonth.day)) {
						result = {
							range: item,
							yearIndex: i,
							rangeIndex: j,
						}
					}
				}
			}
		}
	} else {
		for (let i = 0; i <= currentYear; i++) {
			const ranges = sortBy(get(leaseTerm[i], 'ranges', []), 'date1');
			let stop = ranges.length ;
			if (i === currentYear) stop = currentIndex
			for (let j = 0; j < stop; j++) {
				const item = ranges[j];
				const endDate = moment(item.date2);
				const nextMonth = moment(item.date1).add(1, 'month').subtract(1, 'day');
				if (endDate.isSameOrAfter(nextMonth)) {
					result = {
						range: item,
						yearIndex: i,
						rangeIndex: j,
					}
				}
			}
		}
	}
	return result;
}

/**
 * Get the position of the current range from the first full month
 * @return {number} position
 */
export const getCurrentPositionFromTheFirstFullMonth = (rsData, currentYear, currentIndex) => {
	const lastFullMonth = getLastFullMonthRange(rsData, currentYear, currentIndex);
	const yearIndex = lastFullMonth.yearIndex
	const rangeIndex = lastFullMonth.rangeIndex

	if (currentYear < yearIndex) return null;

	if (currentYear === yearIndex) {
		if (currentIndex < rangeIndex) return null;
		return currentIndex - rangeIndex
	}

	if (lastFullMonth.range) {
		const leaseTerm = get(rsData, 'lease_term', []);

		let position = 0;
		for (let i = yearIndex; i < currentYear; i++) {
			const ranges = get(leaseTerm[i], 'ranges', []);

			if (i === yearIndex) {
				position += ranges.length - rangeIndex - 1;
			} else if (i === currentYear) {
				position += currentIndex
			} else {
				position += ranges.length
			}
		}
		return position
	}
}

/**
 * Extracts the requirements for a given section of data and checks if they are complete
 * @param {Object} obj - An object containing the sectionsObject, v, and data
 * @param {Object} obj.sectionsObject - An object containing the sections and their fields
 * @param {string} obj.v - The section to extract requirements for
 * @param {Object} obj.data - The data to check for requirements
 * @returns {Object} - An object containing the completeness of the requirements and any errors
 */
export const extractRequirements = (obj) => {
	const {
		sectionsObject,
		v,
		data,
	} = obj;

	let errors = [];
	let complete = true;
	if (v === 'Property Information' 
		|| v === 'Premises Information' 
		|| v === 'Lease Information'
		|| v === 'Commission Adjustments'
		|| v === 'Lessee'
	) {
		const sectionObj = sectionsObject[v];
		if (!sectionObj) return {
			errors, complete
		}
		const sectionFields = Object.keys(sectionObj);
		sectionFields.forEach(sfield => {
			const requireType = sectionObj[sfield][0];
			const requireLabel = sectionObj[sfield][1];
			const requireFieldDependency = sectionObj[sfield][2];
			if (requireType === 'required') {
				if (!data[sfield]) {
					if (requireLabel === 'Lease End Date' && (!!data["lease_start_date_estimated"] ||!!data["lease_start_date_estimated_tbd"])) {
						return;
					}
					errors.push(requireLabel + ' required');
					complete = false;
				}
			} else if (requireType === 'array_required') {
				if (get(data, `[${sfield}].length`) === 0) {
					errors.push(requireLabel + ' required');
					complete = false;
				}
			} else if (requireType === 'yes_required') {
				if (requireFieldDependency) {
					if (data[requireFieldDependency] === 'Yes') {
						if (!data[sfield]) {
							errors.push(requireLabel + ' required');
							complete = false;
						}
					}
				}
			} else if (requireType === 'yes_array_required') {
				if (requireFieldDependency) {
					if (data[requireFieldDependency] === 'Yes') {
						if (data[sfield]) { 
							if (data[sfield].length === 0) {
								errors.push(requireLabel + ' required');
								complete = false;
							}
						}
					}
				}
			} else if (requireType === 'exist_required') {
				if (requireFieldDependency) {
					if (data[requireFieldDependency]) {
						if (!data[sfield]) {
							errors.push(requireLabel + ' required');
							complete = false;
						}
					}
				}
			}
		});
	}
	return {
		complete,
		errors
	}
}

export const getRsheetPermissions = () => {
	const user = getUser();
	const permsArr = Object.keys(get(user, 'permObj', {})).filter(f => {
		if (f.indexOf('Routing') !== -1) return true;
	}).map(u => {
		const usplit = u.split(' ');
		return `${usplit[1]} ${usplit[2]}`;
	});
	const rsheetPerms = {
		fill: false,
		illi_number_edit: false,
		approve: []
	};
	permsArr.forEach(p => {
		if (p === 'fill form') rsheetPerms.fill = true;
		if (p === 'number edit') rsheetPerms.illi_number_edit = true;
		if (p.indexOf('approve') !== -1) {
			const level = p.split('level_')[1];
			if (level) rsheetPerms.approve.push(parseInt(level));
		}
	})
	rsheetPerms.approve.sort(function(a, b) {
		return a - b;
	});
	return rsheetPerms;
}

export const getRsheetLadderOptions = () => {
	// STATUS ID's
	// 1 - Draft 
	// 2 - Pending level 1    
	// 3 - Pending level 2
	// 4 - Pending level 3 
	// 5 - Rejected status 2 to Submitter
	// 6 - Rejected status 3 to Level 1 
	// 7 - Rejected status 4 to Level 2
	// 8 - Rejected status 10 to Level 3
	// 9 - FINAL APPROVAL 
	// 10 - Pending level 4 
	const descriptions = {
		1: 'Draft',
		2: 'Approve to Pending Level 1',
		3: 'Approve to Pending Level 2',
		4: 'Approve to Pending Level 3',
		5: 'Reject to Submitter',
		6: 'Reject to Level 1',
		7: 'Reject to Level 2',
		8: 'Reject to Level 3',
		9: 'Approved (Final)',
		10: 'Approve to Pending Level 4'
	}

	const status_descriptions = {
		1: 'Draft',
		2: 'Pending Level 1',
		3: 'Pending Level 2',
		4: 'Pending Level 3',
		5: 'Rejected to Submitter',
		6: 'Rejected to Level 1',
		7: 'Rejected to Level 2',
		8: 'Rejected to Level 3',
		9: 'Approved (Final)',
		10: 'Pending Level 4'
	}

	const approves = {
		1: 3,
		2: 4,
		3: 10,
		4: 9
	}
	const denials = {
		1: [5],
		2: [5,6],
		3: [5,6,7],
		4: [5,6,7,8]
	}

	const denyObj = {
		2: [5],
		3: [5,6],
		4: [5,6,7],
		10: [5,6,7,8]
	}

	const userPerms = getRsheetPermissions();
	let options = {
		denials: [],
		approveObj: {},
		denyObj,
		userPerms,
		descriptions,
		status_descriptions
	};
	console.log('userPerms', userPerms);
	userPerms.approve.forEach(level => {
		options.approveObj = approves;
		denials[level].forEach(dl => {
			let found = false;
			options.denials.forEach(od => {
				if (od.status_to_change_to === dl) {
					found = true;
				}
			})
			if (!found) {
				options.denials.push({
					status_to_change_to: dl,
					description: descriptions[dl]
				})
			}
		})
	})
	return options;
}

export const rsTypes = (name) => {
	const obj = {
		'RESIDENTIAL': 1,
		'LEASE': 2,
		'SUB-LEASE': 3,
		'SALE': 4,
		'TENANT REP': 5,
		'CONSULTING': 6,
		'OPTION COMMISSION': 7,
		'NO LISTING': 8,
		'LEASE ASSIGNMENT FEE': 9
	}
	return obj[name];
}

export const isSaleRSType = (formData) => {
	let isSale = false;
	if (formData) {
		if (formData.rsType) {
			if (formData.rsType === 4) isSale = true;
		}
	}
	return isSale;
}

export const getFlatFeeCommissionFromSale = (rsData) => {
	if (rsData) {
		if (rsData.sale_sale_commission) {
			if (typeof rsData.sale_sale_commission === 'number') return rsData.sale_sale_commission;
			console.log('rrs', rsData.sale_sale_commission)
			return parseFloat(rsData.sale_sale_commission.replace(/[$,]/g, ""));
		}
	}
	return 0;
}

export const checkInternalReview = (obj) => {
	let { errors, complete, data, internalReviewers } = obj;
	if (!data.require_internal_review) {
		complete = false;
		errors.push('Internal Review yes or no should be checked');
	} else {
		if (data.require_internal_review === 'yes') {
			if (!internalReviewers) {
				complete = false;
				errors.push('Incomplete Internal Reviewer approval');
			} else {
				if (internalReviewers.length === 0) {
					errors.push('No Internal Reviewers yet, please add one');
					complete = false;
				} else {
					let all_reviews_complete = true;
					internalReviewers.forEach(di => {
						if (di.review) {
							const status = get(di, 'review.status', null);
							if (status !== 2) {
								// approved
								all_reviews_complete = false;
							}
						}
					});
					if (!all_reviews_complete) {
						errors.push('Incomplete Internal Reviewer approval');
						complete = false;
					}
				}
			}
		}
	}
	return {
		complete, 
		errors
	}
}

export const getChosenSpace = (data) => {
    const chosenObj = {
        unit_number: '',
        sf: '',
        rent_per_sf: '',
        date_available: '',
        lease_type: '',
        monthly_nnn: '',
        sub_lease: '',
        min_divisible: '',
        max_contigous: '',
        lease_term: '',
        property_type: '',
        build_out: ''
    }
    if (data.spaces) {
        if (data.spaces.length > 0 && data.unit_number) {
            for (let i = 0; i < data.spaces.length; i++) {
                const space = data.spaces[i];
                if (space.unit_number_address === data.unit_number) {
                    chosenObj.unit_number = space.unit_number_address;
                    chosenObj.sf = space.total_sqft;
                    chosenObj.rent_per_sf = space.price_per_sqft;
                    chosenObj.date_available = space.date_available;
                    chosenObj.lease_type = space.lease_type;
                    // chosenObj.monthly_nnn = ?
                    chosenObj.sub_lease = space.sub_lease;
                    chosenObj.min_divisible = space.min_divisible;
                    chosenObj.max_contigous = space.max_contigous;
                    // chosenObj.lease_term = ?
                    // chosenObj.property_type = ?
                    // chosenObj.build_out = ?
                }
            }
        }
    }
    return chosenObj;
}

export const getTotalLineItems = (data) => {
	let num = 0;
	if (data) {
		data.forEach(dil => {
			num += dil.amount;
		})
	}
	return num;
}

const presplitsDictionary = {
	'Pre-splits - Both'   : 'Both Lessor and Lessee',
	'Pre-splits - Lessor' : 'Lessor Only',
	'Pre-splits - Lessee' : 'Lessee Only',
	'Pre-splits'		  : 'Both Lessor and Lessee'
}

export const addCommissionSplits = async (newData, rsType, cs) => {
	console.log('chosed', cs, newData);
	if (cs.json_data) {
		// COMMISSION SPLITS ASSOCIATES
		if (cs.json_data.contacts) {
			const cs_contacts = cs.json_data.contacts;
			console.log('cs choose', cs_contacts);
			if (cs_contacts) {
				let associate_commission_splits_lessee = [];
				let associate_commission_splits_lessor = [];
				cs_contacts.forEach(csc => {
					if (csc.referral_type === 'Lessee') {
						associate_commission_splits_lessee.push({
							id: csc.id,
							Name: `${csc.first_name} ${csc.last_name}`,
							Percentage: csc.commission_percent ? csc.commission_percent : 0,
							value: 0,
							dba_type: 'contact'
						})
					}
					if (csc.referral_type === 'Lessor') {
						associate_commission_splits_lessor.push({
							id: csc.id,
							Name: `${csc.first_name} ${csc.last_name}`,
							Percentage: csc.commission_percent ? csc.commission_percent : 0,
							value: 0,
							dba_type: 'contact'
						})
					}
				})
				newData.commission_splits_agent2 = associate_commission_splits_lessee;
				newData.commission_splits_agent = associate_commission_splits_lessor;
			}
		}
		// OUTSIDE BROKERS
		if (cs.json_data.outside_brokers) {
			let outside_brokers_lessee = [];
			let outside_brokers_lessor = [];
			const outside_brokers = cs.json_data.outside_brokers;
			for (let i = 0; i < outside_brokers.length; i++) {
				const ob = outside_brokers[i];
				let companyo = null;
				let contacto = null;
				let companyo_contacto = null;
				if (ob.company_id) {
					try {
						const response = await axios.get('/company?id='+ob.company_id);
						if (response.data) {
							companyo = response.data;
						}
					} catch(e) {
						handleError(e);
					}
				}
				if (ob.contact_id) {
					try {
						const response = await axios.get('/contact?id='+ob.contact_id);
						if (response.data) {
							contacto = response.data;
						}
					} catch(e) {
						handleError(e);
					}
				} 
				if (ob.company_contact_id) {
					try {
						const response = await axios.get('/company_contacts?contact_id='+ob.contact_id);
						if (response.data) {
							console.log('respons', response.data);
							for (let j = 0; j < response.data.length; j++) {
								if (response.data[j].id === ob.company_contact_id) {
									companyo_contacto = response.data[j];
								}
							}
						}
					} catch(e) {
						handleError(e);
					}
				}
				console.log('ob', contacto, companyo, companyo_contacto);
				if (companyo && contacto) {
					// COMPANY CONTACT
					const obj = {
						address1: companyo.mailing_address1,
						address2: companyo.mailing_address2,
						agents: '',
						city: companyo.mailing_city,
						commission: '',
						company_id: companyo.id,
						company_name: companyo.name,
						contact_id: contacto.id,
						email: contacto.email, // COMPANY CONTACT EMAIL
						fax: '',
						first_name: contacto.first_name,
						last_name: contacto.last_name,
						main_phone: contacto.main_phone, // COMPANY CONTACT EMAIL
						mobile_phone: '',
						name: `${contacto.first_name ? contacto.first_name : ''} 
							${contacto.last_name ? contacto.last_name : ''}`,
						outside_broker_commission: '',
						commission: ob.commission_percent ? 
							parseFloat(ob.commission_percent) 
						: 
							'',
						percentage: ob.commission_percent ? 
							parseFloat(ob.commission_percent) 
						: 
							'',
						state: companyo.mailing_state,
						tax_id: '',
						value: ob.commission_flat_amt ? parseFloat(ob.commission_flat_amt) : 0,
						w9: '',
						w9_file: '',
						work_phone: '',
						zip: companyo.mailing_zip
					}
					if (ob.referral_type === 'Lessee') {
						outside_brokers_lessee.push(obj);
					} else if (ob.referral_type === 'Lessor') {
						outside_brokers_lessor.push(obj);
					} 
					
				} else if (contacto && !companyo) {
					// CONTACT ONLY
					let cadd = {};
					if (contacto.addresses) {
						contacto.addresses.forEach(cad => {
							if (cad.type === 'M') {
								cadd = cad;
							}
						})
					}
					const obj = {
						address1: cadd.street2,
						address2: cadd.street1,
						agents: '',
						city: cadd.city,
						commission: '',
						company_id: '',
						company_name: '',
						contact_id: contacto.id,
						email: contacto.email,
						fax: '',
						first_name: contacto.first_name,
						last_name: contacto.last_name,
						main_phone: contacto.main_phone,
						mobile_phone: '',
						name: `${contacto.first_name ? contacto.first_name : ''} 
							${contacto.last_name ? contacto.last_name : ''}`,
						outside_broker_commission: '',
						commission: ob.commission_percent ? 
							parseFloat(ob.commission_percent) 
						: 
							'',
						percentage: ob.commission_percent ? 
							parseFloat(ob.commission_percent) 
						: 
							'',
						state: cadd.state,
						tax_id: '',
						value: ob.commission_flat_amt ? 
							parseFloat(ob.commission_flat_amt) 
						: 
							0,
						w9: '',
						w9_file: '',
						work_phone: '',
						zip: cadd.zip
					}
					if (ob.referral_type === 'Lessee') {
						outside_brokers_lessee.push(obj);
					} else if (ob.referral_type === 'Lessor') {
						outside_brokers_lessor.push(obj);
					}
				} else if (companyo && !contacto) {
					// COMPANY
					const obj = {
						address1: companyo.mailing_address1,
						address2: companyo.mailing_address2,
						agents: '',
						city: companyo.mailing_city,
						commission: '',
						company_id: companyo.id,
						company_name: companyo.name,
						contact_id: '',
						email: companyo.email, // COMPANY CONTACT EMAIL
						fax: '',
						first_name: '',
						last_name: '',
						main_phone: companyo.main_phone, // COMPANY CONTACT EMAIL
						mobile_phone: '',
						name: `${contacto.first_name ? contacto.first_name : ''} 
							${contacto.last_name ? contacto.last_name : ''}`,
						outside_broker_commission: '',
						commission: ob.commission_percent ? 
							parseFloat(ob.commission_percent) 
						: 
							'',
						percentage: ob.commission_percent ? 
							parseFloat(ob.commission_percent) 
						: 
							'',
						state: companyo.mailing_state,
						tax_id: '',
						value: ob.commission_flat_amt ? 
							parseFloat(ob.commission_flat_amt) 
						: 
							0,
						w9: '',
						w9_file: '',
						work_phone: '',
						zip: companyo.mailing_zip
					}
					if (ob.referral_type === 'Lessee') {
						outside_brokers_lessee.push(obj);
					} else if (ob.referral_type === 'Lessor') {
						outside_brokers_lessor.push(obj);
					}
				}
			}
			newData.outside_broker = outside_brokers_lessee;
			newData.lessor_outside_broker = outside_brokers_lessor;
			console.log('newD', newData);
			
		}

		// REFERRALS
		if (cs.json_data.referrals) {
			let referrals_lessee = [];
			let referrals_lessor = [];
			let referrals_presplits = [];
			const referrals = cs.json_data.referrals;
			for (let i = 0; i < referrals.length; i++) {
				const ref = referrals[i];
				let companyo = null;
				let contacto = null;
				let companyo_contacto = null;
				if (ref.company_id) {
					try {
						const response = await axios.get('/company?id='+ref.company_id);
						if (response.data) {
							companyo = response.data;
						}
					} catch(e) {
						handleError(e);
					}
				}
				if (ref.contact_id) {
					try {
						const response = await axios.get('/contact?id='+ref.contact_id);
						if (response.data) {
							contacto = response.data;
						}
					} catch(e) {
						handleError(e);
					}
				} 
				if (ref.company_contact_id) {
					try {
						const response = await axios.get('/company_contacts?contact_id='+ref.contact_id);
						if (response.data) {
							console.log('respons', response.data);
							for (let j = 0; j < response.data.length; j++) {
								if (response.data[j].id === ref.company_contact_id) {
									companyo_contacto = response.data[j];
								}
							}
						}
					} catch(e) {
						handleError(e);
					}
				}
				console.log('ref', contacto, companyo, companyo_contacto);
				if (companyo && contacto) {
					// COMPANY CONTACT
					const refj = {
						address1: companyo.mailing_address1,
						address2: companyo.mailing_address2,
						agents: '',
						city: companyo.mailing_city,
						commission: '',
						company_id: companyo.id,
						company_name: companyo.name,
						contact_id: contacto.id,
						email: companyo_contacto.email, // COMPANY CONTACT EMAIL
						fax: '',
						first_name: contacto.first_name,
						last_name: contacto.last_name,
						main_phone: companyo_contacto.phone, // COMPANY CONTACT EMAIL
						mobile_phone: '',
						name: `${contacto.first_name ? contacto.first_name : ''} 
							${contacto.last_name ? contacto.last_name : ''}`,
						outside_broker_commission: '',
						commission: ref.commission_percent ? 
							parseFloat(ref.commission_percent) 
						: 
							'',
						percentage: ref.commission_percent ? 
							parseFloat(ref.commission_percent) 
						: 
							'',
						state: companyo.mailing_state,
						tax_id: '',
						value: ref.commission_flat_amt ? parseFloat(ref.commission_flat_amt) : 0,
						w9: '',
						w9_file: '',
						work_phone: '',
						zip: companyo.mailing_zip
					}
					if (ref.referral_type === 'Lessee') {
						referrals_lessee.push(refj);
					} else if (ref.referral_type === 'Lessor') {
						referrals_lessor.push(refj);
					} else if (ref.referral_type.indexOf('Pre-splits') !== -1) {
						referrals_presplits.push({
							company_id: refj.company_id ? refj.company_id : '',
							company_name: refj.company_name ? refj.company_name : '',
							contact_id: refj.contact_id ? refj.contact_id : '',
							dollar_value: refj.value ? refj.value : '',
							first_name: refj.first_name,
							last_name: refj.last_name,
							percentage_value: refj.percentage ? refj.percentage : '',
							presplit_applies_to: presplitsDictionary[ref.referral_type],
							value_type: refj.percentage ? 'Percentage' : 'Dollar Value'
						});
					}
				} else if (contacto && !companyo) {
					// CONTACT ONLY
					let cadd = {};
					if (contacto.addresses) {
						contacto.addresses.forEach(cad => {
							if (cad.type === 'M') {
								cadd = cad;
							}
						})
					}
					const refj = {
						address1: cadd.street2,
						address2: cadd.street1,
						agents: '',
						city: cadd.city,
						commission: '',
						company_id: '',
						company_name: '',
						contact_id: contacto.id,
						email: contacto.email,
						fax: '',
						first_name: contacto.first_name,
						last_name: contacto.last_name,
						main_phone: contacto.main_phone,
						mobile_phone: '',
						name: `${contacto.first_name ? contacto.first_name : ''} 
							${contacto.last_name ? contacto.last_name : ''}`,
						outside_broker_commission: '',
						commission: ref.commission_percent ? 
							parseFloat(ref.commission_percent) 
						: 
							'',
						percentage: ref.commission_percent ? 
							parseFloat(ref.commission_percent) 
						: 
							'',
						state: cadd.state,
						tax_id: '',
						value: ref.commission_flat_amt ? 
							parseFloat(ref.commission_flat_amt) 
						: 
							0,
						w9: '',
						w9_file: '',
						work_phone: '',
						zip: cadd.zip
					}
					if (ref.referral_type === 'Lessee') {
						referrals_lessee.push(refj);
					} else if (ref.referral_type === 'Lessor') {
						referrals_lessor.push(refj);
					} else if (ref.referral_type.indexOf('Pre-splits') !== -1) {
						referrals_presplits.push({
							company_id: refj.company_id ? refj.company_id : '',
							company_name: refj.company_name ? refj.company_name : '',
							contact_id: refj.contact_id ? refj.contact_id : '',
							dollar_value: refj.value ? refj.value : '',
							first_name: refj.first_name,
							last_name: refj.last_name,
							percentage_value: refj.percentage ? refj.percentage : '',
							presplit_applies_to: presplitsDictionary[ref.referral_type],
							value_type: refj.percentage ? 'Percentage' : 'Dollar Value'
						});
					}
				} else if (companyo && !contacto) {
					// COMPANY
					const refj = {
						address1: companyo.mailing_address1,
						address2: companyo.mailing_address2,
						agents: '',
						city: companyo.mailing_city,
						commission: '',
						company_id: companyo.id,
						company_name: companyo.name,
						contact_id: '',
						email: companyo.email, // COMPANY CONTACT EMAIL
						fax: '',
						first_name: '',
						last_name: '',
						main_phone: companyo.main_phone, // COMPANY CONTACT EMAIL
						mobile_phone: '',
						name: `${contacto.first_name ? contacto.first_name : ''} 
							${contacto.last_name ? contacto.last_name : ''}`,
						outside_broker_commission: '',
						commission: ref.commission_percent ? 
							parseFloat(ref.commission_percent) 
						: 
							'',
						percentage: ref.commission_percent ? 
							parseFloat(ref.commission_percent) 
						: 
							'',
						state: companyo.mailing_state,
						tax_id: '',
						value: ref.commission_flat_amt ? 
							parseFloat(ref.commission_flat_amt) 
						: 
							0,
						w9: '',
						w9_file: '',
						work_phone: '',
						zip: companyo.mailing_zip
					}
					if (ref.referral_type === 'Lessee') {
						referrals_lessee.push(refj);
					} else if (ref.referral_type === 'Lessor') {
						referrals_lessor.push(refj);
					} else if (ref.referral_type.indexOf('Pre-splits') !== -1) {
						referrals_presplits.push({
							company_id: refj.company_id ? refj.company_id : '',
							company_name: refj.company_name ? refj.company_name : '',
							contact_id: refj.contact_id ? refj.contact_id : '',
							dollar_value: refj.value ? refj.value : '',
							first_name: refj.first_name,
							last_name: refj.last_name,
							percentage_value: refj.percentage ? refj.percentage : '',
							presplit_applies_to: presplitsDictionary[ref.referral_type],
							value_type: refj.percentage ? 'Percentage' : 'Dollar Value'
						});
					}
				}
			}
			console.log('rless import', referrals_lessee, referrals_lessor, referrals_presplits);
			// START IMPORTING DATA 
			if (referrals_lessee.length > 0) {
				// first ref 
				const ref1 = referrals_lessee[0];
				newData.lessee_referral_firm_address1 = ref1.address1;
				newData.lessee_referral_firm_city = ref1.city;
				newData.lessee_referral_firm_company_name = ref1.company_name;
				newData.lessee_referral_firm_contact = ref1.name;
				newData.lessee_referral_firm_contact_dba_name = '';
				newData.lessee_referral_firm_email = ref1.email;
				newData.lessee_referral_firm_fax = '';
				newData.lessee_referral_firm_fee_amount = ref1.value ? ref1.value : '';
				newData.lessee_referral_firm_fee_amount_choice = ref1.percentage ? 'Percentage' : '';
				newData.lessee_referral_firm_fee_amount_percentage = ref1.percentage ? ref1.percentage : '';
				if (ref1.contact_id && !ref1.company_id) {
					// Contact only
					newData.lessee_referral_firm_id = ref1.contact_id;
				} else if (ref1.company_id && !ref1.contact_id) {
					// Company only
					newData.lessee_referral_firm_id = 'c' + ref1.company_id;
				} else if (ref1.company_id && ref1.contact_id) {
					// Company with Contact
					newData.lessee_referral_firm_id = 'c' + ref1.company_id;
				}
				
				newData.lessee_referral_firm_lastname = ref1.last_name;
				newData.lessee_referral_firm_main_phone = ref1.phone;
				newData.lessee_referral_firm_mobile_phone = '';
				newData.lessee_referral_firm_name = ref1.first_name;
				newData.lessee_referral_firm_state = '';
				newData.lessee_referral_firm_taxid = '';
				newData.lessee_referral_firm_work_phone = '';
				newData.lessee_referral_firm_zip = ref1.zip;

				// second or more ref
				if (referrals_lessee.length > 1) {
					let arr = [];
					referrals_lessee.forEach((rl,j) => {
						if (j !== 0) {
							arr.push(
								{
									"id": rl.contact_id ? rl.contact_id : '',
									"name": rl.first_name,
									"lastname": rl.last_name,
									"city": rl.city,
									"state": rl.state,
									"zip": rl.zip,
									"main_phone": rl.phone,
									"email": rl.email,
									"fax": null,
									"contact_dba_type": "contact",
									"address1": rl.address1,
									"fee_amount_choice": rl.percentage ? "Percentage" : "Value",
									"fee_amount_percentage": rl.percentage ? rl.percentage : rl.value
								});
						}
					});
					newData.lessee_referrals = arr;
				}
			}
			if (referrals_lessor.length > 0) {
				// first ref 
				const ref1 = referrals_lessor[0];
				newData.referral_firm_address1 = ref1.address1;
				newData.referral_firm_city = ref1.city;
				newData.referral_firm_company_name = '';
				newData.referral_firm_contact = '';
				newData.referral_firm_contact_dba_name = '';
				newData.referral_firm_email = ref1.email;
				newData.referral_firm_fax = '';
				newData.referral_firm_fee_amount = ref1.value ? ref1.value : '';
				newData.referral_firm_fee_amount_choice = ref1.percentage ? 'Percentage' : '';
				newData.referral_firm_fee_amount_percentage = ref1.percentage ? ref1.percentage : '';
				if (ref1.contact_id && !ref1.company_id) {
					// Contact only
					newData.referral_firm_id = ref1.contact_id;
				} else if (ref1.company_id && !ref1.contact_id) {
					// Company only
					newData.referral_firm_id = 'c' + ref1.company_id;
				} else if (ref1.company_id && ref1.contact_id) {
					// Company with Contact
					newData.referral_firm_id = 'c' + ref1.company_id;
				}
				
				newData.referral_firm_lastname = ref1.last_name;
				newData.referral_firm_main_phone = ref1.phone;
				newData.referral_firm_mobile_phone = '';
				newData.referral_firm_name = ref1.first_name;
				newData.referral_firm_state = ref1.state;
				newData.referral_firm_taxid = '';
				newData.referral_firm_work_phone = '';
				newData.referral_firm_zip = ref1.zip;
				// second or more ref
				if (referrals_lessor.length > 1) {
					let arr = [];
					referrals_lessor.forEach((rl,j) => {
						if (j !== 0) {
							arr.push(
								{
									"id": rl.contact_id ? rl.contact_id : '',
									"name": rl.first_name,
									"lastname": rl.last_name,
									"city": rl.city,
									"state": rl.state,
									"zip": rl.zip,
									"main_phone": rl.phone,
									"email": rl.email,
									"fax": null,
									"contact_dba_type": "contact",
									"address1": rl.address1,
									"fee_amount_choice": rl.percentage ? "Percentage" : "Value",
									"fee_amount_percentage": rl.percentage ? rl.percentage : rl.value
								});
						}
					});
					newData.lessor_referrals = arr;
				}
			}
			if (referrals_presplits.length > 0) {
				newData.presplits = referrals_presplits;
			}
			// newData.outside_broker = outside_brokers_lessee;
			// newData.lessor_outside_broker = outside_brokers_lessor;
			console.log('newD', newData);
			
		}
	}
	// associate_commission_splits_lessee
	return newData;
}