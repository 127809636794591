import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "../../components/CustomRadio/CustomRadio";
import { formatDollar, parseFloatString } from '../../shared/utility.js';
const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

// role: '', // ?
// department: '', // Support, Agent, Property Management
// DRE: '', // 40 characters max
// is_illi_employee: '', // Yes, No
// is_user_replacing_an_existing_user: '', // Yes, No, Maybe
// this_user_is_replacing: '', // 40 characters max 
// lets_sign_it: '', // Yes, No - REQUIRED
// signature_style: '', // Agent, Property Management 
// illi_university: '', // Yes, No - REQUIRED
// property_base: '', // Yes, No 

const updateObj = (obj, field, value) => {
    const newCustomData = clone(obj);
    newCustomData[field] = value;
    if (value === 'No' && field === 'is_illi_employee') {
        newCustomData.is_user_replacing_an_existing_user = '';
        newCustomData.this_user_is_replacing = '';
    }
    if (value === 'No' && field === 'is_user_replacing_an_existing_user') {
        newCustomData.this_user_is_replacing = '';
    }
    return newCustomData;
}

const getEarnedToDate = (arr) => {
    let num = 0;
    if (arr) {
        arr.forEach(a => {
            const n = parseFloatString(a.amount);
            num += n;
        })
    }
    return num;
}

const UserAdminGeneral = (props) => {
    console.log('UserAdminGeneral', props);
    const setCustomData = props.setCustomData;
    const customData = props.customData;
    const illi_backend_data = props.illi_backend_data;

    const update = (field, value) => {
        setCustomData(updateObj(customData, field, value));
    }

    const earned_to_date = getEarnedToDate(props.earned_commissions);

    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                    labelText="Role"
                    inputProps={{
                        value: customData.role,
                        onChange: (e) => {
                            update('role', e.target.value);
                        }
                    }}
                />
                <CustomInput
                    labelText="DRE#"
                    inputProps={{
                        value: customData.DRE,
                        onChange: (e) => {
                            update('DRE', e.target.value)
                        }
                    }}
                />
                {/* property_base */}
                {/* <CustomRadio
                    name="property_base"
                    label="Property Base?,Yes,No"
                    options={['Yes','No']}
                    onChange={(e) => {
                        update('property_base', e.target.value);
                    }}
                    value={customData.property_base}
                  /> */}
                <CustomRadio
                    name="illi_university"
                    label="illi University?,Yes,No"
                    options={['Yes','No']}
                    onChange={(e) => {
                        update('illi_university', e.target.value);
                    }}
                    value={customData.illi_university}
                  />
                <CustomSelect 
                    label="Starting Commission Rate"
                    options={props.gci_entries.map(gci => {
                        return {
                            id: gci.id,
                            desc: `Level ${gci.commission_level} - Percentage:${gci.percentage} - Threshold:${gci.threshold}`
                        }
                    })}
                    choose={(e) => {
                        console.log('choose', e);
                        update('baseCommissionRate', e);
                    }}
                    default={illi_backend_data.base_commission_rate ? illi_backend_data.base_commission_rate : ''}
                    indx="id"
                    desc="desc"
                />
                <CustomInput
                    labelText="Earned to date"
                    inputProps={{
                        value: formatDollar(earned_to_date),
                        disabled: true
                    }}
                />
                <CustomSelect 
                    label="Current Commission Level"
                    options={props.gci_entries.map(gci => {
                        return {
                            id: gci.id,
                            desc: `Level ${gci.commission_level} - Percentage:${gci.percentage} - Threshold:${gci.threshold}`
                        }
                    })}
                    choose={(e) => {
                    }}
                    default={illi_backend_data.current_commission_level ? illi_backend_data.current_commission_level : ''}
                    indx="id"
                    desc="desc"
                    disabled={true}
                />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <CustomSelect
                    label="Department"
                    options={['Support','Brokerage','Property Management','Executive Team']}
                    choose={(e, n) => {
                        
                        update('department', e);
                    }}
                    default={customData.department}
                />
                <div style={{border:'1px solid #ccc',borderRadius:5,padding:10}}>
                    <CustomRadio
                        name="is_illi_employee"
                        label="User Type,Employee,Associate,Both"
                        options={['Employee','Agent','Both']}
                        onChange={(e) => {
                            // if action is Add & there is no is_illi_employee data you can add it
                            // if (props.action !== 'Edit' && !customData.is_illi_employee) update('is_illi_employee', e.target.value);
                            update('is_illi_employee', e.target.value);
                        }}
                        value={customData.is_illi_employee}
                    />
                    {/* {(props.action === 'Edit' && customData.is_illi_employee) && <div style={{marginTop:10,marginBottom:10}}>
                        User Type cannot be updated after creation
                    </div>} */}
                    {customData.is_illi_employee === 'Employee' && <CustomRadio
                        name="is_user_replacing_an_existing_user"
                        label="Is this user replacing an existing user?,Yes,No,Maybe"
                        options={['Yes','No','Maybe']}
                        onChange={(e) => {
                            update('is_user_replacing_an_existing_user', e.target.value)
                        }}
                        value={customData.is_user_replacing_an_existing_user}
                    />}
                    {(customData.is_illi_employee === 'Employee' 
                        && 
                    customData.is_user_replacing_an_existing_user === 'Yes') && 
                    <CustomSelect
                        label="This user is replacing"
                        options={props.userList ? props.userList.map(ul => {
                            if (!ul.name) return '';
                            const givenName = ul.name.givenName;
                            const familyName = ul.name.familyName;
                            return `${givenName} ${familyName}`;
                        }) : []}
                        choose={(e, n) => {
                            
                            update('this_user_is_replacing', e);
                        }}
                        default={customData.this_user_is_replacing}
                    />}
                </div>
                <CustomRadio
                    name="lets_sign_it"
                    label="Let's sign it?,Yes,No"
                    options={['Yes','No']}
                    onChange={(e) => {
                        update('lets_sign_it', e.target.value);
                    }}
                    value={customData.lets_sign_it}
                  />

            </GridItem>
        </GridContainer>
    );
}

export default UserAdminGeneral;
