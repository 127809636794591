import { clone, concat, flatMap, get, has, isEmpty, orderBy, reverse, sortBy, uniqWith } from "lodash";
import moment from "moment";

import { calculateDaysEstimated, getMonthEstimate, checkIfFullMonth, checkIfFullYear, commatize, currencyToNumber, formatDollar, getDateDiffs2, round2dec, parseFloatString, cloneJ } from "../../shared/utility";

import termLogic from "../RSheets/CommonCustomElements/Term/TermLogicMain";

import {
    calculateAbatedRanges2_RS as calculateAbatedRanges2,
    calculateLeaseLogic_RS as calculateLeaseLogic,
    getPaymentAmount,
    isFlatFeeCommissionEnabled,
    getFlatFeeCommission,
    calculateAdjustedCommission,
    isSaleRSType,
    getFlatFeeCommissionFromSale,
    getTotalLineItems, 
} from "../RSheets/RSheetsLogic";
import {
    calculateBalanceForward,
    calculateBalanceForwardTotal,
    getBalanceDue,
    getInvoiceIndex,
    getRemainingBalance,
    getRsData,
    renderSaleInvoiceDetailsHTML
} from "./InvoiceLogic";
import { invoiceFields, termFields } from "./InvoiceModel";
import { invoiceFormInfoFields } from "./Steps/InvoiceFormInfo";

export const INVOICE_SECTIONS = {
    ROUTING_SHEET: "Routing Sheet",
    INFO: "Invoice Info",
};

export const INVOICE_STATUS = {
    DRAFT: 0,
    PENDING: 1,
    DECLINE: 2,
    APPROVED: 3,
    VOIDED: 4
};

export const getDataFromRs = (rs, formData) => {
    const newInvoiceForm = clone(formData);
    newInvoiceForm.attn = get(rs, "billing_attention", "") + " " + get(rs, "billing_attention_lastname", "");
    newInvoiceForm.billing_company = get(rs, "billing_company", "");
    newInvoiceForm.billing_address1 = get(rs, "billing_address1", "");
    newInvoiceForm.billing_address2 = get(rs, "billing_address2", "");
    newInvoiceForm.billing_city = get(rs, "billing_city", "");
    newInvoiceForm.billing_state = get(rs, "billing_state", "");
    newInvoiceForm.billing_zip = get(rs, "billing_zip", "");
    newInvoiceForm.project = get(rs, "project_name", "");
    newInvoiceForm.property_address = get(rs, "project_address", "");
    newInvoiceForm.lease_terms = get(rs, "lease_term", []);
    newInvoiceForm.lessees = get(rs, "lessees", []);
    newInvoiceForm.lessors = get(rs, "lessors", []);
    newInvoiceForm.associates = uniqWith(
        concat(get(rs, "commission_splits_agent", []), get(rs, "commission_splits_agent2", [])),
        (a, b) => a.name === b.name
    );
    return { newInvoiceForm };
};

export const generateSections = (data, routingSheet, func, fieldModels) => {
    let allowToSubmit = true;
    let sections = [];
    Object.values(INVOICE_SECTIONS).forEach((section) => {
        if (data) {
            let complete = true;
            if (section === INVOICE_SECTIONS.INFO) {
                if (!data.title) {
                    allowToSubmit = false;
                    complete = false;
                }
                invoiceFormInfoFields.forEach((field) => {
                    if (!data[field] && fieldModels[field].required) {
                        complete = false;
                        allowToSubmit = false;
                    }
                });
            } else if (section === INVOICE_SECTIONS.ROUTING_SHEET) {
                if (!routingSheet) {
                    complete = false;
                    allowToSubmit = false;
                }
            }
            sections.push({
                name: section,
                complete,
            });
        }
    });

    func(allowToSubmit);
    return sections;
};

const defaults = {};
Object.keys(invoiceFields).forEach((field) => {
    if (field === "date") {
        defaults.date = moment().format("MM/DD/YYYY");
    } else {
        defaults[field] = "";
    }
});

export const defaultData = defaults;

const defaultTerm = {};
Object.keys(termFields).forEach((field) => {
    if (field === "date") {
        defaultTerm.date = moment().format("MM/DD/YYYY");
    } else {
        defaultTerm[field] = "";
    }
});

export const defaultTermFields = defaultTerm;

/*

 Invoice formData 
{
    invoice: Invoice number
    date: MM/DD/YYYY format date
    rsData: Routing sheet data
    entries: array of payment schedule entry objects
    line_items: array of line item objects
}

*/
export const generateInvoiceHTML = (formData, isPreview, paymentScheduleIndex, invoicePayments, invoice_id, theRsType) => {
    console.log('generating invoic html', formData, isPreview, paymentScheduleIndex, invoicePayments, invoice_id);
    console.log('generating invvvv', theRsType);
    const formInput = (field) => {
        if (has(formData, field)) {
            return formData[field];
        }
        return "";
    };
    let rsData = cloneJ(formData.rsData);
    if (theRsType === 6) {
        // Consulting - make sure flat fee commission = yes
        rsData.flat_fee_commission = 'Yes';
    }
    let rsType = formData ? formData.rsType : null;
    if (theRsType) rsType = theRsType;
    const calcs = termLogic.calculateLeaseTerm(rsData);
    console.log('calculating invoice rowsy', calcs, rsData);
    const invoice_calcs = termLogic.calculateInvoiceRows(calcs, rsData);
    if (!invoice_id && formData.id) {
        console.log('invoice id is', formData.id);
        invoice_id = formData.id;
    }
    const invoice_index = formData.invoice_index ? formData.invoice_index : getInvoiceIndex(rsData, invoice_id);
    console.log('invoice_index', invoice_index)
    let attn = getRsData(rsData, "billing_attention") + " " + getRsData(rsData, "billing_attention_lastname");
    let attnEmail = getRsData(rsData, "billing_email");

    let billingCompany = getRsData(rsData, "billing_company");
    let billingAddress = `${getRsData(rsData, "billing_address1")} ${getRsData(rsData, 'billing_address2') ? `, ${getRsData(rsData, 'billing_address2')}` : ''}`;
    let billingCity = getRsData(rsData, "billing_city");
    let billingState = getRsData(rsData, "billing_state");
    let billingZip = getRsData(rsData, "billing_zip");

    let lesseeFieldName = 'Lessee';
    let lessorFieldName = 'Lessor';

    if (formData.rsType || theRsType) {

        // RS TYPE = SALE
        if (formData.rsType === 4 || theRsType === 4) {
            attn = getRsData(rsData, "escrow_officer_name");
            attnEmail = getRsData(rsData, "escrow_email");

            billingCompany = getRsData(rsData, "escrow_company");
            billingAddress = `${getRsData(rsData, "escrow_address")} ${getRsData(rsData, 'escrow_address2') ? `, ${getRsData(rsData, 'escrow_address2')}` : ''}`;
            billingCity = getRsData(rsData, "escrow_city");
            billingState = getRsData(rsData, "escrow_state");
            billingZip = getRsData(rsData, "escrow_zip");

            lesseeFieldName = 'Buyer';
            lessorFieldName = 'Seller';
        }
    }
    const billingPart = `<p>${billingCompany}</p>
                        <p>${billingAddress}</p>
                        <p>${billingCity}, ${billingState} ${billingZip}</p>`;

    let ranges = [];
    get(rsData, "lease_term", []).forEach((lt) => {
        ranges = ranges.concat(lt.ranges);
    });

    const payment_schedule = get(formData, "entries", []);
    const associates = uniqWith(
        concat(
            getRsData(rsData, "commission_splits_agent", { isArray: true }),
            getRsData(rsData, "commission_splits_agent2", { isArray: true })
        ),
        (a, b) => a.id === b.id
    );

    let totalCommissionBeforeAdjustment = 0;
    let saleCommission = 0;
    const flatFeeEnabled = isFlatFeeCommissionEnabled(rsData);
    if (flatFeeEnabled) {
        const flatFeeCommission = getFlatFeeCommission(rsData);
        totalCommissionBeforeAdjustment = flatFeeCommission;
    } else {
        if (rsData.sale_sale_commission) {
            totalCommissionBeforeAdjustment = typeof rsData.sale_sale_commission === 'string' ?
                parseFloatString(rsData.sale_sale_commission)
            :
                rsData.sale_sale_commission;
        } else {
            totalCommissionBeforeAdjustment = parseFloat(invoice_calcs.total_commission)
        }
    }
    console.log('looky here', theRsType, rsType, rsData.sale_sale_commission)
    if (rsType === 4) {
        if (rsData.sale_sale_commission) {
            saleCommission = parseFloat(typeof rsData.sale_sale_commission === 'string' ? rsData.sale_sale_commission.replace(/[$,]/g, "") : rsData.sale_sale_commission);
        }
    }
    const totalLineItems = getTotalLineItems(formData.line_items ? formData.line_items : []);
    const totalCommission = totalCommissionBeforeAdjustment + calculateAdjustedCommission(rsData) + saleCommission;

    const renderLesseeNames = () => {
        const lessees = getRsData(rsData, "lessees", { isArray: true });
        const names = lessees.map((l) => {
            const firstName = get(l, "name", "");
            const lastName = get(l, "last_name", "");
			const fullName = `${firstName} ${lastName}`;

			if (fullName.trim() === "") return get(l, "company_name", "");
			return fullName;
        });
        return names.join(", ");
    };

    const renderLessorNames = () => {
        const lessors = getRsData(rsData, "lessors", { isArray: true });
        const names = lessors.map((l) => {
            const firstName = get(l, "name", "");
            const lastName = get(l, "last_name", "");
			const fullName = `${firstName} ${lastName}`;

			if (fullName.trim() === "") return get(l, "company_name", "");
			return fullName;
        });
        return names.join(", ");
    };

    const renderTermsHTML = (invoice_calcs, calcs, invoicePayments) => {
        console.log('invoice_calcs', invoice_calcs, calcs);
        const years = get(invoice_calcs, `years`, []);
        if (!calcs.is_estimated) {
            let terms = '';
            years.forEach(year => {
                const rows = year.rows;
                rows.forEach(rr_arr => {
                    rr_arr.forEach(rr => {
                        console.log('rraww', rr);
                        const ll1 = moment(rr.date1, 'MM/DD/YYYY').format('LL');
                        const ll2 = moment(rr.date2, 'MM/DD/YYYY').format('LL');
                        const termLabel = `${ll1} to ${ll2}`;
                        if (rr.isAbated) {
                            let rr_term_rent = '$0.00';
                            if (rr.abated_value !== rr.term_rent) {
                                rr_term_rent = formatDollar(rr.term_rent - rr.abated_value);
                            }
                            let rr_commission = '$0.00';
                            if (rr.abated_value !== rr.term_rent) {
                                rr_commission = formatDollar((rr.term_rent - rr.abated_value) * (rr.commission_rate * 0.01) );
                            }
                            let rr_commission_rate = '0 %';
                            if (rr.abated_value !== rr.term_rent) {
                                rr_commission_rate = rr.commission_rate;
                            }
                            terms += `<tr class="fs-roboto">
                                <td style="font-size: 10px; padding: 2px 7px; width: 32%">${termLabel} <span style="font-size:8px">(Abated Rent)</span></td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${formatDollar(rr.monthly_rent)}</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${round2dec(rr.total_number_of_months)}</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${rr_term_rent}</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 11%;">${rr_commission_rate}</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${rr_commission}</td>
                                <td class="col-5"></td>
                            </tr>`;
                        } else {
                            terms += `<tr class="fs-roboto">
                                <td style="font-size: 10px; padding: 2px 7px; width: 32%">${termLabel}</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${formatDollar(rr.monthly_rent)}</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${round2dec(rr.total_number_of_months)}</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${formatDollar(rr.term_rent)}</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 11%;">${rr.commission_rate} %</td>
                                <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${formatDollar(rr.commission)}</td>
                                <td class="col-5"></td>
                            </tr>`;
                        }
                    })
                });
            })
            return terms;
        } else {
            const the_years = get(invoice_calcs, `years`, []);
            let terms = '';
            // check if there are any abated (if so then format is For Month X to Month X)
            let hasAbated = false;
            the_years.forEach((yr) => {
                yr.rows.forEach((yrr) => {
                    if (yrr.is_abated) hasAbated = true;
                });
            });
            the_years.forEach((yr,y) => {
                let year = `Year ${y+1}`;
                terms += `
                    ${yr.rows.map((row,r) => {
                        const { months_est, monthly_rent } = row;
                        let termLabel = '';
                        // if partial stay as is
                        termLabel = `${row.date1} to ${row.date2}`;
                        // if full months - take out Days
                        const isFullMonth = checkIfFullMonth(row.date1, row.date2);
                        const isFullYear = checkIfFullYear(row.date1, row.date2);
                        if (isFullYear && !hasAbated) {
                            termLabel = year;
                            year = '';
                        } else {
                            termLabel = `For ${getMonthEstimate(row.date1)} through ${getMonthEstimate(row.date2)}`;
                        }
                        return `<tr class="fs-roboto">
                            <td style="font-size: 10px; padding: 2px 7px; width: 32%">${termLabel}</td>
                            <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${formatDollar(monthly_rent)}</td>
                            <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${months_est}</td>
                            <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${formatDollar(row.term_rent)}</td>
                            <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 11%;">${row.commission_rate} %</td>
                            <td class="textRight"  style="font-size: 10px; padding: 2px 0; width: 13%;">${formatDollar(row.commission)}</td>
                            <td class="col-5"></td>
                        </tr>`
                    }).join('')}
                `;
            });
            return terms;
        }
    };

    const renderLineItems = () => {
        console.log('renderLineItems', formData);
        const lineItems = formData.line_items ? formData.line_items : [];
        return lineItems.map(li => {
            return `<tr style="padding-top: 10px">
                <td class="col-80" style="padding: 0 7px">
                    <span style="line-height: 13px">
                        <span style="font-weight: bold">${li.description}</span>
                    </span>
                </td>
                <td class="col-20 textRight fs-roboto" style="padding: 0 7px">
                    ${formatDollar(li.amount)}
                </td>
            </tr>`
        }).join('');
    }

    const renderCommissionAdjustment = () => {
        const commissionAdjustments = get(rsData, "commission_adjustment_details", []);
        const hasCommissionAdjustment =
        get(rsData, "commission_adjustment") === "Yes" && !isEmpty(commissionAdjustments);

        if (!hasCommissionAdjustment) return '';

        return commissionAdjustments.map((ca, idx) => {
            if (idx === 0) {
                return (
                    `<tr style="padding-top: 10px">
                        <td class="col-80" style="padding: 5px 7px 0">
                            <span style="font-weight: bold">Adjustment</span> ${get(ca, "comment", "")}
                        </td>
                        <td class="col-20 textRight fs-roboto" style="padding: 5px 7px 0px">
                            ${formatDollar(currencyToNumber(get(ca, "value", 0)))}
                        </td>
                    </tr>`
                );
            }
            return (
                `<tr>
                    <td class="col-80" style="padding: 0 7px">
                        <span style="line-height: 13px">
                            <span style="font-weight: bold">Adjustment</span> ${get(ca, "comment", "")}
                        </span>
                    </td>
                    <td class="col-20 textRight fs-roboto" style="padding: 0 7px">
                        ${formatDollar(currencyToNumber(get(ca, "value", 0)))}
                    </td>
                </tr>`
            );
        }).join(" ");
    };

    let newPaymentsFinal = [];
    if (payment_schedule.length > 0 && !Array.isArray(payment_schedule[0])) {
        newPaymentsFinal = [clone(payment_schedule)];
    } else {
        newPaymentsFinal = clone(payment_schedule);
    }

    const renderInvoiceDetails = () => {
        console.log('payment schedule', payment_schedule, rsData);
        if (payment_schedule.length === 0) return '';
        return payment_schedule
            .map((pm, index) => {
                console.log('payments--', pm);
                const paymentCalc = getPaymentAmount(rsData, pm.option, pm.value);
                const dollar = typeof paymentCalc === 'string' ? paymentCalc : formatDollar(paymentCalc);
                const pm_notes = pm.notes ? `<div> - ${pm.notes}</div>` : '';
                if (index === 0) {
                    return `<tr style="padding-top: 10px">
                        <td class="col-80" style="padding: 5px 7px 0">
                            ${pm.option ? pm.option.split(". ")[1] : ''}
                            ${pm_notes}
                        </td>
                        <td class="col-20 textRight fs-roboto" style="padding: 5px 7px 0px">${dollar}</td>
                    </tr>`;
                }
                return `
                <tr>
                    <td class="col-80" style="padding: 0 7px">
                        <span style="line-height: 13px"> 
                            ${pm.option ? pm.option.split(". ")[1] : ''}
                            ${pm_notes}
                        </span>
                    </td>
                    <td class="col-20 textRight fs-roboto" style="padding: 0 7px">${dollar}</td>
                </tr>`;
            })
            .join(" ");
    };

    const balanceDue = getBalanceDue(formData, totalCommission);
    console.log('balanceDue', balanceDue, rsData);
    const totalPaidToDate = getRemainingBalance(totalCommission - balanceDue, invoice_index, {
        invoicePayments: invoicePayments ? invoicePayments.filter(ip => {
            return true;
            // if (invoice_id) {
            //     if (ip.invoice_id === invoice_id) {
            //         return true;
            //     }
            // }
        }) : []
    }, rsData.invoices);

    console.log('totalPaidToDate', totalPaidToDate, invoicePayments);

    const renderAssociates = () => {
        return `
            <table class="col-30">
                <tbody>
                    ${associates.map((a, index) => {
                            const name = a.Name.split(" ");
                            const formattedName = reverse(name).join(", ");
                            if (index === 0) {
                                return `<tr>
											<td class="col-100"><div style="acumin fwsemibold"><strong>Associate(s):</strong></div> ${formattedName}</td>
			                            </tr>`;
                            } else {
                                return `<tr>
											<td class="col-100">${formattedName}</td>
			                            </tr>`;
                            }
					}).join(" ")}
					<tr style="height: 15px;"></tr>
					<tr>
						<td class="col-100">
							<p>
								<span class="text-red acumin fwsemibold">illi</span> #${rsData.illi_number}
							</p>
                            ${rsData.pm_number ? `<p><span class="text-red acumin fwsemibold">PM</span> #${rsData.pm_number}</p>` : ''}
                            <p style="font-size:9px">
                                ${rsData.date_lease_signed ? `<strong style="font-size:9px">Date Lease Signed:</strong> ${rsData.date_lease_signed}` : ''}
                            </p>
						</td>
					</tr>
                </tbody>
            </table>
        `;
    };

    const getBalanceForward = (invoice_index, balanceForward) => {
        /* 
        *
        *   Gets Balance from invoices before
        * 
        */
        let html = '';
        if (invoice_index > 0) {
            html = `
                <tr>
                    <td class="col-70">
                        <span
                            class="fwsemibold acumin"
                            style="line-height: 10px"
                            >Balance Forward:</span
                        >
                    </td>
                    <td class="col-30 textRight fs-roboto"  style="padding-right: 10px; line-height: 10px">${formatDollar(balanceForward)}</td>
                </tr>
            `
        }
        return html;
    }

    const getTotalBalanceDueNow = (invoice_index, totalBalanceDueNow) => {
        let html = '';
        if (invoice_index !== 0) {
            html = `
                <div
                    class="invoice-table box-shadow"
                    style="padding: 1px 5px 3px; margin: 5px 0 15px 0px"
                >
                    <div class="text-red acumin" style="font-size: 13px; display: flex; justify-content: space-between; align-items: center">
                        <p> Total Balance Due Now: </p>
                        <p class="fs-roboto fwbold" style="padding-right: 4px; font-size: 12px">${formatDollar(
                            totalBalanceDueNow
                        )}</p>
                    </div>
                </div>
            `
        }
        return html;
    }

    const getTotalAmountThisInvoice = (invoice_index, totalAmountThisInvoice) => {
        let html = '';
        if (invoice_index !== 0) {
            html = `
                <tr>
                    <td class="col-70">
                        <span
                            class="fwsemibold acumin"
                            style="line-height: 10px"
                            >Total Amount This Invoice:</span
                        >
                    </td>
                    <td class="col-30 textRight fs-roboto"  style="padding-right: 10px; line-height: 10px">${formatDollar(totalAmountThisInvoice)}</td>
                </tr>
            `;
        }
        return html;
    }

    const getRemainingBalanceDue = (invoice_index, remainingBalanceDue) => {
        let html = ``;
        if (invoice_index === 0) {
            html = `
                <tr>
                    <td class="col-70">
                        <span
                            class="fwsemibold acumin"
                            style="line-height: 10px"
                            >Remaining Balance Due:</span
                        >
                    </td>
                    <td class="col-30 textRight fs-roboto"  style="padding-right: 10px; line-height: 10px">${formatDollar(
                        remainingBalanceDue
                    )}</td>
                </tr>
            `;
        }
        return html;
    }

    const getTotalAmountDueThisInvoice = (invoice_index, totalAmountDueThisInvoice) => {
        let html = ''
        if (invoice_index === 0) {
            html = `
                <div
                    class="invoice-table box-shadow"
                    style="padding: 1px 5px 3px; margin: 5px 0 15px 0px"
                >
                    <div class="text-red acumin" style="font-size: 13px; display: flex; justify-content: space-between; align-items: center">
                        <p> Total Amount Due This Invoice: </p>
                        <p class="fs-roboto fwbold" style="padding-right: 4px; font-size: 12px">${formatDollar(
                            totalAmountDueThisInvoice
                        )}</p>
                    </div>
                </div>
            `
        }
        return html;
    }

    console.log('remaining balance due', balanceDue, totalCommission, totalLineItems, totalPaidToDate.paid)
    // totalCommission + totalLineItems - totalPaidToDate.paid

    const renderAllInvoices = (index) => {
        const remaining_balance_due = totalCommission - (totalPaidToDate.paid - totalLineItems);
        const total_amount_this_invoice = balanceDue - totalPaidToDate.paid;
        const total_amount_due_this_invoice = balanceDue - totalPaidToDate.paid;
        const balance_forward = calculateBalanceForwardTotal(invoice_index, invoicePayments, rsData);
        const total_balance_due_now = total_amount_this_invoice + balance_forward;

        return `
        <div class="style6">
            <div class="style7">
                <img
                    src="https://staging.connect.illicre.com/assets/logo.png"
                    style="width: 85px; margin-left: -7px"
                />
                <div class="text-11" style="margin-top: 0px; margin-left: 10px">
                    <h3 class="invoice-heading acumin-condense">illi Commercial Real Estate</h3>
                    <p style="margin-top: -1px">
                        5945 Canoga Ave.
                    </p>
                    <p style="margin-top: -1px">Woodland Hills, CA 91367</p>
                    <p class="red-text" style="margin-top: -1px">
                        (818) 501-2212
                    </p>
                </div>
            </div>

            <div class="textRight text-11">
                <p class="text-11">${moment(formInput("date"), 'MM/DD/YYYY').format("LL")}</p>
                <p>
                    <span class="fwsemibold acumin">Invoice</span>
                    #${formInput("invoice")}
                </p>
            </div>
        </div>

        <div
            class="invoice-table col-45 box-shadow"
            style="padding-bottom: 25px; margin-top: 35px"
        >
            <h3
                class="invoice-heading acumin-condense text-center border-red"
                style="border-bottom: 1px solid #da2128"
            >
                BILL TO
            </h3>

            <div style="margin: 15px 15px 7px; font-size: 13px">
                <div>
                    ${billingPart}
                </div>
                <div style="margin-top: 15px">Attn: ${attn}</div>
                <div>${attnEmail}</div>
            </div>
        </div>

        <div
            class="invoice-table box-shadow col-100"
            style="margin-top: 15px; padding-bottom: 15px"
        >
            <div style="display: flex">
            <table class="col-70">
                <tbody>
                    <tr>
                        <td class="col-30" style="padding: 10px 7px 0">
                            <span class="acumin fwsemibold">Project:</span>
                        </td>
                        <td class="col-70" style="padding: 10px 0px 0">${getRsData(rsData, "project_name")}</td>
                    </tr>
                    <tr>
                        <td class="col-30" style="padding: 0px 7px 0">
                            <span class="fwsemibold acumin"
                                >Premises Address:</span
                            >
                        </td>
                        <td class="col-70">${getRsData(rsData, 'premises_address')}</td>
                    </tr>
                    <tr>
                        <td class="col-30" style="padding: 0px 7px 0">
                            <span class="fwsemibold acumin"
                                >Unit Number:</span
                            >
                        </td>
                        <td class="col-70">${getRsData(rsData, 'unit_number')}</td>
                    </tr>
                    <tr>
                        <td class="col-30" style="padding: 5px 7px 0">
                            <span class="fwsemibold acumin">${lessorFieldName}:</span>
                        </td>
                        <td class="col-70" style="padding: 5px 0px 0">${renderLessorNames()}</td>
                    </tr>
                    <tr>
                        <td class="col-30" style="padding: 0px 7px 0">
                            <span class="fwsemibold acumin">${lesseeFieldName}:</span>
                        </td>
                        <td class="col-70">${renderLesseeNames()}</td>
                    </tr>
                    ${(isFlatFeeCommissionEnabled(rsData) && rsType !== 4) ? (
                        `<tr>
                            <td class="col-30" style="padding: 27px 7px 0; white-space: nowrap">
                                <span class="fwsemibold acumin">Commission Breakdown:</span>
                            </td>
                            <td class="col-70" style="padding: 27px 0 0">
                                Flat Fee for Services Rendered&nbsp;&nbsp;&nbsp;${formatDollar(getFlatFeeCommission(rsData))}
                            </td>
                        </tr>`
                    ) : ''}
                    ${rsType === 4 ? (
                        `<tr>
                            <td className="col-30" style="padding: 27px 7px 0; white-space: nowrap">
                                <span class="fwsemibold acumin">Commission Breakdown:</span>
                            </td>
                            <td class="col-70" style="padding: 27px 0 0">
                                Flat Fee for Services Rendered&nbsp;&nbsp;&nbsp;${formatDollar(getFlatFeeCommissionFromSale(rsData))}
                            </td>
                        </tr>`
                    ) : ''}
                </tbody>
            </table>

            ${renderAssociates()}
            </div>

            <br />


            ${(!isFlatFeeCommissionEnabled(rsData) && rsType !== 4) ? (
                `<table style="margin-bottom: 5px">
                    <tbody>
                        <tr>
                            <td class="col-100" style="padding: 10px 7px 0;">
                                <span class="acumin fwsemibold">Commission Breakdown:</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="col-100">
                    <tbody>
                        <tr class="gray-bar acumin fwsemibold">
                            <td
                                style="padding: 2px 7px; margin-bottom: 3px; width: 32%"
                            >
                                <strong>Term</strong>
                            </td>
                            <td class="textRight" style="width: 13%">
                                <strong>Monthly Rent</strong>
                            </td>
                            <td class="textRight" style="width: 13%">
                                <strong>Months</strong>
                            </td>
                            <td class="textRight" style="width: 13%">
                                <strong>Term Rent</strong>
                            </td>
                            <td class="textRight" style="width: 11%">
                                <strong>Rate</strong>
                            </td>
                            <td class="textRight" style="width: 13%">
                                <strong>Commission</strong>
                            </td>
                            <td class="col-5"></td>
                        </tr>

                        <tr>
                            <td
                                style="padding: 5px 7px; margin-bottom: 3px; width: 32%"
                            >
                            </td>
                            <td class="textRight" style="width: 13%">
                            
                            </td>
                            <td class="textRight" style="width: 13%">
                            
                            </td>
                            <td class="textRight" style="width: 13%">
                            
                            </td>
                            <td class="textRight" style="width: 11%">
                            
                            </td>
                            <td class="textRight" style="width: 13%">
                            
                            </td>
                            <td class="col-5"></td>
                        </tr>
                        ${renderTermsHTML(invoice_calcs, calcs, invoicePayments)}
                        <tr class="invoice-total">
                            <td style="width: 32%"></td>
                            <td class="textRight fwsemibold acumin" style="width: 13%">
                                <div style="margin-top: 0px">Totals:</div>
                            </td>
                            <td class="textRight" style="width: 13%">
                                <div
                                    class="bt fs-roboto"
                                    style="
                                        padding-top: 5px;
                                        font-size: 10px;
                                        width: 40%;
                                    "
                                >
                                    ${invoice_calcs.total_months}
                                </div>
                            </td>
                            <td class="textRight" style="width: 13%">
                                <div
                                    class="bt fs-roboto"
                                    style="
                                        padding-top: 5px;
                                        font-size: 10px;
                                        width: 64%;
                                    "
                                >
                                    ${formatDollar(invoice_calcs.total_term_rent)}
                                </div>
                            </td>
                            <td class="textRight" style="width: 11%"></td>
                            <td class="textRight" style="width: 13%">
                                <div
                                    class="bt fs-roboto"
                                    style="
                                        padding-top: 5px;
                                        font-size: 10px;
                                        width: 67%;
                                    "
                                >
                                    ${formatDollar(totalCommissionBeforeAdjustment)}
                                </div>
                            </td>
                            <td class="col-5"></td>
                        </tr>
                    </tbody>
                </table>`
            ) : ''}
        </div>

        <section style="page-break-inside: avoid;">

            <h3 style="margin-top: 18px" class="invoice-heading acumin-condense">
                PAYMENT SCHEDULE:
            </h3>

            <table class="invoice-detail col-100">
                <tbody>
                    <tr class="gray-bar" style="margin-top: 10px">
                        <td class="col-80 acumin fwsemibold" style="padding: 3px 7px 3px">
                            DESCRIPTION
                        </td>
                        <td
                            class="textRight col-20 acumin fwsemibold"
                            style="padding: 3px 10px"
                        >
                            <strong>AMOUNT DUE</strong>
                        </td>
                    </tr>
                    ${renderInvoiceDetails(invoice_index)}
                    ${renderSaleInvoiceDetailsHTML(rsType, rsData)}
                    ${renderLineItems()}
                </tbody>
            </table>

            <section
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-top: 40px;
                "
            >
                <div
                    class="inline-block invoice-table box-shadow"
                    style="padding: 5px 10px 10px; width: 43%"
                >
                    <h3 class="invoice-heading acumin-condense">PAYMENT INSTRUCTIONS:</h3>
                    <p class="text-7" style="margin-top: -3px">
                        Please include
                        <span class="acumin fwsemibold">invoice</span> number with payment
                    </p>
                    <p class="acumin fwsemibold" style="margin: 6px 0 7px">
                        MAKE CHECK PAYABLE AND MAIL TO:
                    </p>

                    <h3 class="invoice-heading acumin-condense" style="font-size: 16px;">
                        illi Commercial Real Estate
                    </h3>
                    <p style="margin-top: -2px">5945 Canoga Ave.</p>
                    <div style="margin-top: -2px">Woodland Hills, CA 91367</div>
                    <p class="text-red" style="margin-top: -2px">
                        Tax ID: 26-1085204
                    </p>
                </div>

                <div class="inline-block" style="width: 46%">
                    <h3
                        style="margin-bottom: -2px; margin-left: 2px"
                        class="invoice-heading acumin-condense"
                    >
                        SUMMARY:
                    </h3>
                    <table class="col-100">
                        <tbody>
                            <tr>
                                <td class="col-70">
                                    <span
                                        class="fwsemibold acumin"
                                        style="line-height: 10px"
                                        >Transaction Commission:</span
                                    >
                                </td>
                                <td class="col-30 textRight fs-roboto" style="padding-right: 10px; line-height: 10px">${formatDollar(
                                    totalCommission
                                )}</td>
                            </tr>
                            <tr>
                                <td class="col-70">
                                    <span
                                        class="fwsemibold acumin"
                                        style="line-height: 10px"
                                        >Adjustments:</span
                                    >
                                </td>
                                <td class="col-30 textRight fs-roboto" style="padding-right: 10px; line-height: 10px">${formatDollar(
                                    totalLineItems
                                )}</td>
                            </tr>
                            <tr>
                                <td class="col-70">
                                    <span
                                        class="fwsemibold acumin"
                                        style="line-height: 10px"
                                        >Less Total Paid To Date:</span
                                    >
                                </td>
                                <td class="col-30 textRight fs-roboto"  style="padding-right: 10px; line-height: 10px">-${formatDollar(
                                    totalPaidToDate.paid
                                )}</td>
                            </tr>
                            ${
                                // Only for other than first invoice
                                getBalanceForward(invoice_index, balance_forward)
                             }
                            ${
                                // Only for first invoice
                                getRemainingBalanceDue(invoice_index, remaining_balance_due)
                             }
                            ${
                                // Only for other than first invoice
                                getTotalAmountThisInvoice(invoice_index, total_amount_this_invoice)
                             }
                        </tbody>
                    </table>
                    ${
                        // Only for first invoice
                        getTotalAmountDueThisInvoice(invoice_index, total_amount_due_this_invoice)
                     }
                    ${
                        // For second invoice and above
                        getTotalBalanceDueNow(invoice_index, total_balance_due_now)
                     }

                    <p
                        class="acumin-condense fwsemibold"
                        style="margin: -5px 0 -2px 2px; font-size: 13px"
                    >
                        OTHER PAYMENT OPTIONS:
                    </p>
                    <p style="margin-left: 2px">Via Zelle Use alisa@illicre.com</p>
                </div>
            </section>
        </section>
        ${isPreview ? `<div class="page-break"></div>
        <div style="margin-bottom:120px"></div>` : ``}
        `;
    }

    const html = `
    <html>
    <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;1,400&display=swap"
            rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
            href="https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap"
            rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
            href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
            rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/fqe8cdb.css">

        <style type="text/css">
            @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
            @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

            @font-face {
                font-family: "Acumin Variable Concept";
                font-weight: bold;
                font-stretch: 70% 100%;
                src: url("https://illicre.com/wp-content/uploads/Acumin-Variable-Concept.eot")
                        format("embedded-opentype"),
                    url("https://illicre.com/wp-content/uploads/Acumin-Variable-Concept.woff")
                        format("woff"),
                    url("https://illicre.com/wp-content/uploads/Acumin-Variable-Concept.ttf")
                        format("truetype");
            }

            @media print
            {
            table { page-break-after:auto }
            tr    { page-break-inside:avoid; page-break-after:auto }
            td    { page-break-inside:avoid; page-break-after:auto }
            thead { display:table-header-group }
            tfoot { display:table-footer-group }
            }

            html {
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);
            }

            body {
                margin-right: 20px;
                font-size: 12px;
                color: #131645;
                font-family: "Didact Gothic", sans-serif !important;
            }

            p {
                margin: 0;
            }
            table * {
                font-size: 12px;
            }
            ol {
                counter-reset: list;
                padding-left: 1em;
            }

            .text-7 {
                font-size: 10px;
            }

            .text-10 {
                font-size: 12px;
            }

            .text-11 {
                font-size: 14px;
            }

            .text-12 {
                font-size: 15px !important;
            }

            .page-break {
                page-break-after: avoid;
            }
            

            .text-center {
                text-align: center !important;
            }

            hr {
                border-bottom: 1px solid #ccc;
            }
            .white-text {
                color: white;
            }
            #ertl-number {
                text-align: right;
            }
            .times p,
            .times div {
                font-family: "Times New Roman", Times, serif !important;
            }
            .font-14 {
                font-size: 16px;
            }
            .font-10 {
                font-size: 12px;
            }

            .date {
                margin: 0 auto;
                width: 70%;
                display: flex;
                justify-content: space-between;
            }
            .listing-table {
                width: 100%;
                border-collapse: collapse;
            }

            .listing-table td,
            .listing-table th {
                border: 1px solid black;
                padding: 5px;
                height: 40px;
            }
            .width-15 {
                width: 15%;
            }
            .width-10 {
                width: 10%;
            }
            .width-17 {
                width: 17%;
            }
            .custom-d-flex {
                display: flex;
                justify-content: space-between;
            }
            .red-text {
                color: #da3b2f !important;
            }
            .header {
                font-weight: bold;
                margin: 0;
                text-align: right;
            }
            .fwbold {
                font-weight: bold;
            }
            .mt20 {
                margin-top: 20px;
            }
            .img {
                margin-bottom: 20px;
                width: 100px;
            }
            .style1 {
                border: 1px solid black;
                margin-top: 20px;
                padding: 10px;
            }
            .style2 {
                display: inline-block;
                font-weight: bold;
                width: 150px;
            }
            .style3 {
                border-spacing: 10px;
                width: 100%;
            }
            .style4 {
                text-align: left;
                width: 200px;
            }
            .textRight {
                text-align: right;
            }
            .style5 {
                border-top: 1px solid black;
                border-bottom: 1px solid black;
            }
            .style6 {
                display: flex;
                justify-content: space-between;
            }
            .style7 {
                display: flex;
                justify-content: center;
            }
            .invoice-table {
                border: 0.5px solid #da2128;
            }

            .invoice-table table,
            table.invoice-detail {
                border-collapse: collapse;
            }

            .invoice-table .bt {
                border-top: 1px solid #da2128;
                display: inline-block;
            }

            .gray-bg {
                background-color: #ededed;
            }

            .invoice-detail {
                padding: 5px;
            }

            .invoice-detail .gray-bg {
                border-bottom: 1px solid black;
            }

            .invoice-heading {
                color: #da2128;
                font-size: 19px;
                font-weight: bold;
                font-stretch: 70%;
                margin: 0;
            }
            .text-red {
                color: #da2128;
            }
            .col-5 {
                width: 5%;
            }
            .col-15 {
                width: 15%;
            }
            .col-10 {
                width: 10%;
            }

            .col-14 {
                width: 14%;
            }

            .col-20 {
                width: 20%;
            }

            .col-25 {
                width: 25%;
            }

            .col-30 {
                width: 30%;
            }

            .col-40 {
                width: 40%;
            }
            .col-45 {
                width: 45%;
            }

            .col-50 {
                width: 50%;
            }

            .col-60 {
                width: 60%;
            }

            .col-70 {
                width: 70%;
            }

            .col-80 {
                width: 80%;
            }

            .col-90 {
                width: 90%;
            }

            .col-100 {
                width: 100% !important;
            }
            .no-bt {
                border-top: 1px solid rgba(0, 0, 0, 0) !important;
            }

            .no-bb {
                border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
            }
            .the-table .no-br {
                border-right: 1px solid rgba(0, 0, 0, 0) !important;
            }

            .the-table .no-bl {
                border-left: 1px solid rgba(0, 0, 0, 0) !important;
            }

            .the-table.border-red table,
            .the-table.border-red th,
            .the-table.border-red td {
                border-color: #da2128;
            }
            .the-table table {
                width: 100%;
            }

            .the-table table,
            .the-table th,
            .the-table td {
                border: 1px solid #878787;
                border-collapse: collapse;
            }

            .the-table td {
                padding: 5px;
            }
            .inline-block {
                display: inline-block;
            }
            .drop-shadow {
                filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.18));
            }
            .box-shadow {
                box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.18);
            }
            .fwsemibold {
                font-weight: 600;
            }
            .gray-bar {
                background-color: #ededed;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px 1px;
            }
            .acumin-condense {
                font-family: 'acumin-pro-condensed', sans-serif;
            }
            .acumin {
                font-family: 'acumin-pro', sans-serif;
            }
            .condense {
                font-stretch: 50%;
            }
            .fs-roboto {
                font-family: "Roboto", sans-serif;
            }
            .col-container {
                column-count: 2;
            }
            .col1 {
                column-span: 1;
              }
            .col2 {
                column-span: 10;
            }
            td {
                vertical-align:top
            }
        </style>
    </head>

    <body>
        ${renderAllInvoices(invoice_index)}
    </body>
</html>
    `;

    return html;
};
