import React from "react";
import { useCallback } from "react";
import { get } from 'lodash';
import { clone } from "../../../shared/utility";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";

import { checkOutsideBrokerOrReferralFirmFields } from "../RSheetsLogic";
import Brokers from "../CommonCustomElements/Brokers";

export const LESSEE_OUTSIDE_BROKER_FIELDS = [
	"outside_broker_company_name",
    "outside_broker_name",
    "outside_broker_lastname",
    "outside_broker_address1",
    "outside_broker_address2",
    "outside_broker_city",
    "outside_broker_state",
    "outside_broker_zip",
    "outside_broker_contact",
    "outside_broker_fax",
    "outside_broker_main_phone",
    "outside_broker_email",
    "outside_broker_taxid",
    "outside_broker_w9",
    "outside_broker_w9_upload",
    "outside_broker_commission",
];

export const LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS = ["outside_broker_fax","outside_broker_taxid"];

const Step4 = (props) => {
    const { setQueryFor, getContacts, setOpenedContactModal, renderElement, data, setData } = props;

    const areOutsideBrokerFieldsRequired = checkOutsideBrokerOrReferralFirmFields(data, LESSEE_OUTSIDE_BROKER_FIELDS, LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS);

    const renderOutsideBrokerFields = useCallback(() => {
		if (!data.outside_broker_id) {
            return null;
        }
        return LESSEE_OUTSIDE_BROKER_FIELDS.map((lfield) => {
            const extraValidations =
                !LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS.includes(lfield) && areOutsideBrokerFieldsRequired
                    ? ["required"]
                    : [];
            if (lfield === "outside_broker_w9_upload") {
                if (data.outside_broker_w9 === "Yes") {
                    return (
                        <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                            {renderElement(lfield)}
                        </GridItem>
                    );
                } else {
                    return null;
                }
            }
            let labelOverride = null;
            if (data.outside_broker_id) {
                // is a company
                if (data.outside_broker_id.toString().indexOf("c") !== -1) {
                    if (lfield === "outside_broker_lastname") return null;
                    if (lfield === "outside_broker_name") {
                        labelOverride = "Company Name";
                    }
                }
            }
            return (
                <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    {renderElement(lfield, null, null, null, labelOverride, extraValidations)}
                </GridItem>
            );
        });
    }, [data]);

    return (
        <div>
            <GridContainer>
                {/* Lessee */}
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        color="primary"
                        onClick={() => {
                            setQueryFor("lessees");
                            getContacts({
                                names_only: "All",
                            });
                            setOpenedContactModal(true);
                        }}
                    >
                        <i className="fa fa-plus"></i> ADD ASSIGNEE
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {data.lessees.length === 0 && <div className="red-text" style={{margin:20}}>Assignee Required</div>}
                    {renderElement('lessees')}
                    <hr />
                    {renderElement('lessee_contacts', undefined, undefined, undefined, undefined, undefined, undefined, true)}
                    <hr />
                </GridItem>

                <Brokers 
                    data={data}
                    setData={setData}
                    outside_brokers_field_name="outside_broker" 
                    outside_brokers_field_label="ASSIGNEE"
                />
            </GridContainer>
        </div>
    );
};

export default Step4;
