import React from 'react';
import { useEffect } from 'react';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';

const renderSignRequestExtraFields = (renderElement) => {
	const extra_fields = [
		'order_type',
		'maintenance_description',
		'sign_size',
		'location',
		'sign_request_reason',
	];

	return extra_fields.map((field) => {
		return (
			<GridItem xs={12} sm={3} md={3} key={field}>
				{renderElement(field)}
			</GridItem>
		);
	});
};

const Step2 = (props) => {
	const { data, renderElement, setOpenedChooseSpacesModal } = props;

	return (
		/* Marketing Information */
		<GridContainer>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement('window_signs_returned')}
			</GridItem>
			{data.window_signs_returned && data.window_signs_returned === 'Yes' && (
				<GridItem xs={12} sm={3} md={3}>
					{renderElement('window_sign_number')}
				</GridItem>
			)}

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('sign_removal_requested')}
			</GridItem>
			{(data.sign_removal_requested && data.sign_removal_requested === "Yes") && renderSignRequestExtraFields(renderElement)}

			<GridItem xs={12} sm={6} md={6}>
				{renderElement('loopnet_marketing_updated')}
			</GridItem>
		</GridContainer>
	);
};

export default Step2;
