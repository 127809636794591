import React, { useState } from "react";
import { useCallback } from 'react';
import _debounce from 'lodash/debounce';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInputState from "components/CustomInput/CustomInputStates.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomRadio";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Notification from "../../../components/Notification/Notification";
import propertyFields, { initialPropertyData } from "./PropertiesObj";
import { clone } from "shared/utility";
import { debounce, get } from "lodash";
import { getUser } from "../../../shared/authValidation";
import Datetime from "react-datetime";
import {
    min1,
    min2,
    min6,
    min4,
    min5,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max50,
    max100,
    max250,
    max500,
    no_letters_allow_slash
} from "shared/validation";
import CustomMultipleSelect from "../../../components/CustomSelect/CustomMultipleSelect";
import { useEffect } from "react";
import { saveUserPermsSaga } from "../../../store/sagas/userperms";
import PropertiesLoopnetDialog from "./PropertiesLoopnetDialog";
import { TextField } from "@material-ui/core";
const validate = {
    min1,
    min2,
    min6,
    min4,
    min5,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max50,
    max100,
    max250,
    max500,
    no_letters_allow_slash
};
const getValidations = (f) => {
    if (propertyFields[f].validations) return propertyFields[f].validations.map((v) => validate[v]);
    return [];
};

const processNL = (nl_number) => {
    if (nl_number) {
        if (nl_number.length === 1) {
            return `NL00${nl_number}`;
        } else if (nl_number.length === 2) {
            return `NL0${nl_number}`;
        } else if (nl_number.length === 3) {
            return `NL${nl_number}`;
        }
    }
}

const PropertiesFormMain = (props) => {
    const [startSendingMarketing, setStartSendingMarketing] = useState(false);
    const [userPerms, setUserPerms] = useState({});
    const { updateDataObj, dataObj, action, setDataObj } = props;
    const [notification, setNotification] = useState('');
    const [openedLoopnet, setOpenedLoopnet] = useState(false);
    const openLoopnet = () => setOpenedLoopnet(true);
    const closeLoopnet = () => setOpenedLoopnet(false);

    const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

    function handleDebounceFn(inputValue, data) {
        console.log('inputValue', inputValue, data);
        if (inputValue && data) {
            if (props.checkIlliNumberForProperty) {
                const prefix = (data.property_is === 'No Listing' && data.property_is_for !== 'Sale') ? 
                    'NL' 
                : 
                    data.property_is === 'Property Management' ? 
                        '9' 
                    : 
                        '';
                
                props.checkIlliNumberForProperty({
                    illi_number_check: `${prefix}${inputValue}`, 
                    property_id: data ? data.id : null
                });
            }
        } else {
            props.checkIlliNumberForProperty({
                illi_number_check: 0
            });
        }
    }

    useEffect(() => {
        debounceFn(dataObj.prefixless_illi_number, dataObj);
    }, [dataObj.property_is, dataObj.property_is_for]);

    useEffect(() => {
        const user = getUser();
        if (user) {
            if (user.permObj) {
                setUserPerms(user.permObj);
            }
        }
    }, []);

    useEffect(() => {
        if (props.property_is_for_override && props.property_is_for_override === "Lease") {
            dataObj.property_is_for = ["Lease"];
        }
    }, []);

    useEffect(() => {}, [props.existingAddress]);

    useEffect(() => {
        if (props.success_send_to_marketing && startSendingMarketing) {
            updateDataObj('has_sent_marketing_information', 'yes');
            setNotification('illi Marketing has been notified, Please make sure to save the property');
            props.setMadeChanges(true);
            setStartSendingMarketing(false);
        }
    }, [startSendingMarketing, props.success_send_to_marketing]);

    const checkPropertyExistingAddress = debounce((obj) => {
        props.checkPropertyExistingAddress(obj);
    }, 500);

    const validateAddress = () => {
        let validations = [];
        const checkExistingAddress = (v) => {
            return props.existingAddress;
        };
        if (getValidations("street1") && getValidations("street1").length > 0) {
            validations = getValidations("street1");
            validations.push(checkExistingAddress);
            return validations;
        } else {
            return [checkExistingAddress];
        }
    };

    const illi = dataObj.illi_number;
    const isPmNumberRequired = illi && typeof illi === "string" && illi.length === 5 && illi[0] === "9";
    const hasPmNumber = illi && typeof illi === "string" && illi.length === 5 && (illi[0] === "9" || illi[0] === "8");
    const pmValidations = getValidations("pm_number");
    const allowAddToWebsite = getUser('CRM add_to_website');

    if (isPmNumberRequired) {
        pmValidations.push(required);
    }

    let nl_number = dataObj.nl_number ? dataObj.nl_number : '';
    if (props.property_options) {
        if (props.property_options.next_nl_number) {
            if (!nl_number) nl_number = processNL(props.property_options.next_nl_number.toString());
        }
    }
    // only shows Use Type (Property Type) if imported from costar or is for Sale
    const shouldShowUseType = get(dataObj, 'imported_from', '') === 'costar' ? 1 : dataObj.property_is_for
        ? dataObj.property_is_for.indexOf("Sale") !== -1
            ? true
            : false
        : false;

    const hideNoListing = (dataObj.property_is_for === 'Sale' || (dataObj.property_is ? (dataObj.property_is ? dataObj.property_is.indexOf('Prospective Listing') !== -1 : false) : false)) ? true : false;
    const hidePropertyManagement = dataObj.property_is ? dataObj.property_is.indexOf('Prospective Listing') !== -1 ? true : false : false;

    console.log('PropertiesFormMain', props);

    return (
        <div className={props.action === "View" ? "no-click lightgray-bg enclosure" : "enclosure"}>
            <GridContainer>
                {allowAddToWebsite && <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dataObj.add_to_website === 'Yes' ? true : false}
                                onChange={() => {
                                    updateDataObj('add_to_website', dataObj.add_to_website === 'Yes' ? 'No' : 'Yes');
                                }}
                                color="default"
                            />
                        }
                        label="Add to Website"
                    />
                </GridItem>}
                <GridItem xs={12} sm={3} md={3}>
                    <div>
                        <CustomInput
                            label={propertyFields.prefixless_illi_number.label}
                            value={dataObj.prefixless_illi_number}
                            onChange={(e) => {
                                if (userPerms.hasOwnProperty("CRM number edit")) {
                                    updateDataObj("prefixless_illi_number", e.target.value);
                                    debounceFn(e.target.value, dataObj);
                                }
                            }}
                            validate={getValidations("prefixless_illi_number")}
                            startAdornment={<span style={{fontSize:14}}>{(dataObj.property_is === 'No Listing' && dataObj.property_is_for !== 'Sale') ? 'NL' : 
                                dataObj.property_is === 'Property Management' ? '9' : ''}</span>}
                        />
                    </div>
                    {props.illiNumberExistsProperty && <div style={{color:'red'}}>
                        illi number already exists on 
                        <div>{props.illiNumberExistsProperty.name}</div>
                        <div>{props.illiNumberExistsProperty.street1}</div>
                        <div>{props.illiNumberExistsProperty.street2}</div>
                        <div>{props.illiNumberExistsProperty.city} {props.illiNumberExistsProperty.state} {props.illiNumberExistsProperty.zip}</div>
                    </div>}
                </GridItem>
                {/* Removed - https://www.wrike.com/workspace.htm?acc=554950#/inbox/task/1203714685 */}
                {/* {hasPmNumber && (
                    <GridItem xs={12} sm={3} md={3}>
                        <div>
                            <CustomInput
                                label={propertyFields.pm_number.label}
                                value={dataObj.pm_number}
                                onChange={(e) => {
                                    updateDataObj("pm_number", e.target.value);
                                }}
                                validate={pmValidations}
                                readonly={
                                    props.action === "View" || !userPerms.hasOwnProperty("CRM number edit")
                                        ? true
                                        : false
                                }
                            />
                        </div>
                    </GridItem>
                )} */}
                <GridItem xs={12} sm={3} md={3}>
                    <div>
                        <CustomInput
                            label={propertyFields.total_building_sqft.label}
                            value={dataObj.total_building_sqft}
                            onChange={(e) => {
                                updateDataObj("total_building_sqft", e.target.value);
                            }}
                            validate={getValidations("total_building_sqft")}
                            readonly={
                                props.action === "View" ? true : false
                            }
                        />
                    </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <div>
                        <CustomInput
                            label={propertyFields.total_land_sqft.label}
                            value={dataObj.total_land_sqft}
                            onChange={(e) => {
                                updateDataObj("total_land_sqft", e.target.value);
                            }}
                            validate={getValidations("total_land_sqft")}
                            readonly={
                                props.action === "View" ? true : false
                            }
                        />
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {dataObj.property_is && <div>
                        {dataObj.illi_number && dataObj.pm_number && dataObj.property_is.indexOf('Property Management') !== -1 && (
                            <p className="text-center red bold" style={{ fontSize: 20, marginTop: 20 }}>
                                Property Managed by illi
                            </p>
                        )}
                    </div>}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <div style={{marginTop:20}}>
                        <InputLabel style={{ fontSize: "14px" }}>{propertyFields.property_is_for.label}</InputLabel>
                        <FormGroup
                            onChange={(e) => {
                                let arr = [];
                                if (dataObj.property_is_for) {
                                    if (dataObj.property_is_for && dataObj.property_is_for.includes(",")) {
                                        arr = clone(dataObj.property_is_for.split(","));
                                    } else if (dataObj.property_is_for) {
                                        arr = [clone(dataObj.property_is_for)];
                                    } else {
                                        arr = [];
                                    }
                                }

                                if (e.target.checked) {
                                    arr.push(e.target.name);
                                } else {
                                    arr = arr.filter((item) => item !== e.target.name);
                                }

                                if (props.property_is_for_override && props.property_is_for_override === "Lease") {
                                    if (arr.indexOf("Lease") === -1) {
                                        arr.push("Lease");
                                    }
                                }
                                const newData = clone(dataObj);
                                arr = arr.toString();
                                // MODIFICATION : only hide the fields, don't manipulate them
                                //                  manipulate them on submit
                                if (arr === 'Sale') {
                                    // if (newData.property_is === 'No Listing') {
                                    //     newData.property_is = '';
                                    // }
                                } else {
                                    // if (props.propertyData) {
                                    //     // if previously saved property is No Listing put it back
                                    //     if (props.propertyData.property_is === 'No Listing') {
                                    //         newData.property_is = 'No Listing';
                                    //     }
                                    // }
                                }
                                newData.property_is_for = arr;
                                setDataObj(newData);
                            }}
                        >
                            {(dataObj.property_is ? dataObj.property_is.indexOf('No Listing') !== -1 ? false : true : true ) && <FormControlLabel
                                control={<Checkbox color="default" name="Sale" checked={dataObj.property_is_for ? dataObj.property_is_for.includes("Sale") : false} />}
                                label="Sale"
                            />}
                            <FormControlLabel
                                control={<Checkbox color="default" name="Lease" checked={dataObj.property_is_for ? dataObj.property_is_for.includes("Lease") : false} />}
                                label="Lease"
                            />
                        </FormGroup>
                        <InputLabel style={{ fontSize:14, marginTop:20, display:'inline-block' }}>Property Is :</InputLabel>
                        <FormGroup
                            onChange={(e) => {
                                const name = e.target.name;
                                const newDataObj = clone(dataObj);
                                let property_is_arr = newDataObj.property_is ? newDataObj.property_is.split(',') : [];
                                if (property_is_arr.indexOf(name) === -1) {
                                    property_is_arr.push(name);
                                } else {
                                    property_is_arr = property_is_arr.filter(pi => {
                                        if (pi !== name) return true;
                                    })
                                }
                                newDataObj.property_is = property_is_arr.join(',');
                                const current_illi_number = dataObj.illi_number ? dataObj.illi_number.toString() : '';
                                console.log('current illi', current_illi_number, dataObj);
                                if (current_illi_number) {
                                    let found_existing_prefix = false;
                                    if (name === 'Property Management') {
                                        const arr = current_illi_number.split('NL');
                                        if (arr.length > 1) {
                                            newDataObj.illi_number = `9${arr[1]}`;
                                            newDataObj.pm_number = `9${arr[1]}`;
                                            found_existing_prefix = true;
                                        } else if (current_illi_number[0] === '9') {
                                            found_existing_prefix = true;
                                        }
                                    } else if (name === 'No Listing') {
                                        // No Listing
                                        if (current_illi_number[0] === '9') {
                                            const num_without_prefix = current_illi_number.slice(1);
                                            newDataObj.illi_number = `NL${num_without_prefix}`;
                                            newDataObj.pm_number = ``;
                                            found_existing_prefix = true;
                                        }  else if (current_illi_number[0] === 'N') {
                                            // currently has NL 
                                            found_existing_prefix = true;
                                        }
                                    } else {
                                        newDataObj.illi_number = ``;
                                        newDataObj.pm_number = ``;
                                    }
                                    if (!found_existing_prefix) {
                                        if (name === 'Property Management') {
                                            newDataObj.illi_number = `9${dataObj.illi_number}`;
                                            newDataObj.pm_number = `9${dataObj.illi_number}`;
                                        } else if (name === 'No Listing') {
                                            newDataObj.illi_number = `NL${dataObj.illi_number}`;
                                            newDataObj.pm_number = ``;
                                        } else {
                                            newDataObj.illi_number = ``;
                                            newDataObj.pm_number = ``;
                                        }
                                    } else {
                                        if (dataObj.prefixless_illi_number && (dataObj.property_is === newDataObj.property_is)) {
                                            newDataObj.illi_number = dataObj.prefixless_illi_number;
                                            newDataObj.pm_number = '';
                                            newDataObj.property_is = '';
                                        }
                                    }
                                } else {
                                    // MODIFIED - not to do anything
                                    // allow uncheck
                                    if (newDataObj.property_is === dataObj.property_is) {
                                        newDataObj.property_is = '';
                                    }
                                }
                                if (!newDataObj.property_is && dataObj.property_is === 'Prospective Listing') {
                                    if (props.propertyData) {
                                        if (props.propertyData.property_is !== 'Prospective Listing') {
                                            // restore it to previous value
                                            // newDataObj.property_is = props.propertyData.property_is;
                                        }
                                    }
                                }
                                setDataObj(newDataObj);
                            }}
                        >
                            {!hideNoListing && <FormControlLabel
                                control={<Checkbox color="default" name="No Listing" checked={dataObj.property_is ? dataObj.property_is.indexOf('No Listing') !== -1 : false} />}
                                label="No Listing"
                            />}
                            {!hidePropertyManagement && <FormControlLabel
                                control={<Checkbox color="default" name="Property Management" checked={dataObj.property_is ? dataObj.property_is.indexOf('Property Management') !== -1 : false} />}
                                label="Property Management"
                            />}
                            <FormControlLabel
                                control={<Checkbox color="default" name="Prospective Listing" checked={dataObj.property_is ? dataObj.property_is.indexOf('Prospective Listing') !== -1 : false} />}
                                label="Prospective Listing"
                            />
                            {dataObj.property_is === 'Prospective Listing' && <div>
                                {!dataObj.has_sent_marketing_information && <Button size="sm" color="primary" onClick={() => {
                                    if (props.sendToMarketing) {
                                        setStartSendingMarketing(true);
                                        props.sendToMarketing({
                                            subject: 'illi Marketing Notification',
                                            message: `Property ${dataObj.name} in need of brochure.`
                                        });
                                    }
                                }}>Send to Marketing for Brochure</Button>}
                                {dataObj.has_sent_marketing_information && <Button size="sm" color="white">
                                    Sent to Marketing</Button>}
                            </div>}
                        </FormGroup>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {dataObj.property_is_for && (
                        <div>
                            {dataObj.property_is_for.indexOf("Sale") !== -1 && (
                                <CustomInput
                                    label={propertyFields.property_price_per_sqft.label}
                                    value={dataObj.property_price_per_sqft}
                                    onChange={(e) => {
                                        updateDataObj("property_price_per_sqft", e.target.value);
                                    }}
                                    validate={getValidations("property_price_per_sqft")}
                                    isDollar={propertyFields.property_price_per_sqft.isDollar}
                                />
                            )}
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {dataObj.property_is_for && (
                        <div>
                            {dataObj.property_is_for.indexOf("Sale") !== -1 && (
                                <CustomInput
                                    label={propertyFields.sale_price.label}
                                    value={dataObj.sale_price}
                                    onChange={(e) => {
                                        updateDataObj("sale_price", e.target.value);
                                    }}
                                    validate={getValidations("sale_price")}
                                    isDollar={propertyFields.sale_price.isDollar}
                                />
                            )}
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {dataObj.property_is_for && (
                        <div style={{opacity:0}}>
                            {dataObj.property_is_for.indexOf("Lease") !== -1 && (
                                <CustomSelect
                                    label={propertyFields.lease_type.label}
                                    options={propertyFields.lease_type.options}
                                    choose={(e, n) => {
                                        if (props.action === "View") return;
                                        updateDataObj("lease_type", e);
                                    }}
                                    default={dataObj.lease_type}
                                />
                            )}
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                        label={propertyFields.name.label}
                        value={dataObj.name}
                        onChange={(e) => {
                            updateDataObj("name", e.target.value);
                        }}
                        onClick={() => {
                            const user = getUser();
                            if (user) {
                                if (user.type === 'A') {
                                    let propertyIDsEdited = localStorage.getItem('property_ids_edited') ?
                                        JSON.parse(localStorage.getItem('property_ids_edited'))
                                    :
                                        [];
                                    let showMessage = false;
                                    if (props.action === 'Add') {
                                        showMessage = true;
                                    } else if (props.action === 'Edit') {
                                        // load data if
                                        if (dataObj.id) {
                                            if (propertyIDsEdited.indexOf(dataObj.id) === -1) {
                                                showMessage = true;
                                            }
                                        }
                                    }
                                    if (showMessage) {
                                        if (props.property_options) {
                                            const msg = props.property_options.agent_message;
                                            if (msg && !props.hasShownAgentMessage) {
                                                setNotification(msg);
                                                props.setHasShownAgentMessage(true);
                                                if (props.action === 'Edit' && dataObj.id) {
                                                    propertyIDsEdited.push(dataObj.id);
                                                    localStorage.setItem('property_ids_edited', JSON.stringify(propertyIDsEdited));
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }}
                        validate={getValidations("name")}
                        readonly={props.action === "View" ? true : false}
                    />
                    {dataObj.address_is_range !== 'Yes' && <CustomInput
                        label={propertyFields.street1.label}
                        value={dataObj.street1}
                        onChange={(e) => {
                            checkPropertyExistingAddress({
                                street1: e.target.value,
                                city: dataObj.city ? dataObj.city : '',
                                street_range1: dataObj.street_range1 ? dataObj.street_range1 : '',
                                street_range2: dataObj.street_range2 ? dataObj.street_range2 : '',
                                property_id: dataObj.id ? dataObj.id : null
                            });
                            updateDataObj("street1", e.target.value);
                        }}
                        validate={validateAddress()}
                        readonly={props.action === "View" ? true : false}
                        uppercase={true}
                    />}
                    <div className="boxed mt-20">
                        <CustomRadio
                            label="Address 1 is Range,Yes,No"
                            options={["Yes", ""]}
                            onChange={(e) => {
                                updateDataObj("address_is_range", e.target.value);
                            }}
                            value={dataObj.address_is_range}
                        />
                        {dataObj.address_is_range === 'Yes' && <div>
                            <CustomInput
                                label="Address Starting Range"
                                value={dataObj.street_range1}
                                onChange={(e) => {
                                    updateDataObj("street_range1", e.target.value);
                                }}
                                validate={getValidations("street_range1")}
                                readonly={props.action === "View" ? true : false}
                            />
                            <CustomInput
                                label="Address Ending Range"
                                value={dataObj.street_range2}
                                onChange={(e) => {
                                    updateDataObj("street_range2", e.target.value);
                                }}
                                validate={getValidations("street_range2")}
                                readonly={props.action === "View" ? true : false}
                            />
                            <CustomInput
                                label={propertyFields.street1.label}
                                value={dataObj.street1}
                                onChange={(e) => {
                                    checkPropertyExistingAddress({
                                        street1: e.target.value,
                                        city: dataObj.city ? dataObj.city : '',
                                        street_range1: dataObj.street_range1 ? dataObj.street_range1 : '',
                                        street_range2: dataObj.street_range2 ? dataObj.street_range2 : '',
                                        property_id: dataObj.id ? dataObj.id : null
                                    });
                                    updateDataObj("street1", e.target.value);
                                }}
                                validate={validateAddress()}
                                readonly={props.action === "View" ? true : false}
                                uppercase={true}
                            />
                        </div>}
                    </div>
                    {dataObj.address2_is_range !== 'Yes' && <CustomInput
                        label={propertyFields.street2.label}
                        value={dataObj.street2}
                        onChange={(e) => {
                            updateDataObj("street2", e.target.value);
                        }}
                        validate={getValidations("street2")}
                        readonly={props.action === "View" ? true : false}
                        uppercase={true}
                    />}
                    <div className="boxed mt-20">
                        <CustomRadio
                            label="Address 2 is Range,Yes,No"
                            options={["Yes", ""]}
                            onChange={(e) => {
                                updateDataObj("address2_is_range", e.target.value);
                            }}
                            value={dataObj.address2_is_range}
                        />
                        {dataObj.address2_is_range === 'Yes' && <div>
                            <CustomInput
                                label="Address 2 Starting Range"
                                value={dataObj.street2_range1}
                                onChange={(e) => {
                                    updateDataObj("street2_range1", e.target.value);
                                }}
                                validate={getValidations("street2_range1")}
                                readonly={props.action === "View" ? true : false}
                            />
                            <CustomInput
                                label="Address 2 Ending Range"
                                value={dataObj.street2_range2}
                                onChange={(e) => {
                                    updateDataObj("street2_range2", e.target.value);
                                }}
                                validate={getValidations("street2_range2")}
                                readonly={props.action === "View" ? true : false}
                            />
                            <CustomInput
                                label={propertyFields.street2.label}
                                value={dataObj.street2}
                                onChange={(e) => {
                                    updateDataObj("street2", e.target.value);
                                }}
                                validate={validateAddress()}
                                readonly={props.action === "View" ? true : false}
                                uppercase={true}
                            />
                        </div>}
                    </div>
                    <CustomInput
                        label="County"
                        value={dataObj.county}
                        onChange={(e) => {
                            updateDataObj("county", e.target.value);
                        }}
                        validate={getValidations("county")}
                        readonly={props.action === "View" ? true : false}
                        uppercase={true}
                    />
                    <div style={{ marginTop: 20 }}>
                        <CustomRadio
                            label={propertyFields.la_city_verification.label}
                            options={["1", "0"]}
                            onChange={(e) => {
                                updateDataObj("la_city_verification", e.target.value);
                            }}
                            value={dataObj.la_city_verification}
                        />
                        <div className="group-wrap">
                            Check if in City of L.A -
                            <a href="http://finance.lacity.org" target="_blank" style={{ textDecoration: "underline" }}>
                                <strong>http://finance.lacity.org</strong>
                            </a>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {<div style={{ opacity: shouldShowUseType ? 1 : 0}}><CustomSelect
                        label={propertyFields.use_type.label}
                        options={props.propertyUseTypes.use_type ? props.propertyUseTypes.use_type.sort() : []}
                        choose={(e, n) => {
                            if (props.action === "View") return;
                            updateDataObj("use_type", e);
                        }}
                        default={dataObj.use_type}
                    /></div>}
                    <div style={{ marginTop: "-10px" }}>
                        <CustomInput
                            label={propertyFields.city.label}
                            value={dataObj.city}
                            onChange={(e) => {
                                updateDataObj("city", e.target.value);
                            }}
                            validate={getValidations("city")}
                            readonly={props.action === "View" ? true : false}
                            uppercase={true}
                        />
                    </div>
                    <div>
                        <CustomInputState
                            value={dataObj.state}
                            onChange={(val) => {
                                updateDataObj("state", val);
                            }}
                        />
                    </div>
                    <CustomInput
                        label={propertyFields.zip.label}
                        value={dataObj.zip}
                        onChange={(e) => {
                            updateDataObj("zip", e.target.value);
                        }}
                        validate={getValidations("zip")}
                        readonly={props.action === "View" ? true : false}
                    />
                    <div style={{ marginTop: 20 }}>
                        <InputLabel style={{ fontSize: "14px" }}>{propertyFields.goal.label}</InputLabel>
                        <FormGroup
                            onChange={(e) => {
                                let newGoals = dataObj.goal ? clone(dataObj.goal) : [];

                                if (e.target.checked) {
                                    newGoals.push(e.target.name);
                                } else {
                                    newGoals = newGoals.filter((item) => item !== e.target.name);
                                }

                                updateDataObj("goal", newGoals);
                            }}
                        >
                            {propertyFields.goal.options.map((op, i) => {
                                const checked = dataObj.goal ? dataObj.goal.includes(op) : false;
                                return (
                                    <FormControlLabel
                                        key={i}
                                        control={<Checkbox color="default" name={op} checked={checked} />}
                                        label={op}
                                    />
                                );
                            })}
                        </FormGroup>
                    </div>
                    {(dataObj.goal ? dataObj.goal.includes("Other") : false) && (
                        <CustomInput
                            label={propertyFields.other_goal.label}
                            value={dataObj.other_goal}
                            onChange={(e) => {
                                updateDataObj("other_goal", e.target.value);
                            }}
                            validate={getValidations("other_goal")}
                            readonly={props.action === "View" ? true : false}
                        />
                    )}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                        label={propertyFields.loopnet_url.label}
                        value={dataObj.loopnet_url}
                        onChange={(e) => {
                            updateDataObj("loopnet_url", e.target.value);
                        }}
                        validate={getValidations("loopnet_url")}
                        readonly={props.action === "View" ? true : false}
                    />
                    {dataObj.loopnet_url && (
                        <Button color="rose" onClick={openLoopnet} style={{ pointerEvents: "auto" }}>
                            View
                        </Button>
                    )}
                    <div className="custom relative">
                        {dataObj.last_sale_date && <div className="tiny-label">Last Sale Date</div>}
                        <Datetime
                            inputProps={{
                                placeholder: "Last Sale Date",
                            }}
                            closeOnSelect={true}
                            onChange={(e) => {
                                if (props.action === 'View') return;
                                if (typeof e === 'object') {
                                    const stringDate = e.format('MM/DD/YYYY');
                                    return updateDataObj('last_sale_date', stringDate);;
                                }
                                updateDataObj('last_sale_date', e)
                            }}
                            timeFormat={false}
                            value={dataObj.last_sale_date}
                        />
                    </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {/* <CustomInput
						label={propertyFields.phone_number.label}
						value={dataObj.phone_number}
						onChange={(e) => {
							updateDataObj("phone_number", e.target.value);
						}}
						validate={getValidations("phone_number")}
						readonly={props.action === "View" ? true : false}
					/>
					<CustomInput
						label={propertyFields.fax_number.label}
						value={dataObj.fax_number}
						onChange={(e) => {
							updateDataObj("fax_number", e.target.value);
						}}
						validate={getValidations("fax_number")}
						readonly={props.action === "View" ? true : false}
					/>
					<CustomInput
						label={propertyFields.other_number.label}
						value={dataObj.other_number}
						onChange={(e) => {
							updateDataObj("other_number", e.target.value);
						}}
						validate={getValidations("other_number")}
						readonly={props.action === "View" ? true : false}
					/> */}
                    {/* <div style={{ marginTop: 20 }}>
						<CustomRadio
							label="illi Represented,Yes,No"
							options={["1", "0"]}
							onChange={(e) => {
								if (props.action === "View") return;
								updateDataObj("illi_represented", e.target.value);
							}}
							value={dataObj.illi_represented}
						/>
					</div> */}

                    <div>
                        <CustomInput
                            label={propertyFields.term.label}
                            value={dataObj.term}
                            onChange={(e) => {
                                updateDataObj("term", e.target.value);
                            }}
                            validate={getValidations("term")}
                            readonly={props.action === "View" ? true : false}
                        />
                    </div>
                    <div className="custom relative">
                        {dataObj.term_start_date && <div className="tiny-label">Term Start Date</div>}
                        <Datetime
                            inputProps={{
                                placeholder: "Term Start Date",
                            }}
                            closeOnSelect={true}
                            onChange={(e) => {
                                if (props.action === 'View') return;
                                if (typeof e === 'object') {
                                    const stringDate = e.format('MM/DD/YYYY');
                                    return updateDataObj('term_start_date', stringDate);
                                }
                                updateDataObj('term_start_date', e)
                            }}
                            timeFormat={false}
                            value={dataObj.term_start_date}
                        />
                    </div>
                    <div className="custom relative">
                        {dataObj.term_end_date && <div className="tiny-label">Term End Date</div>}
                        <Datetime
                            inputProps={{
                                placeholder: "Term End Date",
                            }}
                            closeOnSelect={true}
                            onChange={(e) => { 
                                if (props.action === 'View') return;
                                if (typeof e === 'object') {
                                    const stringDate = e.format('MM/DD/YYYY');
                                    return updateDataObj('term_end_date', stringDate);
                                }
                                updateDataObj('term_end_date', e)
                            }}
                            timeFormat={false}
                            value={dataObj.term_end_date}
                        />
                    </div>
                    <div style={{ marginTop: 20 }}>
						<CustomRadio
							label="Three Year Auto Renewal,Yes,No"
							options={["1", "0"]}
							onChange={(e) => {
								if (props.action === "View") return;
								updateDataObj("three_year_auto_renewal", e.target.value);
							}}
							value={dataObj.three_year_auto_renewal}
						/>
					</div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {!dataObj.illi_number && (
                        <CustomRadio
                            label="Interested in Property Management?,Yes,No"
                            options={["1", "0"]}
                            onChange={(e) => {
                                updateDataObj("interested_in_property_mgmt", e.target.value);
                            }}
                            value={dataObj.interested_in_property_mgmt}
                        />
                    )}
                    {/* <div>
                        <!-- NOTE: Retired ->
                        <CustomInput
                            label={propertyFields.price_per_sqft.label}
                            value={dataObj.price_per_sqft}
                            onChange={(e) => {
                                updateDataObj("price_per_sqft", e.target.value);
                            }}
                            validate={getValidations("price_per_sqft")}
                            readonly={props.action === "View" ? true : false}
                        />
                    </div> */}
                    <CustomInput
                        label={propertyFields.asking_price.label}
                        value={dataObj.asking_price}
                        onChange={(e) => {
                            updateDataObj("asking_price", e.target.value);
                        }}
                        validate={getValidations("asking_price")}
                        readonly={props.action === "View" ? true : false}
                        number_only={propertyFields.asking_price.number_only ? true : false}
                        isDollar={propertyFields.asking_price.isDollar ? true : false}
                    />
                    <CustomInput
                        label={propertyFields.exclusions.label}
                        value={dataObj.exclusions}
                        onChange={(e) => {
                            updateDataObj("exclusions", e.target.value);
                        }}
                        validate={getValidations("exclusions")}
                        readonly={props.action === "View" ? true : false}
                    />
                    <CustomInput
                        label={propertyFields.apn_number.label}
                        value={dataObj.apn_number}
                        onChange={(e) => {
                            updateDataObj("apn_number", e.target.value);
                        }}
                        validate={getValidations("apn_number")}
                        readonly={props.action === "View" ? true : false}
                    />
                </GridItem>
                <GridItem xs={12} sm={9} md={9}>
                        <div className="boxed" style={{paddingBottom:20}}>
                            <label style={{opacity: dataObj.notes ? 1 : 0}}>Notes</label>
                            <TextField
                                multiline
                                rows={10}
                                fullWidth={true}
                                value={dataObj.notes ? dataObj.notes : ''}
                                onChange={(e) => {
                                    updateDataObj('notes', e.target.value);
                                }}
                                placeholder="Notes"
                            >
                        </TextField>
                        </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}></GridItem>
            </GridContainer>

            {notification && <Notification 
                open={notification ? true : false}
                close={() => {
                    setNotification('');
                }}
                message={notification}
            />}

            <PropertiesLoopnetDialog src={dataObj.loopnet_url} open={openedLoopnet} onClose={closeLoopnet} />
        </div>
    );
};

export default PropertiesFormMain;
