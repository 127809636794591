import React from "react";
import { useEffect, useCallback } from "react";
import { get } from 'lodash';
import { clone } from "../../../shared/utility";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";

import { checkOutsideBrokerOrReferralFirmFields, setReferralFirmFeeAmountChoice } from "../RSheetsLogic";
import Brokers from "../CommonCustomElements/Brokers";

export const LESSEE_OUTSIDE_BROKER_FIELDS = [
    "outside_broker_company_name",
    "outside_broker_name",
    "outside_broker_lastname",
    "outside_broker_address1",
    "outside_broker_city",
    "outside_broker_state",
    "outside_broker_zip",
    "outside_broker_contact",
    "outside_broker_fax",
    "outside_broker_main_phone",
    "outside_broker_email",
    "outside_broker_taxid",
    "outside_broker_w9",
    "outside_broker_w9_upload",
    "outside_broker_commission",
];
export const LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS = ["outside_broker_fax","outside_broker_taxid"];

export const LESSEE_REFERRAL_FIRM_FIELDS = [
    "lessee_referral_firm_company_name",
    "lessee_referral_firm_name",
    "lessee_referral_firm_lastname",
    "lessee_referral_firm_address1",
    "lessee_referral_firm_city",
    "lessee_referral_firm_state",
    "lessee_referral_firm_zip",
    "lessee_referral_firm_contact",
    "lessee_referral_firm_fax",
    "lessee_referral_firm_main_phone",
    "lessee_referral_firm_email",
    "lessee_referral_firm_taxid",
    "lessee_referral_firm_w9",
    "lessee_referral_firm_w9_upload",
    "lessee_referral_firm_fee_amount_choice",
    "lessee_referral_firm_fee_amount_percentage",
    "lessee_referral_firm_fee_amount",
];
export const LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS = ["lessee_referral_firm_fax", "lessee_referral_firm_fee_amount_choice",
    "lessee_referral_firm_taxid"
];

const Step4 = (props) => {
    const { setQueryFor, getContacts, setOpenedContactModal, renderElement, data, setData } = props;
    console.log('Step4', data);

    useEffect(() => {
        setReferralFirmFeeAmountChoice(setData, data)("lessee");
    }, []);

    const areOutsideBrokerFieldsRequired = checkOutsideBrokerOrReferralFirmFields(
        data,
        LESSEE_OUTSIDE_BROKER_FIELDS,
        LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS
    );
    const areReferralFirmFieldsRequired = checkOutsideBrokerOrReferralFirmFields(
        data,
        LESSEE_REFERRAL_FIRM_FIELDS,
        LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS
    );

    const renderOutsideBrokerFields = useCallback(() => {
		if (!data.outside_broker_id) {
            return null;
        }
        return LESSEE_OUTSIDE_BROKER_FIELDS.map((lfield) => {
            const extraValidations =
                !LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS.includes(lfield) && areOutsideBrokerFieldsRequired
                    ? ["required"]
                    : [];
            if (lfield === "outside_broker_w9_upload") {
                if (data.outside_broker_w9 === "Yes") {
                    return (
                        <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                            {renderElement(lfield)}
                        </GridItem>
                    );
                } else {
                    return null;
                }
            }
            let labelOverride = null;
            if (data.outside_broker_id) {
                // is a company
                if (data.outside_broker_id.toString().indexOf("c") !== -1) {
                    if (lfield === "outside_broker_lastname") return null;
                    if (lfield === "outside_broker_name") return null;
                } else {
                    if (lfield === "outside_broker_company_name") return null;
                }
            }
            return (
                <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    {renderElement(lfield, null, null, null, labelOverride, extraValidations)}
                </GridItem>
            );
        });
    }, [data]);

    const renderReferralFirmFields = useCallback(() => {
        return LESSEE_REFERRAL_FIRM_FIELDS.map((lfield) => {
            const extraValidations =
                !LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS.includes(lfield) && areReferralFirmFieldsRequired
                    ? ["required"]
                    : [];

            if (data.lessee_referral_firm_id) {
                // is a company
                if (data.lessee_referral_firm_id.toString().indexOf("c") !== -1 && get(data, 'lessee_referral_firm_company_only', false)) {
                    if (lfield === 'lessee_referral_firm_name') return null;
                    if (lfield === 'lessee_referral_firm_lastname') return null;
                }
            }

            if (!lfield) {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}></GridItem>;
            }
            if (data.lessee_referral_firm_w9 !== "Yes") {
                if (lfield === "lessee_referral_firm_w9_upload") return null;
            }
            if (
                data.lessee_referral_firm_fee_amount_choice === "Percentage" &&
                lfield === "lessee_referral_firm_fee_amount"
            )
                return null;
            if (
                data.lessee_referral_firm_fee_amount_choice === "Value" &&
                lfield === "lessee_referral_firm_fee_amount_percentage"
            )
                return null;
            return (
                <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    {renderElement(lfield, null, null, null, null, extraValidations)}
                </GridItem>
            );
        });
    }, [data]);

    return (
        <div>
            <GridContainer>
                {/* Lessee */}
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        color="primary"
                        onClick={() => {
                            setQueryFor("lessees");
                            getContacts({
                                names_only: "All",
                            });
                            setOpenedContactModal(true);
                        }}
                    >
                        <i className="fa fa-plus"></i> ADD LESSEE
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {data.lessees.length === 0 && <div className="red-text" style={{margin:20}}>Lessee Required</div>}
                    {renderElement('lessees')}
                    <hr />
                    {renderElement('lessee_contacts', undefined, undefined, undefined, undefined, undefined, undefined, true)}
                    <hr />
                </GridItem>

                <Brokers 
                    data={data}
                    setData={setData}
                    outside_brokers_field_name="outside_broker" 
                    outside_brokers_field_label="LESSEE"
                />

                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <h4>
                        <strong>LESSEE REFERRAL</strong>
                    </h4>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    {!data.lessee_referral_firm_id && <Button
                        color="primary"
                        onClick={() => {
                            setQueryFor("lessee_referral_firm");
                            props.getContacts({
                                names_only: "All",
                            });
                            setOpenedContactModal(true);
                        }}
                    >
                        SELECT REFERRAL
                    </Button>}
                    {data.lessee_referral_firm_id && (!data?.lessee_referrals || !data?.lessee_referrals.length) && <Button
						color='success'
						onClick={() => {
							const newData = clone(data);
                            ['address1','city','company_name','contact','email','fax','fee_amount',
                            'fee_amount_choice','fee_amount_percentage','lastname',
                            'main_phone','name','state','taxid','w9','w9_upload','zip','id'].forEach(fieldy => {
                                const field = `lessee_referral_firm_${fieldy}`;
                                newData[field] = '';
                            })
                            setData(newData);
						}}
					>
						REMOVE REFERRAL
					</Button>}
                </GridItem>

                {data.lessee_referral_firm_id ? renderReferralFirmFields() : null}
            </GridContainer>
        </div>
    );
};

export default Step4;
