import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { clone, inRange, reverseCalculateDays } from '../../../../shared/utility';
import { formatDollar } from '../../../../shared/utility';
import { isEstimatedLease } from '../../RSheetsLogic';
import { 
    date2unix,
    round2dec, 
    getMonth,
    getYear,
    getDaysInRangeInAMonth,
    formatDoubleDigits,
    round,
    calculateDays,
    sortDaysRange
} from 'shared/utility';

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

const getMonthsBetween = (date1, date2) => {
    const month1 = getMonth(date1);
    const month2 = getMonth(date2);
    const year1 = getYear(date1);
    const year2 = getYear(date2);
    const year_difference = year2 - year1;
    if (year_difference > 0) {
        const minused1 = (12 - month1) + 1;
        return minused1 + month2;
    } else {
        return (month2 - month1) + 1;
    }
}

const getCurrentDate = (args) => {
    const { date1, date2, month_number } = args;
    if (month_number === 1) {
        return date1;
    } else {
        const month_num = month_number - 1;
        const month1 = getMonth(date1);
        const year1 = getYear(date1);
        let final_year = year1;
        let month_plus = month1 + month_num;
        if (month_plus > 12) {
            final_year++;
            month_plus = -(12 - month_plus);
        }
        return `${formatDoubleDigits(month_plus)}/01/${final_year}`;
    }
}

const termLogic = {};

termLogic.calculateLeaseTerm = (the_data) => {
    if (!the_data) return;
    const data = JSON.parse(JSON.stringify(the_data));
    console.log('calculating data', data);
    const is_estimated = isEstimatedLease(data);

    // --------------------
    // ESTIMATE CALCULATION
    // --------------------
    if (is_estimated) {
        const calcs = {
            lease_term_calculations: {
                years: []
            },
            abated_term_calculations: {
                years: []
            },
            gross_total_commission: 0,
            gross_rent_collected: 0,
            gross_rent_abated: 0,
            gross_abated_commission: 0,
            net_rent_collected: 0,
            net_commission: 0,
            use_real_days_in_a_month: true,
            is_estimated: true
        }
        if (data.lease_term && data.lease_length) {
            const lease_term = get(data, `lease_term`, []);
            lease_term.forEach((yr,y) => {
                const yearObj = {
                    year_total_term_rent: 0,
                    year_total_commission: 0,
                    ranges: [],
                    start: 0,
                    end: 0
                }
                const ranges = get(yr, `ranges`, []);
                ranges.forEach((range, r) => {
                    if (r === 0) {
                        yearObj.start = calculateDays(range.month1, range.day1);
                    }
                    if (r === (ranges.length - 1)) {
                        yearObj.end = calculateDays(range.month2, range.day2);
                    }
                    const rangeObj = {
                        value: parseFloat(range.value),
                        commission_rate: parseFloat(range.commissionRate),
                        total_term_rent: 0,
                        total_commission: 0,
                        rd: []
                    };
                    const month1 = range.month1 ? parseInt(range.month1.split('Month ')[1]) : 0;
                    const month2 = range.month2 ? parseInt(range.month2.split('Month ')[1]) : 0;
                    const day1  = range.day1 ? parseInt(range.day1.split('Day ')[1]) : 0;
                    const day2  = range.day2 ? parseInt(range.day2.split('Day ')[1]) : 0;

                    rangeObj.month1 = month1;
                    rangeObj.day1 = day1;
                    rangeObj.month2 = month2;
                    rangeObj.day2 = day2;

                    const months_between = month2 - month1 + 1;
                    const value = parseFloat(range.value);
                    const commission_rate = parseFloat(range.commissionRate) * 0.01;
                    for (let month = 1; month < (months_between + 1); month++) {
                        if (month === 1) {
                            const rdObj = {
                                days: 30 - day1 + 1,
                                value,
                                commission_rate,
                                month: (month1 + month) - 1,
                                day: day1
                            }
                            rdObj.term_rent = round2dec((rdObj.days / 30) * value);
                            rdObj.commission_value = round2dec(rdObj.term_rent * commission_rate);
                            rangeObj.rd.push(rdObj);
                        } else if (month === months_between) {
                            const rdObj = {
                                days: day2,
                                value,
                                commission_rate,
                                month: (month1 + month) - 1,
                                day: day2
                            }
                            rdObj.term_rent = round2dec((rdObj.days / 30) * value);
                            rdObj.commission_value = round2dec(rdObj.term_rent * commission_rate);
                            rangeObj.rd.push(rdObj);
                        } else {
                            const rdObj = {
                                days: 30,
                                value,
                                commission_rate,
                                month: (month1 + month) - 1,
                                day: 0
                            }
                            rdObj.term_rent = value;
                            rdObj.commission_value = round2dec(rdObj.term_rent * commission_rate);
                            rangeObj.rd.push(rdObj);
                        }
                    }
                    // calculate range total
                    rangeObj.rd.forEach(rrd => {
                        rangeObj.total_term_rent = round2dec(rangeObj.total_term_rent + rrd.term_rent);
                    });

                    rangeObj.total_commission = round2dec((rangeObj.commission_rate * 0.01) * rangeObj.total_term_rent);
                    
                    yearObj.ranges.push(rangeObj);
                    
                })
                yearObj.ranges.forEach(range => {
                    yearObj.year_total_term_rent = round2dec(yearObj.year_total_term_rent + range.total_term_rent);
                    yearObj.year_total_commission = round2dec(yearObj.year_total_commission + range.total_commission);
                })
                calcs.lease_term_calculations.years.push(yearObj);
            });
        }
        if (data.abated_rent_ranges && data.lease_term) {
            const abated_rent_ranges = get(data, `abated_rent_ranges`, []);
            abated_rent_ranges.forEach((yr,y) => {
                const yearObj = {
                    year_rent_abated: 0,
                    year_abated_commission: 0,
                    ranges: []
                }
                const ranges = get(yr, `ranges`, []);
                ranges.forEach(range => {
                    const rangeObj = {
                        total_rent_abated: 0,
                        total_abated_commission: 0,
                        value: parseFloat(range.value),
                        usePercentage: range.usePercentage,
                        percent: range.percent,
                        ra: {},
                        ra_arr: []
                    }
                    // iterate over lease term
                    const lease_term_range = get(calcs, `lease_term_calculations.years[${y}].ranges`, []);
                    rangeObj.start = calculateDays(range.month1, range.day1);
                    rangeObj.end = calculateDays(range.month2, range.day2);
                    rangeObj.month1 = range.month1 ? range.month1.split(' ')[1] : '';
                    rangeObj.month2 = range.month2 ? range.month2.split(' ')[1] : '';
                    rangeObj.day1 = range.day1 ? range.day1.split(' ')[1] : '';
                    rangeObj.day2 = range.day2 ? range.day2.split(' ')[1] : '';
                    rangeObj.abated_total_days = rangeObj.end - rangeObj.start + 1;

                    for (let day = rangeObj.start; day < (rangeObj.end + 1); day++) {
                        let match = null;
                        lease_term_range.forEach((ltr,ltr_index) => {
                            const ltr1 = calculateDays(ltr.month1, ltr.day1, true);
                            const ltr2 = calculateDays(ltr.month2, ltr.day2, true);
                            if (inRange(day, ltr1, ltr2)) {
                                 match = {
                                    commission_rate: ltr.commission_rate,
                                    range_index: ltr_index,
                                 };
                            }
                        });
                        if (match) {
                            match.day = day;
                            if (rangeObj.ra.hasOwnProperty(match.range_index)) {
                                if (rangeObj.ra[match.range_index].length === 1) {
                                    rangeObj.ra[match.range_index].push(match);
                                } else if (rangeObj.ra[match.range_index].length > 1) {
                                    rangeObj.ra[match.range_index][1] = match;
                                }
                            } else {
                                rangeObj.ra[match.range_index] = [match];
                            }
                        }
                    }

                    Object.keys(rangeObj.ra).forEach(index_key => {
                        const arr = rangeObj.ra[index_key];
                        const raObj = {

                        };
                        arr.forEach((a,y) => {
                            const obj = reverseCalculateDays(a.day);
                            if (y === 0) {
                                raObj.month1 = obj.month;
                                raObj.day1 = obj.day;
                            } else {
                                raObj.month2 = obj.month;
                                raObj.day2 = obj.day;
                            }
                            raObj.commission_rate = a.commission_rate;
                        });
                        const days_in_range = arr[1].day - arr[0].day + 1;
                        raObj.rent_abated = round2dec((range.value / 30) * days_in_range);
                        raObj.abated_commission = round2dec(raObj.rent_abated * (raObj.commission_rate * 0.01));
                        rangeObj.ra_arr.push(raObj);
                    });
                    
                    rangeObj.ra_arr.forEach(ra => {
                        rangeObj.total_abated_commission = round2dec(rangeObj.total_abated_commission + ra.abated_commission);
                        rangeObj.total_rent_abated = round2dec(rangeObj.total_rent_abated + ra.rent_abated);
                    })
                    yearObj.ranges.push(rangeObj);
                });
                yearObj.ranges.forEach(range => {
                    yearObj.year_abated_commission = round2dec(yearObj.year_abated_commission + range.total_abated_commission);
                    yearObj.year_rent_abated = round2dec(yearObj.year_rent_abated + range.total_rent_abated);
                });
                calcs.abated_term_calculations.years.push(yearObj);
            });

            const lease_term = get(data, `lease_term`, []);
            lease_term.forEach((yr,y) => {
                const ltc = get(calcs, `lease_term_calculations.years[${y}]`, {});
                const atc = get(calcs, `abated_term_calculations.years[${y}]`, {});
                ltc.year_net_rent_collected = round2dec(ltc.year_total_term_rent - atc.year_rent_abated);
                ltc.year_net_commission = round2dec(ltc.year_total_commission - atc.year_abated_commission);
            })
        } 
        calcs.abated_term_calculations.years.forEach(yr => {
            calcs.gross_abated_commission = round2dec(calcs.gross_abated_commission + yr.year_abated_commission);
            calcs.gross_rent_abated = round2dec(calcs.gross_rent_abated + yr.year_rent_abated);
        });
        calcs.lease_term_calculations.years.forEach(yr => {
            calcs.gross_rent_collected = round2dec(calcs.gross_rent_collected + yr.year_total_term_rent);
            calcs.gross_total_commission = round2dec(calcs.gross_total_commission + yr.year_total_commission);
        });
        calcs.net_commission = round2dec(calcs.gross_total_commission - calcs.gross_abated_commission);
        calcs.net_rent_collected = round2dec(calcs.gross_rent_collected - calcs.gross_rent_abated);
        console.log('all estimated calculations', calcs);
        return calcs;
    }
    // --------------------------------------
    // REGULAR CALCULATION
    // -------------------------------------- 
    // NOTE: Legacy: Commission Based Off is now Rent Based Off
    // NOTE: There is no more 30 days, there is only Actual Days
    const use_real_days_in_a_month = data.commission_based_off === 'Actual Days' ? true : false;

    const calcs = {
        lease_term_calculations: {
            years: []
        },
        abated_term_calculations: {
            years: []
        },
        gross_total_commission: 0,
        gross_rent_collected: 0,
        gross_rent_abated: 0,
        gross_abated_commission: 0,
        net_rent_collected: 0,
        net_commission: 0,
        use_real_days_in_a_month,
        is_estimated
    };
    if (data.lease_term) {
        data.lease_term.forEach((year_data, y) => {
            console.log('year_data', year_data)
            console.log('Year', y+1);
            const yd = {
                description: `Year ${y+1}`,
                total_value: 0,
                year_total_commission: 0,
                ranges: [],
                date1: moment.unix(year_data.year_start).format('MM/DD/YYYY'),
                date2: moment.unix(year_data.year_end).format('MM/DD/YYYY'),
            };
            let abated_term_for_this_year = {};
            if (data.abated_rent_ranges) {
                if (data.abated_rent_ranges[y]) {
                    abated_term_for_this_year = data.abated_rent_ranges[y];
                }
            }
            // ranges for one year;
            console.log('months_between', '-------')
            year_data.ranges.forEach(range => {
                console.log('the ydd range', range);
                const rangeObj = {};
                let rd = [];
                // make sure date1 and date2 are valid 
                let pass = false;
                if (range.date1 && range.date2) {
                    const date1 = moment(range.date1, 'MM/DD/YYYY', true);
                    const date2 = moment(range.date2, 'MM/DD/YYYY', true);
                    if (date1 && date2) {
                        if (date1.isValid() && date2.isValid()) {
                            pass = true;
                        }
                    }
                }
                if (pass) {
                    const value = parseFloat(range.value);
                    const commission_rate = parseFloat(range.commissionRate);
                    const the_commission_rate = parseFloat(range.commissionRate ? range.commissionRate : '0') * 0.01;
                    rangeObj.commission_rate = commission_rate;
                    rangeObj.value = value;
                    rangeObj.total_real_value = 0;
                    rangeObj.date1 = range.date1;
                    rangeObj.date2 = range.date2;
                    // iterate through each month 
                    // get months between date1 and date2
                    const months_between = getMonthsBetween(range.date1, range.date2); // only works because 1 year is the maximum between the 2 dates
                    console.log('months_between', months_between, range.date1, range.date2);

                    console.log('abated_term_for_this_year', abated_term_for_this_year);
                    let abated_unixes = [];
                    if (abated_term_for_this_year.ranges) {
                            abated_term_for_this_year.ranges.forEach(ayr => {
                                const date1 = moment(ayr.date1, 'MM/DD/YYYY', true).unix();
                                const date2 = moment(ayr.date2, 'MM/DD/YYYY', true).unix();
                                abated_unixes.push({
                                    date1,
                                    date2,
                                    date1_str: ayr.date1,
                                    date2_str: ayr.date2
                                });
                            })
                    }

                    // iterate between months
                    for (let i = 1; i < (months_between + 1); i++) {
                        // MM/DD/YYYY current date
                        const current_date = getCurrentDate({
                            date1: range.date1,
                            date2: range.date2,
                            month_number: i
                        });
                        console.log('months_between ->', i, current_date);
                        const current_date_mom = moment(current_date, 'MM/DD/YYYY', true);
                        const total_days_in_month = current_date_mom.daysInMonth();
                        const days_of_month_calculation = total_days_in_month;
                        const month = current_date.split('/')[0];
                        const day = current_date.split('/')[1];
                        const year = current_date.split('/')[2];
                        let days_in_range = total_days_in_month;
                        if (i === 1) {
                            // first month
                            days_in_range = total_days_in_month - parseInt(day) + 1;
                        } else if (i === months_between) {
                            // last month
                            days_in_range = parseInt(range.date2.split('/')[1]);
                        }
                        // iterate through each day
                        let rows_in_this_month = [];
                        for (let x = 0; x < days_in_range; x++) {
                            let day_iterated = x + 1;
                            if (i === 1) {
                                // first month
                                day_iterated = parseInt(day) + x;
                            }
                            const current_day = `${month}/${formatDoubleDigits(day_iterated)}/${year}`;
                            const day_unix = moment(current_day, 'MM/DD/YYYY', true).unix();
                            // iterate between 
                            let isAbated = false;
                            abated_unixes.forEach(au => {
                                if (au.date1 <= day_unix && day_unix <= au.date2) {
                                    isAbated = true;
                                }
                            })
                            if (isAbated) {
                                console.log('isAbated', current_day);
                                let shouldAdd = false;
                                if (rows_in_this_month.length === 0) {
                                    shouldAdd = true;
                                } else if (!rows_in_this_month[rows_in_this_month.length - 1].isAbated) {
                                    shouldAdd = true;
                                }
                                if (shouldAdd) {
                                    rows_in_this_month.push({
                                        date1: current_day,
                                        date2: current_day,
                                        isAbated,
                                        actual_number_of_days_in_whole_month: total_days_in_month,
                                        days_of_month_calculation,
                                        total_days_in_this_range: 0,
                                        the_commission_value: 0,
                                        real_value: 0,
                                        value
                                    })
                                } else {
                                    rows_in_this_month[rows_in_this_month.length - 1].date2 = current_day;
                                }
                            } else {
                                let shouldAdd = false;
                                if (rows_in_this_month.length === 0) {
                                    shouldAdd = true;
                                } else if (rows_in_this_month[rows_in_this_month.length - 1].isAbated) {
                                    shouldAdd = true;
                                }
                                if (shouldAdd) {
                                    rows_in_this_month.push({
                                        date1: current_day,
                                        date2: current_day,
                                        isAbated,
                                        actual_number_of_days_in_whole_month: total_days_in_month,
                                        days_of_month_calculation,
                                        total_days_in_this_range: 0,
                                        the_commission_value: 0,
                                        real_value: 0,
                                        value
                                    })
                                } else {
                                    rows_in_this_month[rows_in_this_month.length - 1].date2 = current_day;
                                }
                            }
                        }
                        rows_in_this_month.forEach(ritm => {
                            const moment_date1 = moment(ritm.date1, 'MM/DD/YYYY', true);
                            const moment_date2 = moment(ritm.date2, 'MM/DD/YYYY', true);
                            const total_days_in_this_range = moment_date2.diff(moment_date1, 'days')+1;

                            let real_value = round2dec((value / ritm.days_of_month_calculation) * total_days_in_this_range);
                            real_value = total_days_in_month === total_days_in_this_range ? value : real_value;
                            const the_commission_rate = parseFloat(range.commissionRate ? range.commissionRate : '0') * 0.01;
                            const the_commission_value = the_commission_rate * real_value;
                            ritm.real_value = real_value;
                            ritm.total_days_in_this_range = total_days_in_this_range;
                            ritm.the_commission_value = the_commission_value;
                        })
                        console.log('rows_in_this_month', rows_in_this_month);
                        rows_in_this_month.forEach(ritm => {
                            rd.push(ritm);
                        })
                    }
                    rangeObj.rd = rd;

                    // calculate range total
                    rangeObj.rd.forEach(rrd => {
                        rangeObj.total_real_value = round2dec(rangeObj.total_real_value + rrd.real_value);
                    });

                    rangeObj.total_commission = round2dec((rangeObj.commission_rate * 0.01) * rangeObj.total_real_value);
                }
                yd.ranges.push(rangeObj);
            })
            // calculate year total
            yd.ranges.forEach(ydr => {
                yd.total_value = round2dec(yd.total_value + ydr.total_real_value);
                yd.year_total_commission = round2dec(yd.year_total_commission + ydr.total_commission);
            })
            // calculate year total commission
            console.log('yd', yd);
            calcs.lease_term_calculations.years.push(yd);
        });
    }

    if (data.abated_rent_ranges) {
        data.abated_rent_ranges.forEach((year_abated,y) => {
            const year_lease_term = calcs.lease_term_calculations.years[y];
            if (year_abated.year_start && year_abated.year_end) {
                const ya = {
                    description: `Year ${y+1}`,
                    year_total_abated_value: 0,
                    year_total_abated_commission: 0,
                    year_net_rent_collected: 0,
                    ranges: []
                };
                year_abated.ranges.forEach(range => {
                    const rangeObj = {
                        date1: range.date1,
                        date2: range.date2,
                        percent: range.percent,
                        value: parseFloat(range.value),
                        ra: [],
                        total_abated_value: 0,
                        total_commission_value: 0
                    }
                    const unix_date1_abated = date2unix(range.date1);
                    const unix_date2_abated = date2unix(range.date2);
                    
                    year_lease_term.ranges.forEach(yr => {
                        // Lease Term Range
                        const commission_rate = yr.commission_rate;
                        const range_date1 = yr.date1;
                        const range_date2 = yr.date2;
                        const mom_range_date1 = moment(range_date1, 'MM/DD/YYYY', true);
                        const mom_range_date2 = moment(range_date2, 'MM/DD/YYYY', true);
                        const mom_range_date1_unix = mom_range_date1.unix();
                        const mom_range_date2_unix = mom_range_date2.unix();
                        const yr_rd = yr.rd;
                        if (yr_rd) {
                            yr_rd.forEach(yrd => {
                                console.log('yyrd', yrd);
                                if (yrd.isAbated) {
                                    const month = getMonth(yrd.date1, true);
                                    const year = getYear(yrd.date1, true);
                                    const days_in_this_month = use_real_days_in_a_month ? moment(`${month}/01/${year}`, 'MM/DD/YYYY').daysInMonth() : 30;
                                    const actual_days_in_this_month = moment(`${month}/01/${year}`, 'MM/DD/YYYY').daysInMonth();
                                    let month_collection = [];
                                    for (let x = 1; x < (days_in_this_month+1); x++) {
                                        const mom_day = moment(`${month}/${formatDoubleDigits(x)}/${year}`, 'MM/DD/YYYY');
                                        if (mom_day.unix() >= mom_range_date1_unix && mom_day.unix() <= mom_range_date2_unix) {
                                            if (mom_day.unix() >= unix_date1_abated && mom_day.unix() <= unix_date2_abated) {
                                                // day falls in range 
                                                month_collection.push({
                                                    day: mom_day.format('MM/DD/YYYY'),
                                                    commission_rate
                                                });
                                            }
                                        }
                                    }
                                    console.log('month_collection', month_collection);
                                    if (month_collection.length > 0) {
                                        const days_of_month_calculation = use_real_days_in_a_month ? days_in_this_month : 30;
                                        let the_value = round2dec((rangeObj.value / days_of_month_calculation) * month_collection.length);
                                        if (use_real_days_in_a_month) {

                                        }
                                        const commission_value = round2dec(the_value * (commission_rate * 0.01));
                                        rangeObj.total_abated_value = round2dec(rangeObj.total_abated_value + the_value);
                                        rangeObj.total_commission_value = round2dec(rangeObj.total_commission_value + commission_value);
                                        rangeObj.ra.push({
                                            date1: month_collection[0].day,
                                            date2: month_collection[month_collection.length-1].day,
                                            actual_days_in_this_month: days_in_this_month,
                                            days_in_range: month_collection.length, 
                                            commission_rate,
                                            commission_value,
                                            value: the_value
                                        });
                                    }
                                }
                            })
                        }
                    });
                    ya.ranges.push(rangeObj);
                });
                ya.ranges.forEach(yrange => {
                    ya.year_total_abated_value = round2dec(ya.year_total_abated_value + yrange.total_abated_value);
                    ya.year_total_abated_commission = round2dec(ya.year_total_abated_commission + yrange.total_commission_value);
                });
                ya.year_net_rent_collected = round2dec(calcs.lease_term_calculations.years[y].total_value - ya.year_total_abated_value);
                ya.year_net_commission = round2dec(calcs.lease_term_calculations.years[y].year_total_commission - ya.year_total_abated_commission);
                calcs.abated_term_calculations.years.push(ya);
            }
        });
    }

    // calculate gross rent and gross total commission
    calcs.lease_term_calculations.years.forEach(ltc => {
        calcs.gross_rent_collected = round2dec(calcs.gross_rent_collected + ltc.total_value);
        calcs.gross_total_commission = round2dec(calcs.gross_total_commission + ltc.year_total_commission);
    })
    // calculate gross_rent_abated
    calcs.abated_term_calculations.years.forEach(atc => {
        calcs.gross_rent_abated = round2dec(calcs.gross_rent_abated + atc.year_total_abated_value);
        calcs.gross_abated_commission = round2dec(calcs.gross_abated_commission + atc.year_total_abated_commission);
    });
    calcs.net_rent_collected = round2dec(calcs.gross_rent_collected - calcs.gross_rent_abated);
    calcs.net_commission = round2dec(calcs.gross_total_commission - calcs.gross_abated_commission);
    
    console.log('all calculations', calcs);
    return calcs;
}

termLogic.calculateInvoiceRows = (calcs, data) => {
    console.log('calculating invoice for', calcs, data);
    const invoice_calcs = {
        years: [],
        total_term_rent: 0,
        total_commission: 0,
        total_months: 0
    };
    const is_estimated = calcs.is_estimated ? calcs.is_estimated : null;
    const use_real_days_in_a_month = data.commission_based_off === 'Actual Days' ? true : false;

    // ---------
    // ESTIMATED
    // ---------
    if (is_estimated) {
        calcs.lease_term_calculations.years.forEach((yr,y) => {
            const yearObj = {
                description: yr.description,
                rows: []
            }
            const abated_year = get(calcs, `abated_term_calculations.years[${y}]`, {
                ranges: []
            });
            yr.ranges.forEach((range, range_index) => {
                console.log('abated_year', abated_year.ranges, range)
                // iterate over lease term days between range.date1 and range.date2
                const range_start = calculateDays(range.month1, range.day1, true);
                const range_end = calculateDays(range.month2, range.day2, true);
                const collectionArr = [];
                for (let day = range_start; day < (range_end + 1); day++) {
                    let is_abated = false;
                    let abatedObj = null;
                    abated_year.ranges.forEach(abated_range => {
                        if (inRange(day, abated_range.start, abated_range.end)) {
                            abatedObj = abated_range;
                            is_abated = true;
                        }
                    });
                    const dayObj = {
                        commission_rate: range.commission_rate,
                        commission_value: range.total_commission,
                        total_term_rent: range.total_term_rent,
                        value: range.value,
                        range_index,
                        is_abated,
                        abatedObj,
                        start: day,
                        end: null
                    };
                    if (day !== range_start) {
                        const last_index = collectionArr.length - 1;
                        if (collectionArr[last_index].is_abated === is_abated) {
                            collectionArr[last_index].end = day;
                        } else {
                            collectionArr.push(dayObj)
                        }
                    } else {
                        // always record 1st one
                        collectionArr.push(dayObj);
                    }
                }
                console.log('collectionArr 1', range_index, collectionArr);
                collectionArr.forEach((c,i) => {
                    const rowObj = c;

                    rowObj.date1 = reverseCalculateDays(c.start, true);
                    rowObj.date2 = reverseCalculateDays(c.end, true);
                    rowObj.days_in_range = c.end - c.start + 1;
                    rowObj.abated_value = c.abatedObj ? c.abatedObj.total_rent_abated : 0;
                    rowObj.abated_total_days = c.abatedObj ? c.abatedObj.abated_total_days : 0;
                    rowObj.range_abated_value = round2dec((rowObj.abated_value * rowObj.days_in_range) / rowObj.abated_total_days);
                    rowObj.term_rent = round2dec(((rowObj.value / 30 ) * rowObj.days_in_range) - rowObj.range_abated_value);
                    rowObj.commission = round2dec((rowObj.commission_rate * 0.01) * rowObj.term_rent);
                    rowObj.monthly_rent = rowObj.value;
                    rowObj.months_est = round(rowObj.days_in_range / 30, 1);
                    console.log('the row obj', rowObj);
                    yearObj.rows.push(rowObj);
                });
            });
            invoice_calcs.years.push(yearObj);
        });
        invoice_calcs.years.forEach(yr => {
            yr.rows.sort(sortDaysRange);
            yr.rows.forEach(yrr => {
                invoice_calcs.total_commission = round2dec(invoice_calcs.total_commission + yrr.commission);
                invoice_calcs.total_months = invoice_calcs.total_months + (yrr.days_in_range / 30);
                invoice_calcs.total_term_rent = round2dec(invoice_calcs.total_term_rent + yrr.term_rent);
            })
        })
        // just to adjust to regular computation, since this calculation doesn't need compression
        invoice_calcs.years_compressed = invoice_calcs.years;
        console.log('invoice calcs estimated', invoice_calcs);
        return invoice_calcs;
    }

    // -------
    // REGULAR 
    // -------
    console.log('isAbated --> START');
    const invoice_calcs2 = {
        years: [],
        total_term_rent: 0,
        total_commission: 0,
        total_months: 0,
        total_abated_value: 0
    };
    calcs.lease_term_calculations.years.forEach((yr,y) => {
        console.log('isAbated -->', yr);
        const yearObj = {
            description: yr.description,
            rows: [],
            total_year_rent: 0,
            total_abated_value: 0,
            total_commission: 0,
            total_abated_commission: 0
        }
        const abated_ranges = get(calcs, `abated_term_calculations.years[${y}].ranges`, []);
        console.log('aabated', abated_ranges)
        abated_ranges.forEach(rra => {
            rra.date1_unix = moment(rra.date1, 'MM/DD/YYYY', true).unix();
            rra.date2_unix = moment(rra.date2, 'MM/DD/YYYY', true).unix();
            rra.ra.forEach(rrr => {
                rrr.date1_unix = moment(rrr.date1, 'MM/DD/YYYY', true).unix();
                rrr.date2_unix = moment(rrr.date2, 'MM/DD/YYYY', true).unix();
            })
        })

        yr.ranges.forEach((range, rr) => {
            let arr = [];
            // rd = within one month
            // This section compresses the data so that all abated and non-abated ranges are put together alternating
            console.log('sub_range start ---------', abated_ranges, rr);
            if (range.rd) {
                range.rd.forEach((sub_range,x) => {
                    console.log('sub_range x', sub_range, x);
                    let shouldAdd = false;
                    if (arr.length === 0) {
                        shouldAdd = true;
                    } else {
                        // if current range is opposite
                        if ((arr[arr.length - 1].isAbated && !sub_range.isAbated) || (!arr[arr.length - 1].isAbated && sub_range.isAbated)) {
                            shouldAdd = true;
                        }
                    }
                    let equivalent_abated_range = null;
                    if (abated_ranges) {
                        abated_ranges.forEach(ar => {
                            if (ar.ra) {
                                ar.ra.forEach(arra => {
                                    if (arra.date1 === sub_range.date1 && arra.date2 === sub_range.date2) {
                                        equivalent_abated_range = arra;
                                    }
                                })
                            }
                        })
                    }
                    if (shouldAdd) {
                        const obj = {
                            date1: sub_range.date1,
                            date1_unix: moment(sub_range.date1, 'MM/DD/YYYY', true).unix(),
                            date2: sub_range.date2,
                            date2_unix: moment(sub_range.date2, 'MM/DD/YYYY', true).unix(),
                            isAbated: sub_range.isAbated,
                            monthly_rent: sub_range.value,
                            commission_rate: range.commission_rate,
                            rent: sub_range.real_value,
                            abated_value: 0,
                            abated_commission: 0,
                            total_number_of_months: round2dec(sub_range.total_days_in_this_range / sub_range.days_of_month_calculation)
                        };
                        if (obj.isAbated) {
                            if (equivalent_abated_range) {
                                obj.abated_value = equivalent_abated_range.value;
                                obj.abated_commission = obj.abated_value * (range.commission_rate * 0.01)
                            }
                        }
                        arr.push(obj);
                    } else {
                        const last_index = arr.length - 1;
                        const obj = arr[last_index];
                        obj.date2 = sub_range.date2;
                        obj.date2_unix = moment(obj.date2, 'MM/DD/YYYY', true).unix();
                        obj.total_number_of_months += round2dec(sub_range.total_days_in_this_range / sub_range.days_of_month_calculation);
                        if (obj.isAbated) {
                            if (equivalent_abated_range) {
                                obj.abated_value += equivalent_abated_range.value;
                                obj.abated_commission += equivalent_abated_range.value * (range.commission_rate * 0.01); 
                            }
                        }
                        obj.rent += sub_range.real_value;
                    }
                    
                })
            }
            
            // add in abated
            arr.forEach(a => {
                yearObj.total_year_rent += a.rent;
                a.term_rent = a.rent;
                a.commission = a.term_rent * (a.commission_rate * 0.01);
                yearObj.total_commission += a.commission;
                if (a.isAbated) {
                    yearObj.total_abated_value += a.abated_value;
                    yearObj.total_abated_commission += a.abated_commission;
                }
            });
            console.log('sub_range arr check', arr);
            console.log('sub_range totals', invoice_calcs2);
            yearObj.rows.push(arr);
        });
        yearObj.net_rent = yearObj.total_year_rent - yearObj.total_abated_value;
        console.log('isAbated YEAR', yearObj);
        invoice_calcs2.years.push(yearObj);
        console.log('isAbated invoicecalcs2', invoice_calcs2);
    });
    invoice_calcs2.years.forEach(year => {
        invoice_calcs2.total_term_rent += year.net_rent;
        invoice_calcs2.total_commission += year.total_commission - year.total_abated_commission;
    })
    invoice_calcs2.years_compressed = [];
    invoice_calcs2.years.forEach(year => {
        year.rows.forEach(row_ => {
            row_.forEach(row => {
                console.log('row', row);
                let shouldAdd = false;
                if (invoice_calcs2.years_compressed.length === 0) {
                    shouldAdd = true;
                } else {
                    const last_entry = invoice_calcs2.years_compressed[invoice_calcs2.years_compressed.length - 1];
                    console.log('last_entry', last_entry, row)
                    // alternate between is abated and abated
                    if (last_entry.isAbated) {
                        if (!row.isAbated) {
                            shouldAdd = true;
                        }
                    } else {
                        if (row.isAbated) {
                            shouldAdd = true;
                        }
                    }
                    if (!shouldAdd) {
                        console.log('shouldAdd', last_entry.monthly_rent, row.monthly_rent, last_entry.commission_rate, row.commission_rate)
                        // check if the same monthly rent as previous
                        if (last_entry.monthly_rent === row.monthly_rent && last_entry.commission_rate === row.commission_rate) {
                            shouldAdd = false;
                        } else {
                            shouldAdd = true;
                        }
                    }
                }
                if (shouldAdd) {
                    const cloned_row = clone(row);
                    delete cloned_row.date1_unix;
                    delete cloned_row.date2_unix;
                    invoice_calcs2.years_compressed.push(cloned_row);
                    invoice_calcs2.total_months += row.total_number_of_months;
                } else {
                    const last_entry = invoice_calcs2.years_compressed[invoice_calcs2.years_compressed.length - 1];
                    if (last_entry) {
                        last_entry.date2 = row.date2;
                        last_entry.commission += row.commission;
                        last_entry.term_rent += row.term_rent;
                        invoice_calcs2.total_months += row.total_number_of_months;
                    }
                }
            })
        })
    })
    console.log('INVOICECALCS', invoice_calcs2);
    return invoice_calcs2;
}

// for PDF preview
termLogic.renderHTML = {
    renderRentTotals: (calcs) => {
        const is_estimated = calcs.is_estimated;
        const est_str = is_estimated ? '(Estimated)' : '';
        return `<div>
                    <h4>
                        Gross Rent Collected ${est_str}: <span class="bold">${formatDollar(calcs.gross_rent_collected)}</span>
                    </h4>
                    <h4>
                        Rent Abated ${est_str}: <span class="bold">(${formatDollar(calcs.gross_rent_abated)})</span>
                    </h4>
                    <h4>
                        Net Rent Collected ${est_str}: <span class="bold">${formatDollar(calcs.net_rent_collected)}</span>
                    </h4>
                    <h4>
                        Gross Commission ${est_str}: <span class="bold">${formatDollar(calcs.gross_total_commission)}</span>
                    </h4>
                    <h4>
                        Abated Commission ${est_str}: <span class="bold">(${formatDollar(calcs.gross_abated_commission)})</span>
                    </h4>
                    <h4>
                        Net Commission ${est_str}: <span class="bold">${formatDollar(calcs.net_commission)}</span>
                    </h4>
                </div>`;
    },
    renderLeaseTerm: (calcs) => {
        const field_years = get(calcs, `lease_term_calculations.years`,[]);
        const is_estimated = calcs.is_estimated;
        return `<div>
            <ul class="list-unstyled">
                ${field_years.map((m,i) => {
                    return (
                        `<li>
                            <div>
                                <h4>Year ${i+1}</h4>
                                ${m.ranges.length === 0 ? (
                                    `<div>
                                        <em>None</em>
                                    </div>`
                                ) : ''}
                                ${m.ranges.map((mr) => {
                                    if (is_estimated) {
                                        const mr_date1 = `Month ${mr.month1} Day ${mr.day1}`;
                                        const mr_date2 = `Month ${mr.month2} Day ${mr.day2}`;
                                        const term = `${mr_date1} to ${mr_date2}`;
                                        return `<div>
                                                    ${term} - ${formatDollar(mr.total_term_rent)}
                                                    <div>
                                                        ${mr.commission_rate} % Commission Rate
                                                    </div>
                                                    <br /><br />
                                                </div>`; 
                                    } else {
                                        const term = `${mr.date1} to ${mr.date2}`;
                                        return `<div>
                                                    ${term} - ${formatDollar(mr.total_real_value)}
                                                    <div>
                                                        ${mr.commission_rate} % Commission Rate
                                                    </div>
                                                    <br /><br />
                                                </div>`;   
                                    }
                                }).join('')}
                            </div>
                        </li>`
                    )
                }).join('')}
            </ul>
        </div>`;
    },
    renderAbatedTerms: (calcs) => {
        const field_years = get(calcs, `abated_term_calculations.years`,[]);
        const is_estimated = calcs.is_estimated;
        return `<div>
            <ul class="list-unstyled">
                ${field_years.map((m,i) => {
                    return `<li>
                            <div>
                                <h4>Year ${i+1}</h4>
                                ${m.ranges.length === 0 ? (
                                    `<div>
                                        <em>None</em>
                                    </div>`
                                ) : ''}
                                ${m.ranges.map((mr,j) => {
                                    if (is_estimated) {
                                        const mr_date1 = `Month ${mr.month1} Day ${mr.day1}`;
                                        const mr_date2 = `Month ${mr.month2} Day ${mr.day2}`;
                                        const term = `${mr_date1} to ${mr_date2}`;
                                        return `<div>
                                                ${term} - (${formatDollar(mr.total_rent_abated)})
                                                <br /><br />
                                            </div>`;
                                    } else {
                                        const term = `${mr.date1} to ${mr.date2}`;
                                        return `<div>
                                                ${term} - (${formatDollar(mr.total_abated_value)})
                                                <br /><br />
                                            </div>`;
                                    }
                                }).join('')}
                            </div>
                        </li>`
                }).join('')}
            </ul>
        </div>`
    }
}

export default termLogic;

// FULL FORM LEASE TERM Components
export const RentTotals = (props) => {
    const { calcs} = props;
    const { is_estimated } = calcs;
    const est_str = is_estimated ? `(Estimated)` : ``;
    return (
        <div>
            <h4>
                Gross Rent Collected {est_str}: <span className="bold">{formatDollar(calcs.gross_rent_collected)}</span>
            </h4>
            <h4>
                Rent Abated {est_str}: <span className="bold">({formatDollar(calcs.gross_rent_abated)})</span>
            </h4>
            <h4>
                Net Rent Collected {est_str}: <span className="bold">{formatDollar(calcs.net_rent_collected)}</span>
            </h4>
            <h4>
                Gross Commission {est_str}: <span className="bold">{formatDollar(calcs.gross_total_commission)}</span>
            </h4>
            <h4>
                Abated Commission {est_str}: <span className="bold">({formatDollar(calcs.gross_abated_commission)})</span>
            </h4>
            <h4>
                Net Commission {est_str}: <span className="bold">{formatDollar(calcs.net_commission)}</span>
            </h4>
        </div>);
}

// To be used in FormFull
export const TermElement = (props) => {
    const { calcs } = props;
    const field_years = get(calcs, `lease_term_calculations.years`, []);
    const is_estimated = calcs.is_estimated;
    if (is_estimated) {
        return (
            <div>
            <ul className="list-unstyled">
                {field_years.map((m,i) => {
                    return (
                        <li key={`${i}-term-year`}>
                            <div>
                                <h4>Year {i+1}</h4>
                                {m.ranges.length === 0 && (
                                    <div>
                                        <em>None</em>
                                    </div>
                                )}
                                {m.ranges.map((mr,j) => {
                                    const mr_date1 = `Month ${mr.month1} Day ${mr.day1}`;
                                    const mr_date2 = `Month ${mr.month2} Day ${mr.day2}`;
                                    const term = `${mr_date1} to ${mr_date2}`;
                                    return (
                                        <div key={`${j}-range-${i}`}>
                                            {term} - {formatDollar(mr.total_term_rent)}
                                            <div>
                                                {mr.commission_rate} % Commission Rate
                                            </div>
                                            <hr />
                                        </div>
                                    );
                                })}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
        )
    }
    return (
        <div>
        <ul className="list-unstyled">
            {field_years.map((m,i) => {
                return (
                    <li key={`${i}-term-year`}>
                        <div>
                            <h4>Year {i+1}</h4>
                            {m.ranges.length === 0 && (
                                <div>
                                    <em>None</em>
                                </div>
                            )}
                            {m.ranges.map((mr,j) => {
                                const term = `${mr.date1} to ${mr.date2}`;
                                return (
                                    <div key={`${j}-range-${i}`}>
                                        {term} - {formatDollar(mr.total_real_value)}
                                        <div>
                                            {mr.commission_rate} % Commission Rate
                                        </div>
                                        <hr />
                                    </div>
                                );
                            })}
                        </div>
                    </li>
                )
            })}
        </ul>
    </div>
    )
}

// to be used in Form Full
export const AbatedElement = (props) => {
    const { calcs } = props;
    const field_years = get(calcs, `abated_term_calculations.years`,[]);
    const is_estimated = calcs.is_estimated;
    if (is_estimated) {
        return (
            <div>
            <ul className="list-unstyled">
                {field_years.map((m,i) => {
                    return (
                        <li key={`${i}-abated-year`}>
                            <div>
                                <h4>Year {i+1}</h4>
                                {m.ranges.length === 0 && (
                                    <div>
                                        <em>None</em>
                                    </div>
                                )}
                                {m.ranges.map((mr,j) => {
                                    const mr_date1 = `Month ${mr.month1} Day ${mr.day1}`;
                                    const mr_date2 = `Month ${mr.month2} Day ${mr.day2}`;
                                    const term = `${mr_date1} to ${mr_date2}`;
                                    return (
                                        <div key={`${j}-range-${i}`}>
                                            {term} - ({formatDollar(mr.total_rent_abated)})
                                            <hr />
                                        </div>
                                    );
                                })}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>)
    }
    return (
        <div>
        <ul className="list-unstyled">
            {field_years.map((m,i) => {
                return (
                    <li key={`${i}-abated-year`}>
                        <div>
                            <h4>Year {i+1}</h4>
                            {m.ranges.length === 0 && (
                                <div>
                                    <em>None</em>
                                </div>
                            )}
                            {m.ranges.map((mr,j) => {
                                const term = `${mr.date1} to ${mr.date2}`;
                                return (
                                    <div key={`${j}-range-${i}`}>
                                        {term} - ({formatDollar(mr.total_abated_value)})
                                        <hr />
                                    </div>
                                );
                            })}
                        </div>
                    </li>
                )
            })}
        </ul>
    </div>)
}

// To be used for FormWizard - Step 3
export const Step3RentTotals = (props) => {
    const { calcs } = props;
    const { is_estimated } = calcs;
    const str = is_estimated ? `(Estimated)` : ``;
    const calcs_shortened = get(calcs, `lease_term_calculations.years`, []);
    let each_years = null;
    if (is_estimated) {
        each_years = <div>
            {calcs_shortened.map((yr,y) => {
                const abated_year_data = get(calcs, `abated_term_calculations.years[${y}]`, {
                    year_total_abated_value: 0
                });
                return <div key={`yr-${y}`}>
                    <hr />
                    <h4 className="bold">Year {y + 1}</h4>
                    <h4>
                        Rent Collected {str}: <span className="bold">{formatDollar(yr.year_net_rent_collected)}</span>
                    </h4>
                    <h4>
                        Rent Abated {str}: <span className="bold">{formatDollar(abated_year_data.year_rent_abated)}</span>
                    </h4>
                    <h4>
                        Total Commission {str}: <span className="bold">{formatDollar(yr.year_total_commission)}</span>
                    </h4>
                    <hr />
                </div>
            })}
        </div>
    } else {
        each_years = <div>
            {calcs_shortened.map((yr,y) => {
                const abated_year_data = get(calcs, `abated_term_calculations.years[${y}]`, {
                    year_total_abated_value: 0
                });
                return <div key={`yr-${y}`}>
                    <hr />
                    <h4 className="bold">Year {y + 1}</h4>
                    {yr.total_value !== 0 && <p>
                        {(yr.date1 && yr.date2) && <div>{yr.date1} to {yr.date2}</div>}
                    </p>}
                    <h4>
                        Rent Collected {str}: <span className="bold">{formatDollar(yr.total_value)}</span>
                    </h4>
                    <h4>
                        Rent Abated {str}: <span className="bold">{formatDollar(abated_year_data.year_total_abated_value)}</span>
                    </h4>
                    <h4>
                        Total Commission {str}: <span className="bold">{formatDollar(yr.year_total_commission)}</span>
                    </h4>
                    <hr />
                </div>
            })}
        </div>
    }
    return <div>
        {each_years}
        
        <h4>
            Gross Rent Collected {str}: <span className="bold">{formatDollar(calcs.gross_rent_collected)}</span>
        </h4>
        <h4>
            Gross Rent Abated {str}: <span className="bold">{formatDollar(calcs.gross_rent_abated)}</span>
        </h4>
        <h4>
            Net Rent Collected {str}: <span className="bold">{formatDollar(calcs.net_rent_collected)}</span>
        </h4>
        <h4>
            Gross Commission {str}: <span className="bold">{formatDollar(calcs.gross_total_commission)}</span>
        </h4>
        <h4>
            Abated Commission {str}: <span className="bold">{formatDollar(calcs.gross_abated_commission)}</span>
        </h4>
        <h4>
            Net Commission {str}: <span className="bold">{formatDollar(calcs.net_commission)}</span>
        </h4>
    </div>
};