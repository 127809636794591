import React, { useEffect, useState } from "react";
import { clone, cloneJ, createObjName, extractContactAddress, formatDollar, round2dec } from "../../../shared/utility";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import Button from "../../../components/CustomButtons/Button";
import CustomDialogChooser from 'components/CustomDialog/CustomDialogChooser';
import Element from "components/Element";
import { Delete } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import CustomInput from "../../../components/CustomInput/CustomInputValidate";
import CustomRadio from "components/CustomRadio/CustomRadio";
import termLogic from "./Term/TermLogicMain";
import { parseFloatString } from "../../../shared/utility";
import { commissionCalc } from "./CommissionLogic";

const presplit_options = [
    'Both Lessor and Lessee', 
    'Lessor Only', 
    'Lessee Only'
];

export const checkForLeaseInfoCompletion = (data) => {
    let side_amount_amount_check = true;

    let net_commission = 0;
    let found = false;
    if (data.flat_fee_commission === "Yes") {
        if (data.flat_fee_commission_amount) {
            net_commission = parseFloatString(data.flat_fee_commission_amount);
            found = true; 
        } else if (data.flat_fee_commission_amount !== '') {
            found = true;
        }
    } else {
        // get calculated 
        const calcs = termLogic.calculateLeaseTerm(data);
        console.log('calcs->', calcs);
        if (calcs) {
            if (calcs.net_commission) {
                net_commission = calcs.net_commission;
                found = true;
            }
        }
    }
    return {
        checked: found,
        net_commission,
        side_amount_amount_check
    };
}

export const calculatePresplits = (data) => {
    let commissionAmountCheck = true;
    let net_commission = 0;
    let presplitCalculations = [];

    if (data.flat_fee_commission === "Yes") {
        if (data.flat_fee_commission_amount) {
            net_commission = parseFloatString(data.flat_fee_commission_amount);
        }
    } else {
        // get calculated 
        const calcs = termLogic.calculateLeaseTerm(data);
        console.log('calcs->', calcs);
        if (calcs) {
            if (calcs.net_commission) net_commission = calcs.net_commission;
        }
    }

    let commission_distribution_lessee = 0;
    let commission_distribution_lessor = 0;
    let calculated_commission_distribution_lessee = 0;
    let calculated_commission_distribution_lessor = 0;

    if (data.commission_distribution_lessee) {
        commission_distribution_lessee = parseFloat(data.commission_distribution_lessee) * 0.01;
        calculated_commission_distribution_lessee = commission_distribution_lessee * net_commission;
    }
    if (data.commission_distribution_lessor) {
        commission_distribution_lessor = parseFloat(data.commission_distribution_lessor) * 0.01;
        calculated_commission_distribution_lessor = commission_distribution_lessor * net_commission;
    }

    console.log('net commission', net_commission);
    console.log('commission_distribution lessee', commission_distribution_lessee, calculated_commission_distribution_lessee);
    console.log('commission_distribution lessor', commission_distribution_lessor, calculated_commission_distribution_lessor);
    if (data.presplits) {
        if (data.presplits.length > 0) {
            data.presplits.forEach(dp => {
                if (dp.presplit_applies_to === 'Both Lessor and Lessee') {
                    if (dp.value_type === 'Percentage' && dp.percentage_value) {
                        const perc = parseFloat(dp.percentage_value) * 0.01;
                        const to_be_subtracted = net_commission * perc;
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = commission_distribution_lessor * remaining_net_commission;
                        dpClone.lessee_commission_distribution = commission_distribution_lessee * remaining_net_commission;

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor - (to_be_subtracted / 2);
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee - (to_be_subtracted / 2);

                        if (
                            calculated_commission_distribution_lessee < (to_be_subtracted / 2) ||
                            calculated_commission_distribution_lessor < (to_be_subtracted / 2)
                        ) {
                            dpClone.lessor_remaining_commission = 0;
                            dpClone.lessee_remaining_commission = 0;
                            commissionAmountCheck = false;
                        }

                        presplitCalculations.push(dpClone)
                    } else if (dp.value_type === 'Dollar Value' && dp.dollar_value) {
                        const to_be_subtracted = parseFloatString(dp.dollar_value);
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = commission_distribution_lessor * remaining_net_commission;
                        dpClone.lessee_commission_distribution = commission_distribution_lessee * remaining_net_commission;

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor - (to_be_subtracted / 2);
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee - (to_be_subtracted / 2);

                        if (
                            calculated_commission_distribution_lessee < (to_be_subtracted / 2) ||
                            calculated_commission_distribution_lessor < (to_be_subtracted / 2)
                        ) {
                            dpClone.lessor_remaining_commission = 0;
                            dpClone.lessee_remaining_commission = 0;
                            commissionAmountCheck = false;
                        }

                        presplitCalculations.push(dpClone)
                    }   
                } else if (dp.presplit_applies_to === 'Lessor Only') {
                    if (dp.value_type === 'Percentage' && dp.percentage_value) {
                        const perc = parseFloat(dp.percentage_value) * 0.01;
                        const to_be_subtracted = net_commission * perc;
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = (commission_distribution_lessor * net_commission) - to_be_subtracted;
                        dpClone.lessee_commission_distribution = (commission_distribution_lessee * net_commission);

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor - to_be_subtracted;
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee;

                        if (
                            calculated_commission_distribution_lessor < to_be_subtracted
                        ) {
                            dpClone.lessor_remaining_commission = 0;
                            commissionAmountCheck = false;
                        }

                        presplitCalculations.push(dpClone)
                    } else if (dp.value_type === 'Dollar Value' && dp.dollar_value) {
                        const to_be_subtracted = parseFloatString(dp.dollar_value);
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = (commission_distribution_lessor * net_commission) - to_be_subtracted;
                        dpClone.lessee_commission_distribution = (commission_distribution_lessee * net_commission);

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor - to_be_subtracted;
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee;

                        if (
                            calculated_commission_distribution_lessor < to_be_subtracted
                        ) {
                            dpClone.lessor_remaining_commission = 0;
                            commissionAmountCheck = false;
                        }

                        presplitCalculations.push(dpClone)
                    }
                } else if (dp.presplit_applies_to === 'Lessee Only') {
                    if (dp.value_type === 'Percentage' && dp.percentage_value) {
                        const perc = parseFloat(dp.percentage_value) * 0.01;
                        const to_be_subtracted = net_commission * perc;
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = (commission_distribution_lessor * net_commission);
                        dpClone.lessee_commission_distribution = (commission_distribution_lessee * net_commission) - to_be_subtracted;

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor;
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee - to_be_subtracted;

                        if (
                            calculated_commission_distribution_lessee < to_be_subtracted
                        ) {
                            dpClone.lessee_remaining_commission = 0;
                            commissionAmountCheck = false;
                        }

                        presplitCalculations.push(dpClone)
                    } else if (dp.value_type === 'Dollar Value' && dp.dollar_value) {
                        const to_be_subtracted = parseFloatString(dp.dollar_value);
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = (commission_distribution_lessor * net_commission);
                        dpClone.lessee_commission_distribution = (commission_distribution_lessee * net_commission) - to_be_subtracted;

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor;
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee - to_be_subtracted;

                        if (
                            calculated_commission_distribution_lessee < to_be_subtracted
                        ) {
                            dpClone.lessee_remaining_commission = 0;
                            commissionAmountCheck = false;
                        }

                        presplitCalculations.push(dpClone)
                    }
                }
            })
        }
    }
    presplitCalculations.forEach(pc => {
        if (pc.lessor_commission_distribution) {
            pc.lessor_commission_distribution = round2dec(pc.lessor_commission_distribution);
        }
        if (pc.lessee_commission_distribution) {
            pc.lessee_commission_distribution = round2dec(pc.lessee_commission_distribution);
        }
    })
    console.log('presplitCalculations', presplitCalculations);
    return {
        presplitCalculations,
        commissionAmountCheck
    };
}

export const validatePresplits = (data) => {
    let complete = true;
    // if (!data.presplits_tab_opened) {
    //     complete = false;
    // }
    if (data.presplits) {
        data.presplits.forEach(dp => {
            if (dp.value_type === 'Percentage') {
                if (!dp.percentage_value) {
                    complete = false;
                }
                if (dp.percentage_value === '0.00') {
                    complete = false;
                }
            } else {
                if (!dp.dollar_value) {
                    complete = false;
                }
                if (dp.dollar_value === '0.00') {
                    complete = false;
                }
            }
        })
    }
    let net_commission = 0;
    let found = false;
    if (data.flat_fee_commission === "Yes") {
        if (data.flat_fee_commission_amount) {
            net_commission = parseFloatString(data.flat_fee_commission_amount)
            found = true; 
        } else if (data.flat_fee_commission_amount !== '') {
            found = true;
        }
    } else {
        // get calculated 
        const calcs = termLogic.calculateLeaseTerm(data);
        console.log('calcs->', calcs);
        if (calcs) {
            if (calcs.net_commission) {
                net_commission = calcs.net_commission;
                found = true;
            }
        }
    }
    if (!found) complete = false;
    return complete;
}

const PreSplitsForm = props => {
    console.log('presp', props);
    const { data, 
        setData
    } = props;

    useEffect(() => {
        const newData = cloneJ(data);
        newData.presplits_tab_opened = true;
        setData(newData);
    }, []);

    const list = data['presplits'] ? data['presplits'] : [];

    const [openedChooser, setOpenedChooser] = useState(false);
    const [askDelete, setAskDelete] = useState(null);

    const choose = (chosen, a, dba, c, d) => {
        console.log('chosen', chosen, a, dba, c, d);
        if (chosen.first_name) {
            // CONTACT

            const newObj = {
                company_id: '',
                company_name: '',
                contact_id: chosen.id,
                first_name: chosen.first_name,
                last_name: chosen.last_name,
                value_type: '',
                dollar_value: '',
                percentage_value: '',
                presplit_applies_to: ''
            };

            if (dba) {
                newObj.dba_name = chosen?.dbas.find(i => i.id === dba.id)?.name;
                newObj.dba_id = dba.id;
                newObj.dba_type = 'contact';
                newObj.dba_active = 1;
            }

            // CHOSE A COMPANY
            if (chosen.company_dats) {
                const cdat = chosen.company_dats;
                if (cdat.email) {
                    newObj.email = cdat.email;
                    newObj.email_override = cdat.id;
                }
                if (cdat.phone) {
                    newObj.main_phone = cdat.phone;
                    newObj.main_phone_override = cdat.id;
                }
                if (cdat.company_data) {
                    if (cdat.company_data.id) {
                        newObj.company_id = cdat.company_data.id;
                        newObj.company_name = cdat.company_data.name;
                    }
                    // CHOSE A COMPANY DBA
                    if (chosen.company_dba_id) {
                        newObj.dba_name = chosen.company_dbas?.find(i => i.id === chosen.company_dba_id)?.name;
                        newObj.dba_id = chosen.company_dba_id;
                        newObj.dba_type = 'company';
                        newObj.dba_active = 1;
                    }
                }
            }

            const newData = clone(data);
            if (newData['presplits']) {
                newData['presplits'].push(newObj);
            } else {
                newData['presplits'] = [newObj];
            }
            setData(newData);
        } else if (chosen.hasOwnProperty("company_data")) {
            const pc = chosen.company_data;
            const cont = chosen.signers ? chosen.signers[0] ? chosen.signers[0] : null : null;
            const dba = chosen.dba_id ? chosen?.dba_data.find(i => i.id === chosen.dba_id) : null;
            // COMPANY 
            const newObj = {
                company_id: pc.id,
                contact_id: cont ? cont.id : '',
                company_name: pc.name,
                first_name: cont ? cont.first_name : '',
                last_name: cont ? cont.last_name : '', 
                value_type: '',
                dollar_value: '',
                percentage_value: '',
                presplit_applies_to: ''
            }

            if (pc.tin) newObj.tax_id = pc.tin;
            if (pc.w9) {
                newObj.w9 = 'Yes';
                newObj.w9_file = pc.w9;
            }
                
            // COMPANY DBA
            if (dba) {
                newObj.dba_name = dba.name;
                newObj.dba_id = dba.id;
                newObj.dba_type = 'company';
                newObj.dba_active = 1;
            }

            // COMPANY CONTACT
            if (cont) {
                if (cont.company_dats) {
                    const cc = cont.company_dats;
                    if (cc.email) {
                        newObj.email = cc.email;
                        newObj.email_override = cc.id;
                    }
                    if (cc.phone) {
                        newObj.main_phone = cc.phone;
                        newObj.main_phone_override = cc.id;
                    }
                }
            }

            const newData = clone(data);
            if (newData['presplits']) {
                newData['presplits'].push(newObj);
            } else {
                newData['presplits'] = [newObj];
            }
            setData(newData);
        }
    }

    const cpObj = calculatePresplits(data);
    const calculated = cpObj.presplitCalculations;
    const amountCheck = cpObj.commissionAmountCheck;
    const lease_info_completed = props.noLease ? { checked: true } : checkForLeaseInfoCompletion(data);
    if (!lease_info_completed.checked) {
        return <>
            <GridItem xs={12} sm={12} md={12}>
                <h4>Please complete Lease Info Tab first</h4>
            </GridItem>
        </>
    }

    console.log('PreSplitsForm', calculated, data);
    
    return <>
        <GridItem xs={12} sm={12} md={12}>
            <h4><strong>PRE-SPLITS</strong></h4>
            <Button
                color="primary"
                style={{marginBottom:20}}
                onClick={() => {
                    setOpenedChooser(true);
                }}
            >
                ADD PRE-SPLITS REFERRAL
            </Button>
            <div className="group-wrap">
                {!amountCheck && <div className="boxed red-text">Pre-split Amounts can't be bigger than corresponding side's commission</div>}
                {list.map((l,i) => {
                    let show = false;
                    //if (calculated.length === list.length) show = true;
                    return <div style={{marginBottom:20}} key={`outside-broker-${i}`} className="parentable">
                    <h4>#{i+1}</h4>
                    <div className="float-right">
                        <IconButton
                            onClick={() => {
                                setAskDelete(i);
                            }}
                        >
                            <Delete className="red" />
                        </IconButton>
                    </div>
                    <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                            {createObjName(l)}
                            {show && <div className="boxed mt-20">
                                Net Commission: <strong>{formatDollar(calculated[i].net_commission)}</strong><br />
                                Value to be Subtracted: <strong>{formatDollar(calculated[i].to_be_subtracted)}</strong><br />
                                Remaining Net Commission: <strong>{formatDollar(calculated[i].remaining_net_commission)}</strong><br />
                                Lessee Commission: <strong>{formatDollar(calculated[i].lessee_remaining_commission)}</strong><br />
                                Lessor Commission: <strong>{formatDollar(calculated[i].lessor_remaining_commission)}</strong><br />
                            </div>}
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <CustomRadio 
                                label="Value Type,Percentage,Dollar Value"
                                options={['Percentage','Dollar Value']}
                                onChange={(e) => {
                                    const newData = clone(data);
                                    newData.presplits = newData.presplits.map((x,y) => {
                                        if (y === i) {
                                            x.value_type = e.target.value;
                                            if (e.target.value === 'Percentage') {
                                                x.dollar_value = '';
                                            } else if (e.target.value === 'Dollar Value') {
                                                x.percentage_value = '';
                                            }
                                            return x;
                                        }
                                        return x;
                                    })
                                    setData(newData);
                                }}
                                value={l.value_type}
                            />
                            {l.value_type === 'Percentage' && <div>
                                <CustomInput
                                    label="Percentage"
                                     value={l.percentage_value}
                                     isRequired={true}
                                     onChange={(e) => {
                                        const newData = clone(data);
                                        newData.presplits = newData.presplits.map((x,y) => {
                                            if (y === i) {
                                                x.percentage_value = e.target.value;
                                                return x;
                                            }
                                            return x;
                                        })
                                        setData(newData);
                                     }}
                                     isCurrency={true}
                                     endAdornment="%"
                                />
                            </div>}
                            {l.value_type === 'Dollar Value' && <div>
                                    <CustomInput
                                        label="Dollar Value"
                                        value={l.dollar_value}
                                        isRequired={true}
                                        onChange={(e) => {
                                            const newData = clone(data);
                                            newData.presplits = newData.presplits.map((x,y) => {
                                                if (y === i) {
                                                    x.dollar_value = e.target.value;
                                                    return x;
                                                }
                                                return x;
                                            })
                                            setData(newData);
                                        }}
                                        isCurrency={true}
                                        startAdornment="$"
                                    />
                            </div>}
                            {!l.dollar_value && !l.percentage_value && <div className="red-text">
                                <em>Required</em>
                            </div>}
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <div>
                                <div className="required-radio">
                                    <CustomRadio
                                        label={['PreSplit Applies To'].concat(presplit_options).join(',')}
                                        options={presplit_options}
                                        onChange={(e) => {
                                            const newData = clone(data);
                                            newData.presplits = newData.presplits.map((x,y) => {
                                                if (y === i) {
                                                    x.presplit_applies_to = e.target.value;
                                                    return x;
                                                }
                                                return x;
                                            })
                                            setData(newData);
                                        }}
                                        value={l.presplit_applies_to}
                                    />
                                    {!l.presplit_applies_to && <div className="red-text mb-20 small-text">Required</div>}
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                })}

                {list.length === 0 && <div className="text-center">
                    No Pre-split entries
                </div>}
            </div>
        </GridItem>

        {openedChooser && (
                <CustomDialogChooser
                    open={openedChooser}
                    close={() => {
                        setOpenedChooser(false);
                    }}
                    choose={choose}
                    create_and_choose={choose}
                    title="Select Contact or Company"
                    label="Search Contacts"
                    should_skip_company_contact={false}
                    showContactCompaniesTab={true}
                    no_property={true}
                    allowNoContacts={true}
                    allowMultipleContacts={false}
                />
            )}

        {askDelete !== null && <NotificationAsk
            open={askDelete === null ? false : true}
            close={() => { setAskDelete(null) }}
            title="Are you sure?"
            message="Are you sure you want to remove this entry?"
            success={() => {
                const newData = clone(data);
                newData['presplits'] = newData['presplits'].filter((ob,x) => {
                    if (x !== askDelete) return true;
                });
                setData(newData);
                setAskDelete(null);
            }}
            success_button_text="OK, DELETE"
        />}
    </>
}

export const PresplitsFormFull = props => {
    const { data } = props;
    const cpObj = commissionCalc.calculate(data);
    const amountCheck = cpObj.commissionAmountCheck;
    const calculated = cpObj.presplitCalculations;
    const list = data['presplits'] ? data['presplits'] : [];
    console.log('calculated', calculated, list);
    return <>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-20"><strong>Referral Name</strong></td>
                        <td className="col-10"><strong>Percentage</strong></td>
                        <td className="col-10"><strong>Value</strong></td>
                        <td className="col-10"><strong>Pre-split Applies To</strong></td>
                    </tr>
                    {list.map((l,i) => {
                        let percentage = '';
                        let value = '';
                        if (l.value_type === 'Percentage') {
                            percentage = `${l.percentage_value} %`;
                            value = formatDollar(calculated[i].to_be_subtracted);
                        } else {
                            percentage = 'N/A';
                            value = `$${l.dollar_value}`;
                        }
                        const presplit_applies_to = l.presplit_applies_to;
                        const lessee_remaining_commission = calculated[i] ? formatDollar(calculated[i].lessee_remaining_commission) : '';
                        const lessor_remaining_commission = calculated[i] ? formatDollar(calculated[i].lessor_remaining_commission) : '';

                        return <tr key={`row-${i}-`}>
                            <td className="col-20">{createObjName(l)}</td>
                            <td className="col-10">{percentage}</td>
                            <td className="col-10">{value}</td>
                            <td className="col-10">{presplit_applies_to}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </>
}

export const PresplitsFormPDF = props => {
    let html = '';
    const { data } = props;
    const cpObj = commissionCalc.calculate(data);
    const amountCheck = cpObj.commissionAmountCheck;
    const calculated = cpObj.presplitCalculations;
    console.log('calculated', calculated);
    const list = data['presplits'] ? data['presplits'] : [];
    html = `<div class="the-table">
            <table>
                <tbody>
                    <tr>
                        <td class="col-20"><strong>Referral Name</strong></td>
                        <td class="col-10"><strong>Percentage</strong></td>
                        <td class="col-10"><strong>Value</strong></td>
                        <td class="col-10"><strong>Pre-split Applies To</strong></td>
                        <td class="col-15"><strong>Lessee Commission</strong></td>
                        <td class="col-15"><strong>Lessor Commission</strong></td>
                    </tr>
                    ${list.map((l,i) => {
                        let percentage = '';
                        let value = '';
                        if (l.value_type === 'Percentage') {
                            percentage = `${l.percentage_value} %`;
                            value = '';
                        } else {
                            percentage = 'N/A';
                            value = `$${l.dollar_value}`;
                        }
                        const presplit_applies_to = l.presplit_applies_to;
                        const lessee_remaining_commission = calculated[i] ? formatDollar(calculated[i].lessee_remaining_commission) : '';
                        const lessor_remaining_commission = calculated[i] ? formatDollar(calculated[i].lessor_remaining_commission) : '';

                        return `<tr>
                            <td class="col-20">${createObjName(l)}</td>
                            <td class="col-10">${percentage}</td>
                            <td class="col-10">${value}</td>
                            <td class="col-10">${presplit_applies_to}</td>
                        </tr>`
                    }).join('')}
                </tbody>
            </table>
        </div>`;
    return html;
}

export default PreSplitsForm;