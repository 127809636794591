import React, { useEffect, useState } from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { getDateDiffs, commatize, clone, round2dec } from '../../../shared/utility';
import CustomInput from "components/CustomInput/CustomInputValidate";
import {
    calculateAbatedRanges_RS as calculateAbatedRanges,
    calculateStartingRent,
    makeTotalCalcs,
    makeAllRanges,
} from '../RSheetsLogic'
import moment from 'moment';
import Button from '../../../components/CustomButtons/Button';

const Step3Sale = (props) => {
    const [calculatedAbatedRanges, setCalculatedAbatedRanges] = useState(null);
    const [updateSaleCommission, setUpdateSaleCommission] = useState(false);
    const { renderElement, data, setData } = props;

    useEffect(() => {
        const calc = calculateAbatedRanges({
            lease_term: data.lease_term,
            abated: data.abated_rent_ranges
        });
        setCalculatedAbatedRanges(calc);
        
    }, [data.lease_term, data.abated_rent_ranges]);

    useEffect(() => {
        if (data.sale_purchase_price && data.sale_commission_rate && !updateSaleCommission) {
            const newData = clone(data);
            newData.sale_sale_commission = round2dec(parseFloat(data.sale_purchase_price.replace(/,/g,'')) * (parseFloat(data.sale_commission_rate.replace(/,/g,'')) * 0.01));
            setData(newData);
        }
    }, [data.sale_purchase_price, data.sale_commission_rate, data.sale_sale_commission]);

    const allRanges = makeAllRanges(data);

    const convert = (date) => {
        const darr = date.split('/');
        const y = darr[2];
        const m = darr[0];
        const d = darr[1];
        return `${y}-${m}-${d}`;
    }
    
    return <GridContainer>
        {/* Escrow Information */}
        <GridItem xs={12} sm={6} md={6}>
            {/* {renderElement('sale_purchase_price')} */}
            <CustomInput
                label="Sale Purchase Price"
                value={data.sale_purchase_price ? data.sale_purchase_price : ""}
                onChange={(e) => {
                    const newData = clone(data);
                    newData.sale_purchase_price = e.target.value;
                    setData(newData);
                }}
                validate={[(val) => {
                    if (!val) return true;
                    return false;
                }]}
                startAdornment="$"
                isCurrency={true}
            />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {/* {renderElement('sale_commission_rate')} */}
            <CustomInput
                label="Sale Commission Rate"
                value={data.sale_commission_rate ? data.sale_commission_rate : ""}
                onChange={(e) => {
                    const newData = clone(data);
                    newData.sale_commission_rate = e.target.value;
                    setData(newData);
                }}
                validate={[(val) => {
                    if (!val) return true;
                    return false;
                }]}
                isCurrency={true}
                endAdornment="%"
            />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <div className={`${updateSaleCommission ? `` : 'unclickable gray-bg'}`}>
                {renderElement('sale_sale_commission')}
            </div>
            <Button color={updateSaleCommission ? 'rose' : 'white'} size="sm"
                onClick={() => {
                    setUpdateSaleCommission(!updateSaleCommission);
                }}
            >{updateSaleCommission ? 'FINISH EDITING' : 'EDIT'} SALE COMMISSION</Button>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_escrow_opening_date')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_escrow_closing_date')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_building_sqft')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_land_sqft')}
        </GridItem>
    </GridContainer>
}

export default Step3Sale;
