export const required = (value) => (value ? undefined : 'Required');

export const no_numbers = (value) => {
	var no_numbers = /[^0-9]/g;
	if (value) {
		if (!value.match(no_numbers)) {
			return 'No numbers';
		}
	}
	return undefined;
};

export const no_special_chars = (value) => {
	var myRegex = /^[a-z 0-9]+$/i;
	var whitelisted = myRegex.test(value);
	return value && !whitelisted ? 'Alphanumeric characters only' : undefined;
};

export const no_letters = (value) => {
	var numbers = /^[-+ 0-9]+$/g;
	if (value && typeof value === 'string') {
		if (!value.match(numbers)) {
			return 'Only Numbers, - and + allowed';
		}
	}
	return undefined;
};

export const no_letters_allow_slash = (value) => {
	var numbers = /^[-+ / 0-9]+$/g;
	if (value && typeof value === 'string') {
		if (!value.match(numbers)) {
			return 'Only Numbers, - and + allowed';
		}
	}
	return undefined;
};

export const percentage = (value) => {
    // Check if the number is a valid number.
    if (!/^\d+([\.,]\d{0,2})?$/.test(value)) return "Only percentage value allowed";
    // Check if the number is within the custom range.
    if (value < 0 || value > 100) return "Only percentage value allowed";
    return undefined;
}

export const min1 = (value) => {
	if (value) {
		if (value.length < 1) {
			return 'Minimum 1 characters';
		}
	}
	return undefined;
};

export const min2 = (value) => {
	if (value) {
		if (value.length < 2) {
			return 'Minimum 2 characters';
		}
	}
	return undefined;
};

export const min4 = (value) => {
	if (value) {
		if (value.length < 4) {
			return 'Minimum 4 characters';
		}
	}
	return undefined;
};

export const min5 = (value) => {
	if (value) {
		if (value.length < 5) {
			return 'Minimum 5 characters';
		}
	}
	return undefined;
};

export const min6 = (value) => {
	if (value) {
		if (value.length < 6) {
			return 'Minimum 6 characters';
		}
	}
	return undefined;
};

export const min9 = (value) => {
	if (value) {
		if (value.length < 9) {
			return 'Minimum 9 characters';
		}
	}
	return undefined;
};

export const min10 = (value) => {
	if (value) {
		if (value.length < 10) {
			return 'Minimum 10 characters';
		}
	}
	return undefined;
};

export const max4 = (value) => {
	if (value) {
		if (value.length > 4) {
			return 'Maximum 4 characters';
		}
	}
	return undefined;
};

export const max3 = (value) => {
	if (value) {
		if (value.length > 3) {
			return 'Maximum 3 characters';
		}
	}
	return undefined;
};

export const max10 = (value) => {
	if (value) {
		if (value.length > 10) {
			return 'Maximum 10 characters';
		}
	}
	return undefined;
};

export const max15 = (value) => {
	if (value) {
		if (value.length > 15) {
			return 'Maximum 15 characters';
		}
	}
	return undefined;
};

export const max20 = (value) => {
	if (value) {
		if (value.length > 20) {
			return 'Maximum 20 characters';
		}
	}
	return undefined;
};

export const max45 = (value) => {
	if (value) {
		if (value.length > 45) {
			return 'Maximum 45 characters';
		}
	}
	return undefined;
};

export const max50 = (value) => {
	if (value) {
		if (value.length > 50) {
			return 'Maximum 50 characters';
		}
	}
	return undefined;
};

export const max100 = (value) => {
	if (value) {
		if (value.length > 100) {
			return 'Maximum 100 characters';
		}
	}
	return undefined;
};

export const max125 = (value) => {
	if (value) {
		if (value.length > 125) {
			return 'Maximum 125 characters';
		}
	}
	return undefined;
};

export const max250 = (value) => {
	if (value) {
		if (value.length > 250) {
			return 'Maximum 250 characters';
		}
	}
	return undefined;
};

export const max500 = (value) => {
	if (value) {
		if (value.length > 500) {
			return 'Maximum 500 characters';
		}
	}
	return undefined;
};

export const email = (value) => {
	if (value) {
		// const itMatches = value.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i);

		const itMatches = value.match(/\S+@\S+\.\S+/);

		return value && !itMatches ? 'Invalid email address' : undefined;
	}
	return value;
};

export const equal100 = (value) => {
	if (value) {
		const v = parseFloat(value);
		return v !== 100 ? `The values must add up to 100` : undefined;
	}
};
// value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
//     'Invalid email address' : undefined

export const blacklist = (value) => {
	var myRegex = /[!#%^*+=[\]'`~;{}|_:<>?]/g;
	var blacklisted = myRegex.test(value);
	return value && blacklisted ? "!#%^*+=[]'`~;{}|_:<>? characters are not allowed" : undefined;
};

export const tel_blacklist = (value) => {
	var myRegex = /[!@#$%^&*=[\]'`~;,{}|_:<>?]/g;
	var blacklisted = myRegex.test(value);
	return value && blacklisted ? "!#%^*+=[]'`~;{}|_:<>? characters are not allowed" : undefined;
};

export const zipcode_blacklist = (value) => {
	var myRegex = /[!@#$%^&*=[\]'`~;,{}|_:<>?]/g;
	var blacklisted = myRegex.test(value);
	return value && blacklisted ? "!@#$%^&*=[]'`~;,{}|_:<>? characters are not allowed" : undefined;
};

export const alphanum_whitelist = (value) => {
	var myRegex = /^[a-z0-9]+$/i;
	var whitelisted = myRegex.test(value);
	return value && !whitelisted ? 'Alphanumeric characters only' : undefined;
};

export const alpha_whitelist = (value) => {
	var myRegex = /^[a-z \-A-Z]+$/i;
	var whitelisted = myRegex.test(value);
	return value && !whitelisted ? 'Alphabet characters only, no characters accents' : undefined;
};

export const num_whitelist = (value) => {
	if (value || value === 0) {
		var myRegex = /^[0-9+ \-()]+$/i;
		var whitelisted = myRegex.test(value);
		return value && !whitelisted ? 'No letters allowed' : undefined;
	}
};

export const email_blacklist = (value) => {
	var myRegex = /[!#$%^&*+=[\]\\';,()/{}|:<>?]/i;
	var blacklisted = myRegex.test(value);
	return value && blacklisted ? "!#$%^&*+=[]\\';,()/{}|:<>? characters only" : undefined;
};

export const not_just_num = (value) => {
	return value && !isNaN(value) ? 'Cannot be made up of only numbers' : undefined;
};

export const no_num_start = (value) => {
	return value && !isNaN(value[0]) ? 'Value cannot start with a number' : undefined;
};

export const valid_url = (string) => {
	let url;
	
	try {
	  url = new URL(string);
	} catch (_) {
	  return false;  
	}
  
	return url.protocol === "http:" || url.protocol === "https:";
  }
