import React from 'react';
import { useEffect } from 'react';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';

const Step2 = (props) => {
	const { data, renderElement, setOpenedChooseSpacesModal } = props;

	return (
		/* Premises Information */
		<GridContainer>
			{
				<GridItem xs={12} sm={12} md={12}>
					{data.spaces && (
						<div>
							<Button
								color='primary'
								className='mr-20'
								disabled={data.spaces.length > 0 ? false : true}
								onClick={() => {
									setOpenedChooseSpacesModal(true);
								}}
							>
								SELECT ADDRESS
							</Button>
							{data.spaces.length === 0 && (
								<span style={{ color: 'red' }}>
									Select a Property with Spaces
								</span>
							)}
						</div>
					)}
					{!data.unit_number && (
						<span style={{ color: 'red' }}>Select a Space</span>
					)}
				</GridItem>
			}

			<GridItem xs={12} sm={6} md={6}>
				{renderElement('premises_address')}
			</GridItem>

			<GridItem xs={12} sm={6} md={6}>
				{renderElement('unit_number')}
			</GridItem>

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('city')}
			</GridItem>

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('zip')}
			</GridItem>

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('county')}
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				{renderElement('property_type')}
			</GridItem>
		</GridContainer>
	);
};

export default Step2;
