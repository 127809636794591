// base data
const originalTextData = {
    main_title: 'COMMERCIAL PROPERTY MANAGEMENT AGREEMENT',
    title: '',
    recitals: 'R E C I T A L S!',

    a_agreement_title: '("Agreement")',
    a_owner_title: '("Owner(s)").',
    a_and: 'and',
    a_manager_title: '("Manager"),',

    b_property_title: '("Property").',
    b_agreement_title: 'A G R E E M E N T',

    c_manager_title: 'Manager:',
    c_tel_title: 'Tel:',
    c_fax_title: 'Fax:',
    c_email_title: 'e-mail:',

    d_owner_title: 'Owner:',
    d_company_title: 'Company Name:',
    d_name_title: 'Name:',
    d_title_title: 'Title:',
    d_address_title: 'Address:',
    d_tel_title: 'Tel:',
    d_email_title: 'Email:',

    e_owner_title: 'Owner',
    e_manager_title: 'Manager',

    by_one: 'By:',
    by_two: 'By:',

    f_illi_title: 'VII COMMERCIAL CORP, a California corporation d/b/a illi Commercial Real Estate',

    g_owner_title: 'Owner:',
    g_name_title: 'Name:',
    g_title_title: 'Title:',

    h_president_title: 'Name:',
    its_president: 'Its: President',
    president_date: 'Signature Date:',

    n1: '1.',
    n2: '2.',

    n3: '3.',
    n3a: 'a.',
    n3b: 'b.',
    n3c: 'c.',
    n3d: 'd.',
    n3e: 'e.',
    n3f: 'f.',
    n3g: 'g.',
    n3h: 'h.',

    n4: '4.',
    n4a: '•',
    n4b: '•',
    n4c: '•',

    n5: '5.',
    n5a: 'a.',
    n5b: 'b.',
    n5c: 'c.',
    n5d: 'd.',
    n5e: 'e.',
    n5f: 'f.',
    n5g: 'g.',
    n5h: 'h.',

    n6: '6.',
    n6a: 'a.',
    n6b: 'b.',
    n6c: 'c.',
    n6d: 'd.',
    n6e: 'e.',
    n6f: 'f.',

    n6: '6.',

    n7: '7.',

    n8: '8.',
    n8a: 'a.',
    n8b: 'b.',
    n8c: 'c.',
    n8d: 'd.',
    n8e: 'e.',
    n8f: 'f.',

    n9: '9.',
    n9a: 'a.',
    n9a1: 'i.',
    n9a2: 'ii.',
    n9a3: 'iii.',
    n9b: 'b.',
    n9c: 'c.',
    n9d: 'd.',
    n9e: 'e.',
    n9f: 'f.',

    n10: '10.',
    n11: '11.',
    n12: '12.',
    n13: '13.',
    n14: '14.',
    n15: '15.',
    n16: '16.',
    n17: '17.',
    n18: '18.',
    n19: '19.',
    n20: '20.',
    n21: '21.',
    n22: '22.',
    n23: '23.',
    n24: '24.',
    n25: '25.',
    n26: '26.',
    n27: '27.',
    n28: '28.',
    n29: '29.',

    cover_address: `5945 Canoga Ave, Woodland Hills, CA 91367`,
    cover0: `Greetings:`,

    cover1: `We are hopeful that we can somewhat normalize management duties as of February 1st with having the government relax some of the many restrictions placed on us during COVID-19 and the Moratorium.`,

    cover2: `We suspect that the Unlawful Detainer Courts are going to be buried in work and want to be sure we have our “I’s” dotted and our “t’s” crossed.   Judges sometimes want to review our management agreements giving us the authority to act on behalf of our clients.`, 

    cover3: `Therefore, in anticipation, we want to renew management agreements as most are out of date and are required by the Department of Real Estate (DRE).`,

    cover4: `We are sending them out DocuSign to make this step as easy as possible.  Please review and sign the management agreement, feeling free to contact us with any questions you might have.`,

    cover5: `Best regards,`,

    cover6_1: `Keith Corneliuson`,
    cover6_2: `Executive Vice President`,
    cover6_3: `Property Management Division`,


    a1: `THIS PROPERTY MANAGEMENT AGREEMENT`,
    a2: `is dated for
        identification purposes only as of`,
    a3: `and is made by and
    between VII Commercial Corp., a California corporation d/b/a "illi
    Commercial Real Estate"`,
    whereas_now: `WHEREAS, Owner(s) is/are or will be the owner(s) of that certain commercial property located at`,
    whereas_now2: `WHEREAS, Owner(s) desires to retain the services of Manager to rent, lease, operate and manage the Property on a day-to-day basis.`,
    now_therefore: `NOW, THEREFORE, in consideration of the mutual covenants herein contained and other good and valuable consideration, the receipt of which is hereby acknowledged, the parties agree as follows:`,
    
    manager_and_acceptance_title: `Appointment of Manager and Acceptance.`,
    manager_and_acceptance: `Owner(s) hereby appoints and grants Manager the exclusive right to rent, lease, operate and manage the Property on a day-to-day basis, and Manager hereby accepts such appointment, on the terms and conditions set forth in this Agreement.`,
    
    term_and_termination_title: `Term and Termination.`,
    term_and_termination_p1: `This Agreement shall be effective and binding upon the parties on the date of the signing of this Agreement. The term of this Agreement (the "Term") shall begin on`,
    term_and_termination_p2: ` (the "Commencement Date") and shall continue until the effective date of the termination pursuant to the terms of this Agreement which is for a period of one year (the "Expiration Date'). Either party may terminate this Agreement at any time, with or without cause, by giving the other party at least sixty (60) days' advance written notice prior to the expiration of any monthly term. If neither party has notified the other party as provided, the term of this Agreement shall be extended for another one-year period until terminated as provided above. In no event shall any termination of this Agreement affect Manager's right to receive compensation and monies due or accrued hereunder prior to any such termination and Owner(s)' obligations in connection therewith shall survive the termination or expiration of this Agreement.`,
    
    management_duties_title: 'Management Duties.',
    management_duties: `In its capacity as Manager hereunder, Manager shall use commercially reasonable efforts to manage the Property on a day-to-day basis in accordance with such management practices as are normal and customary for commercial property substantially similar to the Property as of the date of this Agreement. Subject to the limitations on Manager's authority in Paragraph 5 below, Owner(s) hereby grants Manager the power and authority, at Owner(s)' expense, to do and      perform the following services for, on behalf of, and in the name of Owner(s): `,
  
    record_statements_collections_title: 'Records; Statements; Collections.',
    record_statements_collections: `To monitor, supervise, and keep digital files and records (see selection above signature block) on the operations of the Property and in connection therewith: within sixty (60) days after the date hereof, to prepare a proposed operating budget for the remainder of the current calendar year; to prepare an annual operating budget for the following calendar year no later than November 1st of the then-current calendar year to collect rents and other charges from tenants, licensees, concessionaires and REA parties as and when the same become due and payable; to prepare and deliver to Owner(s) within twenty-five (25) days after the end of each month, an income and expense statement, rent roll and delinquency list, and within seventy (75) days after the end of fiscal year such reports shall also include a budget variance report showing and comparing against the approved budget, the receipts and expenses relating to the Property for the preceding fiscal year; to maintain a segregated bank account in the name of Manager holding such funds in trust for the Owner(s) (the "Trust Account") at an FDIC insured institution selected by Manager and to deposit in the Trust Account all rents and income (less any sums properly deducted or disbursed pursuant to this Agreement) relating to the Property. Manager shall not be liable in the event of bankruptcy or failure of financial institutions.`,
  
    repair_maintenance_title: 'Repair; Maintenance.',
    repair_maintenance: `To monitor and attend to the physical condition
        of the Property and in connection therewith: to cause the common
        areas of the Property to be maintained and kept in good condition
        and repair; to negotiate, execute, and deliver contracts in
        Manager's or Owner(s)' name for utilities and services for the
        Property, including, for example, electricity, gas, fuel, water,
        telephone, window cleaning, trash or rubbish hauling, sweeping,
        landscaping, gardening, vermin extermination, and such other
        activities as Manager may deem necessary or appropriate. Manager may
        perform any of Manager's duties through attorneys, agents,
        employees, or independent contractors and, except for persons
        working in Manager's firm, shall not be responsible for their acts,
        omissions, defaults, negligence and/or costs of same. It is
        expressly understood that certain vendors may be affiliated, related
        to or otherwise associated with Manager, and where such
        relationships exist those vendors shall only be used when their
        prices and services are competitive and comparable to non-related
        vendors.`,
  
    insurance_proposals_title: 'Insurance Proposals.',
    insurance_proposals: `If requested by Owner(s), develop and submit to Owner(s) for Owner(s)' 
        review and approval proposals for insurance coverage for the 
        Property with limits and deductibles as Owner(s) may designate. 
        Due to the liability involved, neither the manager or the manager’s employees 
        will make any recommendations as to an insurance company, what constitutes the correct 
        amount of coverage contained in the policy, etc. The owner(s) should meet with the insurance 
        companies or request assistance from an attorney that deals with insurance matters to 
        determine what coverage is appropriate and let the manager know of the decision that 
        the owner(s) has made.`,
  
    enforcement_title: 'Enforcement.',
    enforcement: `Subject to being reimbursed by Owner(s) for out-of-pocket
        expenses: prepare, execute and serve in Owner(s)' name such notices as
        Owner(s), Manager, or counsel may specify which may be necessary or
        proper to enforce any lease, to evict any tenant in breach or
        default of its lease or to terminate such lease and recover
        possession in Owner(s)' name, including the right to retain attorneys
        in connection therewith; appear and represent Owner(s) before any court
        or tribunal as is necessary for the proper management of the
        Property. Owner(s) further authorizes Manager to obtain legal counsel
        as necessary to defend any actions filed against Owner(s) and/or
        Manager in connection with management of the Property.`,
  
    payments_title: 'Payments.',
    payments: `To cause to be paid the costs, fees, and expenses
        associated with the ownership, operation, and management of the
        Property as and when the same become due and payable, from the funds
        on deposit in the Trust Account, including, but not limited to, the
        following: the management fees and reimbursements to Manager and
        leasing compensation specified in this Agreement; real property
        taxes; personal property taxes; special assessments and bonds which
        are a lien on the Property; mortgage indebtedness; insurance
        premiums; all common area maintenance repair and service fees,
        costs, and expenses; legal and accounting fees incurred specific to
        the Property; and all other costs, fees, or expenses, which are
        incurred during the routine management of the Property, including
        the costs of the on-site office, on-site property manager and other
        personnel, if any. Notwithstanding the foregoing, Manager shall have
        no liability or responsibility for such payments if Owner(s) fails to
        provide Manager with all information necessary to pay said costs,
        fees and expenses (e.g. tax bills). In the event that the funds on
        deposit in the Trust Account are inadequate to pay each of the
        foregoing expenses with a reserve of at least $1,000.00, then Owner(s)
        shall deposit any needed funds into the Trust Account so that
        expenses can be timely paid (in no event shall Manager be required
        to advance any of its own funds to or for the account of Owner(s)).`,
  
    consultation_title: 'Consultation.',
    consultation: `To consult with Owner(s) from time to time regarding the
        general status of the Property, any problems with the Property, any
        potential capital improvements relating to the Property, and any
        other aspect of the Property which Owner(s) or Manager deems
        appropriate.`,
  
    security_deposits_title: 'Security Deposits.',
    security_deposits: `To receive security deposits from tenants, which
        security deposits shall be placed in the Operating Trust Account.`,
  
    owner_distributions_title: 'Owner Distributions.',
    owner_distributions: `Distributions to Owner(s) under this Agreement
        shall be made electronically by Manager to Owner(s)’ designated bank
        account on or before the 10th of every month. Said distribution
        shall consist of all funds remaining at the end of the month that
        are in excess of indicated reserve and any monthly impounds agreed
        upon for insurance, property taxes and mortgages unless other
        instructions have been agreed to, in writing, by the parties to this
        Agreement.`,
  
    leasing_responsibilities_title: 'Leasing Responsibilities.',


    leasing_responsibilities_p1: `In its capacity as Manager hereunder, Manager
        shall use commercially reasonable effort to cause the Property to be
        leased as and when the same becomes available on terms and lease forms
        approved by Owner(s), and Owner(s) hereby grants Manager the power and
        authority to do and perform the following services for, on behalf of,
        and in the name of Owner(s): to advertise the availability of space for
        rent, to display "For Lease" signs on the Property, to negotiate terms
        of leases, lease amendments (including extensions), assignments and
        sublease consents; provided, however, that Manager shall not have the
        authority to execute any such leases or lease amendments, or to execute
        any such consents to assignments or subleases. The parties agree that
        all lease negotiations with respect to the Property shall be through
        Manager. Owner(s) shall make itself available at all times for consultation
        regarding lease terms and activities and for execution of leases and
        leases amendments and consents to assignments and subleases. Owner(s) and
        its counsel will be responsible for determining the legal sufficiency of
        a lease and any other documents relating to any transaction contemplated
        by this Agreement. Owner(s) consents to Manager acting as a dual agent for
        Owner(s) and tenant(s) in any resulting transaction. `,
  
    leasing_responsibilities_p2: `Under California law, it is unlawful for a landlord, managing agent,
        real estate broker, or salesperson to discriminate against a person or
        harass a person because of the person’s race, color, religion, sex
        (including pregnancy, childbirth or medical conditions related to them,
        as well as gender and perception of gender), sexual orientation, marital
        status, national origin, ancestry, familial status, source of income, or
        disability. California law also prohibits discrimination based on any of
        the following:`,
  
    leasing_responsibilities_p3: `A person's medical condition or mental or physical disability; or`,
  
    leasing_responsibilities_p4: `Personal characteristics, such as a person's physical appearance or
        sexual orientation that are not related to the responsibilities of a
        tenant; or`,
  
    leasing_responsibilities_p5: `A perception of a person's race, color, religion, sex, sexual
        orientation, marital status, national origin, ancestry, familial
        status, source of income, disability or medical condition, or a
        perception that a person is associated with another person who may
        have any of these characteristics.`,

    limitation_on_manager_authority_title: "Limitation on Manager's Authority.",
  
    limitation_on_manager_authority_p1: `Notwithstanding any other provision
        of this Agreement to the contrary, Manager shall have no authority to:`,
  
    limitation_on_manager_authority_p2: `Do any acts which would make it impossible to carry on the ordinary
        business of operating the Property;`,
  
    limitation_on_manager_authority_p3: `Confess a judgment against Owner(s);`,
  
    limitation_on_manager_authority_p4: `Encumber the Property;`,
  
    limitation_on_manager_authority_p5: `Expend funds in excess of $2,000 for any one repair, replacement or
        improvement without Owner(s)' prior consent, except for monthly,
        budgeted or recurring charges and except for emergency repairs;`,
  
    limitation_on_manager_authority_p6: `Release any tenant from liability on a lease except with the express
        consent of Owner(s);`,
  
    limitation_on_manager_authority_p7: `Execute leases on behalf of Owner(s) unless so directed in writing by
        Owner(s) with respect to such particular lease;`,
  
    limitation_on_manager_authority_p8: `Litigate on behalf of the Owner(s) without Owner(s) prior consent,
        except for three-day notices and related unlawful detainer
        litigation that Manager shall keep Owner(s) informed of; and`,
  
    limitation_on_manager_authority_p9: `Settle, compromise or otherwise dispose of any claims, demands or
        liabilities, whether or not covered by insurance, without the prior
        written consent of the Owner(s).`,
  
    owners_responsibilities_title: "Owner's Responsibilities.",

    owners_responsibilities_intro: `Notwithstanding any other provision of this
        Agreement to the contrary, and in addition to Owner(s)' responsibilities
        set forth elsewhere in this Agreement, Owner(s) shall have the following
        responsibilities under the provisions of this Agreement: `,
  
    owners_responsibilities_generally_title: 'Generally.',
    owners_responsibilities_generally: `Owner(s) shall make itself available at all reasonably times
        for consultation regarding the day-to-day management of the
        Property, lease negotiations, and execution of leases; Owner(s) shall
        be liable for all costs, fees, and expenses incurred in connection
        with the management of the Property, and Manager shall not be
        obligated to continue to perform or provide services in the event
        that the Trust Account has insufficient funds to pay such costs;
        Owner(s) shall review promptly the bank statements issued by the bank
        with reference to the Trust Account.`,
  
    owners_responsibilities_insurance_title: 'Insurance.',
    owners_responsibilities_insurance: `Owner(s) shall carry and pay for (i) public and premises
        liability insurance in an amount of no less than $1,000,000 per
        occurrence, $2,000,000 aggregate; and (ii) property damage and
        worker's compensation insurance adequate to protect the interests of
        Owner(s) and Manager. Owner(s) shall cause Manager to be named as an
        additional insured on such insurance relating to the Property with a
        waiver of rights of subrogation. All such insurance policies shall
        provide that Manager shall receive not less than thirty (30) days
        written notice prior to cancellation of the policy. Owner(s) shall look
        solely to such insurance for indemnity against any and all loss
        and/or damage to the Property or loss of rents.`,
  
    owners_responsibilities_legal_comp_n_disc_title: 'Legal Compliance and Disclosures.',
    owners_responsibilities_legal_comp_n_disc: `Owner(s) shall disclose to Manager
        and to prospective tenants or purchasers any and all information
        which Owner(s) has regarding present and future zoning and
        environmental matters affecting the Property and regarding the
        condition of the Property, including, but not limited to,
        structural, mechanical and soils conditions, the presence and
        location of asbestos, PCB transformers, other toxic, hazardous or
        contaminated substances, and underground storage tanks, in, on, or
        about the Property, as well as whether the Property is or may be
        situated in a flood zone, dam inundation zone or an Earthquake Fault
        Zone and/or Seismic Hazard Zone, as defined in Sections 2621 et.
        seq. and 2690 et. seq. of the California Public Resource Code, it
        being agreed that MANAGER is authorized to disclose any such
        information to prospective purchasers or tenants; in connection
        therewith, Owner(s) shall provide all documentation, records and
        disclosures as required by law and/or reasonably required by Manager
        to manage and operate the Property, and immediately notify Manager
        if Owner(s) becomes aware of any change in such documentation, records
        or disclosures, or becomes aware of any other matter(s) materially
        affecting the Property; OWNER(S) ACKNOWLEDGES THAT MANAGER IS NOT
        ASSUMING ANY RESPONSIBILITY OR LIABILITY FOR ISSUES PERTAINING TO
        ZONING OR PERMITS FOR ANY TENANTS (REGARDLESS OF WHETHER SUCH
        TENANTS WERE SECURED BY MANAGER, OWNER(S) OR ANOTHER PARTY), OR FOR
        EXISTING USE CONFLICTS, AND OWNER(S) AGREES TO HOLD MANAGER HARMLESS
        FROM ANY LIABILITY OR DAMAGES ARISING FROM ALL MATTERS SET FORTH IN
        THIS SUBSECTION (C) (COLLECTIVELY, THE "COMPLIANCE AND DISCLOSURE
        MATTERS"), INCLUDING ANY INCORRECT INFORMATION SUPPLIED BY OWNER(S) OR
        ANY INFORMATION WHICH OWNER(S) FAILS TO SUPPLY. Owner(s) acknowledges that
        Manager shall have the right to rely on the accuracy of any
        representation by Owner(s) pertaining to the Property.`,
  
    owners_responsibilities_security_depo_int_title: 'Security Deposit Interest.',
    owners_responsibilities_security_depo_int: `Owner(s) shall pay all interest on tenants'
        security deposits if required by local law or ordinance;`,
  
    owners_responsibilities_owner_rep_p1_title: 'Owner Representative.',
    owners_responsibilities_owner_rep_p1: `Owner(s) shall designate an authorized
        representative; Owner(s)' current authorized representative(s) is`,
        
    owners_responsibilities_owner_rep_p2: `and can be reached at the following
        telephone number: `,
  
    owners_responsibilities_owner_rep_p3: `(the “Owner
        Representative”). Owner(s) may change the designated Owner
        Representative at any time by written notice to Manager. Any
        document or request for approval signed or initialed by the Owner
        Representative shall be binding on Owner(s).`,
  
    owners_responsibilities_tax_prep_title: 'Tax Preparation.',
    owners_responsibilities_tax_prep: `Owner(s) shall prepare and submit any and all federal,
        state and local income tax returns respecting the Property, it being
        agreed that Manager has no responsibility for such matters.`,
  
    owners_representations_title: "Owner's Representations.",
    owners_representations: `Owner(s) represents and warrants that: (a) each
        person executing this Agreement on behalf of Owner(s) has the full right,
        power and authority to execute this Agreement as or on behalf of Owner(s);
        (b) Owner(s) has the full, right, power and authority to execute this
        Agreement and to consummate the transactions contemplated herein and to
        perform Owner(s)' obligations hereunder; (c) neither Owner(s) nor the
        Property is the subject of a bankruptcy, insolvency, probate or
        conservatorship proceeding; (d) Owner(s) has no notice or knowledge that
        any lessee or sub-lessee of the Property, if any, is the subject of a
        bankruptcy or insolvency proceeding; and (e) Owner(s) has delivered to
        Manager true, correct and complete copies of all material documents and
        records pertaining to the Property in order for Manager to property
        manage the Property, including, without limitation, all lease agreements
        and related amendments and documentation (collectively, the "Existing
        Leases") and all covenants, conditions and restrictions (collectively,
        the "CC&R's") respecting the Property, each as described on Exhibit A,
        and there are no effective, valid or enforceable option rights, rights
        of first refusal, rights of first offer, exclusive use rights or any
        other restrictions, impediments or limitations on Owner(s)' right, ability
        and capacity to consummate a lease transaction, except as expressly set
        forth in the Existing Leases and/or CC&R's.`,
  
    management_fee_title: 'Management Fee.',
    management_fee_p1: `Manager shall be entitled to a monthly fee for the day-to-day
        management of the Property in an amount equal to`,
  
    management_fee_p2: `of the monthly gross income derived
        from the operation of the Property (including, without limitation,
        all rent, percentage rent, common area maintenance reimbursements,
        tax reimbursements, insurance reimbursements, concessioned rent,
        payments received for utilities and services and other miscellaneous
        income received from tenants and other users of the Property) during
        the applicable month. Gross income shall specifically exclude any
        insurance or condemnation proceeds or any unapplied security
        deposits (i.e. prior to forfeiture thereof). However, both parties
        agree that notwithstanding the formula above, the management fee
        shall be no less than `,
  
    management_fee_p3: `. The management
        fee shall be paid monthly, in arrears, and may be paid from the
        funds on deposit in the Trust Account. In addition to the monthly
        management fee, Owner(s) shall reimburse Manager for any and all
        out-of-pocket expenses incurred by Manager in connection with its
        services provided pursuant to this Agreement, including, but not
        limited to, overnight courier fees, costs of attorneys' fees to
        enforce leases, and fees related to leasing and marketing of the
        Property. Manager shall be entitled to its Management Fee under this
        paragraph even if Owner(s) provides a concession to tenants in the form
        of rent credit and/or abatement. In the event Owner(s) provides a
        tenant rental credit and/or rent abatement, Manager shall still be
        entitled to its `,
        
    management_fee_p4: `management fee of the
        agreed upon rental value of the subject property. `,
  
    management_fee_p5: `THIS AGREEMENT DOES NOT INCLUDE PROVIDING THE FOLLOWING SERVICES:
        ON-SITE MANAGEMENT SERVICES, PROPERTY SALES, REFINANCING, PREPARING
        PROPERTY FOR SALE OR REFINANCING, MODERNIZATION, FIRE OR MAJOR
        DAMAGE RESTORATION, REHABILITATION, OBTAINING INCOME TAX, ACCOUNTING
        OR LEGAL ADVICE, REPRESENTATION BEFORE PUBLIC AGENCIES, ADVISING ON
        PROPOSED NEW CONSTRUCTION, ZONING OR PERMITTING, CONSTRUCTION
        MANAGEMENT, DEBT COLLECTION, COUNSELING OR ATTENDING OWNER(S)'
        ASSOCIATION MEETINGS. IF OWNER(S) REQUESTS MANAGER TO PERFORM SERVICES
        NOT INCLUDED IN THIS AGREEMENT, AND IF MANAGER AGREES TO PERFORM
        SUCH SERVICES IN ITS SOLE DISCRETION (WHICH AGREEMENT MAY ONLY BE
        EVIDENCED IN WRITING), AN ADDITIONAL FEE SHALL BE PAYABLE TO MANAGER
        (THE AMOUNT OF WHICH SHALL BE AGREED UPON BY THE PARTIES).`,

    additional_management_fee_schedule_title: 'Additional Management Fee Schedule.',
  
    add_manage_fee_sched_p1: `Management will be paid a fee of $125.00 per hour if we are called
        upon to meet with attorneys or to be present in court appearances
        for evictions. Other accounting or management duties not covered in
        this Agreement will be at an hourly fee or a quoted price upon
        request.`,
  
    add_manage_fee_sched_p2: `Late Fees and Returned check fees: Delinquent rents and returned
        checks require substantial labor for computer and accounting
        processing and also in collection efforts through additional past
        due statement processing, individual telephonic pursuit of payment
        schedules, making payment arrangements complete with agreement and
        post-dated checks, issuing and serving 3-day Demands for payment or
        possession on tenant and turning over to attorney for unlawful
        detainer action. The late charges and/or interest payments collected
        from Tenants will be paid 50% to Manager and 50% to owner(s) to cover
        Manager’s costs in collecting and processing the delinquent rental
        amounts.`,
  
    add_manage_fee_sched_p3: `Set-up fee: Initial time invested in setting-up a new account
        including accounting, opening the bank trust account, programing all
        tenant and building information into the computer software system
        and lease abstracting: `,
  
    add_manage_fee_sched_p4: `Supervision fee for construction or major cosmetic rehabilitation:
        Applies to projects in which the total project cost will exceed
        $7,500 for which there will be a fee of ten percent (10%) of the
        total remodeling/upgrade project for our services in supervising the
        project. Supervision may include overseeing or publishing a scope of
        work (job spec), drafting RFP’s (request(s) for proposal), putting
        RFP’s out to bid and obtaining at least 3 competitive work
        proposals, reviewing the same making recommendations to owner(s),
        execute and/or draft contracts, change orders, overseeing licensing
        and permit requirements, lien release waivers, liability and
        worker’s compensation insurance, completion bonds, building
        department sign off of permit, completion bonds, may include weekly
        job walk(s) with contractor and trades, authorizing change orders,
        and drafting work progress payment checks for work completed,
        completion bonds and final sign off by building department.`,
  
    add_manage_fee_sched_p5: `Miscellaneous costs: In addition to the above expenses, Owner(s) shall
        reimburse Manager for all postage, overnight mail fees, DocuSign and
        messenger fees incurred by Manager as a result of Manager’s duties
        under this Agreement.`,
  
    leasing_compensation_title: 'Leasing Compensation.',
    leasing_comp: `The following additional compensation is payable
        to Manager pursuant to this Agreement: `,
  
    commissions_generally_title: 'Commissions Generally.',
    leasing_comp_commissions_generally: `Owner(s) agrees that Manager shall be entitled
        to leasing commissions as follows:`,
  
    leases_other_than_month_to_month_title: 'Leases Other than Month-to-Month.',
    leasing_comp_other_leases: `For a lease procured for a new
        lessee at the Property (other than a lease for a month-to-month
        term), Owner(s) shall pay Manager a leasing commission fee in the
        amount of six percent (6%) of the total base rental income for
        months one (1) through sixty (60) and three percent (3%) of the
        total base rental income for months sixty one (61) through the end
        of the term, and subject to a minimum commission equal to one
        month’s rent, whichever is greater, which commission fee shall be
        payable as follows: Fifty percent (50%) upon signing of the lease,
        and the remaining fifty percent (50%) upon commencement of the
        lease term.`,
  
    leasing_comp_m_to_m_lease_title: 'Month-to-Month Leases.',
    leasing_comp_m_to_m_lease: `For a lease procured for a new lessee at
        the Property on the basis of a month-to-month term, Owner(s) shall
        pay Manager a leasing commission fee in the amount of one month’s
        rent for such leasing work, which commission fee shall be payable
        as follows: Fifty percent (50%) upon signing of the lease and
        tenant’s payment of any costs due at signing, and the remaining
        fifty percent (50%) upon commencement of the lease term. If such
        lessee subsequently executes a lease for space at the Property (on
        terms other than month-to-month) during the Term or within 24
        months from the date of initial occupancy (whichever is later),
        then Manager shall receive a leasing commission with respect to
        such lease in accordance with the provisions of subparagraph (i)
        above.`,
  
    leasing_comp_renewals_extensions_title: 'Renewals/Extensions.',
    leasing_comp_renewals_extensions: `If a lessee (including assignees and
        subtenants, as applicable) at the Property extends its existing
        lease or otherwise exercises an option to extend its lease
        (whether strictly in accordance with the terms of such option(s)),
        then Owner(s) shall pay Manager a leasing commission fee in the
        amount of three percent (3%) of the total base rental income for
        months one (1) through sixty (60)] and one and one-half percent
        (1.5%) of the total rental base income for months sixty one (61)
        through the end of the term of the lease, and subject to a minimum
        commission equal to one month’s rent, whichever is greater, which
        commission fee shall be payable in full on the effective date of
        such renewal or option to extend.`,

    leasing_comp_transaction_fees_title: 'Transaction Fees.',
    leasing_comp_transaction_fees: `Manager may receive and keep fees and charges for
        processing credit applications. In the event that one or more
        tenants request a lease assignment or attempt to sublease, then to
        the extent permitted by the related tenant lease, Manager may charge
        that tenant up to $2,500 for such transaction and 100% of such
        payment may be retained by Manager as compensation for such review
        of assignment or sublease. In no event will Owner(s) be responsible for
        the payment of any such review fee to Manager.`,
  
    leasing_comp_cooperating_brokers_title: 'Cooperating Brokers.',
    leasing_comp_cooperating_brokers: `If an outside real estate broker or finder
        is entitled to a commission or finder's fee in connection with any
        such lease, the amounts paid to such broker or finder shall (i) be
        subject to the approval of Owner(s) prior to entering into any such
        agreement and (ii) be credited against the amount otherwise to be
        paid to Manager; provided, however, that in no event shall the
        amount paid to Manager be less than 50% of the amounts otherwise set
        forth above.`,
  
    leasing_comp_post_term_transactions_title: 'Post-Term Transactions.',
    leasing_comp_post_term_transactions: `Within thirty (30) days following the
        Expiration Date, Manager may provide a registration list of tenants
        with whom Manager has negotiated (either directly or through another
        broker or agent) respecting the Property or to whom the Property has
        been submitted prior to the Expiration Date (the "Registration
        List"), and Manager may continue such negotiations with such persons
        or entities. Notwithstanding the foregoing, those persons or
        entities who submitted written offers or letters of intent for the
        Property, or for whom proposed leases of the Property have been
        submitted, shall automatically be deemed to be on the Registration
        List. Owner(s) shall pay Manager a commission pursuant to the
        above-compensation terms if, within one hundred eighty (180) days
        after the Expiration Date, the Property is leased to, or
        negotiations continue, resume or commence during such period and
        thereafter continue leading to a lease of the Property to any person
        or entity (including his/her/its successors, assigns or affiliates)
        identified (or deemed identified) on the Registration List.`,
  
    leasing_comp_signage_permission_title: 'Signage Permission.',
    leasing_comp_signage_permission: `Owner(s) grants Manager Permission to place signage
        on the property to advertise vacancies or coming vacancies for lease
        or for notification purposes of how to reach the management company
        in the event of an emergency.`,
  
    leasing_comp_payments_to_manager_title: 'Payments to Manager.',
    leasing_comp_payments_to_manager: `All payments by Owner(s) to Manager shall be made
        in lawful United States currency. If Owner(s) fails to pay to Manager
        any amount when due under this Agreement, then such amount shall
        bear interest at the rate of 10% percent per annum or the maximum
        rate allowed by law, whichever is less.`,
  
    indemnity_title: 'Indemnity.',
    indemnity: `Owner(s) shall defend, indemnify and hold Manager (including its
        officers, shareholders, members, partners, directors, employees and
        agents) free and harmless from and against any and all claims, demands,
        costs, fees and expenses which may arise against the Manager in
        connection with the Property and/or the performance of Manager's duties
        under this Agreement, other than any claim, demand, cost, fee or expense
        arising from (a) any acts of Manager, its officers, agents or employees,
        outside the scope of the authority of Manager hereunder, or (b) the
        gross negligence or willful misconduct by Manager, its officers, agents
        or employees. Owner(s)' indemnity obligations hereunder shall include
        indemnification of Manager (including its officers, directors, employees
        and agents) for all claims, demands, costs, fees and expenses arising
        out of the Compliance and Disclosure Matters described in Paragraph 6(c)
        above. Manager shall defend, indemnify and hold Owner(s) (including its
        partners, officers and agents) free and harmless from and against any
        and all claims, demands, costs, fees and expenses which may arise
        against the Owner(s) in connection with (a) any acts of Manager, its
        officers, agents or employees, outside the scope of the authority of
        Manager hereunder, or (b) the gross negligence or willful misconduct by
        Manager or its officers, agents or employees. The mutual indemnity
        obligation hereunder shall survive the termination of this Agreement.`,
  
    entire_agreement_title: 'Entire Agreement.',
    entire_agreement: `This Agreement constitutes the entire agreement and
        understanding between the parties relating to the Property, and all
        prior or contemporaneous or oral statements and agreements are merged
        herein. This Agreement may not be amended or modified except in writing
        and signed by the parties hereto.`,
  
    attorneys_fees_title: "Attorneys' Fees.",
    attorneys_fees: `In the event of a dispute under the provisions of this
        Agreement resulting in litigation or arbitration between the parties,
        the non-prevailing party in such dispute shall reimburse the prevailing
        party for all costs and expenses of such litigation and/or arbitration,
        including, without limitation, reasonable attorneys' fees, court costs
        and other costs of suit.`,
  
    waiver_title: 'Waiver.',
    waiver: `The waiver by any party of any breach or default of this
        Agreement shall not operate as a waiver of any other provision of this
        Agreement nor shall it operate as a waiver of the right to performance
        of the same provision on a subsequent occasion.`,
  
    additional_documents_title: 'Additional Documents.',
    additional_documents: `Each of the parties hereto agree to execute such
        other and further instruments as may be reasonably required to
        effectuate the terms, provisions, and objectives of this Agreement.`,
  
    other_info_confidentiality_title: 'Other Information/Confidentiality.',
    other_info_confidentiality: `Owner(s) or Manager may generate
        publicity, press releases or documentation evidencing activities on the
        site including those related to Manager's management of the Property,
        new leases, promotional events or on-site activities, except to the
        extent prohibited by any agreement, lease or other contract pertaining
        to the Property. Neither party will release information designated in
        writing as confidential to any third party, except that disclosure may
        be made to such party's consultants, accountants, attorneys, officers,
        partners, employees and agents, as well as to any governmental entity,
        agency, or person to whom disclosure is required by applicable law or
        regulation.`,
  
    manager_conflict_waiver_title: 'Manager Conflict Waiver.',
    manager_conflict_waiver: `Owner(s) acknowledges that Manager retains and/or
        may acquire or develop an interest in other commercial property
        (including, without limitation, ownership interests and/or management
        interests). To the extent that the retention or acquisition of such
        interests constitutes or creates a conflict of interest, Owner(s) hereby
        waives such conflict. Similarly, Owner(s) understands that Manager may have
        or obtain property management agreements and/or listing agreements
        respecting other property, and that potential tenants may consider, make
        offers on, or lease through Manager, property the same as or similar to
        the subject Property. Owner(s) consents to Manager's ownership and/or
        representation of other owner(s)' properties before, during and after the
        expiration of this Agreement.`,
  
    notices_title: 'Notices.',
    notices: `Any notice required hereunder shall be made in writing by
        either hand delivery, facsimile, nationally recognized courier, or
        certified mail, return receipt requested. Such notice shall be deemed
        given: (a) at the actual time of receipt if notice is given by hand
        delivery or transmission by facsimile; (b) on the date of delivery as
        set forth on a receipt provided by a nationally recognized courier
        service if notice is given by courier; or (c) three business days after
        sending if notice is given by certified mail, return receipt requested.
        All notices given under this Agreement shall be sent to the parties at
        the addresses set forth below; provided, however, that any party may
        change the address to which notices are to be sent by giving notice of
        such change of address to the other parties in the manner herein
        provided.`,
  
    manager_data_company_name_division: `illi Commercial Real Estate - Property Management Division`,
    manager_data_attn_person_title: `Attn: Keith Corneliuson – Executive Vice President`,
    manager_data_address: `5945 Canoga Ave, Woodland Hills, CA 91367`,
    manager_data_phone_ext: `818 501-2212 x 3001`,
    manager_data_phone_fax: `818.322.1342`,
    manager_data_email: `keith@illicre.com`,
  
    no_partnership_title: 'No Partnership.',
    no_partnership: `Nothing in this Agreement shall constitute or be
        construed to create a partnership, joint venture, or relationship of
        employer and employee between Manager and Owner(s), it being the intention
        of the parties hereto that the relationship created hereby is that of
        independent contractor for the sole and exclusive purpose of renting,
        leasing, operating and managing the Property on behalf of and for the
        benefit of the Owner(s).`,
  
    approval_title: 'Approval.',
    approval: `Wherever Owner(s)’ approval is required pursuant to this
        Agreement, such approval shall not be unreasonably withheld, conditioned
        or delayed, it being agreed that Owner(s) shall not make the ability of the
        Manager to carry out this Agreement impractical or impossible. If Owner(s)
        fails to respond to any such request within ten (10) days of receipt
        thereof, Owner(s) shall be conclusively deemed to have approved and
        consented to the proposed action by Manager, and Manager may proceed
        accordingly.`,
  
    limitation_of_liability_title: 'Limitation of Liability.',
    limitation_of_liability: `Owner(s) agrees that no lawsuit or other legal
        proceeding involving a breach of duty, error or omission relating to the
        services to be performed by Manager pursuant to this Agreement may be
        brought against Manager more than one year after the Expiration Date and
        that the total liability (including courts costs and attorneys' fees) of
        Manager with respect to such lawsuits and/or legal proceedings shall not
        exceed the fees received by Manager pursuant to this Agreement;
        provided, however, that the foregoing limitation on liability shall not
        be applicable to any gross negligence or willful misconduct of Manager.
        Furthermore, no officer, shareholder, member, partner, director,
        employee or agent of Manager shall be personally liable for the
        performance of Manager's obligations under this Agreement.
        Notwithstanding anything to the contrary, Manager shall not be liable
        under any circumstances for consequential or other indirect damages,
        including, without limitation, injury or damage to, or interference
        with, Owner(s)' business, loss of profits, loss of rents or other
        revenues, loss of business opportunity, loss of goodwill or loss of use,
        in each case, however occurring.`,
  
    no_3rd_party_benef_title: 'No Third Parties Beneficiaries.',
    no_3rd_party_benef: `Nothing contained in this Agreement,
        either express or implied, is intended or should be deemed to confer
        upon or give any person or entity, other than the parties hereto or
        their successors and assigns, any rights or remedies under or by reason
        of this Agreement.`,
  
    severability_title: 'Severability.',
    severability: `If any portion of this Agreement as applied to either
        party or to any circumstances shall be adjudged by a court to be void or
        unenforceable, such portion shall be deemed severed from this Agreement
        and shall in no way affect the validity or enforceability of the
        remaining portions of this Agreement.`,
  
    counterpart_facsimiles_title: 'Counterparts and Facsimiles.',
    counterpart_facsimiles: `This Agreement may be executed in multiple
        counterparts, each of which shall be deemed an original, but all of
        which together shall constitute one and the same agreement. Facsimile
        signatures shall have the same force and effect as original signatures.`,
  
    governing_law_title: 'Governing Law.',
    governing_law: `This Agreement shall be governed by and construed in
        accordance with the laws of the state of California. The venue of any
        action or proceeding brought by either party against the other arising
        out of this Agreement shall, to the extent legally permissible, be in
        the county and state in which the Property is located.`,
  
    time_of_essence_title: 'Time of the Essence.',
    time_of_essence: `Time is of the essence in the performance of the
        parties' obligations under this Agreement.`,
  
    successors_assigns_title: 'Successors and Assigns.',
    successors_assigns: `This Agreement shall be binding upon, and shall
        benefit the heirs, successors and assignees of the parties.`,
  
    interpretation_title: 'Interpretation.',
    interpretation: `Words used in the singular shall include the plural, and
        vice versa, and any gender shall be deemed to include the other.
        Whenever the words “including”, “include” or “includes” are used in this
        Agreement, they should be interpreted in a non-exclusive manner. The
        captions and headings of the paragraphs of this Agreement are for
        convenience of reference only, and shall not be deemed to define or
        limit the provisions hereof. Each party acknowledges and agrees that
        this Agreement (a) has been reviewed by it and its counsel; (b) is the
        product of negotiations between the parties, and (c) shall not be deemed
        prepared or drafted by any one party. In the event of any dispute
        between the parties concerning this Agreement, the parties agree that
        any ambiguity in the language of the Agreement is to not to be resolved
        against the Owner(s) or Manager, but shall be given a reasonable
        interpretation in accordance with the plain meaning of the terms of this
        Agreement and the intent of the parties as manifested hereby.`,
  
    managers_employees_title: "Manager's Employees.",
    managers_employees: `Owner(s) understands and agrees that Manager has
        expended (or will have expended) great amounts of time and effort in the
        selection, hiring and training of its employees and that Manager's
        business, and the conduct thereof, is dependent to a large extent upon
        maintaining and retaining employees who have been trained by Manager and
        that Manager faces extreme hardship and monetary loss whenever such
        employees leave its service. For the above reasons, Owner(s) agrees that it
        shall not, directly or indirectly, during the Term of this Agreement or
        for a period of twelve (12) months after the expiration of the Term of
        this Agreement, without the prior written consent of the President of
        Manager, (a) induce, entice or solicit employees of Manager to leave
        their employment, or (b) hire, employ or utilize the services of any
        employee of Manager. The parties agree that in the event of a breach of
        the covenants in this paragraph, the resulting actual damages to Manager
        would be costly, extremely difficult and inconvenient for the parties to
        determine. Therefore, the parties agree that in addition to Manager's
        right to any available equitable remedy, Owner(s) shall pay to Manager
        liquidated damages in the amount of Twenty-Five Thousand and No/100
        Dollars ($25,000) for each employee of Manager employed or utilized in
        violation of this paragraph. The parties agree that the amounts stated
        herein as liquidated damages are reasonable under the circumstances
        existing as of the date of this Agreement. Notwithstanding anything to
        the contrary above, Owner(s) may hire any employee terminated by Manager
        upon termination of said employment.`,
  
    arbitration_of_disputes_title: 'Arbitration of Disputes.',
    arbitration_of_disputes: `IF ANY DISPUTE ARISES CONCERNING THE
        INTERPRETATION, VALIDITY, OR PERFORMANCE OF THIS AGREEMENT OR ANY OF ITS
        TERMS AND PROVISIONS, INCLUDING BUT NOT LIMITED TO THE ISSUE OF WHETHER
        OR NOT A DISPUTE IS ARBITRABLE, THEN THE PARTIES SHALL SUBMIT SUCH
        DISPUTE FOR BINDING DETERMINATION BEFORE A RETIRED JUDGE SELECTED FROM
        JAMS/ENDISPUTE’S OFFICE LOCATED NEAREST TO THE PROPERTY, OR ANY SIMILAR
        ORGANIZATION MUTUALLY ACCEPTABLE TO THE PARTIES (THE "ARBITRATING
        ORGANIZATION"). IF THE PARTIES ARE UNABLE TO AGREE UPON A SINGLE
        ARBITRATOR FROM THE LIST OF ARBITRATORS PROVIDED BY THE ARBITRATING
        ORGANIZATION, THEN THE ARBITRATING ORGANIZATION SHALL PROVIDE THE
        PARTIES WITH A LIST OF THREE (3) NAMES OF POSSIBLE ARBITRATORS, AND EACH
        PARTY SHALL STRIKE THE NAME OF ONE ARBITRATOR FROM THE LIST, AND THE
        REMAINING ARBITRATOR SHALL SERVE AS THE ARBITRATOR OF THE DISPUTE. IF A
        PARTY FAILS TO PROVIDE NOTICE OF THE ARBITRATOR IT DESIRES TO STRIKE
        FROM THE LIST WITHIN FIFTEEN (15) DAYS FOLLOWING RECEIPT OF SUCH LIST,
        THEN THE OTHER PARTY SHALL HAVE THE RIGHT TO DESIGNATE THE ARBITRATOR
        FROM THE REMAINING TWO NAMES ON THE LIST. THE ARBITRATION SHALL TAKE
        PLACE IN LOS ANGELES COUNTY, CALIFORNIA AND SHALL BE CONDUCTED IN
        ACCORDANCE WITH THE THEN PREVAILING RULES OF THE ARBITRATING
        ORGANIZATION, EXCEPT AS SET FORTH IN THIS PARAGRAPH. THE PARTIES SHALL
        HAVE ALL RIGHTS FOR DEPOSITIONS AND DISCOVERY AS SET FORTH IN SECTION
        1283.05 OF THE CALIFORNIA CODE OF CIVIL PROCEDURE. THE ARBITRATOR SHALL
        APPLY CALIFORNIA SUBSTANTIVE LAW AND THE CALIFORNIA EVIDENCE CODE TO THE
        PROCEEDING. THE ARBITRATOR SHALL HAVE THE POWER TO GRANT ALL LEGAL AND
        EQUITABLE REMEDIES INCLUDING PROVISIONAL REMEDIES AND AWARD COMPENSATORY
        DAMAGES PROVIDED BY CALIFORNIA LAW, BUT THE ARBITRATOR MAY NOT ORDER
        RELIEF IN EXCESS OF WHAT A COURT COULD ORDER OR OTHERWISE EXPRESSLY NOT
        PERMITTED UNDER THIS AGREEMENT. THE ARBITRATOR SHALL NOT HAVE AUTHORITY
        TO AWARD PUNITIVE OR EXEMPLARY DAMAGES. THE ARBITRATOR SHALL NOT HAVE
        THE POWER TO COMMIT ERRORS OF LAW OR LEGAL REASONING OR TO MAKE FINDINGS
        OF FACT EXCEPT UPON SUFFICIENCY OF THE EVIDENCE AND ANY AWARD MAY BE
        VACATED OR CORRECTED FOR ANY SUCH ERROR. THE ARBITRATOR SHALL PREPARE
        AND PROVIDE THE PARTIES WITH A WRITTEN AWARD INCLUDING FACTUAL FINDINGS
        AND THE LEGAL REASONING UPON WHICH THE AWARD IS BASED. THE ARBITRATOR
        SHALL AWARD COSTS AND ATTORNEYS’ FEES IN ACCORDANCE WITH THE TERMS OF
        THIS AGREEMENT. JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR MAY BE
        ENTERED IN ANY COURT HAVING JURISDICTION. THE PARTIES UNDERSTAND THAT BY
        AGREEMENT TO BINDING ARBITRATION THEY ARE GIVING UP THE RIGHTS THEY MAY
        OTHERWISE HAVE TO A TRIAL BY A COURT OR A JURY AND ALL RIGHTS OF APPEAL,
        AND TO AN AWARD OF PUNITIVE OR EXEMPLARY DAMAGES. PENDING RESOLUTION OF
        ANY ARBITRATION PROCEEDING, EITHER PARTY MAY APPLY TO ANY COURT OF
        COMPETENT JURISDICTION FOR ANY PROVISIONAL REMEDY, INCLUDING BUT NOT
        LIMITED TO A TEMPORARY RESTRAINING ORDER OR A PRELIMINARY INJUNCTION BUT
        EXCLUDING ANY DISPUTE RELATING TO DISCOVERY MATTERS, AND FOR ENFORCEMENT
        OF ANY SUCH ORDER. THE APPLICATION FOR OR ENFORCEMENT OF ANY PROVISIONAL
        REMEDY BY A PARTY SHALL NOT OPERATE AS A WAIVER OF THE AGREEMENT TO
        SUBMIT A DISPUTE TO BINDING ARBITRATION. Owner(s) warrants that it is the
        sole owner(s) of the Property (or on the Commencement Date will be the sole
        Owner(s) of the Property) and has the authority to execute this Agreement.
        Owner(s) acknowledges that Owner(s) has read, understands, accepts and has
        received a copy of this Agreement.`,
  
    note_conversion_of_docs: `illi CRE converts all leases and documents to electronic documents
        and stores them securely in our Cloud Storage system that has multiple
        back-ups in various cities. The Department of Real Estate and the Court
        Systems allow and accept electronic documents and the need for “inked”
        copies is in the past. That said, some clients still prefer the original
        hard copies and we are happy to accommodate that and will forward those
        to you should you desire. Please make an election by placing an “X” in
        one of the boxes below to indicate whether you would like the original
        paper documents to be forwarded to you acknowledge that otherwise, we
        will shred the paper documents after storing them electronically.`,
  
    in_witness_whereof: `IN WITNESS WHEREOF, the parties hereby execute this Agreement with an
        Effective Date as first written above. `,

    additional_signatures: [],
    additional_initials: [],
}

export default originalTextData;