import React, { useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { List, ListItem, ListItemIcon, ListItemText, Dialog } from "@material-ui/core";
import { generateSections, sectionNames, checkIfCurrentUserIsSubmitter, checkIfCurrentUserIsAnAgentApprover } from "./CommissionSplitsLogic";
import { ArrowBack, ArrowForward, Close } from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step3a from "./Step3a";
import { getUser } from "../../shared/authValidation";

const getUserPerm = (user) => {
    if (user.permObj) {
        return Object.keys(user.permObj);
    }
    return [];
}

const CommissionSplitsFormWizard = (props) => {
    const [openedChooseCRMModal, setOpenedChooseCRMModal] = useState(false);
    const { setPropertyOwner, setOpenedPropertyModal, renderElement, 
        setStep, step } = props;

    let isAgent = false;
    const usr = getUser();
    if (usr.type === 'A') {
        isAgent = true;
    }

    const user = getUser();
    const userPerms = getUserPerm(user);
    const hasReadAccess = userPerms.indexOf('CRM agent_access_illi_db') !== -1 ? true : false;
    const hasFullAccess = userPerms.indexOf('CRM agent_access_illi_db_full') !== -1 ? true : false;
    console.log('uuuus', user, hasFullAccess)
    const currentUserIsSubmitter = checkIfCurrentUserIsSubmitter(user, props.history);
    const currentUserIsAnAgentApprover = checkIfCurrentUserIsAnAgentApprover(user, props.history);

    const renderNav = () => {
        const section_count = Object.keys(sectionNames).length;
        return (
            <div style={{ marginBottom: 10 }}>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        props.save();
                        setStep(step - 1);
                    }}
                    disabled={step === 1 ? true : false}
                >
                    <ArrowBack /> Previous
                </Button>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        props.save();
                        if (step < section_count) return setStep(step + 1);
                        props.setTab("full");
                    }}
                >
                    Next <ArrowForward />
                </Button>
                {(props.parentTab !== 'my_completed' && props.parentTab !== 'all_completed') && <span>
                    {((props.action !== 'view') && currentUserIsSubmitter) && (
                        <Button
                            color="white"
                            className="mr-20"
                            onClick={() => {
                                props.save();
                            }}
                        >
                            SAVE
                        </Button>
                    )}
                </span>}
            </div>
        );
    };

    const sections = generateSections(props.dataObj, props.parentTab);
    const finalActions = ["SUBMIT", "APPROVE / REJECT"];
    console.log('CommissionSplitsFormWizard', props)

    const openIlli = () => {
        setPropertyOwner("illi");
        setOpenedPropertyModal(true);
    }

    return <div>
        <GridContainer>
            <GridItem xs={12} sm={9} md={9}>
                <h3>
                    <span className="font-10">
                        {step} of {Object.keys(sectionNames).length}
                    </span>{" "}
                    {sectionNames[step]}
                </h3>
                {renderNav()}
                <hr />
                {step === 1 && <Step1 
                    setOpenedChooseCRMModal={setOpenedChooseCRMModal}
                    openIlli={openIlli}
                    dataObj={props.dataObj}
                    updateDataObj={props.updateDataObj}
                    renderElement={renderElement}
                    currentUserIsSubmitter={currentUserIsSubmitter}
                    currentUserIsAnAgentApprover={currentUserIsAnAgentApprover}
                    hasBeenCompletedBefore={props.hasBeenCompletedBefore}
                    parentTab={props.parentTab}
                />}
                {step === 2 && <Step2
                    dataObj={props.dataObj}
                    updateDataObj={props.updateDataObj}
                    setDataObj={props.setDataObj}
                    setOpenedChooseModal={props.setOpenedChooseModal}
                    setQueryFor={props.setQueryFor}
                    currentUserIsSubmitter={currentUserIsSubmitter}
                    currentUserIsAnAgentApprover={currentUserIsAnAgentApprover}
                    setOpenedChooseAssociateModal={props.setOpenedChooseAssociateModal}
                    parentTab={props.parentTab}
                    hasBeenCompletedBefore={props.hasBeenCompletedBefore}
                />}

                {step === 3 && <Step3 
                    dataObj={props.dataObj}
                    updateDataObj={props.updateDataObj}
                    setOpenedChooseModal={props.setOpenedChooseModal}
                    setQueryFor={props.setQueryFor}
                    setDataObj={props.setDataObj}
                    currentUserIsSubmitter={currentUserIsSubmitter}
                    currentUserIsAnAgentApprover={currentUserIsAnAgentApprover}
                    parentTab={props.parentTab}
                    hasBeenCompletedBefore={props.hasBeenCompletedBefore}
                />}

                {step === 4 && <Step3a 
                    dataObj={props.dataObj}
                    updateDataObj={props.updateDataObj}
                    setOpenedChooseModal={props.setOpenedChooseModal}
                    setQueryFor={props.setQueryFor}
                    setDataObj={props.setDataObj}
                    currentUserIsSubmitter={currentUserIsSubmitter}
                    currentUserIsAnAgentApprover={currentUserIsAnAgentApprover}
                    parentTab={props.parentTab}
                />}
                
                {step === 5 && <Step4 
                    dataObj={props.dataObj}
                    updateDataObj={props.updateDataObj}
                    all_potential_reviewers={props.all_potential_reviewers}
                    history={props.history}
                    createCommissionSplitsHistory={props.createCommissionSplitsHistory}
                    getCommissionSplitsHistory={props.getCommissionSplitsHistory}
                    entryData={props.entryData}
                    success_history={props.success_history}
                    save={props.save}
                    updateCommissionSplit={props.updateCommissionSplit}
                    cancel={props.cancel}
                    currentUserIsSubmitter={currentUserIsSubmitter}
                    currentUserIsAnAgentApprover={currentUserIsAnAgentApprover}
                    parentTab={props.parentTab}
                    action={props.action}
                />}

                {step === 6 && <Step5
                    dataObj={props.dataObj}
                    final_approvers={props.final_approvers}
                    setDataObj={props.setDataObj}
                    currentUserIsAnAgentApprover={currentUserIsAnAgentApprover}
                    save={props.save}
                    parentTab={props.parentTab}
                />}
                
                <hr />
                {renderNav()}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {/* Tracker */}
                <List component="nav">
                    {sections.map((s, i) => {
                        const name = s.name;
                        if (props.parentTab === 'pending_associate_review') {
                            if (name === 'Final Approver') return null;
                        }
                        return (
                            <ListItem
                                key={`li-${i}`}
                                button
                                onClick={() => {
                                    setStep(i + 1);
                                }}
                            >
                                {finalActions.indexOf(s.name) === -1 && (
                                    <ListItemIcon>
                                        {s.complete ? (
                                            <i
                                                className={`fas fa-check font-22 ${
                                                    step - 1 === i ? "bold red-text" : ""
                                                }`}
                                            ></i>
                                        ) : (
                                            <i
                                                className={`fas fa-times font-22 ${
                                                    step - 1 === i ? "bold red-text" : ""
                                                }`}
                                            ></i>
                                        )}
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    inset
                                    primary={<div className={step - 1 === i ? "bold red-text" : ""}>{name}</div>}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </GridItem>
        </GridContainer>

        {openedChooseCRMModal && (
                <Dialog
                    open={openedChooseCRMModal}
                    onClose={() => {
                        setOpenedChooseCRMModal(false);
                    }}
                >
                    <div className="dialog-wrapper">
                        <div
                            className="close-btn"
                            onClick={() => {
                                setOpenedChooseCRMModal(false);
                            }}
                        >
                            <Close />
                        </div>
                        <h4 className="text-center">Choose Property Database</h4>
                        {(!isAgent || (isAgent && (hasFullAccess || hasReadAccess))) && <Button
                            color="primary"
                            className="mr-20"
                            onClick={() => {
                                setPropertyOwner("illi");
                                setOpenedChooseCRMModal(false);
                                setOpenedPropertyModal(true);
                            }}
                        >
                            illi Database
                        </Button>}
                        {<Button
                            color="primary"
                            onClick={() => {
                                if (isAgent) {
                                    setPropertyOwner("own");
                                    setOpenedChooseCRMModal(false);
                                    setOpenedPropertyModal(true);
                                }
                            }}
                            style={{opacity: isAgent ? 1 : 0}}
                        >
                            Your Database
                        </Button>}
                    </div>
                </Dialog>
            )}
    </div>
}

export default CommissionSplitsFormWizard;