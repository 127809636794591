import React, { useState } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import { clone } from 'shared/utility';
import CustomDialogChooser from 'components/CustomDialog/CustomDialogChooser';
import { isEmpty, get } from 'lodash';
import { getContactAddress } from '../../../shared/utility';

const Billing = (props) => {
    const [openedCustomDialogChooser, setOpenedCustomDialogChooser] = useState(false);
    const {
        renderElement, 
        data, 
        setData 
    } = props;

    const choose = (chosen, a, b) => {
        console.log('chose billing', chosen, a, b);
        const signers = get(chosen, 'signers', []);
        const company_data = get(chosen, 'company_data', null);
        // TYPES OF DATA FOR INTEGRATION / SCENARIOS
        // -----------------------------------------
        // 1. 1 Company only,
        // 2. 1 Company with  1 contact,
        // 3. 1 Contact only or Contact with company 
        // 4. 1 Property Manager - Company only
        // 5. 1 Property Manager - Contact only
        // 6. 1 Property Manager - Company with 1 contact

        // -----------------------------
        // 1. 1 Company only
        // -----------------------------
        if (company_data && signers.length === 0) {
            if (!isEmpty(company_data)) {
                const c = company_data;
                const obj = {};
                obj.company_id = c.id;
                obj.company = c.name;
                obj.address1 = c.mailing_address1;
                obj.address2 = c.mailing_address2;
                obj.city = c.mailing_city;
                obj.state = c.mailing_state;
                obj.zip = c.mailing_zip;
                obj.phone = c.main_phone;
                obj.fax = c.fax;
                obj.email = c.email;

                // company with DBA
                if (chosen.dba_data && chosen.dba_id) {
                    if (chosen.dba_data.length > 0) {
                        chosen.dba_data.forEach(dba => {
                            if (dba.id === chosen.dba_id) {
                                obj.dba_name = dba.name;
                                obj.dba_id = dba.id;
                                obj.dba_active = 1;
                                obj.dba_type = 'company';
                            }
                        })
                    }
                }

                // CARE OF COMPANY / PROPERTY MANAGEMENT 
                if (chosen.care_of_company) {
                    const coc = chosen.care_of_company;
                    obj.company_id = coc.id;
                    // COMPANY DATA
                    obj.company = `${c.name} c/o ${coc.name}`;
                    obj.address1 = coc.mailing_address1;
                    obj.address2 = coc.mailing_address2;
                    obj.city = coc.mailing_city;
                    obj.state = coc.mailing_state;
                    obj.zip = coc.mailing_zip;
                    obj.fax = coc.fax;
                    obj.email = coc.email;
                }

                const newData = clone(data);
                Object.keys(obj).forEach(field => {
                    newData['billing_'+field] = obj[field];
                })
                console.log('new data', newData);
                setData(newData);
                setOpenedCustomDialogChooser(false);
            }
            
        } else if (company_data && signers.length > 0) {
            // -----------------------------
            // 2. 1 Company with  1 contact 
            // -----------------------------
            const s = signers[0];
            const d = s.company_dats ? s.company_dats : {};
            const c = company_data;
            const obj = {};
            obj.company_id = c.id;
            obj.contact_id = s.id;
            // COMPANY DATA
            obj.company = c.name;
            obj.address1 = c.mailing_address1;
            obj.address2 = c.mailing_address2;
            obj.city = c.mailing_city;
            obj.state = c.mailing_state;
            obj.zip = c.mailing_zip;
            obj.phone = c.main_phone;
            obj.fax = c.fax;
            // COMPANY CONTACT's DATA
            obj.attention = s.first_name;
            obj.attention_lastname = s.last_name;
            obj.phone = d.phone ? d.phone : '';
            obj.email = d.email ? d.email : '';

            // DBA DATA
            if (chosen.dba_data && chosen.dba_id) {
                if (chosen.dba_data.length > 0) {
                    chosen.dba_data.forEach(dba => {
                        if (dba.id === chosen.dba_id) {
                            obj.dba_name = dba.name;
                            obj.dba_id = dba.id;
                            obj.dba_active = 1;
                            obj.dba_type = 'company';
                        }
                    })
                }
            }

            // CARE OF COMPANY / PROPERTY MANAGEMENT 
            if (chosen.care_of_company) {
                const coc = chosen.care_of_company;
                obj.company_id = coc.id;
                // COMPANY DATA
                obj.company = `${c.name} c/o ${coc.name}`;
                obj.address1 = coc.mailing_address1;
                obj.address2 = coc.mailing_address2;
                obj.city = coc.mailing_city;
                obj.state = coc.mailing_state;
                obj.zip = coc.mailing_zip;
                obj.fax = coc.fax;
            }

            // COMPANY CONTACT's DATA either Company Contact or Property Contact
            // 1 Property Manager - Company with 1 contact
            // 1 Property Manager - Company only
            if (chosen.signers) {
                if (chosen.signers.length > 0) {
                    const s = chosen.signers[0];
                    obj.attention = s.first_name;
                    obj.attention_lastname = s.last_name;
                    obj.phone = s.main_phone ? s.main_phone : '';
                    obj.email = d.email ? d.email : '';
                    const cs = s.company_dats ? s.company_dats : null;
                    if (cs) {
                        obj.phone = cs.phone ? cs.phone : '';
                        obj.email = cs.email ? cs.email : '';
                    }
                }
            }

            const newData = clone(data);
            Object.keys(obj).forEach(field => {
                newData['billing_'+field] = obj[field];
            })
            setData(newData);
            setOpenedCustomDialogChooser(false);
        } else if (chosen.first_name) {
            // ------------------
            // 3. 1 Contact only or Contact with company 
            // ------------------
            const a = getContactAddress(chosen);
            const obj = {};
            const c = chosen;
            obj.contact_id = c.id;
            obj.address1 = a.street1;
            obj.address2 = a.street2;
            obj.city = a.city;
            obj.state = a.state;
            obj.zip = a.zip;
            obj.phone = c.main_phone;
            obj.phone_type = 'Main';
            if (c.overrideContactPhone) {
                obj.phone = c.overrideContactPhone.phone;
                obj.phone_type = c.overrideContactPhone.type;
            }
            obj.fax = c.fax;
            obj.attention = c.first_name;
            obj.attention_lastname = c.last_name;
            obj.email = c.email ? c.email : '';

            // 1 Property Manager - Contact only
            if (chosen.overrideContacts) {
                if (chosen.overrideContacts.length > 0) {
                    const o = chosen.overrideContacts[0];
                    const aa = getContactAddress(o);
                    obj.contact_id = c.id;

                    // obj.address1 = aa.street1;
                    // obj.address2 = aa.street2;
                    // obj.city = aa.city;
                    // obj.state = aa.state;
                    // obj.zip = aa.zip;

                    obj.phone = o.main_phone;
                    obj.fax = o.fax;
                    obj.attention = o.first_name;
                    obj.attention_lastname = o.last_name;
                    obj.email = c.email ? o.email : '';
                    if (o.company_dats) {
                        obj.email = o.company_dats.email;
                        obj.phone = o.company_dats.phone;
                    }
                }
            } else {
                // CONTACT DBA
                obj.attention_dba_name = b?.name;
                obj.attention_dba_id= b?.id;
                obj.attention_dba_active = b?.active;
                obj.attention_dba_type = 'contact';
            }

            let company = chosen?.company_dats?.company_data;
            
            // 1 Property Manager - Company only
            if (chosen.overrideCompany) {
                company = chosen.overrideCompany;
            } else {
                // COMPANY DBA
                const companyDba = chosen?.company_dbas?.find(dba => dba.id === chosen?.company_dba_id);
                if (companyDba) {
                    obj.dba_name = companyDba.name;
                    obj.dba_id = companyDba?.id;
                    obj.dba_active = companyDba?.active;
                    obj.dba_type = 'company';
                }
            }

            if (company) {
                obj.company_id = company.id;
                obj.company = company.name;
                obj.address1 = company.mailing_address1;
                obj.address2 = company.mailing_address2;
                obj.city = company.mailing_city;
                obj.state = company.mailing_state;
                obj.zip = company.mailing_zip;
                if (!obj.phone) obj.phone = company.main_phone;
                obj.fax = company.fax;
            }

            const newData = clone(data);
            Object.keys(obj).forEach(field => {
                newData['billing_'+field] = obj[field];
            })
            console.log('new data', newData);
            setData(newData);
            setOpenedCustomDialogChooser(false);
        }

    }

    const hasBillingData = (data.billing_id || data.billing_contact_id || data.billing_company_id);

    console.log('Billing', props);

    return <>
    <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
        <GridContainer>
                {/* Billing Information */}
                <GridItem xs={12} sm={12} md={12}>
                    {!hasBillingData && <Button color="primary" onClick={() => {
                        setOpenedCustomDialogChooser(true);
                    }}>SELECT BILLING</Button>}
                    {hasBillingData && <Button color="success" onClick={() => {
                        const newData = clone(data);
                        [
                        'id',
                        'company',
                        'attention',
                        'attention_lastname',
                        'address1',
                        'address2',
                        'city',
                        'state',
                        'zip',
                        'phone',
                        'phone_type',
                        'fax',
                        'email',
                        'dba_type',
                        'dba_name',
                        'dba_id',
                        'dba_active',
                        'attention_dba_active',
                        'attention_dba_id',
                        'attention_dba_name',
                        'attention_dba_type',
                        'company_id',
                        'contact_id',
                        'flat_fee_commission_amount'
                        ].forEach(fieldy => {
                            if (fieldy === 'flat_fee_commission_amount') {
                                newData[fieldy] = '';
                            } else {
                                const field = `billing_${fieldy}`;
                                newData[field] = '';
                            }
                        })

                        setData(newData);
                    }}>REMOVE BILLING</Button>}
                </GridItem>
        </GridContainer>
        {hasBillingData && <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {data.billing_company && <div className="no-click" style={{backgroundColor:'#f8f8f8'}}>
                        {renderElement('billing_company')}
                    </div>}
                </GridItem>
                {data.billing_dba_name && 
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            label='Billing Company DBA'
                            value={data.billing_dba_name}
                            readonly
                            readonly_show_gray
                            endAdornment={data.billing_dba_active === 0 && <span style={{color: 'red', fontWeight: 'bold', fontSize: '.7em'}}>(removed)</span> }
                        />
                    </GridItem>
                }
                <GridItem xs={12} sm={2} md={2}>
                    {data.billing_attention && <div className="no-click" style={{backgroundColor:'#f8f8f8'}}>
                        {renderElement('billing_attention')}
                    </div>}
                </GridItem>
                <GridItem xs={12} sm={2} md={2}>
                    {data.billing_attention_lastname && <div className="no-click" style={{backgroundColor:'#f8f8f8'}}>
                        {renderElement('billing_attention_lastname')}
                    </div>}
                </GridItem>
                {data.billing_attention_dba_name && 
                    <GridItem xs={12} sm={2} md={2}>
                        <CustomInput
                            label='Attention DBA'
                            value={data.billing_attention_dba_name}
                            readonly
                            readonly_show_gray
                            endAdornment={data.billing_attention_dba_active === 0 && <span style={{color: 'red', fontWeight: 'bold', fontSize: '.7em'}}>(removed)</span> }
                        />
                    </GridItem>
                }
                <GridItem xs={12} sm={3} md={3}></GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement('billing_address1')}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement('billing_address2')}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement('billing_city')}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement('billing_state')}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement('billing_zip')}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement('billing_fax')}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement('billing_email')}
                </GridItem>
                {props.rsType === 'consulting' && <GridItem xs={12} sm={3} md={3}>
                    {renderElement('flat_fee_commission_amount')}
                </GridItem>}
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    {data.billing_phone_type && <strong>{data.billing_phone_type} Phone :</strong>}
                    {renderElement('billing_phone')}
                </GridItem>
        </GridContainer>}

        {openedCustomDialogChooser && <CustomDialogChooser 
                open={openedCustomDialogChooser}
                close={() => {
                    setOpenedCustomDialogChooser(false);
                }}
                choose={choose}
                showContactCompaniesTab={true}
                create_and_choose={choose}
                title="Select Billing Contact or Company"
                label="Search Billing Contact/Company"
                no_property={true}
                queryFor="billing_information"
                allowNoContacts={true}
        />}
    </div>
    </>
}

export default Billing;